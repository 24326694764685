import React, { Component } from 'react'

export class PatientHistory extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    componentDidMount() {
        this.setState({
          patientProfile: this.props.patientProfile,
        });
      }
    
      componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
          this.setState({
            patientProfile: this.props.patientProfile,
          });
        }
      }
    
    render() {
        const { patientProfile } = this.state;

        return (
          <form>
            <div className="card-body custom-inner-content  ">
              <h5 className="stepper-form-headings">
                Patient History
              </h5>

              {
                  patientProfile?.PatientHistory === null ? <h4 className="font-italic">No Patient History Information Added</h4> : <>
                  
                  <label className="text-left stepper-form-sub-headings">
               Have you had any of the following problems in the past year?
              </label>
              <div className="row mb-4 mt-3">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Abdominal pain/bloating</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-4"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.abdominal}
                    />
                    <label htmlFor="switch-4" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                {/* (heartburn) */}
                  <p className="text-left">Acid reflux </p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-5"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.acid}
                    />
                    <label htmlFor="switch-5" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Alcoholism</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-6"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.alcoholism}
                    />
                    <label htmlFor="switch-6" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Allergies</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-7"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.allergies}
                    />
                    <label htmlFor="switch-7" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Anxiety</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-8"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.anxiety}
                    />
                    <label htmlFor="switch-8" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Asthma</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-9"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.asthma}
                    />
                    <label htmlFor="switch-9" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Atrial fibrillation</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-10"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.atrialFibrillation}
                      
                    />
                    <label htmlFor="switch-10" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Bladder/Kidney/Urinary problems</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-11"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.bladderKidneyUrinaryProblem}
                      
                    />
                    <label htmlFor="switch-11" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Breast problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-12"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.breastProblem}
                    />
                    <label htmlFor="switch-12" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Cancer</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-13"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.cancer}
                    />
                    <label htmlFor="switch-13" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">
                  {/* (bleeding or clotting) */}
                    Coagulation  problems
                  </p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-14"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.coagulationProblem}
                     
                    />
                    <label htmlFor="switch-14" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                  <p className="text-left">Cholesterol problem</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-15"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.cholesterolProblem}
                      
                    />
                    <label htmlFor="switch-15" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Chronic low back pain</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-16"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.chronicLowBackPain}
                      
                    />
                    <label htmlFor="switch-16" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                  <p className="text-left">Constipation/diarrhea</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-17"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.constipationDiarrhea}
                      
                    />
                    <label htmlFor="switch-17" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Coordination problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-18"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.coordinationProblem}
                      
                    />
                    <label htmlFor="switch-18" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                  <p className="text-left">Depression</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-19"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.depression}
                    />
                    <label htmlFor="switch-19" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Diabetes mellitus</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-20"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.diabetesMellitus}
                      
                    />
                    <label htmlFor="switch-20" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Dizziness</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-21"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.dizziness}
                    />
                    <label htmlFor="switch-21" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Erectile dysfunction</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-22"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.erectileDysfunction}
                      
                    />
                    <label htmlFor="switch-22" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Fatigue/weakness</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-23"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.fatigueWeakness}
                    />
                    <label htmlFor="switch-23" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Headaches</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-24"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.headaches}
                    />
                    <label htmlFor="switch-24" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">
                    Heart disease/chest pain/rhythm disturbance
                  </p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-25"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.heartDisease}
                    />
                    <label htmlFor="switch-25" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Hypertension (high blood pressure)</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-26"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.hypertension}
                    />
                    <label htmlFor="switch-26" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Irritable bowel syndrome</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-27"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.irritableBowelSyndrome}
                      
                    />
                    <label htmlFor="switch-27" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Memory loss</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-28"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.memoryLoss}
                    />
                    <label htmlFor="switch-28" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Migraines</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-29"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.migraines}
                    />
                    <label htmlFor="switch-29" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Muscle/joint pain or swelling</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-30"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.musclePain}
                    />
                    <label htmlFor="switch-30" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Numbness</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-31"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.numbness}
                    />
                    <label htmlFor="switch-31" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Osteopenia or Osteoporosis</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-32"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.osteopenia}
                    />
                    <label htmlFor="switch-32" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Pain</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-33"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.pain}
                    />
                    <label htmlFor="switch-33" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Prostate problem</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-34"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.prostateProblem}
                    />
                    <label htmlFor="switch-34" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Sexual function problems</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-35"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.sexualFunctionProblems}
                      
                    />
                    <label htmlFor="switch-35" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Skin problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-36"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.skinProblems}
                    />
                    <label htmlFor="switch-36" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Sleep problems</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-37"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.sleepProblems}
                    />
                    <label htmlFor="switch-37" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Substance abuse</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-38"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.substanceAbuse}
                    />
                    <label htmlFor="switch-38" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                  <p className="text-left">Thyroid problem</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-39"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.thyroidProblem}
                    />
                    <label htmlFor="switch-39" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Vision/hearing problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-40"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.visionHearingProblem}
                      
                    />
                    <label htmlFor="switch-40" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Weight loss/gain</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-41"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.weightLossGain}
                    />
                    <label htmlFor="switch-41" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Other problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-42"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.PatientHistory?.otherProblems}
                    />
                    <label htmlFor="switch-42" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
                  
                   </> }
              
           
    

            
            
            </div>
          </form>
        );
      }
}

export default PatientHistory
