import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from "../../../../environment";
import { withRouter } from "react-router-dom";

export class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: "",
      patientStatus: this.props.status,
      userID:  this.props.userID,
      token: localStorage.getItem("token"),
      disabled: true,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }
  componentDidMount() {
    this.getSummary()
  }

  handleUserInputChange(field, e) {
    this.setState({ note: e.target.value ,disabled: false});
  }

  getSummary = async() => {
    const data = this.state.patientStatus === "physician" ? "intake/summary?userId="+this.state.userID : "intake/summary";

    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.data.data.note !== "") {
          if (response.data.data.note !== null) {
            this.setState({
              note: response.data.data.note,
            });
          }
        }
      })
      .catch((e) => {
      });
  }

  onSubmit = async () => {
     const params = {
      note: this.state.note,
     };
      const patientParams = {
        note: this.state.note,
        userId: this.state.userID
      }
    
    const data = this.state.patientStatus === "physician" ?  patientParams : params;

    await axios.post(environment.endPoint+"intake/summary", data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 500,
          });
          
          this.callCompleted();
        }
      })
      .catch((e) => {
        // if (e.response.status === 400) {
        //   toast.error("Patient History Not Updated", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 3000,
        //   });
        // }
      });
  }

  callCompleted() {
    const queryParams = this.state.patientStatus === "physician" ? "/physician/dashboard" : "/dashboard"
    this.props.history.push(queryParams)
    this.setState({
      disabled: true
    });
    var myButtonClasses = document.getElementById("summary").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
  }
  render() {
    return (
   
   <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">Summary</h5>
          <h4 className="stepper-form-sub-headings mb-4">
            Please tell us in your own words what you feel is most important
          </h4>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <textarea
                  className="form-control custom-form-control"
                  placeholder="Please tell us in your own words what you feel is most important."
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  value={this.state.note}
                  onChange={this.handleUserInputChange.bind(this, "note")}
                ></textarea>

                <label className="label-helper py-2 px-1">
                  Please tell us in your own words what you feel is most
                  important.
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit}
                disabled={this.state.disabled ? true: false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
            
          </div>
        </div>
      </form>
   
   
   );
  }
}

export default withRouter(Summary);
