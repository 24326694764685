import React, { PureComponent } from "react";

export class Contact extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      patientProfile: {},
    };
  }
  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }

  render() {
    const { patientProfile } = this.state;

    return (
      <div className="card-body custom-inner-content  ">
        <h5 className="stepper-form-headings ">Contacts</h5>

        {patientProfile?.EmergencyContact === null ?  <>
           <h4 className="font-italic">No Contact Information Added</h4>
          </> :        
         
        <div className="row">
          <div className="col-lg-12 col-12">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.firstName === null ||
                       patientProfile?.EmergencyContact?.firstName === "" ?
                        "field-not-found" : "field-label"}`}>
                      Emergency Contact First Name:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.firstName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.lastName === null ||
                       patientProfile?.EmergencyContact?.lastName === "" ?
                        "field-not-found" : "field-label"}`}>
                      Emergency Contact Last Name:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.lastName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.contactPhone === null ||
                       patientProfile?.EmergencyContact?.contactPhone === "" ?
                        "field-not-found" : "field-label"}`}>
                      Emergency Contact Phone :
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.contactPhone}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.relationship === null ||
                       patientProfile?.EmergencyContact?.relationship === "" ?
                        "field-not-found" : "field-label"}`}>
                    Emergency Contact Relationship:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.relationship}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.physicianFirstName === null ||
                       patientProfile?.EmergencyContact?.physicianFirstName === "" ?
                        "field-not-found" : "field-label"}`}>
                    Physician First Name:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.physicianFirstName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.physicianLastName === null ||
                       patientProfile?.EmergencyContact?.physicianLastName === "" ?
                        "field-not-found" : "field-label"}`}>
                    Physician Last Name:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.physicianLastName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.physicianPhoneNumber === null ||
                       patientProfile?.EmergencyContact?.physicianPhoneNumber === "" ?
                        "field-not-found" : "field-label"}`}>
                      Primary Care Physcian Phone:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.physicianPhoneNumber}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.physicianKnowCannabisUse === null ||
                       patientProfile?.EmergencyContact?.physicianKnowCannabisUse === "" ?
                        "field-not-found" : "field-label"}`}>
                     Does your physician know you wiil be using cannabis?
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.physicianKnowCannabisUse === false ? "No" : "Yes"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.referredBy === null ||
                       patientProfile?.EmergencyContact?.referredBy === "" ?
                        "field-not-found" : "field-label"}`}>
                      Referred By:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.referredBy}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.EmergencyContact?.caretakerName === null ||
                       patientProfile?.EmergencyContact?.caretakerName === "" ?
                        "field-not-found" : "field-label"}`}>
                    If a minor or dependent adult, name and relationship of caretaker or guardian completing this form:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.EmergencyContact?.caretakerName}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        }
      
      </div>
    );
  }
}

export default Contact;
