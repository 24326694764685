import React, { Component } from "react";
import authServices from "../../../../services/authServices";
import Medication from "../../../auth/registration/modal/medication";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";
import { environment } from "../../../../environment";
import ReactAutocomplete from "react-autocomplete";
import { connect } from 'react-redux';
import store from "../../../../store/alertStore";
import { alertP450 } from "../../../../actions/alertP450Actions";


const medicationAllergies = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

export class MedicationSupplements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medicationName: "",
      medicationId: "",
      url: "",
      fields: {},
      errors: {},

      medications: [
        {
          name: "",
          dose: "",
          frequency: "",
          phone: "",
          medicationId: "",
        },
      ],
      hideShowMedication: [
        {
          index: 0,
          value: false,
        },
      ],
      hideShowMedicationSelect: [
        {
          index: 0,
          selectValue: true,
        },
      ],
      initilizeMedication: [],
      medType: "",
      addModalMedicationShow: false,
      patientStatus: this.props.status,
      userID: this.props.userID,
      index: "",
      medicationList: {
        data: [
          {
            name: "",
            id: "",
          },
        ],
      },
      show: false,
      showAllergyFlag: "",
      allergies: [
        {
          name: "",
        },
      ],
      showMedication: false,
      notFoundMedication: false,
      allergy: "",
      token: localStorage.getItem("token"),
      disabled: true,
      addButtonDisabled: false,
      visibility: "",
      alertP450: {},
      validateTrue: true,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.handleInputDoseChange = this.handleInputDoseChange.bind(this);
    this.addMedicationList = this.addMedicationList.bind(this);
    this.handleInputNameChange = this.handleInputNameChange.bind(this);
    this.handleInputDoseChange = this.handleInputDoseChange.bind(this);
    this.handleInputFrequencyChange = this.handleInputFrequencyChange.bind(
      this
    );
    this.addAllergyList = this.addAllergyList.bind(this);
    this.handleInputAllergy = this.handleInputAllergy.bind(this);
  }

  addMedicationList(e) {
    e.preventDefault();

    if (this.state.medType === "AddMedication" && this.state.initilizeMedication.length > 0) {
      let array = this.state.medications;
      array.push({
        id: array.length + 1,
        name: "",
        dose: "",
        frequency: "",
        medicationId: "",
      });
      let x = this.state.hideShowMedication;
      x.push({ index: x.length + 1, value: false });
      let select = this.state.hideShowMedicationSelect;
      select.push({ index: select.length + 1, selectValue: true });
      this.setState({
        medications: array,
        hideShowMedication: x,
        hideShowMedicationSelect: select,
        disabled: true,
        addButtonDisabled: true,
      });
    } else if (this.state.medType === "") {
      let array = this.state.medications;
      array.push({
        id: array.length + 1,
        name: "",
        dose: "",
        frequency: "",
        medicationId: "",
      });
      let x = this.state.hideShowMedication;
      x.push({ index: x.length + 1, value: false });
      let select = this.state.hideShowMedicationSelect;
      select.push({ index: select.length + 1, selectValue: true });
      this.setState({
        medications: array,
        hideShowMedication: x,
        hideShowMedicationSelect: select,
        disabled: true,
        addButtonDisabled: true,
      });
    } else {
      if (this.state.medicationList.data.length === 0) {
        this.handleValidation();
      }
    }
  }
  changeMethod(value, index, item) {


  }
  handleChangeKey(e, index) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[index].name = e.target.value;
    const medicationName = nextMedicalData[index].name;
    this.searchMedicationList(medicationName, index);


    this.setState({
      medications: nextMedicalData,
      disabled: false,
      index: index,
      addButtonDisabled: true,
      medicationName: e.target.value
    });
    if (this.state.medicationList.data.length === 0) {
      this.setState({ visibility: "none", disabled: true });
    }
    else {
      if (this.state.medicationList.data.length > 0) {
        // nextMedicalData[index].name =  this.state.medicationList.data[0].name;
        // nextMedicalData[index].medicationId = this.state.medicationList.data[0].id;
        this.setState({ visibility: "block", disabled: true });
      }
    }
  }

  handleInputNameChange(e, object, idx) {
    let nextMedicalData = this.state.medications.slice();
    if (object.id !== "" || object.id !== undefined || object.id !== null) {
      nextMedicalData[idx].name = object.name;
      nextMedicalData[idx].medicationId = object.id;
      this.setState({
        medications: nextMedicalData,
        disabled: false,
        index: idx,
        medicationName: e,
        addButtonDisabled: false,
        validateTrue: true,

      });
      let x = this.state.hideShowMedication;
            x[idx].value = false;
            let select = this.state.hideShowMedicationSelect;
            x[idx].selectValue = true;
          
            this.setState(
              {
                hideShowMedication: x,
                hideShowMedicationSelect: select,
              },
              () => { }
            );
          }
    }
  

  searchMedicationList(name, idx) {
    authServices
      .getMedicationByName(name)
      .then((response) => {
        this.setState({
          medicationList: response.data,
        });
        // this.checkName(response.data.data,name)
      

        let nextMedicalList = response.data.data;
        let errors = {}
        const string1 = name;
        const string2 = nextMedicalList[idx]?.name;
        const result = string1.localeCompare(string2);
        const finalResult = result === 0 ? true : false;

        let nextMedicalData = this.state.medications.slice();
        nextMedicalData[idx].medicationId = nextMedicalList[idx]?.id;
        
        if (response.data.data.length === 0) {
          let x = this.state.hideShowMedication;
          x[idx].value = true;
          this.setState(
            {
              hideShowMedication: x,
            },
            () => { }
          );
        } else if (response.data.data.length > 0) {
          if (finalResult === false && string1 !== "" && this.state.medType === "") {
            let x = this.state.hideShowMedication;
            x[idx].value = true;

            errors["notFound"] =
              "Please choose any from Suggested Medication or";
            this.setState(
              {
                hideShowMedication: x,
                validateTrue: false,
                medications: nextMedicalData,
              },
              () => { }
            );
          } else {
            let x = this.state.hideShowMedication;
            x[idx].value = false;
            let select = this.state.hideShowMedicationSelect;
            x[idx].selectValue = true;
          
            this.setState(
              {
                hideShowMedication: x,
                hideShowMedicationSelect: select,
                validateTrue: true,
                medications: nextMedicalData
              },
              () => { }
            );
          }
        } 

        this.setState({ errors: errors });

      })
      .catch((e) => {
        console.log(e);
      });
  }

  //  checkName(arr, name) {
  //   const { length } = arr;
  //   const id = length + 1;
  //   const found = arr.some(el => el.name === name);
  //   // if (!found) arr.push({ id, name: name });
  //   console.log(found)
  //   return arr;
  // }

  handleInputDoseChange(e, idx) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[idx].dose = e.target.value;
    this.setState({
      medications: nextMedicalData,
      disabled: false,
    });
  }

  handleInputFrequencyChange(e, idx) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[idx].frequency = e.target.value;
    this.setState({
      medications: nextMedicalData,
      disabled: false,
    });
  }

  handleRemoveSocial(idx) {
    let someArray = this.state.medications;
    someArray.splice(idx, 1);
    this.setState({ medications: someArray });
    let x = this.state.hideShowMedication;
    x[idx].value = false;

    this.setState({
      hideShowMedication: x,
      disabled: false,
      addButtonDisabled: false,
    });
  }
  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
    // var myButtonClasses = document.getElementById("treatment").classList;
    // if (myButtonClasses.contains("active")) {
    //   myButtonClasses.remove("active");
    // } else {
    //   myButtonClasses.add("patient-intake-active");
    // }
  }
  addModalMedication(e) {
    this.setState({ addModalMedicationShow: true });
  }

  addAllergyList(e) {
    e.preventDefault();
    let array = this.state.allergies;
    array.push({ id: array.length + 1, name: "" });
    this.setState({
      allergies: array,
      disabled: false,
    });
  }

  handleInputAllergy(e, idx) {
    let nextAllergyData = this.state.allergies.slice();
    nextAllergyData[idx].name = e.currentTarget.value;
    this.setState({ allergies: nextAllergyData, disabled: false });
  }

  remove(idx, e) {
    let allergies = this.state.allergies;
    allergies.splice(idx, 1);
    this.setState({ allergies: allergies, disabled: false });
  }

  handleUserInputChange(field, e) {
    if (field === "allergy") {
      if (e.target.value === "yes") {
        this.setState({
          allergy: e.target.value,
          show: true,
          showAllergyFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          show: false,
          allergy: e.target.value,
          showAllergyFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          show: false,
          allergy: null,
          showAllergyFlag: null,
          disabled: false,
        });
      }
    }
  }

  componentDidMount() {
    this.input.focus()
    this.getMedication();
  }

  getMedication = async () => {
    const data =
      this.state.patientStatus === "physician"
        ? "intake/medications?userId=" + this.state.userID
        : "intake/medications";

    await axios
      .get(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.data.data.allergy === true) {
          this.setState({
            allergy: "yes",
            show: true,
            showAllergyFlag: true,
          });
        } else if (response.data.data.allergy === null) {
          this.setState({
            allergy: "",
            show: false,
            showAllergyFlag: null,
          });
        } else if (response.data.data.allergy === false) {
          this.setState({
            allergy: "no",
            show: false,
            showAllergyFlag: false,
          });
        }
        if (response.data.data.allergies.length > 0) {
          this.setState({
            allergies: response.data.data.allergies,
          });
        } else if (response.data.data.allergies.length === 0) {
          this.setState({
            allergies: this.state.allergies,
          });
        }
        if (response.data.data.medications.length > 0) {
        
          let option = []
          response.data.data.medications.forEach((data, index) => {
            let x = this.state.hideShowMedication;
            let select = this.state.hideShowMedicationSelect;
            if (index > 0) {
              select.push({ index: index, selectValue: true });
              x.push({ index: index, value: false });
            }
            let medications = {};             
            medications.name = data.MedicationList.name;
            medications.dose = data.dose;
            medications.frequency = data.frequency;
            medications.medicationId = data.MedicationList.id
            option.push(medications);
           
            this.setState(
              {
                hideShowMedication: x,
                hideShowMedicationSelect: select,
              },
              () => { }
            );
          });
          this.setState({
            medications: option
          })
        } else if (response.data.data.medications.length === 0) {
          this.setState({
            medications: this.state.medications,
          });
        }
      })
      .catch((e) => { });
  };

  handleValidation(index) {
    let errors = {};
    let formIsValid = true;

    if (this.state.medType === "" && this.state.medicationList.data.length === 0) {
      formIsValid = false;
      this.setState({ showMedication: true });
      errors["notFound"] =
        "Please choose any from Suggested Medication or";
    } else if (this.state.medType === "AddMedication" && this.state.initilizeMedication.length > 0) {
      formIsValid = true;
      this.setState({ showMedication: false, notFoundMedication: false,  validateTrue: true });
      errors["notFound"] =
        "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidation() && this.state.validateTrue) {

      
      const medicationSupplements = this.state.fields;
      medicationSupplements.medications = this.state.medications;
      medicationSupplements.allergies = this.state.allergies;
      medicationSupplements.allergy = this.state.showAllergyFlag;
      if (this.state.patientStatus === "physician") {
        medicationSupplements.userId = this.state.userID;
      }
      this.setState({
        medicationSupplements: medicationSupplements,
      });

      await axios
        .post(
          environment.endPoint + "intake/medications",
          medicationSupplements,
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            if (this.state.patientStatus === "physician") {
              this.getPatientProfile(this.state.userID)
            }
            toast.success(
              "Medication & Supplements Information Update Successfully",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
            this.setState({
              medications: this.state.medications,
              allergies: this.state.allergies,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
          // if (e.response.status === 400) {
          //   toast.error("Medication Supplements Information Not Updated", {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 3000,
          //   });
          // }
        });
    }
  };

  getPatientProfile = async (id) => {
    try {
      const response = await axios
        .get(environment.endPoint + "physician/patient?patientId=" + id, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        })
      if (response.status === 200) {

        store.dispatch(
          alertP450(response.data.data)
        );
        ;
      }
    } catch (err) {
    }
  };

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true,
    });
    var myButtonClasses = document.getElementById("medications").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
  }

  addModalMedicationClose = () => {
    this.setState({ addModalMedicationShow: false ,disabled: false});
  };

  initiliazeMedicationList = (medicationList, string) => {
    this.setState({
      initilizeMedication: medicationList,
      medType: string
    })
  }
  medicationSearchStatus = () => {
    this.setState({ addButtonDisabled: false });
  };
  medicationId = (medications, hideShowMedication) => {
    this.setState({ medications: medications, hideShowMedication: hideShowMedication });
  };

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">
            Medications and Supplements
          </h5>

          <label className="text-left stepper-form-sub-headings">
            Please list ALL prescribed and over-the-counter medications taken
            regularly or as needed.
          </label>
          
          <div className="row">
            <div className="col-lg-4 col-4">
              <label className="text-primary stepper-form-sub-headings">
                Medication Name
              </label>
            </div>
            <div className="col-lg-3 col-3">
              <label className="text-primary stepper-form-sub-headings">
                Dose
              </label>
            </div>
            <div className="col-lg-3 col-3">
              <label className="text-primary stepper-form-sub-headings">
                Frequency
              </label>
            </div>
          </div>

          {this.state.medications.map((value, index) => {
            return (
              <div key={`${value.id}-${index}`} className="row mb-2">
                <div className="col-lg-4 col-4 ">
                  {this.state.hideShowMedicationSelect[index]?.selectValue ===
                    true ? (
                    <div className="custom-form-row autocomplete-input">
                      <ReactAutocomplete
                        ref={(el) => (this.input = el)}
                        menuStyle={{ display: this.state.visibility }}
                        items={this.state.medicationList.data}
                        getItemValue={(item) => item.name}
                        shouldItemRender={(item, value) =>
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                        }
                        inputProps={{
                          className: "form-control custom-form-control",
                          onFocus: (e) => {
                            const input = e.target;
                            const value = input.value;

                            setTimeout(() => input.select(), 0);
                            this.changeMethod(input, value);
                          },
                          onBlur: (e, item) => {
                            console.log()
                            const input = e.target;
                            const value = input.value;
                            this.changeMethod(input, value, item);
                          },
                        }}
                        renderItem={(item, isHighlighted, styles) => (
                          <div>
                            <div
                              // onFocus={(e) => this.changeMethod(e,item, index)}
                              key={item.id}
                              style={{
                                background: isHighlighted ? "#1473E6" : "white",
                                color: isHighlighted ? "white" : "#2C2D2F",
                              }}
                            >
                              {item?.name}
                            </div>
                          </div>
                        )}
                        value={value.name || ""}
                        onChange={(e) => this.handleChangeKey(e, index)}
                        onSelect={(value, object) => {
                          this.handleInputNameChange(value, object, index);
                          setTimeout(() => this.input && this.input.blur(), 0)
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                </div>

                <div className="col-lg-3 col-3">
                  <input
                    className="form-control custom-form-control py-4"
                    value={value.dose || ""}
                    onChange={(e) => this.handleInputDoseChange(e, index)}
                  />
                </div>
                <div className="col-lg-3 col-3">
                  <input
                    className="form-control custom-form-control py-4"
                    value={value.frequency || ""}
                    onChange={(e) => this.handleInputFrequencyChange(e, index)}
                  />
                </div>
                {/* {
                                             this.state.diagnosesList.data
                                           } */}

                {this.state.medications.length > 1 ? (
                  <div className="col-lg-1 col-2">
                    <label></label>
                    <div className="custom-btn">
                      <button
                        onClick={this.handleRemoveSocial.bind(this, index)}
                        className="btn btn-danger child-btn border-radius mb-1"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.hideShowMedication[index]?.value === true ? (
                  <p className="pull-right ml-3">
                    {this.state.showMedication === true ? (
                      <>
                        <span className="text-danger">
                          {this.state.errors["notFound"]}
                        </span>
                        <Link onClick={this.addModalMedication.bind(this)}>
                          {" "}
                          Add New Medication
                        </Link>{" "}
                      </>
                    ) : (
                      <>
                        <span className="text-danger">
                          Please choose any from Suggested Medication or
                        </span>
                        <Link onClick={this.addModalMedication.bind(this)}>
                          {" "}
                          Add New Medication
                        </Link>  </>

                    )}
                  </p>
                ) : (
                  ""
                )}

                <Medication
                  addModalMedicationShow={this.state.addModalMedicationShow}
                  hideModal={this.addModalMedicationClose}
                  user_id={this.state.user_id}
                  medicationId={this.medicationId}
                  medicationName={this.state.medicationName}
                  onUpdate={this.medicationSearchStatus}
                  initiliazeMedicationList={this.initiliazeMedicationList}
                  medications={this.state.medications}
                  index={this.state.index}
                  hideShowMedication={this.state.hideShowMedication}
                />
              </div>
            );
          })}

          <div className="row mb-4">
            <div className="col-lg-1 col-1">
              <button
                // disabled={this.state.addButtonDisabled === true ? true : false}
                className="btn btn-primary child-btn border-radius pull-left plus-button"
                onClick={this.addMedicationList}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.allergy}
                  className={
                    this.state.allergy === "" ||
                      this.state.allergy === null ||
                      this.state.allergy === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "allergy")}
                >
                  <option value="">
                    {" "}
                    Do you have any medication allergies?
                  </option>
                  {medicationAllergies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you have any medication allergies?{" "}
                </label>
              </div>
            </div>
          </div>

          {this.state.show === true ? (
            <div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <label className="text-center  stepper-form-sub-headings">
                    Please List Medication Allergies
                  </label>
                </div>
              </div>
              {this.state.allergies.map((value, index) => {
                return (
                  <div key={`${value.id}-${index}`} className="row mb-4">
                    <div className="col-lg-11 col-12">
                      <input
                        key={index}
                        type="text"
                        value={value.name}
                        placeholder=""
                        className="form-control custom-form-control"
                        onChange={(e) => this.handleInputAllergy(e, index)}
                      />
                    </div>
                    {this.state.allergies.length > 1 ? (
                      <div className="col-lg-1 col-2">
                        <label></label>
                        <div className="custom-btn">
                          <button
                            onClick={this.remove.bind(this, index)}
                            className="btn btn-danger child-btn border-radius mb-1"
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
              <div className="row mb-4">
                <div className="col-lg-1 col-1">
                  <button
                    className="btn btn-primary child-btn border-radius pull-left plus-button"
                    onClick={this.addAllergyList}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                // disabled={this.state.disabled ? true : false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToAlertP450Props = (store) => {
  return {
    alertP450: store.alertP450
  }
}


export default connect(mapStateToAlertP450Props)(MedicationSupplements);
