import React, { Component } from "react";
import "../../../../assets/scss/symtoms.scss";
import moment from "moment";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { environment } from "../../../../environment";
import QuestionNo from "../../../../assets/images/question.svg";
// import Select from "react-select";

import { toast } from "react-toastify";
const frequency = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];
const methodOfIngestion = [
  { value: "Inhaled", label: "Inhaled" },
  { value: "Ingested", label: "Ingested" },
  { value: "Sublingual", label: "Sublingual" },
  { value: "Suppository", label: "Suppository" },
  { value: "Topically", label: "Topically" },
];
export class AddSymptoms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideShowYes: true,
      hideShowNo: false,
      one: true,
      two: false,
      time: moment().format("LTS"),
      status: "",
      className: "",
      classNameFontAwesome: "",
      date: moment(new Date()).format("MMMM DD YYYY"),
      cannabisToday: "",
      objectiveList: [],
      clearable: true,
      objective: {},
      products: [],
      selectedOption: "",
      objectiveslabels: {
        antiCancerTherapy: "Anti-cancer Therapy",
        pharmaceuticalReplacement: "Pharmaceutical Replacement",
        manageSideEffectsofChemo: "Manage Side Effects of Chemo",
        mentalHealth: "Mental Health",
        seizureControl: "Seizure Control",
        painRelief: "Pain Relief",
      },
      canabiesDose: [
        {
          amount: "",
          frequency: "",
          ingestionMethod: "",
          product: "",
        },
      ],
      comment: "",
      disabled: true,
      token: localStorage.getItem("token"),
    };
    this.addcanabiesDose = this.addcanabiesDose.bind(this);
  }
  componentDidMount() {
    setInterval(() => {
      if (this.state.one === true) {
        this.setState({
          time: moment().format("LTS"),
        });
      } else if (this.state.four === true) {
        this.setState({
          time: moment().format("LT"),
        });
      }
    }, 1000);

    if (this.props.location.search.includes("yes")) {
      this.setState({
        hideShowYes: true,
        cannabisToday: "yes",
        className: "badge badge-yes",
        classNameFontAwesome: "fa fa-check",
      });
    } else {
      this.setState({
        cannabisToday: "no",
        hideShowYes: false,
        className: "badge badge-no",
        classNameFontAwesome: "fa fa-check",
      });
    }
    this.getObjective(this.state.token);
    this.getProducts(this.state.token);
  }
  cannabisQuestion(value, e) {
    e.preventDefault();
    if (value === "yes") {
      this.setState({
        hideShowYes: true,
        cannabisToday: "yes",
        className: "badge badge-yes",
        classNameFontAwesome: "fa fa-check",
      });
    } else if (value === "no") {
      this.setState({
        hideShowYes: false,
        cannabisToday: "no",
        className: "badge badge-no",
        classNameFontAwesome: "fa fa-check",
      });
    }
  }

  addcanabiesDose(e) {
    e.preventDefault();
    let canabiesDose = this.state.canabiesDose;

    canabiesDose.push({
      id: canabiesDose.length + 1,
      amount: "",
      frequency: "",
      ingestionMethod: "",
    });
    this.setState({
      canabiesDose: canabiesDose,
    });
  }

  getObjective = async (token) => {
    await axios
      .get(environment.endPoint + "dashboard/objectives", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.setState({
          objectiveList: response.data.data,
        });
      })
      .catch((e) => {
        // if (e.response.status === 404) {
        //   // toast.error("Patient Information Not Updated", {
        //   //   position: toast.POSITION.TOP_RIGHT,
        //   //   autoClose: 3000,
        //   // });
        // }
      });
  };

  getProducts = async (token) => {
    await axios
      .get(environment.endPoint + "dashboard/products", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.getProductData(response);
      })
      .catch((e) => {
        // if (e.response.status === 404) {
        //   // toast.error("Patient Information Not Updated", {
        //   //   position: toast.POSITION.TOP_RIGHT,
        //   //   autoClose: 3000,
        //   // });
        // }
      });
  };

  getProductData(response) {
    let option = [];
    if (response.data.data.length > 0) {
      response.data.data.forEach((product) => {
        let productData = {};
        productData.value = product.id;
        productData.label = product.name;
        option.push(productData);
      });
    }
    this.setState({
      products: option,
    });
  }

  onSubmit = async () => {
    const params = {
      cannabisToday: this.state.cannabisToday,
      status: this.state.status,
      canabiesDose: this.state.canabiesDose,
      comment: this.state.comment,
      objective: this.state.objective,
    };
    await axios
      .post(environment.endPoint + "dashboard/healthCheckUp", params, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          this.props.history.push("/health-journey");
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.setState({
            disabled: true,
          });
          this.setState({
            cannabisToday: "",
            status: "",
            canabiesDose: [
              {
                amount: "",
                frequency: "",
                ingestionMethod: "",
                product: "",
              },
            ],
            comment: "",
            className: "",
            classNameFontAwesome: "",
          });
          window.scrollTo(0, 0);
        }
      })
      .catch((e) => {});
  };

  onReset = () => {
    this.setState({
      cannabisToday: "",
      status: "",
      className: "",
      classNameFontAwesome: "",
      canabiesDose: [
        {
          amount: "",
          frequency: "",
          ingestionMethod: "",
          product: "",
        },
      ],
      comment: "",
    });
  };
  handleInputCannabisNameChange(e, idx) {
    let cannabisName = this.state.canabiesDose.slice();
    cannabisName[idx].amount = e.target.value;
    this.setState({ canabiesDose: cannabisName, disabled: false });
  }

  handleChange(e, idx) {
    let cannabisFrequency = this.state.canabiesDose.slice();
    cannabisFrequency[idx].frequency = e.target.value;
    this.setState({ canabiesDose: cannabisFrequency, disabled: false });
  }

  handleInputProductChange(index, e) {
    let product = this.state.canabiesDose.slice();
    product[index].product = e.target.value;
    this.setState({ canabiesDose: product, disabled: false });
  }

  handleInputIngestionMethodChange(e, idx) {
    let ingestionMethod = this.state.canabiesDose.slice();
    ingestionMethod[idx].ingestionMethod = e.target.value;
    this.setState({ canabiesDose: ingestionMethod, disabled: false });
  }
  valueChange(field, e) {
    this.setState({
      status: field,
      disabled: false,
    });
  }

  valueChangeObjective(field, value, e) {
    let objective = this.state.objective;
    objective[value] = field;
    this.setState({
      objective,
      disabled: false,
    });
  }

  handleCommentSectionChange(e) {
    this.setState({
      comment: e.target.value,
      disabled: false,
    });
  }

  render() {
    return (
      <div className="symptomps-main">
        <div className="header container mb-5">
          <div className="main-heading">
            <h1>Please elaborate on your feelings.</h1>
            <p className="sub-heading">
              Please provide more details about your symptoms from Medicine use
              today.
            </p>
          </div>
          <div className="top-white-box">
            <div className="row first-line">
              <div className="col-lg-6 col-md-12">
                <p className="question">Did you take medicine today?</p>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="yes-no-btns">
                <button
                  className="btn-primary no"
                  onClick={this.cannabisQuestion.bind(this, "no")}
                >
                  <img
                    src={QuestionNo}
                    alt="QuestionNo"
                    width="30"
                    height="30"
                    className=""
                  />{" "}
                  NO
                </button>
                <button
                  className="btn-success"
                  onClick={this.cannabisQuestion.bind(this, "yes")}
                >
                  <i class="fa fa-smile-o" aria-hidden="true"></i>YES{" "}
                </button>

                {this.state.hideShowYes === true ? (
                  <span class={this.state.className}>
                    <i
                      class={this.state.classNameFontAwesome}
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span class={this.state.className}>
                    <i
                      class={this.state.classNameFontAwesome}
                      aria-hidden="true"
                    ></i>
                  </span>
                )}
                </div>
              </div>
            </div>
            {this.state.hideShowYes === true ? (
              <div className="great">
                <h3>Great!</h3>
              </div>
            ) : (
              ""
            )}

            {this.state.hideShowYes === true ? (
              <div className="nd-line">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Amount (mg)</th>
                      <th>Frequency</th>
                      <th>Method of Ingestion</th>
                      <th>Product</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.canabiesDose.map((value, index) => {
                      return (
                        <tr key={`${value.id}-${index}`}>
                          <td className="mt-2" width="20%">
                            <input
                              value={value.amount || ""}
                              type="number"
                              className="form-control first-input height-select"
                              placeholder="Amount (mg)"
                              name=""
                              min="0"
                              onChange={(e) =>
                                this.handleInputCannabisNameChange(e, index)
                              }
                            ></input>
                          </td>
                          <td className="mt-2" width="20%">
                            <select
                              value={value.frequency || ""}
                              class="form-control height-select"
                              id="sel1"
                              name="sellist1"
                              onChange={(e) => {
                                this.handleChange(e, index);
                              }}
                            >
                              <option
                                className="first-item"
                                value=""
                                disabled
                                selected
                              >
                                {" "}
                                Select Frequency
                              </option>
                              {frequency.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="mt-2" width="20%">
                            <select
                              className="form-control height-select"
                              id="sel1"
                              name="sellist1"
                              value={value.ingestionMethod || ""}
                              onChange={(e) =>
                                this.handleInputIngestionMethodChange(e, index)
                              }
                            >
                              <option value="" disabled selected>
                                {" "}
                                Select Method of Ingestion
                              </option>
                              {methodOfIngestion.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="mt-2" width="20%">
                          <input
                              value={value.product || ""}
                              type="text"
                              className="form-control first-input height-select"
                              placeholder="Product"
                              onChange={this.handleInputProductChange.bind(
                                this,
                                index
                              )}
                            ></input>
                            {/* <Select
                              className=""
                              placeholder="Select Product"
                              name="form-field-name"
                              onChange={this.handleInputProductChange.bind(
                                this,
                                index
                              )}
                              options={this.state.products}
                            /> */}
                           
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <button className="btn-primary" onClick={this.addcanabiesDose}>
                  <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Row
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="gray-box">
              {this.state.hideShowYes === true ? (
                <h3>How did it make you feel taking your medicine ?</h3>
              ) : (
                ""
              )}

              {this.state.hideShowYes === true ? (
                <div className="stepper">
                  <div className="mt-auto">
                    <div className="row mb-3">
                      <div className="col-md-12 mb-4 col-12">
                        <div className="feelings-emoji">
                          <form id="smileys">
                            <input
                              type="radio"
                              id="worse"
                              name="smiley"
                              defaultValue="worse"
                              className="happy"
                              onChange={this.valueChange.bind(this, "worse")}
                            />
                            <label htmlFor="worse" className="worse">
                              Worse
                            </label>
                            <input
                              type="radio"
                              name="smiley"
                              defaultValue="same"
                              className="neutral"
                              id="same"
                              onChange={this.valueChange.bind(this, "same")}
                            />
                            <label htmlFor="same" className="same">
                              Same
                            </label>

                            <input
                              type="radio"
                              id="better"
                              name="smiley"
                              defaultValue="better"
                              className="sad"
                              onChange={this.valueChange.bind(this, "better")}
                            />
                            <label htmlFor="better" className="better">
                              Better
                            </label>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                " "
              )}
              {this.state.hideShowYes === true ? (
                <h3 className="obejective-heading">
                  Is it helping you in your Primary Diagnosis ?
                </h3>
              ) : (
                ""
              )}
              {this.state.hideShowYes === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="obejective-label text-truncate">
                        {this.state.objectiveList[0] !== undefined
                          ? this.state.objectiveList[0]
                              .charAt(0)
                              .toUpperCase() +
                            this.state.objectiveList[0].substr(1).toLowerCase()
                          : ""}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="stepper">
                        <div className="mt-auto">
                          <div className="row mb-3">
                            <div className="col-lg-12 mb-4 col-12">
                              <div className="feelings-emoji-samll">
                                {this.state.objectiveList[0] !== undefined ? (
                                  <form id="smileys">
                                    <input
                                      type="radio"
                                      id="word-primary-diagnosis-first"
                                      name="smiley"
                                      defaultValue="worse"
                                      className="happy"
                                      onChange={this.valueChangeObjective.bind(
                                        this,
                                        "worse",
                                        this.state.objectiveList[0]
                                      )}
                                    />
                                    <label
                                      htmlFor="word-primary-diagnosis-first"
                                      className="worse"
                                    >
                                      Worse
                                    </label>
                                    <input
                                      type="radio"
                                      name="smiley"
                                      defaultValue="same"
                                      className="neutral"
                                      id="same-primary-diagnosis-second"
                                      onChange={this.valueChangeObjective.bind(
                                        this,
                                        "same",
                                        this.state.objectiveList[0]
                                      )}
                                    />
                                    <label
                                      htmlFor="same-primary-diagnosis-second"
                                      className="same"
                                    >
                                      Same
                                    </label>

                                    <input
                                      type="radio"
                                      id="better-primary-diagnosis-third"
                                      name="smiley"
                                      defaultValue="better"
                                      className="sad"
                                      onChange={this.valueChangeObjective.bind(
                                        this,
                                        "better",
                                        this.state.objectiveList[0]
                                      )}
                                    />
                                    <label
                                      htmlFor="better-primary-diagnosis-third"
                                      className="better"
                                    >
                                      Better
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {this.state.hideShowYes === true ? (
                <h3 className="obejective-heading">
                  Is it meeting your objective ?
                </h3>
              ) : (
                ""
              )}

              {this.state.hideShowYes === true ? (
                <div>
                  {/* Objectives */}
                  {this.state.objectiveList.map((value, index) => (
                    <div className="row">
                      {index === 0 ? (
                        <span></span>
                      ) : (
                        <div className="col-md-6">
                          <p className="obejective-label text-truncate">
                            {this.state.objectiveslabels[value]
                              ? this.state.objectiveslabels[value]
                              : value}
                          </p>
                        </div>
                      )}

                      {/* {index === 0 ? <div className="col-lg-3">
                            <p className="text-left mr-5">Primary Diagnose</p>
                          </div> : <div className="col-lg-3">
                            <p className="text-left mr-5">Objective</p>
                          </div>} */}
                      {index === 0 ? (
                        <span></span>
                      ) : (
                        <div className="col-md-6">
                          <div className="stepper">
                            <div className="mt-auto">
                              <div className="row mb-3">
                                <div className="col-lg-12 mb-4 col-12">
                                  <div className="feelings-emoji-samll">
                                    <form id="smileys">
                                      <input
                                        type="radio"
                                        id={`word-${index}`}
                                        name="smiley"
                                        defaultValue="worse"
                                        className="happy"
                                        onChange={this.valueChangeObjective.bind(
                                          this,
                                          "worse",
                                          value
                                        )}
                                      />
                                      <label
                                        htmlFor={`word-${index}`}
                                        className="worse"
                                      >
                                        Worse
                                      </label>
                                      <input
                                        type="radio"
                                        name="smiley"
                                        defaultValue="same"
                                        className="neutral"
                                        id={`same-${index}`}
                                        onChange={this.valueChangeObjective.bind(
                                          this,
                                          "same",
                                          value
                                        )}
                                      />
                                      <label
                                        htmlFor={`same-${index}`}
                                        className="same"
                                      >
                                        Same
                                      </label>

                                      <input
                                        type="radio"
                                        id={`better-${index}`}
                                        name="smiley"
                                        defaultValue="better"
                                        className="sad"
                                        onChange={this.valueChangeObjective.bind(
                                          this,
                                          "better",
                                          value
                                        )}
                                      />
                                      <label
                                        htmlFor={`better-${index}`}
                                        className="better"
                                      >
                                        Better
                                      </label>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}

              <form action="">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="5"
                    id="comment"
                    placeholder={
                      this.state.cannabisToday === "yes"
                        ? "Please describe more about your feelings"
                        : "Is there a reason for not taking cannabis today ?"
                    }
                    name="text"
                    value={this.state.comment}
                    onChange={(e) => this.handleCommentSectionChange(e)}
                  ></textarea>
                </div>
              </form>
              <div className="upadate ml-2">
                <span className="status">Update your health status for</span>
                <br></br>
                <span className="date">{this.state.date}</span>
                <span className="time"> {this.state.time}</span>
                {/* <button class=" btn btn-primary update-btn">Update</button> */}
              </div>
            </div>

            <div className="double-btn">
              <button
                class=" btn btn-primary submit mb-4"
                onClick={this.onSubmit}
                disabled={this.state.disabled ? true : false}
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i> Submit
              </button>
              {/* <button class=" btn btn-success reset mb-4"
                onClick={this.onReset}
                >
                <i class="fa fa-repeat" aria-hidden="true"></i> Reset
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddSymptoms);
