import React, { Component } from 'react'

export class Comments extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    componentDidMount() {
        this.setState({
          patientProfile: this.props.patientProfile,
        });
      }
    
      componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
          this.setState({
            patientProfile: this.props.patientProfile,
          });
        }
      }
    
    render() {
        const { patientProfile } = this.state;

        return (
            <div className="card-body custom-inner-content  ">
            <h5 className="stepper-form-headings ">Summary</h5>
            {patientProfile?.IntakeSummary === null ?  <h4 className="font-italic">No Summary Added</h4> : <div className="row">
              <div className="col-lg-12 col-12">
                  <table class="table table-striped">
                      <tbody>
                          <tr>
                              <td>
                                  <span className="field-label">Please tell us in your own words what you feel is most important:</span>
                                  <span className="field-text"> <p className="text-break ml-4">{patientProfile?.IntakeSummary?.note}</p> </span>
                              </td>
                          </tr>
                         
                      </tbody>
                  </table>
              </div>
            </div>}
            
          </div>
        )
    }
}

export default Comments
