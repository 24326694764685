import React, { Component } from 'react'
import { toast } from "react-toastify";
import Avatar from "react-avatar-edit";
import axios from "axios";
import { environment } from '../../../../environment';
import User from "../../../../assets/images/user.png";
import Pencil from "../../../../assets/images/Icon-pencil.svg";

export class ProfileImage extends Component {
    constructor(props) {
        super(props)

        const src = "";

        this.state = {
            preview: User,
            src,
            loading: true,
            hide: true,
            showValidate: true,
            token: localStorage.getItem("token")
        };
        this.onCrop = this.onCrop.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    componentDidMount() {
        this.getProfile(this.state.token);
    }
    onClose() {
        this.setState({ preview: User });
    }

    onCrop(preview) {
        this.setState({ preview, showValidate: false });
    }

    prev = () => {
        this.props.prev();
    };

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    onSubmit = async (token) => {
        this.setState({
            token: localStorage.getItem("token")
        })
        const formData = new FormData();

        var ImageURL = this.state.preview;
        // Split the base64 string in data and contentType
        var block = ImageURL.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1]; // In this case "image/gif"

        // get the real base64 content of the file
        var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
        // Convert it to a blob to upload
        var blob = this.b64toBlob(realData, contentType);

        // Create a FormData and append the file with "image" as parameter name
        formData.append("file", blob);
        this.setState({
            loading: true,
            hide: true,
        });
        await axios
            .post(environment.endPoint + "dashboard/profileImage", formData, {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                },
            }).then((response) => {
                if (response.status === 200 || response.status !== undefined) {
                    toast.success("Personal Image Update Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    this.setState({
                        loading: false,
                        hide: true,
                        showValidate: true,
                    });
                    this.props.next();
                }
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    toast.error("Personal Image Not Updated", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            });
    }
    getProfile = async (token) => {
        await axios.get(environment.endPoint + "dashboard/profile", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                this.setState({
                    profileDetail: response.data.data,
                    preview:
                        response.data.data.profileImage === null
                            ? User
                            : response.data.data.profileImage,
                });
                this.setState({
                    loading: false,
                });
            })
            .catch((e) => {
                if (e.response.status === 404) {
                }
            });
    }
    showChooseFile(e) {
        e.preventDefault();
        this.setState({
            hide: !this.state.hide
        })
    }
    render() {
        return (
          <div className="card-body card-body-profile custom-inner-content personal-image">
            <h5 className="stepper-form-headings d-none d-lg-block">
              Profile Image
            </h5>
    
            {this.state.hide === true ? (
               <div className="row mb-4">
               <div className="d-flex align-items-center col-lg-5 offset-lg-3">
                 <div className="pos-relative">
                 <img src={this.state.preview} alt="Preview" />
                 <button className="image-btn" onClick={this.showChooseFile.bind(this)}>
                    {/* <i className="fa fa-pencil" aria-hidden="true"></i> */}
                    <img src={Pencil} alt="Pencil"/>
                  </button>
                 </div>
                 
               </div>
             </div>
             
            ) : (
              <div className="row marginBottom">
                <div className="col-lg-5 offset-lg-4">
                  <Avatar
                    width={150}
                    height={133}
                    onCrop={this.onCrop}
                    onClose={this.onClose}
                    src={this.state.src}
                    label = "Choose a image"
                  />
                </div>
              </div>
            )}
    
            {this.state.loading ? (
              <div className="col-lg-2">
                <div class="loading">Loading&#8230;</div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-lg-3 offset-lg-3 col-6">
                <button
                  id="first"
                  type="button"
                  className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                  onClick={this.prev}
                >
                  <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                  Back
                </button>
              </div>
              <div className="col-lg-4 col-6">
                <button
                  id="third"
                  type="button"
                  className="btn btn-primary border-radius py-1  px-3"
                  onClick={(e, token) => this.onSubmit(e, token)}
                  disabled={this.state.showValidate ? true : false}
                >
                  Update
                  <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        );
      }
}

export default ProfileImage
