import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import Validation from "../../../common/util";
import axios from "axios";
import { environment } from "../../../../environment";

const physician = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      contactPhone: null,
      physicianPhoneNumber: null,
      errors: {},
      patientStatus: this.props.status,
      userID:  this.props.userID,
      token: localStorage.getItem("token"),
      disabled: true,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }
  componentDidMount() {
    this.contactGetInformation()
  }
      contactGetInformation = async () =>{
     
      const data = this.state.patientStatus === "physician" ? "intake/contacts?userId="+this.state.userID : "intake/contacts";
        await axios.get(environment.endPoint+data, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }).then((response) => {
          const data = response.data.data;
          this.setState({
            fields: data,
          });


          this.setState({
            contactPhone: Object.keys(data).length === 0 ? null : data.contactPhone,
            physicianPhoneNumber: Object.keys(data).length === 0 ? null : data.physicianPhoneNumber,
          });
        })
        .catch((e) => {
        });
      }

      next(e) {
    e.preventDefault();
    this.props.next();
  }

  handleUserInputChange(field, e) {
    if (field === "physicianPhoneNumber") {
      this.setState({
        physicianPhoneNumber: e,
        disabled: false,
      });
    } else if (field === "contactPhone") {
      this.setState({
        contactPhone: e,
        disabled: false,
      });
    } else {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields,disabled: false,
      });
    }
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
    var myButtonClasses = document.getElementById("Patient").classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      errors: Validation.contactsInformation(
        this.state.fields,
        this.state.contactPhone,
        this.state.physicianPhoneNumber,
        "contactInformation"
      ).error,
    });
    if (
      Validation.contactsInformation(
        this.state.fields,
        this.state.contactPhone,
        this.state.physicianPhoneNumber,
        "contactInformation"
      ).formIsValid
    ) {
      let contactsInformation = this.state.fields;
      contactsInformation.contactPhone = this.state.contactPhone;
      contactsInformation.physicianPhoneNumber = this.state.physicianPhoneNumber;
      if(this.state.patientStatus === "physician"){
        contactsInformation.userId = this.state.userID;
      }
      this.setState({ fields: contactsInformation });
      await axios.post(environment.endPoint+"intake/contacts", contactsInformation, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success("Contact Information Update Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error("Contact Information Not Updated", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } 
        });
    }
  }

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true
    })
    var myButtonClasses = document.getElementById("Contacts").classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content">
          <h5 className="stepper-form-headings ">Contacts</h5>

          <div className="row">
            <div className="col-lg-6 col-6 ">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Emergency Contact First Name *"
                  value={this.state.fields.firstName}
                  onChange={this.handleUserInputChange.bind(this, "firstName")}
                />
                <label className="label-helper py-2 px-1">
                  Emergency Contact First Name{" "}
                  <strong className="text-danger">*</strong>
                </label>
                <span className="text-danger">
                {this.state.errors["firstName"]}
              </span>
              </div>
             
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Emergency Contact Last Name *"
                  value={this.state.fields.lastName}
                  onChange={this.handleUserInputChange.bind(this, "lastName")}
                />
                <label className="label-helper py-2 px-1">
                  Emergency Contact Last Name{" "}
                  <strong className="text-danger">*</strong>
                </label>
                <span className="text-danger">
                {this.state.errors["lastName"]}
              </span>
              </div>
             
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="wrapper ">
                <div  className={
                    this.state.contactPhone !== null
                      ? "has-number custom-form-row"
                      : "custom-form-row"
                  }>
                  <PhoneInput
                    id="Input"
                    country={"us"}
                    inputProps={{
                      required: true,
                    }}
                    disableDropdown
                    value={
                      this.state.contactPhone
                    }
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "contactPhone"
                    )}
                  />
                  <label className="verify-label text-uppercase"></label>

                  <label className="label-helper py-2 px-1">
                    Emergency Contact Phone{" "}
                    <strong className="text-danger">*</strong>
                  </label>
                  <span className="text-danger">
                  {this.state.errors["contactPhone"]}
                </span>
                </div>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Emergency Contact Relationship"
                  value={this.state.fields.relationship}
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "relationship"
                  )}
                />
                <label className="label-helper py-2 px-1">
                  Emergency Contact Relationship
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-6 ">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Emergency Physician Name"
                  value={this.state.fields.physicianFirstName}
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "physicianFirstName"
                  )}
                />
                <label className="label-helper py-2 px-1">
                  Physician First Name
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Emergency Physician Last Name"
                  value={this.state.fields.physicianLastName}
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "physicianLastName"
                  )}
                />
                <label className="label-helper py-2 px-1">
                  Physician Last Name
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="wrapper ">
                <div  className={
                    this.state.physicianPhoneNumber !== null
                      ? "has-number custom-form-row"
                      : "custom-form-row"
                  }>
                  <PhoneInput
                    id="Input"
                    country={"us"}
                    inputProps={{
                      required: true,
                    }}
                    disableDropdown
                    value={
                      this.state.physicianPhoneNumber
                    }
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "physicianPhoneNumber"
                    )}
                  />
                  <label className="label-helper py-2 px-1">
                    Primary Care Physician Phone{" "}
                  </label>
                  <span className="text-danger">
                  {this.state.errors["physicianPhoneNumber"]}
                </span>
                </div>
               
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.physicianKnowCannabisUse}
                  className={
                    this.state.fields.physicianKnowCannabisUse === "" ||
                    this.state.fields.physicianKnowCannabisUse === null ||
                    this.state.fields.physicianKnowCannabisUse === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "physicianKnowCannabisUse"
                  )}
                >
                  <option value="">
                    {" "}
                    Does your physician know you will be using cannabis?
                  </option>
                  {physician.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Does your physician know you will be using cannabis?{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12 ">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder=" Referred By"
                  value={this.state.fields.referredBy}
                  onChange={this.handleUserInputChange.bind(this, "referredBy")}
                />
                <label className="label-helper py-2 px-1">Referred By</label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-12">
              <h4 className="stepper-form-sub-headings ">
                If a minor or dependent adult, name and relationship of
                caretaker or guardian completing this form:
              </h4>
              <textarea
                value={this.state.fields.caretakerName}
                onChange={this.handleUserInputChange.bind(
                  this,
                  "caretakerName"
                )}
                className="form-control custom-form-control"
                name=""
                id=""
                rows="4"
              ></textarea>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                class="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                class="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true : false}
              >
                Update
                <i class="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
       
        </div>
      </form>
    );
  }
}

export default Contact;
