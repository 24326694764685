import React, { Component } from "react";
import saveAs from "file-saver";

export class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: {},
      documentsList: [],
    };
  }
  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
    this.getDocuments(this.props.patientProfile?.Documents);
    //  this.getDocumentsList(this.props.patientProfile?.Documents)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
      this.getDocuments(this.props.patientProfile?.Documents);
      //  this.getDocumentsList(this.props.patientProfile?.Documents)
    }
  }

  getDocuments(documents) {
    if (documents !== undefined && documents.length > 0) {
      const documentTitle = documents
        .map((item) => item.documentTitle)
        .join(",");
      const documentDescription = documents
        .map((item) => item.documentDescription)
        .join(",");
      const documentType = documents.map((item) => item.documentType).join(",");

      const params = {
        documentTitle: documentTitle,
        documentDescription: documentDescription,
        documentType: documentType,
      };
      this.setState({
        documents: params,
      });
    }
  }

  async onDownload(documentTitle,documenUrl) {
    const downloadResult = await fetch(documenUrl);
    const blob = await downloadResult.blob();
    saveAs(blob, documentTitle);
  }



  render() {
    const { patientProfile } = this.state;

    return (
      <div className="card-body custom-inner-content  ">
        <h5 className="stepper-form-headings ">Documents</h5>
        {patientProfile?.Documents?.length === 0 ? (
          <h4 className="font-italic">No Documents Added</h4>
        ) : (
          <div className="row">
             {patientProfile?.Documents?.map((value,index) => (
             
             <div className="col-lg-12 col-12">
             <table class="table table-striped">
               <tbody>
                 <tr>
                   <td>
                     <span className="field-label">Document Title</span>
                     <span className="field-text">
                       {" "}
                       {value?.documentTitle}
                     </span>
                   </td>
                 </tr>
                 <tr>
                   <td>
                     <span className="field-label">Document Type</span>
                     <span className="field-text">
                       {" "}
                       {value?.documentType}
                     </span>
                   </td>
                 </tr>
                 <tr>
                   <td>
                     <span className="field-label">Document Decription</span>
                     <span className="field-text">
                       {" "}
                       {value?.documentDescription}
                     </span>
                   </td>
                 </tr>
                 <tr>
                 {/* onClick={()=> window.open(value?.documenUrl, "_blank")} */}
                   <td onClick={()=> this.onDownload(value?.documentTitle,value?.documenUrl)}>
                     <span className="field-label">Document Url:</span>
                     <span className="field-text text-primary">
                       {" "}
                         Download Document
                     </span>
                   </td>
                 </tr>
               </tbody>
             </table>
           </div>
      
             ))}

       
          </div>
        )}
      </div>
    );
  }
}

export default Documents;
