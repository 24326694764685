import React, { Component } from "react";
import { toast } from "react-toastify";
import "../../../assets/scss/physician.scss";
import ReactAutocomplete from "react-autocomplete";
import { Link } from "react-router-dom";
import DiagnosisModal from "../../auth/registration/modal/addDiagnose";
import authServices from "../../../services/authServices";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Medication from "../../auth/registration/modal/medication";
import { environment } from "../../../environment";
import axios from "axios";
import { withRouter } from "react-router-dom";
const stageCancer = [
  {
    value: "stage0",
    label: "Stage 0",
  },
  {
    value: "stage1",
    label: "Stage 1",
  },
  {
    value: "stage2",
    label: "Stage 2",
  },
  {
    value: "stage3",
    label: "Stage 3",
  },
  {
    value: "stage4",
    label: "Stage 4",
  },
];
const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  { value: "Decline To Answer", label: "Decline To Answer" }
];

const cannabis = [
  {
    value: "NeverTriedit",
    label: "Cannabis Naive (Never tried it)",
  },
  {
    value: "TriedItYears10Ago",
    label: "Tried more than 10 years ago",
  },
  {
    value: "TriedItYears5Ago",
    label: "Tried more than 5 years ago",
  },
  {
    value: "CurrentlyUseOccasionally",
    label: "Currently use occasionally",
  },
  {
    value: "CurrentlyUseDaily",
    label: "Currently use daily",
  },
];
export class AddPatient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideShow: false,
      addModalDiagnoseShow: false,
      isCancer: false,
      cancerStage: "",
      fields: {},
      errors: {},
      medicationName: "",
      dob: null,
      filterednamesShow: true,
      showPrimaryDiagnose: false,
      diagnoseId: null,
      name: "",
      value: "",
      index: "",
      visibility: "",
      disabled: true,
      icdCodeClassName: "",
      loading: false,
      diagnosesList: {
        data: [],
      },
      medications: [
        {
          name: "",
          dose: "",
          frequency: "",
          phone: "",
          medicationId: "",
        },
      ],
      objective: {
      },
      objectiveFlag: false,
      showField: false,
      hideShowMedication: [
        {
          index: 0,
          value: false,
        },
      ],
      medicationId: "",
      addButtonDisabled: false,
      hideShowMedicationSelect: [
        {
          index: 0,
          selectValue: true,
        },
      ],
      medicationList: {
        data: [
          {
            name: "",
          },
        ],
      },
      initilizeDiagnose: [],
      addModalMedicationShow: false,
      token: localStorage.getItem("token"),
      type: "",
      initilizeMedication: [],
      medType: "",
      validateTrue: true,
      medicaionVisibility: ""
    };
    this.handleChangeKey = this.handleChangeKey.bind(this);
    this.addMedicationList = this.addMedicationList.bind(this);
    this.handleInputNameChange = this.handleInputNameChange.bind(this);
    this.handleInputDoseChange = this.handleInputDoseChange.bind(this);
    this.handleInputFrequencyChange = this.handleInputFrequencyChange.bind(
      this
    );
    this.handlePersonalDateChange = this.handlePersonalDateChange.bind(this);
  }

  componentDidMount() { }
  handleChange(e, string) {
    if (string === "cancerStage") {
      this.setState({
        cancerStage: e.target.value,
      });
    }
  }
  addMedicationList(e) {
    e.preventDefault();
    let array = this.state.medications;
    array.push({
      id: array.length + 1, name: "", dose: "", frequency: "", medicationId: "",
    });
    let x = this.state.hideShowMedication;
    x.push({ index: x.length + 1, value: false });
    let select = this.state.hideShowMedicationSelect;
    select.push({ index: select.length + 1, selectValue: true });
    this.setState({
      medications: array,
      hideShowMedication: x,
      hideShowMedicationSelect: select,
      addButtonDisabled: true,
    });
  }

  handleChangeMedicationKey(e, index) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[index].name = e.target.value;

    const medicationName = nextMedicalData[index].name;
    this.searchMedicationList(medicationName, index);
    this.setState({
      medications: nextMedicalData,
      index: index,
      addButtonDisabled: true,
      medicationName: e.target.value
    });

    if (this.state.medicationList.data.length === 0) {
      this.setState({ medicaionVisibility: "none", disabled: true });
    } else {
      if (this.state.medicationList.data.length > 0) {
        this.setState({ medicaionVisibility: "block", disabled: true });
      }
    }
  }
  handleInputNameChange(e, object, idx) {
    let nextMedicalData = this.state.medications.slice();
    if (object.id !== "" || object.id !== undefined || object.id !== null) {
      nextMedicalData[idx].name = object.name;
      nextMedicalData[idx].medicationId = object.id;
      this.setState({
        medications: nextMedicalData,
        disabled: false,
        index: idx,
        medicationName: e,
        addButtonDisabled: false,
        validateTrue: true
      });
      let x = this.state.hideShowMedication;
      x[idx].value = false;
      let select = this.state.hideShowMedicationSelect;
      x[idx].selectValue = true;

      this.setState(
        {
          hideShowMedication: x,
          hideShowMedicationSelect: select,
        },
        () => { }
      );
    }
  }
  handlePersonalDateChange(value, dateString) {

    this.setState({
      dob: value,
      disabled: false,
    });
  }
  searchMedicationList(name, idx) {
    authServices
      .getMedicationByName(name)
      .then((response) => {
        this.setState({
          medicationList: response.data,
        });
        let nextMedicalList = response.data.data;
        let errors = {}
        const string1 = name;
        const string2 = nextMedicalList[idx]?.name;
        const result = string1.localeCompare(string2);
        const finalResult = result === 0 ? true : false;

        let nextMedicalData = this.state.medications.slice();
        nextMedicalData[idx].medicationId = nextMedicalList[idx]?.id;

        if (response.data.data.length === 0) {
          let x = this.state.hideShowMedication;
          x[idx].value = true;
          this.setState(
            {
              hideShowMedication: x,
            },
            () => { }
          );
        }
        if (response.data.data.length > 0) {
          if (finalResult === false && string1 !== "" && this.state.medType === "") {
            let x = this.state.hideShowMedication;
            x[idx].value = true;

            errors["notFoundMedication"] =
              "Please choose any from Suggested Medication or";
            this.setState(
              {
                hideShowMedication: x,
                validateTrue: false,
                medications: nextMedicalData
              },
              () => { }
            );
          } else {
            let x = this.state.hideShowMedication;
            x[idx].value = false;
            let select = this.state.hideShowMedicationSelect;
            x[idx].selectValue = true;

            this.setState(
              {
                hideShowMedication: x,
                hideShowMedicationSelect: select,
                validateTrue: true,
                medications: nextMedicalData
              },
              () => { }
            );
          }
        }

        this.setState({ errors: errors });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleInputDoseChange(e, idx) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[idx].dose = e.target.value;
    this.setState({ medications: nextMedicalData, disabled: false });
  }

  handleInputFrequencyChange(e, idx) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[idx].frequency = e.target.value;
    this.setState({ medications: nextMedicalData, disabled: false });
  }

  handleRemoveSocial(idx) {
    let someArray = this.state.medications;
    someArray.splice(idx, 1);
    this.setState({
      medications: someArray, addButtonDisabled: false,
    });
  }

  addModalMedication(e) {
    this.setState({ addModalMedicationShow: true });
  }

  handleValidation(key, name, isCancer) {
    let fieldsPrimaryDiagnose = this.state;
    let fields = this.state.fields;
    let objective = this.state.objective

    let errors = {};
    let formIsValid = true;
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "This field is required";
    }
    if (typeof fields["email"] !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      } else if (fields["email"].length > 255) {
        errors["email"] = "Please enter a short email";
      }
    }
    if (fieldsPrimaryDiagnose["name"] === "") {
      if (!fieldsPrimaryDiagnose["name"]) {
        formIsValid = false;
        errors["name"] = "This field is required";
        // this.setState({ showPrimaryDiagnose: false, hideShow: false });
      }
    } else if (this.state.filterednamesShow === false && this.state.diagnoseId === null) {
      formIsValid = false;
      this.setState({ showPrimaryDiagnose: true, hideShow: true });
      errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
    } else if (this.state.type === "" && this.state.diagnosesList.data.length === 0) {

      formIsValid = false;
      this.setState({ showPrimaryDiagnose: true, hideShow: true });
      errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
    } else if (this.state.type === "AddDiagnose" && this.state.initilizeDiagnose.length > 0) {

      formIsValid = true;
      this.setState({ showPrimaryDiagnose: false, hideShow: false, validateTrue: true });
      errors["notFound"] = "";
    } else if (
      this.state.diagnoseId === null ||
      fieldsPrimaryDiagnose["name"] === ""
    ) {
      formIsValid = false;
      errors["name"] = "";
      errors["notFound"] = "";
      this.setState({ showPrimaryDiagnose: false, hideShow: false });
    }
    if (isCancer === true) {
      // formIsValid = true;
      if (fieldsPrimaryDiagnose["cancerStage"] !== "") {
        formIsValid = true;
        errors["canncerStage"] = "";
        errors["notFound"] = "";
        this.setState({ showPrimaryDiagnose: false, hideShow: false });
      }
    }

    if (Object.keys(objective).length === 0) {
      this.setState({
        objectiveFlag: true
      })
      // formIsValid = false;
    } else if (Object.keys(objective).length > 0) {
      this.setState({
        objectiveFlag: false
      })
      // formIsValid = true;
    }
    if (this.state.medType === "" && this.state.medicationList.data.length === 0) {
      formIsValid = false;
      this.setState({ showMedication: true });
      errors["notFoundMedication"] =
        "Please choose any from Suggested Medication or";
    } else if (this.state.medType === "AddMedication" && this.state.initilizeMedication.length > 0) {
      formIsValid = true;
      this.setState({ showMedication: false });
      errors["notFoundMedication"] =
        "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChangePrimaryDiagnose(e, object) {
    if (object.id !== "" || object.id !== undefined || object.id !== null) {
      this.setState({
        name: e,
        diagnoseId: object.id,
        hideShow: false,
        showPrimaryDiagnose: false,
        disabled: false,
      });
      if (object.isCancer === true) {
        this.setState({
          name: e,
          diagnoseId: object.id,
          isCancer: true,
          disabled: false,
          hideShow: false,
          showPrimaryDiagnose: false,
          icdCodeClassName: "mb-1 mt-1",
        });
      } else if (object.isCancer === false) {
        this.setState({
          name: e,
          diagnoseId: object.id,
          isCancer: false,
          disabled: false,
          showPrimaryDiagnose: false,
          hideShow: false,
          icdCodeClassName: "",
        });
      }
    }
  }

  handleObjectiveValidation() {
    let objective = this.state.objective
    let formIsValid = true;

    if (Object.keys(objective).length === 0) {
      this.setState({
        objectiveFlag: true
      })
      formIsValid = false;
    } else if (Object.keys(objective).length > 0) {
      this.setState({
        objectiveFlag: false
      })
      formIsValid = true;
    }
    return formIsValid;
  }


  handleChangeKey(e, string) {
    this.searchDiagnose(e.target.value);
    this.setState({
      name: e.target.value,
      disabled: false,
    });
    if (this.state.diagnosesList.data.length === 0) {
      this.setState({
        isCancer: false,

        visibility: "none"

      });
    } else {
      if (this.state.diagnosesList.data.length > 0) {
        this.state.diagnosesList.data.forEach(
          // eslint-disable-next-line array-callback-return
          (optionName, index) => {
            if (optionName.isCancer === true) {
              this.setState({
                name: e.target.value,
                isCancer: true,
                visibility: "block",
                disabled: false,
              });
            } else if (optionName.isCancer === false) {
              this.setState({
                isCancer: false,
                name: e.target.value,
                visibility: "none",
                disabled: false,
              });
            }
          }
        );
      }
    }
  }

  searchDiagnose(name) {
    authServices
      .getDiagnoseByName(name)
      .then((response) => {
        this.setState({
          diagnosesList: response.data,
        });
        if (response.data.data.length === 0) {
          this.setState({
            hideShow: true,
            isCancer: false,
            visibility: "none",
          });
        }
        if (response.data.data.length > 0) {
          var filterednames = response.data.data.filter(obj => {
            return (obj.name === this.state.name);
          });
          if (filterednames.length === 0 && this.state.type === "") {
            this.setState({
              filterednamesShow: false,
              visibility: "block",
            });
          } else {
            this.setState({
              filterednamesShow: true,
              visibility: "block",
              diagnoseId: filterednames[0]?.id,
              isCancer: filterednames[0]?.isCancer
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleCheckboxesObjective(field, e) {
    let objective = this.state.objective;
    objective[field] = e.target.checked;
    this.setState({ objective });
    if (field === "other") {
      if (e.target.checked === true) {
        this.setState({ showField: true, objective, disabled: false });
      } else if (e.target.checked === false) {
        this.setState({ showField: false, objective, disabled: false });
      }
    }
  }

  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields, disabled: false, loading: false });
  }

  addModalDiagnose(e) {
    e.preventDefault();
    this.setState({ addModalDiagnoseShow: true });
  }
  addModalDiagnoseClose = () => this.setState({ addModalDiagnoseShow: false });
  diagnoseSearchStatus = () => {
    this.setState({ showPrimaryDiagnose: false, hideShow: false });
  };

  medicationSearchStatus = () => {
    this.setState({ addButtonDisabled: false });
  };

  diagnoseId = (id, isCancer) => {
    this.setState({ diagnoseId: id, isCancer: isCancer });
  };
  medicationId = (medications, hideShowMedication) => {
    this.setState({ medications: medications, hideShowMedication: hideShowMedication });
  };

  initiliazeDiagnoseList = (diagnoseList, string) => {
    this.setState({
      initilizeDiagnose: diagnoseList,
      type: string
    });
  }

  initiliazeMedicationList = (medicationList, string) => {
    this.setState({
      initilizeMedication: medicationList,
      medType: string
    })
  }

  addModalMedicationClose = () => {
    this.setState({ addModalMedicationShow: false });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    if (
      this.handleValidation(
        this.state.diagnoseId,
        this.state.name,
        this.state.isCancer,
        this.state.objective
      ) && this.state.validateTrue
    ) {
      let addPatientInformation = this.state.fields;
      addPatientInformation.firstName = this.state.fields.firstName;
      addPatientInformation.email = this.state.fields.email;
      addPatientInformation.previousExperience = this.state.fields.previousExperience;
      addPatientInformation.icdCode = this.state.fields.icdCode;

      if(this.state.dob !== null) {
        var fullDate = moment(this.state.dob, "MM-DD-YYYY");
        var date = fullDate.format("DD");
        var year = fullDate.format("YYYY");
        var month = fullDate.format("MM");
        const selectedDate = month + "-" + date + "-" + year;
        addPatientInformation.dob = this.state.dob !== null || this.state.dob === " " ? selectedDate : null;
      } else {
        addPatientInformation.dob = null
      }

      addPatientInformation.gender = this.state.fields.gender;
      if (this.state.isCancer === true) {
        addPatientInformation.cancerStage = this.state.cancerStage;
      } else if (this.state.isCancer === false) {
        addPatientInformation.cancerStage = "";
      }
      addPatientInformation.name = this.state.name;
      addPatientInformation.diagnoseId = this.state.diagnoseId;
      addPatientInformation.medications = this.state.medications;
      addPatientInformation.objectiveOther = this.state.fields.objectiveOther;
      addPatientInformation.objective = this.state.objective;
      await axios
        .post(
          environment.endPoint + "physician/addPatient",
          addPatientInformation,
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {

            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.props.history.push("/physician/list-patient");

            let fields = this.state.fields;
            fields["firstName"] = "";
            fields["lastName"] = "";
            fields["gender"] = "";
            fields["objectiveOther"] = "";
            fields["email"] = "";
            fields["previousExperience"] = "";
            fields["icdCode"] = "";

            this.setState({
              objective: {},
              cancerStage: "",
              name: "",
              diagnoseId: null,
              loading: false,
              dob: null,
              medications: [
                {
                  name: "",
                  dose: "",
                  frequency: "",
                  phone: "",
                },
              ],
            });

            this.setState({
              disabled: true,
            });
          } else if (response.status === undefined || response.status === 400) {
            toast.error('Please use another email as this email is already registed', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }).catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.data, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  };

  render() {
    return (
      <div className="auth-form-phyiscian-patient add-new-patient-phys mb-5">
        <form>
          <div className="container custom-card-patient">
            <h3 className="text-center stepper-form-headings">
              Add New Patient
            </h3>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control py-4"
                    placeholder="Patient First Name *"
                    type="text"
                    value={this.state.fields.firstName || ""}
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "firstName"
                    )}
                  />
                  <label className="label-helper py-2 px-1">
                    Patient First Name{" "}
                    <strong className="text-danger">*</strong>
                  </label>
                  <span className="text-danger">
                    {this.state.errors["firstName"]}
                  </span>
                </div>

              </div>
              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control py-4"
                    placeholder="Patient Last Name *"
                    type="text"
                    value={this.state.fields.lastName || ""}
                    onChange={this.handleUserInputChange.bind(this, "lastName")}
                  />
                  <label className="label-helper py-2 px-1">
                    Patient Last Name <strong className="text-danger">*</strong>
                  </label>
                  <span className="text-danger">
                    {this.state.errors["lastName"]}
                  </span>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control py-4"
                    placeholder="Patient Best Email *"
                    type="text"
                    value={this.state.fields.email}
                    onChange={this.handleUserInputChange.bind(this, "email")}
                  />
                  <label className="label-helper py-2 px-1">
                    Patient Best Email{" "}
                    <strong className="text-danger">*</strong>
                  </label>
                  <span className="text-danger">
                    {this.state.errors["email"]}
                  </span>
                </div>

              </div>

              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      
                      placeholder="Patient Date of Birth"
                      className={
                        this.state.dob === "" ||
                          this.state.dob === null ||
                          this.state.dob === undefined
                          ? "disabled-option form-control custom-form-control"
                          : " form-control custom-form-control"
                      }
                      openTo="date"
                      value={this.state.dob}
                      format="MM-dd-yyyy"
                      views={["year", "month", "date"]}
                      onChange={this.handlePersonalDateChange}
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                  <label className={`label-helper ${this.state.dob === "" ||
                    this.state.dob === null ||
                    this.state.dob === undefined ? 'label-helper-opacity-0' : "label-helper-opacity-1"} py-2 px-1`}>
                    Patient Date of Birth
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row">
                  <select
                    className={
                      this.state.fields.gender === "" ||
                        this.state.fields.gender === null ||
                        this.state.fields.gender === undefined
                        ? "disabled-option form-control custom-form-control"
                        : "form-control custom-form-control"
                    }
                    onChange={this.handleUserInputChange.bind(this, "gender")}
                    value={this.state.fields.gender || ""}
                  >
                    <option value="" disabled selected>
                      {" "}
                      What sex patient was assigned at birth ?
                    </option>
                    {gender.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <label className="label-helper py-2 px-1">
                    {" "}
                    What sex were you assigned at birth ?{" "}
                  </label>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row">
                  <select
                    //   value={this.state.previousExperience}
                    className={
                      this.state.fields.previousExperience === "" ||
                        this.state.fields.previousExperience === null ||
                        this.state.fields.previousExperience === undefined
                        ? "disabled-option form-control custom-form-control"
                        : "form-control custom-form-control"
                    }
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "previousExperience"
                    )}
                  >
                    <option value="" disabled selected>
                      {" "}
                      What is the patient previous experience with cannabis?
                    </option>
                    {cannabis.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <label className="label-helper py-2 px-1">
                    What is the patient(s) experience with cannabis?{" "}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="custom-form-row autocomplete-input">
                  <ReactAutocomplete
                    menuStyle={{ display: this.state.visibility }}
                    items={this.state.diagnosesList?.data}
                    getItemValue={(item) => item.name}
                    shouldItemRender={(item, value) =>
                      item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    inputProps={{
                      placeholder: "Principal / Primary Diagnosis *",
                      className: "form-control custom-form-control",
                    }}
                    renderItem={(item, isHighlighted, styles) => (
                      <div>
                        <div
                          key={item.id}
                          style={{
                            background: isHighlighted ? "#1473E6" : "white",
                            color: isHighlighted ? "white" : "#2C2D2F",
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    )}
                    value={this.state.name}
                    onChange={this.handleChangeKey}
                    onSelect={(value, object) =>
                      this.handleChangePrimaryDiagnose(value, object)
                    }
                  />
                  <i
                    className="fa fa-2x fa-question-circle text-primary"
                    aria-hidden="true"
                    title="What is your primary diagnosis?"
                  ></i>
                  <label className=" label-helper py-2 px-1">
                    Principal / Primary Diagnosis{" "} <strong className="text-danger">*</strong>
                  </label>


                  <span className="text-danger">{this.state.errors["name"]}</span>

                </div>
                {this.state.hideShow === true ? (
                  <p className="pull-left mb-0">
                    {this.state.showPrimaryDiagnose === true ? (

                      <span className="text-danger">
                        {this.state.errors["notFound"]}
                      </span>

                    ) : (
                      <span>Please Choose any from Suggested Diagnosis or</span>
                    )}
                    <Link
                      href={this.state.url}
                      onClick={this.addModalDiagnose.bind(this)}
                    >
                      {" "}
                      Add New Diagnose
                    </Link>{" "}
                  </p>
                ) : (
                  <>

                    {/* {
                      this.state.filterednamesShow === true ? <> <span className="text-danger">Please Choose any from Suggested Diagnosis or</span>
                        <Link
                          href={this.state.url}
                          onClick={this.addModalDiagnose.bind(this)}
                        >
                          {" "}
                          Add New Diagnose
                        </Link>{" "}

                      </> : ""
                    }   */}
                  </>
                )}
                <DiagnosisModal
                  addModalDiagnoseShow={this.state.addModalDiagnoseShow}
                  onHideModal={this.addModalDiagnoseClose}
                  name={this.state.name}
                  onUpdate={this.diagnoseSearchStatus}
                  diagnoseId={this.diagnoseId}
                  initiliazeDiagnoseList={this.initiliazeDiagnoseList}
                />
              </div>

              {this.state.isCancer === true ? (
                <div className="col-lg-6 col-md-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.cancerStage}
                      className={
                        this.state.cancerStage === "" ||
                          this.state.cancerStage === null ||
                          this.state.cancerStage === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "cancerStage");
                      }}
                    >
                      <option value=""> Select Stage of Cancer</option>
                      {stageCancer.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Stage of Cancer
                    </label>

                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-lg-6 col-md-6">
                <div
                  className={`custom-form-row ${this.state.icdCodeClassName}`}
                >
                  <input
                    type="text"
                    className="form-control custom-form-control"
                    placeholder="ICD-10 Code (if known)"
                    value={this.state.fields.icdCode}
                    onChange={this.handleUserInputChange.bind(this, "icdCode")}
                  />
                  <label className="label-helper py-2 px-1">
                    ICD-10 Code (if known){" "}
                  </label>
                  <i
                    className="fa fa-2x fa-question-circle text-primary"
                    aria-hidden="true"
                    title="This is the statistical classification for your
                        condition used by medical professionals. You are not
                        required to answer this question."
                  ></i>
                </div>
              </div>
            </div>

            <label className="text-left stepper-form-sub-headings ">
              Please list ALL prescribed and over-the-counter medications,
              patient taken regularly or as needed.
            </label>
            <div className="row">
              <div className="col-lg-4 col-md-3">
                <label className="text-primary stepper-form-sub-headings">
                  Medication Name
                </label>
              </div>
              <div className="col-lg-3 col-md-3">
                <label className="text-primary stepper-form-sub-headings">
                  Dose
                </label>
              </div>
              <div className="col-lg-2 col-md-3">
                <label className="text-primary stepper-form-sub-headings text-left">
                  Frequency
                </label>
              </div>
            </div>

            {this.state.medications.map((value, index) => {
              return (
                <div key={`${value.id}-${index}`} className="row mb-2">
                  <div className="col-lg-4 col-md-3 ">
                    {this.state.hideShowMedicationSelect[index]?.selectValue ===
                      true ? (
                      <div className="custom-form-row autocomplete-input">
                        <ReactAutocomplete
                          menuStyle={{ display: this.state.medicaionVisibility }}
                          items={this.state.medicationList.data}
                          getItemValue={(item) => item.name}
                          shouldItemRender={(item, value) =>
                            item.name.toLowerCase().indexOf(value.toLowerCase()) >
                            -1
                          }
                          inputProps={{
                            className: "form-control custom-form-control",
                          }}
                          renderItem={(item, isHighlighted, styles) => (
                            <div>
                              <div
                                key={item.id}
                                style={{
                                  background: isHighlighted ? "#1473E6" : "white",
                                  color: isHighlighted ? "white" : "#2C2D2F",
                                }}
                              >
                                {item?.name}
                              </div>
                            </div>
                          )}
                          value={value.name || ""}
                          onChange={(e) => this.handleChangeMedicationKey(e, index)}
                          onSelect={(value, object) =>
                            this.handleInputNameChange(value, object, index)
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}

                  </div>

                  <div className="col-lg-3 col-md-3">
                    <input
                      className="form-control custom-form-row custom-form-control py-4"
                      value={value.dose || ""}
                      onChange={(e) => this.handleInputDoseChange(e, index)}
                    />
                  </div>
                  <div className="col-lg-3 col-3">
                    <input
                      className="form-control custom-form-control py-4"
                      value={value.frequency || ""}
                      onChange={(e) =>
                        this.handleInputFrequencyChange(e, index)
                      }
                    />
                  </div>


                  {this.state.medications.length > 1 ? (
                    <div className="col-lg-1 col-2">
                      <label></label>
                      <div className="custom-btn">
                        <button
                          type="button"
                          onClick={this.handleRemoveSocial.bind(this, index)}
                          className="btn btn-danger child-btn border-radius mb-1"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.hideShowMedication[index]?.value === true ? (
                    <p className="pull-right ml-3">
                      {this.state.showMedication === true ? (
                        <span className="text-danger">
                          {this.state.errors["notFoundMedication"]}
                        </span>
                      ) : (
                        <span className="text-danger">
                          Please choose any from Suggested Medication or
                        </span>
                      )}
                      <Link onClick={this.addModalMedication.bind(this)}>
                        {" "}
                        Add New Medication
                      </Link>{" "}
                    </p>
                  ) : (
                    ""
                  )}
                  <Medication
                    addModalMedicationShow={this.state.addModalMedicationShow}
                    hideModal={this.addModalMedicationClose}
                    user_id={this.state.user_id}
                    medicationId={this.medicationId}
                    medicationName={this.state.medicationName}
                    initiliazeMedicationList={this.initiliazeMedicationList}
                    onUpdate={this.medicationSearchStatus}
                    medications={this.state.medications}
                    index={this.state.index}
                    hideShowMedication={this.state.hideShowMedication}
                  />
                </div>
              );
            })}
            <div className="row mb-4">
              <div className="col-lg-1 col-1">
                <button
                  // disabled={this.state.addButtonDisabled === true ? true : false}
                  type="button"
                  className="btn btn-primary child-btn border-radius pull-left plus-button"
                  onClick={this.addMedicationList}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="row mb-4 add-patient">
              <div className="col-lg-12 col-12 mt-2">
                <h4 className="stepper-form-sub-headings">
                  What is patient objective? <strong className="text-danger">*</strong>
                </h4>

                <div className="objective-input" data-toggle="buttons">
                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.antiCancerTherapy === true
                      ? "active"
                      : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.antiCancerTherapy}
                      value={"antiCancerTherapy"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "antiCancerTherapy"
                      )}
                    />
                    Anti-cancer Therapy
                  </label>

                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.manageSideEffectsofChemo === true
                      ? "active"
                      : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.manageSideEffectsofChemo}
                      value={"manageSideEffectsofChemo"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "manageSideEffectsofChemo"
                      )}
                    />
                    Manage Side Effects of Chemo
                  </label>

                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.painRelief === true ? "active" : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.painRelief}
                      value={"painRelief"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "painRelief"
                      )}
                    />
                    Pain Relief
                  </label>

                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.mentalHealth === true
                      ? "active"
                      : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.mentalHealth}
                      value={"mentalHealth"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "mentalHealth"
                      )}
                    />
                    Mental Health
                  </label>

                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.pharmaceuticalReplacement === true
                      ? "active"
                      : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.pharmaceuticalReplacement}
                      value={"pharmaceuticalReplacement"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "pharmaceuticalReplacement"
                      )}
                    />
                    Pharmaceutical Replacement
                  </label>
                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.seizureControl === true
                      ? "active"
                      : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.seizureControl}
                      value={"seizureControl"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "seizureControl"
                      )}
                    />
                    Seizure Control
                  </label>

                  <label
                    className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.other === true ? "active" : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      id="options"
                      name="options"
                      autoComplete="off"
                      checked={this.state.objective?.other}
                      value={"other"}
                      onChange={this.handleCheckboxesObjective.bind(
                        this,
                        "other"
                      )}
                    />
                    Other
                  </label>
                </div>
                {this.state.objectiveFlag === true ?
                  <span className="text-danger"> This field is Required </span>
                  : ""}

              </div>

            </div>

            {this.state.showField === true ? (
              <div>
                <div className="row mb-4">
                  <div className="col-lg-6 col-6">
                    <div className="custom-form-row">
                      <input
                        className="form-control custom-form-control"
                        placeholder="Your Objective"
                        value={this.state.fields?.objectiveOther}
                        onChange={this.handleUserInputChange.bind(
                          this,
                          "objectiveOther"
                        )}
                      />
                      <label className="label-helper py-2 px-1">
                        Your patient Objective{" "}
                      </label>
                    </div>
                  </div>
                </div>
                {/* {this.state.showOtherFieldAdministration === true ? (

                   ) : (
                      ""
                    )} */}
              </div>
            ) : (
              ""
            )}
            {this.state.loading ? (
              <div className="col-lg-2">
                <div class="loading">Loading&#8230;</div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-lg-5 offset-lg-5 col-5 offset-4 mt-4">
                <button
                  disabled={this.state.disabled ? true : false}
                  onClick={this.onSubmit}
                  className="btn btn-primary add-new-patient-btn py-2  px-4"
                >
                  Add New Patient
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(AddPatient);
