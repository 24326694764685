import { Link } from "react-router-dom";
import axios from 'axios'
import React, { Component } from 'react'
import { environment } from '../../environment'
import moment from "moment";
export class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem("token"),
      notifications: [],
      showhide: false,
      visible: 5,
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {

  }

  loadMore = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 5 };
    });
  }



  onReadNotifications = async (id) => {
    const param = {
      id: id
    }
    await axios
      .post(environment.endPoint + "dashboard/notifications", param, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.props.onHide()
        }
      })
      .catch((e) => {
      });
  }

  render() {
    return (
      <div className={`main-right-side-notice ${this.props.notificationHideShow === true ? "noti-drawer-open" : ""}`}>
        <div className="notice-bar">
          <div className="header">
            <h3>Notifications</h3>
            <Link onClick={() => this.props.onHide()}><i class="fa fa-times" aria-hidden="true"></i></Link>
          </div>

          {
            this.props.notifications.slice(0, this.state.visible).map((value, index) =>
              <div className="notificaton-group fade-in" >
                <div className={`single-notification ${value.isRead === true ? "read-notification" : ""}`}>
                  <div className="left-col">
                    <i class="fa fa-bell" aria-hidden="true"></i>
                  </div>
                  <Link to={value.link} className="right-col" onClick={() => this.onReadNotifications(value.id)}>
                    <span className="notify-info">{value.body} <strong><span className="notify-info">  {moment(value.createdAt)
                      .local()
                      .format("MMMM-DD-YYYY")}{" "}
                              , {moment(value.createdAt).local().format("LTS")}</span></strong>   </span>
                  </Link>
                </div>
              </div>
            )
          }

          {this.props.notifications.length === 0 ? <h6 className="text-center text-secondary"> No Notification Found  </h6> : ""}
          {this.state.visible < this.props.notifications.length && (
            <div class="container load-more">
              <div class="row">
                <div class="col col-lg-7 offset-lg-3 text-center">
                  <button
                    onClick={this.loadMore}
                    type="button"
                    className="btn btn-primary submit mb-4"
                  >
                    Load more
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>

      </div>


    )
  }
}

export default Notification
