import React, { Component } from 'react'

 class Footer extends Component {
    render() {
        return (
            <footer className="footer">
            <div className="row py-3">
               <div className="col-lg-12 col-12">
                <p className="text-center copyright">
                  Copyright © 2021 Octopi Wellness. All rights reserved  Privacy Policy Site Map             
                </p>
               </div>
            </div>
            </footer>
        )
    }
}

export default Footer
