import React, { Component } from 'react'

export class FamilyHistory extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    componentDidMount() {
        this.setState({
          patientProfile: this.props.patientProfile,
        });
      }
    
      componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
          this.setState({
            patientProfile: this.props.patientProfile,
          });
        }
      }

    render() {
        const { patientProfile } = this.state;

        return (
            <div className="card-body custom-inner-content  ">
              <h5 className="stepper-form-headings ">
                Family History
              </h5>
    
       {
          patientProfile?.FamilyHistory === null ? <h4 className="font-italic">No Family History Information Added</h4> : <>
          <label className="text-left stepper-form-sub-headings">
                Have any of your immediate family members had any of the following
                conditions?
              </label>

                            
    
              <div className="row mb-4 mt-3">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Alcoholism</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-422"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.alcoholism}
                      
                    />
                    <label htmlFor="switch-422" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Anemia</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-511"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.anemia}
                    />
                    <label htmlFor="switch-511" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
              
              
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Anesthesia problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-611"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.anesthesia}
                    />
                    <label htmlFor="switch-611" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Arthritis</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-711"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.arthritis}
                    />
                    <label htmlFor="switch-711" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Asthma</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-811"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.asthma}
                    />
                    <label htmlFor="switch-811" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Birth Defects</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-911"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.birthDefects}
                    />
                    <label htmlFor="switch-911" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Bleeding problems</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-101"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.bleedingProblems}
                      
                    />
                    <label htmlFor="switch-101" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Cancer</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-111"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.cancer}
                    />
                    <label htmlFor="switch-111" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                {/* (polyps, colitis) */}
                  <p className="text-left">Colon Problems </p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-121"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.colonProblems}
                    />
                    <label htmlFor="switch-121" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Crohn’s Disease</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-131"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.crohnDisease}
                    />
                    <label htmlFor="switch-131" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Depression</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-141"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.depression}
                    />
                    <label htmlFor="switch-141" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                {/* (child) */}
                  <p className="text-left">Diabetes, Type 1 </p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-151"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.diabetesType1}
                    />
                    <label htmlFor="switch-151" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                {/* (adult) */}
                  <p className="text-left">Diabetes, Type 2 </p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-161"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.diabetesType2}
                    />
                    <label htmlFor="switch-161" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                  <p className="text-left">Eczema Other</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-171"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.eczemaOther}
                    />
                    <label htmlFor="switch-171" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                {/* (Seizures) */}
                  <p className="text-left">Epilepsy</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-181"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.epilepsy}
                    />
                    <label htmlFor="switch-181" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
    
                <div className="col-lg-4 col-12">
                  <p className="text-left">Genetic diseases</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-191"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.geneticDiseases}
                    />
                    <label htmlFor="switch-191" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Glaucoma</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-201"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.glaucoma}
                    />
                    <label htmlFor="switch-201" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                {/* (Allergies) */}
                  <p className="text-left">Hay fever</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-211"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.hayFever}
                    />
                    <label htmlFor="switch-211" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                {/* (CAD) */}
                  <p className="text-left">Heart Attack </p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-222"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.heartAttack}
                    />
                    <label htmlFor="switch-222" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">High Blood Pressure</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-232"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.highBloodPressure}
                     
                    />
                    <label htmlFor="switch-232" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">High cholesterol</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-242"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.highCholesterol}
                    />
                    <label htmlFor="switch-242" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Kidney diseases</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-252"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.kidneyDiseases}
                    />
                    <label htmlFor="switch-252" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Lupus (SLE)</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-261"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.lupus}
                    />
                    <label htmlFor="switch-261" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Mental retardation</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-272"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.mentalRetardation}
                      
                    />
                    <label htmlFor="switch-272" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Migraine headaches</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-281"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.migraineHeadaches}
                     
                    />
                    <label htmlFor="switch-281" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Mitral Valve Prolapse</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-292"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.mitralValveProlapse}
                      
                    />
                    <label htmlFor="switch-292" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Osteoporosis</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-301"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.osteoporosis}
                    />
                    <label htmlFor="switch-301" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Stroke (CVA)</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-311"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.stroke}
                    />
                    <label htmlFor="switch-311" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Substance abuse</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-322"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.substanceAbuse}
                    />
                    <label htmlFor="switch-322" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <p className="text-left">Thyroid disorders</p>
                </div>
                <div className="col-lg-1 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-331"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.thyroidDisorders}
                     
                    />
                    <label htmlFor="switch-331" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
    
              <div className="row mb-4">
                <div className="col-lg-4 col-12">
                  <p className="text-left">Tuberculosis</p>
                </div>
                <div className="col-lg-2 col-12 text-right">
                  <div className="switch">
                    <input disabled
                      id="switch-342"
                      type="checkbox"
                      className="switch-input"
                      checked={patientProfile?.FamilyHistory?.tuberculosis}
                    />
                    <label htmlFor="switch-342" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
            
            
           </> } 
       


            
            </div>        
        
        )
    }
}

export default FamilyHistory
