import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { environment } from "../../../environment";
import moment from "moment";

export class DosingHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      hideShow: [
        {
          open: true,
        },
      ],
      loading: true,
      dosingHistory: [],
      visible: 10,
    };
  }
  componentDidMount(){
    this.getDosingHistoryPatients()
  }

  loadMore = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 5 };
    });
  }

  getDosingHistoryPatients = async () => {
    await axios
      .get(environment.endPoint + "dashboard/shoops", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
             this.setState({
              dosingHistory: data,
              loading: false
             });
             this.getDosingHistoryList(data) 
        }
      })
      .catch((e) => {});
  };

  getDosingHistoryList(response) {
    if (response.length > 0) {
      response.forEach((value, index) => {
        let x = this.state.hideShow;
        x.push({ index: index, open: false, className: "card-link" });
        this.setState({
          hideShow: x,
        });
      });
    }
  }


  onClickAccordion = (index) => {
    console.log(index)
    let select = this.state.hideShow;
    select[index].open = !select[index].open;

    if (select[index].open === true) {
      this.setState(
        {
          hideShow: select,
        },
        () => {}
      );
    } else if (select[index]?.open === false) {
      select[index].className = "";
      this.setState(
        {
          hideShow: select,
        },
        () => {}
      );
    }
  };

  render() {
    return (
      <div className="auth-form-phyiscian-patient physicain-patient-profile">
                <h4 className="dosing-history-form-headings text-center">Dosing History</h4>
                <p className="text-center dosing-history-p"> Your medical records. Now in your Hands</p>

        <div className="container">
        {this.state.dosingHistory.slice(0, this.state.visible).map((value, index) => (
          <div className="add-symptoms-body">
            <div>
              <div className="card profile-symptoms fade-in">
                <div className="card-header">
                  <div className="row">
                    <div class="col-md-8">
                      <Link
                        className={
                          this.state.hideShow[index]?.open === true
                            ? "collapsed-minus"
                            : "card-link"
                        }
                        onClick={(e) => this.onClickAccordion(index)}
                      ></Link>

                      <div className="header-date">
                        <p>
                          
                         {value?.targetCannabinoid}
                        
                          
                        </p>
                      </div>
                      <h5 className="text-success">
                       <strong className="ml-1"> Date Created: </strong>{moment(value.createdAt)
                            .local()
                            .format("MMMM-DD-YYYY")}
                      </h5>
                    </div>
                  </div>
                </div>
                {this.state.hideShow[index]?.open === true ? (
                  <div>
                    <div className="card-body">
                      
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Target Cannabinoid:</strong>{" "}
                              {value?.targetCannabinoid}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Tarpenes:</strong> {value?.terpenes?.map((item) => item.value)?.join()}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Target Dose:</strong> {value?.dailyDose} {value?.dailyDoseUnit}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Frequency:</strong>  {value?.frequency} x {value?.frequencyUnit}
                            </td>
                          </tr>
                          {value?.ShoopDoses.map((dailyDose, index) => (
                                <tr>
                                  <td>
                                  <strong> Daily Dose {index + 1}: </strong> {dailyDose?.dose}{" "}
                                    <span>
                                      {" "}
                                      {value?.dailyDoseUnit}{" "}
                                      {dailyDose?.doseTime}{" "}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          <tr>
                            <td>
                              <strong>Ingestion Method:</strong> {value?.ingestionMethod}
                            </td>
                          </tr> 
                          <tr> 
                            <td>
                              <strong>Dosage Notes:</strong> {value?.note}
                            </td>
                          </tr>
                          <tr> 
                            <td>
                              <strong>Admin Notes:</strong> {value?.adminNotes !== null ? value?.adminNotes : <p className="text-secondary font-italic d-inline"> Admin not entered notes yet </p>}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

      ))}
       {this.state.visible < this.state.dosingHistory.length && (
            <div class="container load-more">
              <div class="row">
                <div class="col col-lg-7 offset-lg-3 text-center">
                  <button
                    onClick={this.loadMore}
                    type="button"
                    className="btn btn-primary submit mb-4"
                  >
                    Load more
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
        </div>
     
      </div>
    );
  }
}

export default DosingHistory;
