import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import AuthService from "../../../../services/authServices";
import { toast } from "react-toastify";

class DiagnoseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name:  this.props.name,
      user_id: "",
      is_cancer: "",
      errors: {},
      modalHide: true,
      showPrimaryDiagnose: false,
      hideShow: false,
      diagnosesList: {
        data: [],
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleUserInputChange = this.handleUserInputChange.bind(this);
    this.handleUserCheckInputChange = this.handleUserCheckInputChange.bind(
      this
    );
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.name !== this.props.name){
      this.setState({ name:this.props.name });
    }
  }

  handleUserInputChange(e) {
    this.setState({ name: e.target.value });
  }
  handleUserCheckInputChange(e) {
    if (e.target.checked === true) {
      this.setState({ is_cancer: true });
    }
    if (e.target.checked === false) {
      this.setState({ is_cancer: false });
    }
  }

  handleValidation() {
    let diagnose = this.state;
    let errors = {};
    let formIsValid = true;

    if (!diagnose["name"]) {
      formIsValid = false;
      errors["name"] = "This field is required";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const params = {
        name: this.state.name,
        user_id: this.props.userId,
        is_cancer: this.state.is_cancer,
      };
      AuthService.postDiagnose(params)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Diagnose Add Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            // this.setState({ user_id: response.data.data.user_id  });
            const data = []
            data.push({
              id: response.data.data.id,
              isCancer: response.data.data.isCancer
            })           
            this.props.onHideModal();
            this.props.onUpdate();
            this.props.diagnoseId(response.data.data.id, response.data.data.isCancer,response.data.data.name);
            this.props.initiliazeDiagnoseList(data,"AddDiagnose")

          }
        })
        .catch((e) => {
          // if (e.response.status === 400) {
          //   toast.error("Please check with your service provider", {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 2000,
          //   });
          // } else if (e.response.status === 500) {
          //   toast.error("Server Error", {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 2000,
          //   });
          // }
        });
    }
  }
  render() {
    return (
      <div>
        <Modal
          show={this.props.addModalDiagnoseShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-verify-modal"
        >
          <form onSubmit={this.onSubmit}>
            {/* <Modal.Header>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header> */}
            <Modal.Body>
              <h5 className="verify-modal-title p-3">Add Diagnose</h5>

              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="custom-form-row">
                  <input
                        type="text"
                        placeholder="Name"
                        className="form-control custom-form-control"
                        value={this.state.name}
                        onChange={this.handleUserInputChange}
                      />
                    <label className="label-helper py-2 px-1">Name </label>
                  </div>
                  <span className="text-danger">
                    {this.state.errors["name"]}
                  </span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-5 offset-lg-2 col-6">
                  <p className="text-left">Is it a Cancer ?</p>
                  <span className="text-danger">
                    {this.state.errors["is_cancer"]}
                  </span>
                </div>
                <div className="col-lg-2 col-1">
                  <div className="switch">
                    <input
                      id="switch-2"
                      type="checkbox"
                      className="switch-input"
                      value={this.state.is_cancer}
                      onChange={this.handleUserCheckInputChange}
                    />
                    <label htmlFor="switch-2" className="switch-label">
                      Switch
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className="verify-modal-footer">
              <button
                type="button"
                className="btn btn-white verify-button border-radius py-1  px-3"
                onClick={this.props.onHideModal}
              >
                Cancel{" "}
              </button>
              <button
                type="submit"
                className="btn btn-primary border-radius py-1  px-3"
              >
                Submit{" "}
                <i className="fa fa-location-arrow" aria-hidden="true"></i>
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
export default DiagnoseModal;
