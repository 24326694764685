import React, { Component } from "react";
import PersonalInformation from "./PersonalInformation";
import PersonalImage from "./Personal-Image";
import Password from "./Password";
import Physician from "./Physician";
import Dispensary from "./Dispensary";
import Settings from "./Setting";
import "../../../assets/scss/profile.scss";
import Stepper from "bs-stepper";
import axios from "axios";
import { environment } from '../../../environment';

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileDetail: {},
      loading: false,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#profile-update"), {
      linear: false,
      animation: true,
    });
    const token = localStorage.getItem("token");
    this.getProfile(token)
  }

  getProfile = async (token) => {
    this.setState({
      loading: true,
    });
    await axios.get(environment.endPoint + "dashboard/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            profileDetail: response.data.data,
          });
          this.setState({
            loading: false,
          });
        }
      })
      .catch((e) => { });
  }


  next(e) {
    this.stepper.next();
  }

  prev(e) {
    this.stepper.previous();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.profileDetail !== this.state.profileDetail) {
      this.setState({
        profileDetail: this.state.profileDetail,
      });
    }
  }

  render() {
    return (
      <div className="patient-intake mb-5">
        <div className="row">
          <div className="col-lg-12 col-12">
            <h5 className=" text-center stepper-form-headings custom-font mt-2">
              My Profile
            </h5>
          </div>
        </div>

        <div className="mt-2 mb-2">
          <div className="container">
            <div className="card card-border">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body p-0">
                    <div id="profile-update" class="bs-stepper">
                      <div className="row">
                        <div className="col-lg-4 col-md-3">
                          <div className="register-sidebar">
                            <div className=" d-lg-block">
                              <div className="bs-stepper-header ">
                                <div
                                  id="personal-information"
                                  className="step"
                                  data-target="#personal-information-step-1"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Personal Information
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="personal-image"
                                  className="step"
                                  data-target="#personal-image-step-2"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Profile Image
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="password"
                                  className="step"
                                  data-target="#password-step-3"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Update Password
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="physician"
                                  className="step"
                                  data-target="#physician-step-4"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Physician
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="dispensary"
                                  className="step"
                                  data-target="#dispensary-step-5"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Dispensary
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="settings"
                                  className="step"
                                  data-target="#settings-step-6"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Settings
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-9">
                          <div class="bs-stepper-content registration">
                            <div
                              className="bs-stepper-header content"
                              id="personal-information-step-1"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#personal-information-step-1">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Personal Information
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <PersonalInformation
                                next={this.next}
                                prev={this.prev}
                                profileDetail={this.state.profileDetail}
                              ></PersonalInformation>
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="personal-image-step-2"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#personal-image-step-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Personal Image
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <PersonalImage
                                next={this.next}
                                prev={this.prev}
                                profileDetail={this.state.profileDetail}
                              ></PersonalImage>
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="password-step-3"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#password-step-3">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Update Password
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Password
                                next={this.next}
                                prev={this.prev}
                                profileDetail={this.state.profileDetail}
                              ></Password>
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="physician-step-4"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#physician-step-4">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Physician
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Physician next={this.next} prev={this.prev}>
                                {" "}
                              </Physician>
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="dispensary-step-5"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#dispensary-step-5">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Dispensary
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Dispensary next={this.next} prev={this.prev}>
                                {" "}
                              </Dispensary>
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="settings-step-6"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#settings-step-6">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Settings
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Settings
                                next={this.next}
                                prev={this.prev}
                                profileDetail={this.state.profileDetail}
                              >
                                {" "}
                              </Settings>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Profile;
