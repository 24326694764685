import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import authServices from "../../../../services/authServices";
import axios from "axios";
import { environment } from "../../../../environment";

class Verify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      login: "",
      showHideVerify: true,
      showHideResendOtp: true,
      showLoader: false,
      user_id: "",
      email : "",
      token: localStorage.getItem("token"),
    };
    this.next = this.next.bind(this);
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  handleChange = (otp) => {
    const code = otp
    const checkLend =code.toString().length;
    this.setState({ otp });
    if(checkLend === 6){
      this.setState({ otp,showHideVerify: false });
    } else {
      this.setState({showHideVerify: true, showHideResendOtp: false });
    }
  };

  modalSubmit(e) {
    const params = {
      code: this.state.otp,
      phone: this.props.phone,
    };
    this.setState({
      showLoader: true
    });  
    authServices
      .verifyPhone(params)
      .then((response) => {
        if (response.status === 200) {
          if (this.props.login === true) {
            this.props.redirect();
            toast.success("Login Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({
              otp: "",
              phone: "",
              showHideVerify: true,
              showLoader: false
            });  
            this.props.onHide();
          } else if (this.props.registration === true) {
            let personalInformation = this.props.fields;
            personalInformation.user_id = this.state.user_id
            authServices
              .personalInformation(personalInformation)
              .then((response) => {
                if (response.status === 200) {
                  this.setState({
                    otp: "",
                    phone: "",
                    user_id: response.data.data.user_id,
                    showHideVerify: true,
                    showLoader: false
                  });
                  
                 localStorage.setItem("userId", response.data.data.user_id)
                 document.getElementById("Input").setAttribute("readonly", "true");
                 document
                   .getElementById("confirmEmail")
                   .setAttribute("readonly", "true");
                 document
                   .querySelector(".react-tel-input")
                   .children[1].setAttribute("readonly", "true");
                  toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  });
                  this.props.next();
                  this.props.onHide();
                  
                }
              })
              .catch((e) => {
                if (e.response.status === 400) {
                  this.setState({
                    user_id: response.data.data.user_id,
                    showLoader: false,
                    showHideVerify: true
                  }); 
                  toast.error(e.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  });
                } else if(e.response.status === 500){
                  this.setState({
                    user_id: response.data.data.user_id,
                    showLoader: false,
                    showHideVerify: true
                  }); 
                  toast.error(e.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  });
                }
              });
            
          } else if (this.props.physicianRegistration === true) {
            const formData = new FormData();
            formData.append("firstName", this.props.fields.firstName);
            formData.append("lastName", this.props.fields.lastName);
            formData.append("email", this.props.fields.email);
            formData.append("phone", this.props.phone);
            formData.append("file", this.props.file);
            formData.append("officeNumber", this.props.officeNumber);
            formData.append("officeEmail", this.props.fields.officeEmail);
            formData.append("specialization", this.props.fields.specialization.label);

            authServices
              .physicianRegistrationInformation(formData)
              .then((response) => {
                if (response.status === 200) {
                  this.setState({
                    otp: "",
                    phone: "",
                    showHideVerify: true,
                    showLoader: false
                  });
                                
                  toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  });
                  this.props.onHide();
                  this.props.history.push("/checkMail");
                }
              })
              .catch((e) => {
                if (e.response.status === 400) {
                  this.setState({
                    showLoader: false,
                    showHideVerify: true,
                  }); 

                  toast.error(e.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  });
                } else if(e.response.status === 500) {
                  this.setState({
                    showLoader: false,
                    showHideVerify: true,
                  }); 
                  toast.error(e.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  });
                }
              });
            
          } else if(this.props.myProfile === true){
              let profileInformation = this.props.profileFields;
                profileInformation.country = this.props.country;
                profileInformation.state = this.props.state;
                profileInformation.phone = this.props.phone;

             axios.post(environment.endPoint + "dashboard/profile", profileInformation, {
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            })
            .then((response) => {
              if (response.status === 200 || response.status !== undefined) {
                toast.success("Personal Information Update Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
                this.props.nextProfile();
                this.props.onHide();

              }
            })
            .catch((e) => {
              if (e.response.status === 400) {
                toast.error("Personal Information Not Updated", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              }
            });
        }
        else if(this.props.completeSignUp === true){
            const params = {
              code: this.props.code,
              phone: this.props.phone
            }

         axios.post(environment.endPoint + "userAuth/setPhone", params, {
        })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.props.onHide();
            this.props.history.push("/setPassword/"+this.props.code+"/setPassword");
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
      }
      })
      .catch((e) => {
        if (e.response.status !== undefined) {
          if (e.response.status === 400) {
            this.setState({
              showHideVerify: true,
              showLoader: false,
              otp: "",
            });
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              
            });
          } else if (e.response.status === 500) {
            this.setState({
              showHideVerify: true,
              showLoader: false,
              otp: "",
            });
            this.props.addModalClose()
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }
      });
  }

  onResendOptp(e) {
    e.preventDefault();
    let params = {}
    this.setState({
      showHideResendOtp: false,
      showLoader: true,
    });
    if (this.props.login === true) {
        params = {
        phone: this.props.phone,
        name: "login",
        email : this.props.fields.email
      }
     } else if(this.props.registration === true) {
        params = {
        phone: this.props.phone,
        email : this.props.fields.email,
        name: "registration",
      }
    } else if(this.props.physicianRegistration === true) {
      params = {
      phone: this.props.phone,
      email : this.props.fields.email,
      name: "registration",
      }
    } else if(this.props.myProfile === true) {
      params = {
      phone: this.props.phone,
      email : this.props.fields.email,
      name: "registration",
    }
   } else if(this.props.completeSignUp === true) {
      params = {
      phone: this.props.phone,
      email : this.props.email,
      name: "registration",
    }
  }
    authServices
      .resendPhoneOtp(params)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.setState({
            showHideVerify: false,
            showHideResendOtp: true,
            showLoader: false,
            otp: "",
          });
        }
      })
      .catch((e) => {
        if (e.response.status !== undefined) {
          if (e.response.status === 400) {
            this.setState({
              showLoader: false,
            })
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }
      });
  }


  onWrongNo(e){
    e.preventDefault();
    this.props.onHide();
    this.setState({
      otp: ""
    })
  }

  render() {
    // var n = this.props.phone;
    // var digits = ("" + n).split("");
    return (
      <div>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-verify-modal"
          backdrop="static"
        >
          <Modal.Header>
            {/* <button type="button" className="close" data-dismiss="modal" onClick={this.props.onHide}>
              <span aria-hidden="true">&times;</span>
            </button> */}
          </Modal.Header>

          <Modal.Body>
            <h5 className="verify-modal-title p-3" id="exampleModalLabel">
              Verify it's You
            </h5>
            <h6 className="verify-inner-content text-center">
              {" "}
              {this.props.textHeading} <br /> {this.props.textBreakHeading}
            </h6>
            {this.props.wrongSignup === true ? (
              <h6 className="verify-inner-content">
                {" "}
                +{this.props.phone}.
                <span onClick={this.onWrongNo.bind(this)} className="text-primary">
                  {" "}
                  Wrong number?
                </span>
              </h6>
            ) : (
              ""
            )}

            <div className="d-flex verify-input">
              <OtpInput
                className="form-control verify-custom-form-control"
                value={this.state.otp}
                onChange={this.handleChange}
                numInputs={6}
                isInputNum= {true}
                // separator={<span>-</span>}
              />
            </div>
            <h6 className="verify-text mt-3"> Enter 6-digit code </h6>
          </Modal.Body>
          <Modal.Footer className="verify-modal-footer">
            {this.state.showLoader === true ? <div class="loader mb-3 ">Loading...</div> :   
            <div>
            <button
              type="button"
              className="btn btn-white verify-button"
              
              onClick={this.onResendOptp.bind(this)}
            >
              <i className="fa fa-refresh verify-button" aria-hidden="true"></i>{" "}
              Resend SMS
            </button>
            <button
              type="button"
              disabled={this.state.showHideVerify}
              className="btn btn-primary border-radius py-1  px-3"
              onClick={this.modalSubmit.bind(this)}
            >
              Submit <i className="fa fa-location-arrow" aria-hidden="true"></i>
            </button>
            </div>
            }           
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Verify);
