import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from "../../../../environment";

const cardiovascular = [
    {
      value: "yes",
      label: "Yes",
    },
    {
      value: "no",
      label: "No",
    },
  ];
  
  const regularStrengthTraining = [
    {
      value: "yes",
      label: "Yes",
    },
    {
      value: "no",
      label: "No",
    },
  ];
  const participateFlexibility = [
    {
      value: "yes",
      label: "Yes",
    },
    {
      value: "no",
      label: "No",
    },
  ];
  const describeIntensity = [
    {
      value: "Low",
      label: "Low",
    },
    {
      value: "Moderate",
      label: "Moderate",
    },
    {
      value: "Heavy",
      label: "Heavy",
    },
  ];
export class Exercise extends Component {
  constructor(props) {
    super(props);

    this.state = {
        token: localStorage.getItem("token"),
        exercisePerWeek: 0,
        fields: {},
        showCardiovascular: false,
        cardiovascularExercise: "",
        cardiovascularExerciseFlag: "",

        showregularStrengthTraining: false,
        regularStrengthTraining: "",
        regularStrengthTrainingFlag: "",

        showStretchingFlexibilityExercises: false,
        stretchingFlexibilityExercises: "",
        stretchingFlexibilityExercisesFlag: "",
        patientStatus: this.props.status,
        userID:  this.props.userID,
        exerciseData: {},
        disabled: true,
        loading: false,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }


  onSubmit = async(e) => {
    e.preventDefault();
      let exerciseInformation = {};
      exerciseInformation = JSON.parse(
        JSON.stringify(this.state.fields)
      );
      this.setState({
        loading: true
      })
      exerciseInformation.exercisePerWeek = this.state.exercisePerWeek;
      exerciseInformation.cardiovascularExercise = this.state.cardiovascularExerciseFlag;
      exerciseInformation.cardiovascularExerciseType = this.state.fields.cardiovascularExerciseType;
      exerciseInformation.cardiovascularExerciseOften = this.state.fields.cardiovascularExerciseOften;
      exerciseInformation.cardiovascularExerciseDuration = this.state.fields.cardiovascularExerciseDuration;
      exerciseInformation.cardiovascularExerciseIntensity = this.state.fields.cardiovascularExerciseIntensity;
      exerciseInformation.regularStrengthTraining = this.state.regularStrengthTrainingFlag;
      exerciseInformation.regularStrengthTrainingType = this.state.fields.regularStrengthTrainingType;
      exerciseInformation.regularStrengthTrainingIntensity = this.state.fields.regularStrengthTrainingIntensity;
      exerciseInformation.stretchingFlexibilityExercises = this.state.stretchingFlexibilityExercisesFlag;
      exerciseInformation.stretchingFlexibilityExercisesType = this.state.fields.stretchingFlexibilityExercisesType;
      exerciseInformation.stretchingFlexibilityExercisesOften = this.state.fields.stretchingFlexibilityExercisesOften;
      exerciseInformation.stretchingFlexibilityExercisesIntensity = this.state.fields.stretchingFlexibilityExercisesIntensity;
      if(this.state.patientStatus === "physician"){
        exerciseInformation.userId = this.state.userID;
      }
      await axios.post(environment.endPoint+"intake/exercise", exerciseInformation, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success("Exercise Update Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
        });
  }

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true,
      loading: false
    })
    var myButtonClasses = document.getElementById("exercise")
      .classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }

  
  componentDidMount(){
    this.getExerciseService()
  }

  getExerciseService = async () => {
    this.setState({
      loading: true
    });
    const data = this.state.patientStatus === "physician" ? "intake/exercise?userId="+ this.state.userID : "intake/exercise";
    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
    .then((response) => {
        if (response.data.data !== "") {
          if (response.data.data !== null) {
            this.setState({
              exerciseData: response.data.data,
              loading: false
            });
          }

          if (Object.keys(response.data.data).length !== 0) {
            let fields = this.state.fields;
            this.setState({
              exercisePerWeek: response.data.data.exercisePerWeek ? response.data.data.exercisePerWeek : 0,
            });
          
    
            if (response.data.data.cardiovascularExercise === true) {
              fields[
                "cardiovascularExerciseType"
              ] = response.data.data.cardiovascularExerciseType;
              fields[
                "cardiovascularExerciseOften"
              ] = response.data.data.cardiovascularExerciseOften;
              fields[
                "cardiovascularExerciseDuration"
              ] = response.data.data.cardiovascularExerciseDuration;
              fields[
                "cardiovascularExerciseIntensity"
              ] = response.data.data.cardiovascularExerciseIntensity;
              this.setState({
                cardiovascularExercise: "yes",
                showCardiovascular: true,
                cardiovascularExerciseFlag: true,
              });
            } else if (response.data.data.cardiovascularExercise === false) {
              this.setState({
                cardiovascularExercise: "no",
                showCardiovascular: false,
                cardiovascularExerciseFlag: false,
              });
            } else if (response.data.data.cardiovascularExercise === null) {
              this.setState({
                cardiovascularExercise: "",
                showCardiovascular: false,
                cardiovascularExerciseFlag: null,
              });
            }
    
            if (response.data.data.regularStrengthTraining === true) {
              fields[
                "regularStrengthTrainingType"
              ] = response.data.data.regularStrengthTrainingType;
              fields[
                "regularStrengthTrainingIntensity"
              ] = response.data.data.regularStrengthTrainingIntensity;
              this.setState({
                regularStrengthTraining: "yes",
                showregularStrengthTraining: true,
                regularStrengthTrainingFlag: true,
              });
            } else if (response.data.data.regularStrengthTraining === false) {
              this.setState({
                regularStrengthTraining: "no",
                showregularStrengthTraining: false,
                regularStrengthTrainingFlag: false,
              });
            } else if (response.data.data.regularStrengthTraining === null) {
              this.setState({
                regularStrengthTraining: "",
                showregularStrengthTraining: false,
                regularStrengthTrainingFlag: null,
              });
            }
    
            if (response.data.data.stretchingFlexibilityExercises === true) {
              fields[
                "stretchingFlexibilityExercisesType"
              ] = response.data.data.stretchingFlexibilityExercisesType;
              fields[
                "stretchingFlexibilityExercisesType"
              ] = response.data.data.stretchingFlexibilityExercisesOften;
              fields[
                "stretchingFlexibilityExercisesIntensity"
              ] = response.data.data.stretchingFlexibilityExercisesIntensity;
              this.setState({
                stretchingFlexibilityExercises: "yes",
                showStretchingFlexibilityExercises: true,
                stretchingFlexibilityExercisesFlag: true,
              });
            } else if (
              response.data.data.stretchingFlexibilityExercises === false
            ) {
              this.setState({
                stretchingFlexibilityExercises: "no",
                showStretchingFlexibilityExercises: false,
                stretchingFlexibilityExercisesFlag: false,
              });
            } else if (
              response.data.data.stretchingFlexibilityExercises === null
            ) {
              this.setState({
                stretchingFlexibilityExercises: "",
                showStretchingFlexibilityExercises: false,
                stretchingFlexibilityExercisesFlag: null,
              });
            }
            this.setState({ fields:response.data.data });
          }
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error("Please Check your Service Provider", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      });
  }

  handleUserInputChange(field, e) {
    if (field === "cardiovascularExercise") {
      if (e.target.value === "yes") {
        this.setState({
          cardiovascularExercise: e.target.value,
          showCardiovascular: true,
          cardiovascularExerciseFlag: true,
          disabled: false
        });
      } else if (e.target.value === "no") {
        this.setState({
          cardiovascularExercise: e.target.value,
          showCardiovascular: false,
          cardiovascularExerciseFlag: false,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          cardiovascularExercise: null,
          showCardiovascular: false,
          cardiovascularExerciseFlag: null,
          disabled: false
        });
      }
    }
    if (field === "regularStrengthTraining") {
      if (e.target.value === "yes") {
        this.setState({
          regularStrengthTraining: e.target.value,
          showregularStrengthTraining: true,
          regularStrengthTrainingFlag: true,
          disabled: false
        });
      } else if (e.target.value === "no") {
        this.setState({
          regularStrengthTraining: e.target.value,
          showregularStrengthTraining: false,
          regularStrengthTrainingFlag: false,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          regularStrengthTraining: null,
          showregularStrengthTraining: false,
          regularStrengthTrainingFlag: null,
          disabled: false
        });
      }
    }
    if (field === "stretchingFlexibilityExercises") {
      if (e.target.value === "yes") {
        this.setState({
          stretchingFlexibilityExercises: e.target.value,
          showStretchingFlexibilityExercises: true,
          stretchingFlexibilityExercisesFlag: true,
          disabled: false
        });
      } else if (e.target.value === "no") {
        this.setState({
          stretchingFlexibilityExercises: e.target.value,
          showStretchingFlexibilityExercises: false,
          stretchingFlexibilityExercisesFlag: false,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          stretchingFlexibilityExercises: null,
          showStretchingFlexibilityExercises: false,
          stretchingFlexibilityExercisesFlag: null,
          disabled: false
        });
      }
    }
    if (field === "exercisePerWeek") {
        const number = parseInt(e.target.value)
      this.setState({
        exercisePerWeek: number,
        disabled: false
      });
    }
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
      disabled: false
    });
  }
  render() {
    return (
      <form>
        <div className="card-body custom-inner-content">
          <h5 className="stepper-form-headings ">
            Exercise
          </h5>

          <div className="row">
            <div className="col-lg-12 col-12 mt-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="custom-form-row">
                    <h4 className="stepper-form-sub-headings">
                      <span className="bolder">
                        {" "}
                        How many days per week did you exercise, In the past 3 months?
                        <span className="text-danger">*</span>
                      </span>
                      <br />
                    </h4>
                    <div>
                      <input
                        id="typeinp"
                        type="range"
                        min="0"
                        max="7"
                        className="range-slider"
                        value={this.state.exercisePerWeek}
                        onChange={this.handleUserInputChange.bind(
                          this,
                          "exercisePerWeek"
                        )}
                        step="1"
                      />
                      <label className="stepper-form-sub-headings">
                        {this.state.exercisePerWeek}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* showCardiovascular */}
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.cardiovascularExercise}
                      className={
                        this.state.cardiovascularExercise === ""
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "cardiovascularExercise"
                      )}
                    >
                      <option value="" disabled selected>
                        Do you participate in regular Cardiovascular exercise?
                      </option>
                      {cardiovascular.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Do you participate in regular Cardiovascular exercise?{" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showCardiovascular === true ? (
                <div>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="What type ?"
                          value={this.state.fields.cardiovascularExerciseType}
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "cardiovascularExerciseType"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          What type ?
                        </label>
                      </div>
                      {/* <span className="text-danger">
                        {this.state.errors["cultivarName"]}
                      </span> */}
                    </div>
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="How often?"
                          value={this.state.fields.cardiovascularExerciseOften}
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "cardiovascularExerciseOften"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          How often?
                        </label>
                      </div>
                      {/* <span className="text-danger">
                        {this.state.errors["cultivarName"]}
                      </span> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="Duration ?"
                          value={
                            this.state.fields.cardiovascularExerciseDuration
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "cardiovascularExerciseDuration"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          Duration ?
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <select
                          value={
                            this.state.fields.cardiovascularExerciseIntensity
                          }
                          className={
                            this.state.fields
                              .cardiovascularExerciseIntensity === "" ||
                            this.state.fields
                              .cardiovascularExerciseIntensity === null
                              ? "disabled-option form-control custom-form-control mb-2"
                              : "form-control custom-form-control mb-2"
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "cardiovascularExerciseIntensity"
                          )}
                        >
                          <option value="" disabled selected>
                            Describe your intensity?
                          </option>
                          {describeIntensity.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        <label className="label-helper py-2 px-1 mr-3">
                          How would you describe your intensity?{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* Do you participate in regular strength training exercise? */}
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.regularStrengthTraining}
                      className={
                        this.state.regularStrengthTraining === ""
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "regularStrengthTraining"
                      )}
                    >
                      <option
                        value=""
                        disabled
                        selected
                        className="text-truncate"
                      >
                        Do you participate in regular strength training
                        exercise?
                      </option>
                      {regularStrengthTraining.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Do you participate in regular strength training exercise?{" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showregularStrengthTraining === true ? (
                <div>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="What kind?"
                          value={this.state.fields.regularStrengthTrainingType}
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "regularStrengthTrainingType"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          What kind?
                        </label>
                      </div>
                      {/* <span className="text-danger">
                        {this.state.errors["cultivarName"]}
                      </span> */}
                    </div>
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <select
                          value={
                            this.state.fields.regularStrengthTrainingIntensity
                          }
                          className={
                            this.state.fields
                              .regularStrengthTrainingIntensity === "" ||
                            this.state.fields
                              .regularStrengthTrainingIntensity === null
                              ? "disabled-option form-control custom-form-control mb-2"
                              : "form-control custom-form-control mb-2"
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "regularStrengthTrainingIntensity"
                          )}
                        >
                          <option value="" disabled selected>
                            Describe your intensity?
                          </option>
                          {describeIntensity.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        <label className="label-helper py-2 px-1 mr-3">
                          How would you describe your intensity?{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* Do you participate in flexibility exercises and stretching? */}
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.stretchingFlexibilityExercises}
                      className={
                        this.state.stretchingFlexibilityExercises === ""
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "stretchingFlexibilityExercises"
                      )}
                    >
                      <option value="" disabled selected>
                        Do you participate in flexibility exercises and
                        stretching?
                      </option>
                      {participateFlexibility.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Do you participate in flexibility exercises and
                      stretching?{" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showStretchingFlexibilityExercises === true ? (
                <div>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="What kind ?"
                          value={
                            this.state.fields.stretchingFlexibilityExercisesType
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "stretchingFlexibilityExercisesType"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          What kind ?
                        </label>
                      </div>
                      {/* <span className="text-danger">
                        {this.state.errors["cultivarName"]}
                      </span> */}
                    </div>
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="How often?"
                          value={
                            this.state.fields
                              .stretchingFlexibilityExercisesOften
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "stretchingFlexibilityExercisesOften"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          How often?
                        </label>
                      </div>
                      {/* <span className="text-danger">
                        {this.state.errors["cultivarName"]}
                      </span> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="custom-form-row">
                        <select
                          value={
                            this.state.fields
                              .stretchingFlexibilityExercisesIntensity
                          }
                          className={
                            this.state.fields
                              .stretchingFlexibilityExercisesIntensity === "" ||
                            this.state.fields
                              .stretchingFlexibilityExercisesIntensity === null
                              ? "disabled-option form-control custom-form-control mb-2"
                              : "form-control custom-form-control mb-2"
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "stretchingFlexibilityExercisesIntensity"
                          )}
                        >
                          <option value="" disabled selected>
                            Describe your intensity?
                          </option>
                          {describeIntensity.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        <label className="label-helper py-2 px-1 mr-3">
                          How would you describe your intensity?{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                disabled={this.state.disabled ? true: false}
                onClick={this.onSubmit.bind(this)}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </form>
    );
  }
}

export default Exercise;
