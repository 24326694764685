import React, { Component } from "react";
// import { DatePicker } from "antd";
import moment from "moment";
import Validation from "../../../common/util";
import { toast } from "react-toastify";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { environment } from "../../../../environment";
import {
  isIOS,isSafari
} from "react-device-detect";
const cannabis = [
  {
    value: "NeverTriedit",
    label: "Cannabis Naive (Never tried it)",
  },
  {
    value: "TriedItYears10Ago",
    label: "Tried more than 10 years ago",
  },
  {
    value: "TriedItYears5Ago",
    label: "Tried more than 5 years ago",
  },
  {
    value: "CurrentlyUseOccasionally",
    label: "Currently use occasionally",
  },
  {
    value: "CurrentlyUseDaily",
    label: "Currently use daily",
  },
];
const tolerance = [
  { value: "unknown", label: "Unknown" },
  { value: "lowtolerance", label: "Low Tolerance" },
  { value: "averagetolerance", label: "Average Tolerance" },
  { value: "hightolerance", label: "High Tolerance" },
];
const methodAdministration = [
  { value: "Smoked Flower", label: "Smoked Flower" },
  { value: "Vaporized Flower", label: "Vaporized Flower" },
  { value: "Vaporized Oil", label: "Vaporized Oil" },
  { value: "Sublingual", label: "Sublingual" },
  { value: "Suppository", label: "Suppository" },
  { value: "Topical", label: "Topical" },
  { value: "Tincture", label: "Tincture" },
  { value: "Capsules", label: "Capsules" },
  { value: "Edibles", label: "Edibles" },
  { value: "Extract", label: "Extract" },
  { value: "Oil", label: "Oil" },
  { value: "Other", label: "Other" },
];

const favoriteCultivar = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
const symptoms = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
const medicalSupervision = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const frequency = [
  {
    value: "Once",
    label: "Once",
  },
  {
    value: "Twice ",
    label: "Twice",
  },
  {
    value: "Three Times",
    label: "Three Times",
  },
  {
    value: "Four Times",
    label: "Four Times ",
  },
  {
    value: "Five Times",
    label: "Five Times ",
  },
  {
    value: "Over Five Times",
    label: "Over Five Times ",
  },
];

const amount = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "75",
    label: "75",
  },
  {
    value: "100",
    label: "100",
  },
  {
    value: "Over 100",
    label: "Over 100",
  },
];

export class CannabisUse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objective: {
      },
      objectiveFlag: false,
      disabled: true,
      showField: false,
      fields: {},
      cannabisRegimenCommitment: 0,
      useMethod: {
        smokedflower: false,
      },
      showCannabisFields: false,
      showOtherFieldAdministration: false,
      showfavoriteCultivar: false,
      showSymptoms: false,
      showMedicalSupervision: false,
      loading: false,
      token: localStorage.getItem("token"),
      products: [
        {
          name: "",
        },
      ],
      cannabisUseInformation: {},
      errors: {},
      previousExperience: "",
      tolerance: "",
      frequency: "",
      productType: "",
      cannabisUseData: {},
      favoriteCultivar: "",
      cannabisForSymptoms: "",
      medicalSupervision: "",
      favoriteCultivarFlag: "",
      cannabisForSymptomsFlag: "",
      medicalSupervisionFlag: "",
      treatmentStartDate: null,
      patientStatus: this.props.status,
      userID: this.props.userID
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.handleInputProduct = this.handleInputProduct.bind(this);
    this.addProductList = this.addProductList.bind(this);
    this.handlePersonalDateChange = this.handlePersonalDateChange.bind(this);
  }

  handlePersonalDateChange(value, dateString) {
    let cannabisUseInformation = this.state;
    cannabisUseInformation.treatmentStartDate = value;
    this.setState({
      cannabisUseInformation: cannabisUseInformation,
      treatmentStartDate: value,
      disabled: false
    });
  }
  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }

  handleUserInputChange(field, e) {
    if (field === "cannabisRegimenCommitment") {
      this.setState({
        cannabisRegimenCommitment: e.target.value, disabled: false,
      });
    }
    if (field === "favoriteCultivar") {
      if (e.target.value === "yes") {
        this.setState({
          favoriteCultivar: e.target.value,
          showfavoriteCultivar: true,
          favoriteCultivarFlag: true,
          disabled: false
        });
      } else if (e.target.value === "no") {
        this.setState({
          favoriteCultivar: e.target.value,
          showfavoriteCultivar: false,
          favoriteCultivarFlag: false,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          favoriteCultivar: null,
          showfavoriteCultivar: false,
          favoriteCultivarFlag: null,
          disabled: false
        });
      }
    } else if (field === "cannabisForSymptoms") {
      if (e.target.value === "yes") {
        this.setState({
          cannabisForSymptoms: e.target.value,
          showSymptoms: true,
          cannabisForSymptomsFlag: true,
          disabled: false
        });
      } else if (e.target.value === "no") {
        this.setState({
          cannabisForSymptoms: e.target.value,
          showSymptoms: false,
          cannabisForSymptomsFlag: false,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          cannabisForSymptoms: null,
          showSymptoms: false,
          cannabisForSymptomsFlag: null,
          disabled: false
        });
      }
    } else if (field === "medicalSupervision") {
      if (e.target.value === "yes") {
        this.setState({
          medicalSupervision: e.target.value,
          showMedicalSupervision: true,
          medicalSupervisionFlag: true,
          disabled: false
        });
      } else if (e.target.value === "no") {
        this.setState({
          medicalSupervision: e.target.value,
          showMedicalSupervision: false,
          medicalSupervisionFlag: false,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          medicalSupervision: null,
          showMedicalSupervision: false,
          medicalSupervisionFlag: null,
          disabled: false
        });
      }
    }
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
      disabled: false
    });
  }

  handleCheckboxesObjective(field, e) {
    let objective = this.state.objective;
    objective[field] = e.target.checked;
    this.setState({ objective, disabled: false });
    if (field === "other") {
      if (e.target.checked === true) {
        this.setState({ showField: true, objective, disabled: false });
      } else if (e.target.checked === false) {
        this.setState({ showField: false, objective, disabled: false });
      }
    }
  }

  handleCheckboxes(field, e) {
    let useMethod = this.state.useMethod;
    useMethod[field] = e.target.checked;
    this.setState({ useMethod, disabled: false });
    if (field === "otherAdministration") {
      if (e.target.checked === true) {
        this.setState({ showOtherFieldAdministration: true, disabled: false });
      } else if (e.target.checked === false) {
        this.setState({ showOtherFieldAdministration: false, disabled: false });
      }
    }
  }

  handleChange(e, string) {
    if (string === "previousExperience") {
      if (e.target.value === "NeverTriedit") {
        this.setState({
          showCannabisFields: false,
          previousExperience: e.target.value,
          disabled: false
        });
      } else if (e.target.value === "") {
        this.setState({
          showCannabisFields: false,
          previousExperience: "",
          disabled: false
        });
      } else {
        this.setState({
          showCannabisFields: true,
          previousExperience: e.target.value,
          disabled: false
        });
      }
    }
    if (string === "productType") {
      this.setState({
        productType: e.target.value,
        disabled: false
      });
    }
    if (string === "tolerance") {
      this.setState({
        tolerance: e.target.value,
        disabled: false
      });
    }
    if (string === "frequency") {
      this.setState({
        frequency: e.target.value,
        disabled: false
      });
    }
    if (string === "amount") {
      this.setState({
        amount: e.target.value,
        disabled: false
      });
    }
    let fields = this.state.fields;
    fields[string] = e.target.value;
    this.setState({ fields, disabled: false });
  }

  addProductList(e) {
    e.preventDefault();
    let array = this.state.products;
    array.push({ id: array.length + 1, name: "" });
    this.setState({ products: array, disabled: false });
  }

  handleInputProduct(e, idx) {
    let nextProductData = this.state.allergies.slice();
    nextProductData[idx].name = e.currentTarget.value;
    this.setState({ products: nextProductData, disabled: false });
  }

  remove(idx, e) {
    let products = this.state.products;
    products.splice(idx, 1);
    this.setState({ products: products, disabled: false });
  }
  componentDidMount() {
    this.getCannabisUse();
  }
  getCannabisUse = async () => {
    const data = this.state.patientStatus === "physician" ? "intake/cannabis?userId=" + this.state.userID : "intake/cannabis";
    await axios.get(environment.endPoint + data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    }).then((response) => {
      const data = response.data.data;
      this.setState({
        loading: false
      })
      if (Object.keys(data).length !== 0) {
        let fields = this.state.fields;
        fields["amount"] = data.amount;
        fields["cannabisRegimenCommitment"] = data.cannabisRegimenCommitment;
        fields["cultivarName"] = data.cultivarName;
        fields["favoriteCultivarReason"] = data.favoriteCultivarReason;
        fields["feelingUsingCannabis"] = data.feelingUsingCannabis;
        fields["medicineSource"] = data.medicineSource;
        fields["treatmentLocatin"] = data.treatmentLocatin;

        this.setState({
          treatmentStartDate: data.treatmentStartDate,
        });
        fields["productType"] = data.productType;

        fields["frequency"] = data.frequency;
        if (data.objectiveOther !== "") {
          fields["objectiveOther"] = data.objectiveOther;
          if (data.objective.other === false) {
            this.setState({
              showField: false,
            });
          } else if (data.objective.other === true) {
            this.setState({
              showField: true,
            });
          }
        }
        fields["methodAdministration"] = data.methodAdministration;
        if (
          data.previousExperience !== "NeverTriedit" &&
          data.previousExperience !== null &&
          data.previousExperience !== ""
        ) {
          this.setState({
            showCannabisFields: true,
            previousExperience: data.previousExperience,
            tolerance: data.tolerance
          });
        } else if (
          data.previousExperience === null &&
          data.previousExperience === "NeverTriedit" &&
          data.previousExperience === ""
        ) {
          this.setState({
            showCannabisFields: false,
            previousExperience: data.previousExperience,
          });
        } else {
          this.setState({
            showCannabisFields: false,
            previousExperience: data.previousExperience,
          });
        }
        if (data.favoriteCultivar === true) {
          this.setState({
            favoriteCultivar: "yes",
            showfavoriteCultivar: true,
            favoriteCultivarFlag: true,
          });
        } else if (data.favoriteCultivar === null) {
          this.setState({
            favoriteCultivar: "",
            showfavoriteCultivar: false,
            favoriteCultivarFlag: null,
          });
        } else if (data.favoriteCultivar === false) {
          this.setState({
            favoriteCultivar: "no",
            showfavoriteCultivar: false,
            favoriteCultivarFlag: null,
          });
        }
        if (data.cannabisForSymptoms === true) {
          this.setState({
            cannabisForSymptoms: "yes",
            showSymptoms: true,
            cannabisForSymptomsFlag: true,
          });
        } else if (data.cannabisForSymptoms === null) {
          this.setState({
            cannabisForSymptoms: "",
            showSymptoms: false,
            cannabisForSymptomsFlag: null,
          });
        } else if (data.cannabisForSymptoms === false) {
          this.setState({
            cannabisForSymptoms: "no",
            showSymptoms: false,
            cannabisForSymptomsFlag: false,
          });
        }
        if (data.medicalSupervision === true) {
          this.setState({
            medicalSupervision: "yes",
            showMedicalSupervision: true,
            medicalSupervisionFlag: true,
          });
        } else if (data.medicalSupervision === null) {
          this.setState({
            medicalSupervision: "",
            showMedicalSupervision: false,
            medicalSupervisionFlag: null,
          });
        } else if (data.medicalSupervision === false) {
          this.setState({
            medicalSupervision: "no",
            showMedicalSupervision: false,
            medicalSupervisionFlag: false,
          });
        }
        if (
          data.useMethod !== null ||
          data.useMethod !== "" ||
          data.objective !== "" ||
          data.objective !== null
        ) {
          this.setState({
            useMethod: data.useMethod,
            objective: data.objective,
          });
          if (data.useMethod.otherAdministration === true) {
            this.setState({
              showOtherFieldAdministration: true,
            });
          }
        }
        if (data.cannabisRegimenCommitment !== null) {
          this.setState({
            cannabisRegimenCommitment: data.cannabisRegimenCommitment,
          });
        } else {
          this.setState({ cannabisRegimenCommitment: 0 });
        }
        this.setState({ fields: data });
      }
    })
      .catch((e) => {
      });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      errors: Validation.cannabisUseInformation(
        this.state,
        "cannabisUseInformation"
      ).error,
    });
    if (
      Validation.cannabisUseInformation(
        this.state.fields,
        "cannabisUseInformation"
      ).formIsValid
    ) {
      let cannabisUseInformation = {};
      cannabisUseInformation = JSON.parse(JSON.stringify(this.state.fields));
      cannabisUseInformation.previousExperience = this.state.previousExperience;
      cannabisUseInformation.useMethod = this.state.useMethod;
      cannabisUseInformation.objective = this.state.objective;
      cannabisUseInformation.tolerance = this.state.fields.tolerance;
      cannabisUseInformation.amount = this.state.fields.amount;
      cannabisUseInformation.frequency = this.state.fields.frequency;
      cannabisUseInformation.feelingUsingCannabis = this.state.fields.feelingUsingCannabis;
      cannabisUseInformation.cultivarName = this.state.fields.cultivarName;
      cannabisUseInformation.favoriteCultivarReason = this.state.fields.favoriteCultivarReason;
      cannabisUseInformation.treatmentStartDate = this.state.fields.treatmentStartDate;
      cannabisUseInformation.methodAdministration = this.state.fields.methodAdministration;
      cannabisUseInformation.productType = this.state.fields.productType;
      cannabisUseInformation.cultivarName = this.state.fields.cultivarName;
      cannabisUseInformation.medicineSource = this.state.fields.medicineSource;
      cannabisUseInformation.medicalSupervision = this.state.medicalSupervision;
      cannabisUseInformation.objectiveOther = this.state.fields.objectiveOther;

      if(this.state.treatmentStartDate !== null) {
        var fullDate = moment(this.state.treatmentStartDate, "MM-DD-YYYY");
        var date = fullDate.format("DD");
        var year = fullDate.format("YYYY");
        var month = fullDate.format("MM");
        const selectedDate = month + "-" + date + "-" + year;
        cannabisUseInformation.treatmentStartDate = this.state.treatmentStartDate !== null || this.state.treatmentStartDate === " " ? selectedDate : null;
      } else {
        cannabisUseInformation.treatmentStartDate = null
      }

      cannabisUseInformation.favoriteCultivar = this.state.favoriteCultivarFlag;
      cannabisUseInformation.cannabisForSymptoms = this.state.cannabisForSymptomsFlag;
      cannabisUseInformation.medicalSupervision = this.state.medicalSupervisionFlag;
      cannabisUseInformation.treatmentLocatin = this.state.fields
        .treatmentLocatin
        ? this.state.fields.treatmentLocatin
        : null;
      cannabisUseInformation.cannabisRegimenCommitment = this.state.cannabisRegimenCommitment;
      if (this.state.patientStatus === "physician") {
        cannabisUseInformation.userId = this.state.userID;
      }
      await axios.post(environment.endPoint + "intake/cannabis", cannabisUseInformation, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
          // if (e.response.status === 400) {
          //   toast.error("Patient History Not Updated", {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 3000,
          //   });
          // }
        });
    }
  }
  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true
    })
    var myButtonClasses = document.getElementById("cannabis").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.remove("active");
    }
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings ">Cannabis Use</h5>
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-form-row">
                <select
                  value={this.state.previousExperience}
                  className={
                    this.state.previousExperience === "" ||
                      this.state.previousExperience === null ||
                      this.state.previousExperience === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={(e) => {
                    this.handleChange(e, "previousExperience");
                  }}
                >
                  <option value="">
                    {" "}
                    What is your previous experience with cannabis?
                  </option>
                  {cannabis.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  What is your previous experience with cannabis?{" "}
                </label>
              </div>
            </div>
          </div>

          {this.state.showCannabisFields === true ? (
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.tolerance}
                      className={
                        this.state.tolerance === "" ||
                          this.state.tolerance === null ||
                          this.state.tolerance === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "tolerance");
                      }}
                    >
                      <option value="">
                        {" "}
                        How would you describe your tolerance?
                      </option>
                      {tolerance.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      How would you describe your tolerance?{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-12 mt-2">
                  <h4 className="stepper-form-sub-headings">
                    What methods of administration have you tried?
                  </h4>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck111"
                      value={"smokedflower"}
                      checked={this.state.useMethod?.smokedflower}
                      onChange={this.handleCheckboxes.bind(
                        this,
                        "smokedflower"
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck111"
                    >
                      Smoked flower
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck211"
                      value={"vaporizedFlower"}
                      checked={this.state.useMethod?.vaporizedFlower}
                      onChange={this.handleCheckboxes.bind(
                        this,
                        "vaporizedFlower"
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck211"
                    >
                      Vaporized flower
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck311"
                      value={"vaporizedOil"}
                      checked={this.state.useMethod?.vaporizedOil}
                      onChange={this.handleCheckboxes.bind(
                        this,
                        "vaporizedOil"
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck311"
                    >
                      Vaporized oil
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck411"
                      value={"sublingual"}
                      checked={this.state.useMethod?.sublingual}
                      onChange={this.handleCheckboxes.bind(this, "sublingual")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck411"
                    >
                      Sublingual
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck511"
                      value={"suppository"}
                      checked={this.state.useMethod?.suppository}
                      onChange={this.handleCheckboxes.bind(this, "suppository")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck511"
                    >
                      Suppository
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck611"
                      value={"topical"}
                      checked={this.state.useMethod?.topical}
                      onChange={this.handleCheckboxes.bind(this, "topical")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck611"
                    >
                      Topical
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck711"
                      value={"Tincture"}
                      checked={this.state.useMethod?.tincture}
                      onChange={this.handleCheckboxes.bind(this, "tincture")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck711"
                    >
                      Tincture
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck811"
                      value={"capsules"}
                      checked={this.state.useMethod?.capsules}
                      onChange={this.handleCheckboxes.bind(this, "capsules")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck811"
                    >
                      Capsules
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck812"
                      value={"edibles"}
                      checked={this.state.useMethod?.edibles}
                      onChange={this.handleCheckboxes.bind(this, "edibles")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck812"
                    >
                      Edibles
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck8122"
                      value={"extract"}
                      checked={this.state.useMethod?.extract}
                      onChange={this.handleCheckboxes.bind(this, "extract")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck8122"
                    >
                      Extract
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck8213"
                      value={"oil"}
                      checked={this.state.useMethod?.oil}
                      onChange={this.handleCheckboxes.bind(this, "oil")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck8213"
                    >
                      Oil
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck833"
                      value={"other"}
                      checked={this.state.useMethod?.otherAdministration}
                      onChange={this.handleCheckboxes.bind(
                        this,
                        "otherAdministration"
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck833"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showOtherFieldAdministration === true ? (
                <div className="row mt-4">
                  <div className="col-lg-12 col-12">
                    <div className="custom-form-row">
                      <input
                        className="form-control custom-form-control"
                        placeholder="Your Method Of Administration "
                        value={this.state.fields?.methodAdministration}
                        onChange={this.handleUserInputChange.bind(
                          this,
                          "methodAdministration"
                        )}
                      />
                      <label className="label-helper py-2 px-1">
                        Your Method Of Administration{" "}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mt-4">
                <div className="col-lg-4 col-4">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.amount || ""}
                      className={
                        this.state.fields.amount === "" ||
                          this.state.fields.amount === undefined ||
                          this.state.fields.amount === null
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "amount");
                      }}
                    >
                      <option value="" > Amount?</option>
                      {amount.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">Amount </label>
                  </div>
                </div>
                <span className="bold">
                  <label htmlFor="" className="mt-4">
                    {" "}
                    mg
                  </label>
                </span>
                <div className="col-lg-4 col-4">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.frequency || ""}
                      className={
                        this.state.fields.frequency === "" ||
                          this.state.fields.frequency === undefined ||
                          this.state.fields.amount === null
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "frequency");
                      }}
                    >
                      <option value="" disabled selected> Frequency?</option>
                      {frequency.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Frequency{" "}
                    </label>
                  </div>
                </div>
                <span className="bold">
                  <label htmlFor="" className="mt-4">
                    per day
                  </label>
                </span>
              </div>

              <div className="row ">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <textarea
                      className="form-control custom-form-control"
                      placeholder="Please describe in a few words how you feel when
                            using cannabis?"
                      name=""
                      id=""
                      rows="3"
                      value={this.state.fields.feelingUsingCannabis}
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "feelingUsingCannabis"
                      )}
                    ></textarea>

                    <label className="label-helper py-2 px-1">
                      {" "}
                      Please describe in a few words how you feel when using
                      cannabis?
                    </label>
                  </div>
                  <span className="text-danger">
                    {this.state.errors["feelingUsingCannabis"]}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.favoriteCultivar}
                      className={
                        this.state.favoriteCultivar === ""
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "favoriteCultivar"
                      )}
                    >
                      <option value="">
                        {" "}
                        Do you have a favorite cultivar (strain) of cannabis?
                      </option>
                      {favoriteCultivar.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Do you have a favorite cultivar (strain) of cannabis?{" "}
                    </label>
                  </div>
                </div>
              </div>
              {this.state.showfavoriteCultivar === true ? (
                <div>
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="Your Favourite Cultivar name"
                          value={this.state.fields.cultivarName}
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "cultivarName"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          Your Favourite Cultivar name
                        </label>
                      </div>
                      <span className="text-danger">
                        {this.state.errors["cultivarName"]}
                      </span>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-12 col-12">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="Why do you like this cultivar?"
                          value={this.state.fields.favoriteCultivarReason}
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "favoriteCultivarReason"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          Why do you like this cultivar?
                        </label>
                      </div>
                      <span className="text-danger">
                        {this.state.errors["favoriteCultivarReason"]}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row ">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.cannabisForSymptoms}
                      className={
                        this.state.cannabisForSymptoms === ""
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "cannabisForSymptoms"
                      )}
                    >
                      <option value="">
                        {" "}
                        Are you currently using cannabis for your symptoms?
                      </option>
                      {symptoms.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Are you currently using cannabis for your symptoms?{" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showSymptoms === true ? (
                <div>
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="custom-form-row">
                        <select
                          value={this.state.fields.productType || ""}
                          className={
                            this.state.productType === ""
                              ? "disabled-option form-control custom-form-control mb-2"
                              : "form-control custom-form-control mb-2"
                          }
                          onChange={(e) => {
                            this.handleChange(e, "productType");
                          }}
                        >
                          <option value="">
                            {" "}
                            What Type of Product are you currently using?
                          </option>
                          {methodAdministration.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        <label className="label-helper py-2 px-1">
                          What Type of Product are you currently using?{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-lg-12 col-12">
                      <div className="custom-form-row">
                        <select
                          value={this.state.medicalSupervision}
                          className={
                            this.state.medicalSupervision === ""
                              ? "disabled-option form-control custom-form-control"
                              : "form-control custom-form-control"
                          }
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "medicalSupervision"
                          )}
                        >
                          <option value="">
                            {" "}
                            Are you currently using the Product(s) under medical
                            supervision?
                          </option>
                          {medicalSupervision.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        <label className="label-helper py-2 px-1">
                          Are you currently using the Product(s) under medical
                          supervision?{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.showMedicalSupervision === true ? (
                    <div>
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <div className="custom-form-row">
                         
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                
                                placeholder="Date you started treatment?"
                                className={
                                  this.state.treatmentStartDate === null
                                    ? "form-control"
                                    : "form-control"
                                }
                                openTo="date"
                                value={this.state.treatmentStartDate}
                                format="MM-dd-yyyy"
                                views={["year", "month", "date"]}
                                onChange={this.handlePersonalDateChange}
                                disableFuture
                              />
                            </MuiPickersUtilsProvider>
                            <label className="label-helper py-2 px-1">
                              Date you started treatment?
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          <div className="row objective-intake">
            <div className="col-lg-12 col-12 mt-2">
              <h4 className="stepper-form-sub-headings">
                What is your objective?  <strong className="text-danger">*</strong>
              </h4>

              <div className="objective-input" data-toggle="buttons">
                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.antiCancerTherapy === true
                      ? "active"
                      : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.antiCancerTherapy}
                    value={"antiCancerTherapy"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "antiCancerTherapy"
                    )}
                  />
                  Anti-cancer Therapy
                </label>

                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.manageSideEffectsofChemo === true
                      ? "active"
                      : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.manageSideEffectsofChemo}
                    value={"manageSideEffectsofChemo"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "manageSideEffectsofChemo"
                    )}
                  />
                  Manage Side Effects of Chemo
                </label>

                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.painRelief === true ? "active" : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.painRelief}
                    value={"painRelief"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "painRelief"
                    )}
                  />
                  Pain Relief
                </label>

                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.mentalHealth === true ? "active" : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.mentalHealth}
                    value={"mentalHealth"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "mentalHealth"
                    )}
                  />
                  Mental Health
                </label>

                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.pharmaceuticalReplacement === true
                      ? "active"
                      : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.pharmaceuticalReplacement}
                    value={"pharmaceuticalReplacement"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "pharmaceuticalReplacement"
                    )}
                  />
                  Pharmaceutical Replacement
                </label>

                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.seizureControl === true
                      ? "active"
                      : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.seizureControl}
                    value={"seizureControl"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "seizureControl"
                    )}
                  />
                  Seizure Control
                </label>

                <label
                  className={`btn btn-outline-primary border-radius mar-x-3 ${this.state.objective?.other === true ? "active" : ""
                    }`}
                >
                  <input
                    type="checkbox"
                    id="options"
                    name="options"
                    autoComplete="off"
                    checked={this.state.objective?.other}
                    value={"other"}
                    onChange={this.handleCheckboxesObjective.bind(
                      this,
                      "other"
                    )}
                  />
                  Other
                </label>
              </div>
            </div>
            <span className="text-danger">  {this.state.errors["objectiveFlag"]} </span>

          </div>

          {this.state.showField === true ? (
            <div>
              <div className="row mt-4">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <input
                      className="form-control custom-form-control"
                      placeholder="Your Objective"
                      value={this.state.fields?.objectiveOther}
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "objectiveOther"
                      )}
                    />
                    <label className="label-helper py-2 px-1">
                      Your Objective{" "}
                    </label>
                  </div>
                </div>
              </div>
              {/* {this.state.showOtherFieldAdministration === true ? (

                   ) : (
                      ""
                    )} */}
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-form-row">
                <h4 className="stepper-form-sub-headings">
                  <span className="bolder">
                    {" "}
                    How committed are you to the cannabis regimen?&nbsp;
                  </span>
                  <br />


                </h4>

                <div>
                  <input
                    id="typeinp"
                    type="range"
                    min="0"
                    max="10"
                    className="range-slider"
                    value={this.state.cannabisRegimenCommitment}
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "cannabisRegimenCommitment"
                    )}
                    step="1"
                  />
                  <label className="stepper-form-sub-headings">
                    {this.state.cannabisRegimenCommitment}
                  </label>
                </div>
                <span className="un-emph d-in-block">
                  On a scale of 1-10 <br /> (1 not committed at all and 10
                    very committed)
                  </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Where will you source your medicine?"
                  value={this.state.fields.medicineSource}
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "medicineSource"
                  )}
                />
                <label className="label-helper py-2 px-1">
                  Where will you source your medicine?
                </label>
              </div>
              <span className="text-danger">
                {this.state.errors["medicineSource"]}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Where will you receive treatment?"
                  value={this.state.fields.treatmentLocatin}
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "treatmentLocatin"
                  )}
                />
                <label className="label-helper py-2 px-1">
                  Where will you receive treatment?
                </label>
              </div>
              <span className="text-danger">
                {this.state.errors["treatmentLocatin"]}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true : false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </form>
    );
  }
}

export default CannabisUse;
