import React, { PureComponent } from 'react'
import moment from "moment";
export class TreatmentHistory extends PureComponent {
    constructor(props) {
        super(props)
    
        this.state = {
            patientProfile: {},
             treatmentHistory: [],
             month: "",
             year: ""
        }
    }
    

    componentDidMount(){
        this.setState({
            patientProfile: this.props.patientProfile,
          });
    }
    
      componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
          this.setState({
            patientProfile: this.props.patientProfile,
          });
          this.getTreatmentHistory(this.props.patientProfile?.TreatmentHistories)

        }
      }

      getTreatmentHistory(TreatmentHistories){
        let option = [];
        if(TreatmentHistories?.length > 0) {
          TreatmentHistories.forEach((value,index) => {
            const check = moment(value?.date, 'YYYY/MM/DD');
            const month = check.format('MMMM');
            const year  = check.format('YYYY');
            let treatmentData = {};
            treatmentData.month = month;
            treatmentData.year = year;
            treatmentData.name = value.name;

            option.push(treatmentData);
            
           
            this.setState({
              treatmentHistory : option
            })
          });
        }
      }

    

    render() {
        const { patientProfile } = this.state;
       


        return (
            <div className="card-body custom-inner-content  ">
            <h5 className="stepper-form-headings ">Treatment History</h5>


            {patientProfile?.TreatmentHistories === null ? 
            <h4 className="font-italic">No Treatment History Information Added</h4> :                
             <> 
              <div className="row">
              <div className="col-lg-12 col-12">
                  <table class="table table-striped">
                      <tbody>
                          <tr>
                              <td>
                                  <span className="field-label">Have you had any surgeries/treatments?</span>
                                  <span className="field-text"> {patientProfile?.previousTreatment === true ? "Yes" :
                                   patientProfile?.previousTreatment === null ? 
                                   
                                   <p className="font-italic px-4"> No Treatment History Added</p>
                                   
                                   : "No"}</span>

                              </td>
                          </tr>
                        
                      </tbody>
                  </table>
              </div>
          
            </div>
            {patientProfile?.previousTreatment === null || patientProfile?.previousTreatment === false ? 
            
           ""
             
            
            :  <div className="row">
            {this.state.treatmentHistory?.map((value, index) => ( 
                <div className="col-lg-12 col-12">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="field-label">Surgery/Treatment:</span>
                                    <span className="field-text"> {value?.name}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="field-label">Month & Year:</span>
                                    <span className="field-text"> {value.month} {value.year}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                ))}
              </div> }

             </>
           
          }

          </div>
        )
    }
}

export default TreatmentHistory
