import React, { Component } from "react";
import "../../../../assets/scss/symtoms.scss";
import { Link } from "react-router-dom";
import QuestionNo from "../../../../assets/images/question-No.png";
import moment from "moment";
import axios from "axios";
import { environment } from "../../../../environment";
import "../../../../assets/images/question.svg";
import { toast } from "react-toastify";

export class ViewSymptoms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cannabisList: [],
      visible: 10,
      loading: true,
      activeKey: null,
      primaryDiagnose: "",
      statusPrimaryDiagnose: "",
      emotions: "",
      listOfObjective: [],
      objectiveslabels: {
        "antiCancerTherapy" : "Anti-cancer Therapy",
        "pharmaceuticalReplacement" : "Pharmaceutical Replacement",
        "manageSideEffectsofChemo" : "Manage Side Effects of Chemo",
        "mentalHealth" : "Mental Health",
        "seizureControl" : "Seizure Control",
        "painRelief":  "Pain Relief"
      },
      objective: {},
      className: "",
      token: localStorage.getItem("token"),
      hideShow: [],
      patientStatus: this.props.myPatintHeathStatus,
      userID:  this.props.userID,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    this.setState((prev) => {
      return { visible: prev.visible + 10 };
    });
  }

  componentDidMount() {
    this.getHealthCheckUp();
  }

  getHealthCheckUp = async () => {
    const data = this.state.patientStatus === "physician" ? "dashboard/healthCheckUp?userId="+this.state.userID : "dashboard/healthCheckUp";

    await axios
      .get(environment.endPoint+data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          this.setState({
            cannabisList: data,
            loading: false,
          });
        }
        this.getCannabisData(this.state.cannabisList);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.success("Please Check your Service Provider", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.setState({
            loading: false
          })
        }
      });
  };

  getCannabisData(response) {
    if (response.length > 0) {
      response.forEach((value, index) => {
        let x = this.state.hideShow;
        x.push({ index: index, open: false, className: "card-link" });
        this.setState({
          hideShow: x,
        });
      });
    }
  }

  onClickAccordion = (index) => {
    let select = this.state.hideShow;
    select[index].open = !select[index].open;

    if (select[index].open === true) {
      this.setState(
        {
          hideShow: select,
        },
        () => {}
      );
    } else if (select[index].open === false) {
      select[index].className = "";
      this.setState(
        {
          hideShow: select,
        },
        () => {}
      );
    }
  };
  render() {
    return (
   
   <div className={`${this.state.patientStatus === "physician" ? "" : "first-main"}`}>
        <div className="container add-symptoms-main">
          <div className="main-heading">
            <h1>Check {this.state.patientStatus === "physician" ? "Patient" : "Your"} Health Journey!</h1>
            <p className="sub-heading">
              Showing <strong>{this.state.cannabisList.length}</strong> Health
              Check-Up Entries Between{" "}
              <strong>
                {" "}
                {moment(
                  this.state.cannabisList[this.state.cannabisList.length - 1]
                    ?.createdAt
                )
                  .local()
                  .format("MMMM-YYYY")}{" "}
                -{" "}
                {moment(this.state.cannabisList[0]?.createdAt)
                  .local()
                  .format("MMMM-YYYY")}
              </strong>
            </p>
          </div>
          {this.state.cannabisList.length === 0 ? (
           <p className="text-center stepper-form-headings text-secondary">
           {" "}
           {this.state.patientStatus === "physician" ? "Patient's" : "Your"} Daily Health Progress along with Cannabis usage will show here
         </p>
          ) : (
            ""
          )}

          {this.state.cannabisList
            .slice(0, this.state.visible)
            .map((value, index) => (
           
           <div className="add-symptoms-body">
                <div>
                  <div className="card profile-symptoms fade-in">
                    <div className="card-header">
                      <div className="row">
                        <div class="col-lg-7 col-md-12">
                          <Link
                            className={
                              this.state.hideShow[index]?.open === true
                                ? "collapsed-minus"
                                : "card-link"
                            }
                            onClick={(e) => this.onClickAccordion(index)}
                          ></Link>

                          <div className="header-date">
                            <p>
                              {moment(value.createdAt)
                                .local()
                                .format("MMMM-DD-YYYY")}{" "}
                              ~ {moment(value.createdAt).local().format("LTS")}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="padding-fix">
                            <p className="header-status">Cannabis Today:</p>

                            {value.cannabisToday === "yes" ? (
                              <button className="btn btn-success ">
                                <i class="fa fa-smile-o" aria-hidden="true"></i>
                                {value.cannabisToday.charAt(0).toUpperCase() +
                                  value.cannabisToday.substr(1).toLowerCase()}
                              </button>
                            ) : (
                              <button className="btn btn-primary">
                                <img
                                  src={QuestionNo}
                                  alt="QuestionNo"
                                  width="30"
                                  height="30"
                                  className=""
                                />{" "}
                                {value.cannabisToday.charAt(0).toUpperCase() +
                                  value.cannabisToday.substr(1).toLowerCase()}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 mt-2 text-right">
                          {value.cannabisToday === "yes" ? (
                            <div className="main-status ">
                              {value.status === "worse" ? (
                                <>
                                  <p className="header-status">Status: </p>

                                  <i
                                    class="fa fa-frown-o fa-2x text-primary-status ml-2"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : (
                                ""
                              )}
                              {value.status === "better" ? (
                                <>
                                  <p className="header-status">Status: </p>
                                  <i
                                    class="fa fa-smile-o fa-2x  text-primary-status ml-2"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : (
                                ""
                              )}
                              {value.status === "same" ? (
                                <>
                                  <p className="header-status">Status: </p>
                                  <i
                                    class="fa fa-meh-o fa-2x text-primary-status ml-2"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : (
                                ""
                              )}
                              {value.status === "" ? (
                                <p className="header-status font-italic ml-2 mt-5">
                                  No Status Added
                                </p>
                              ) : (
                                <p className="header-status show-header-status ml-2">
                                  {value.status.charAt(0).toUpperCase() +
                                    value.status.substr(1).toLowerCase()}
                                </p>
                              )}
                            </div>
                          ) : (
                            <p className="header-status font-italic ml-2 mt-5">
                              No Status Added
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="medicene">
                        <div className="row">
                          {/* {value.comment} */}

                          {value.cannabisToday === "yes" ? (
                            <div className="col-lg-3 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="padding-fix text-secondary">
                                  Amount:{" "}
                                  <span className="taken text-secondary"></span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="col-lg-3 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="padding-fix text-secondary">
                                  Amount:{" "}
                                  <span className="taken text-secondary"></span>
                                </div>
                              )}
                            </div>
                          )}
                          {value.cannabisToday === "yes" ? (
                            <div className="col-lg-2 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="Frequency padding-fix text-secondary">
                                  Frequency:{" "}
                                  <span className="taken text-secondary"></span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="col-lg-2 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="Frequency padding-fix  text-secondary">
                                  Frequency:{" "}
                                  <span className="taken text-secondary"></span>
                                </div>
                              )}
                            </div>
                          )}
                          {value.cannabisToday === "yes" ? (
                            <div className="col-lg-3 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="padding-fix text-secondary">
                                  Method of Ingestion:{" "}
                                  <span className="taken text-secondary text-truncate"></span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="col-lg-3 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="padding-fix text-secondary">
                                  Method of Ingestion:{" "}
                                  <span className="taken text-secondary text-truncate"></span>
                                </div>
                              )}
                            </div>
                          )}
                          {value.cannabisToday === "yes" ? (
                            <div className="col-lg-2 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="padding-fix text-secondary">
                                  Products:{" "}
                                  <span className="taken text-secondary text-truncate"></span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="col-lg-2 col-md-6 mt-2">
                              {value.CannabisDoses.length > 0 ? (
                                ""
                              ) : (
                                <div className="padding-fix text-secondary">
                                  Products:{" "}
                                  <span className="taken text-secondary text-truncate"></span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.hideShow[index]?.open === true ? (
                      <div>
                        <div className="card-body">
                          {value.CannabisDoses.map((cannabisValue, index) => (
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                              <td>
                                <div className="view-symptoms-table">
                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="padding-fix">
                                  <p className="header-status"> Amount: </p>
                                  {this.cannabisValue?.amount !== null ? (
                                    <span className="taken text-primary">
                                      ({cannabisValue?.amount}mg)
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-2 col-md-6">
                                <div className="Frequency padding-fix">
                                  <p className="header-status"> Frequency: </p>
                                  {cannabisValue?.frequency !== null ? (
                                    <span className="taken text-primary">
                                      {cannabisValue?.frequency}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6">
                                <div className="padding-fix">
                                  <p className="header-status">
                                    Method of Ingestion:{" "}
                                  </p>
                                  {cannabisValue?.ingestionMethod !== null ? (
                                    <span className="taken text-primary">
                                      {cannabisValue?.ingestionMethod
                                        .charAt(0)
                                        .toUpperCase() +
                                        cannabisValue?.ingestionMethod
                                          .substr(1)
                                          .toLowerCase()}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="padding-fix">
                                  <p className="header-status">Product: </p>
                                  {cannabisValue?.product !== null ? (
                                    <span className="taken text-primary">
                                      {cannabisValue?.product
                                        .charAt(0)
                                        .toUpperCase() +
                                        cannabisValue?.product
                                          .substr(1)
                                          .toLowerCase()}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            </div>
                            </td>
                            </tr>
                            </tbody>
                            </table>
                          ))}
                          {value.objective !== null ? (
                            <div>
                              {Object.keys(value.objective).map(
                                (label, index) => (
                                <div className="row mt-5">
                                  <div className="col-lg-4 col-md-6">
                                        <div className="padding-fix view-symptoms-status">
                                          
                                          <span className="taken text-primary">
                                            {this.state.objectiveslabels[label] ? this.state.objectiveslabels[label] : label
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  label
                                                    .substr(1)
                                                    .toLowerCase()}
                                             
                                          </span>
                                        </div>
                                      </div>

                                      <div className="col-lg-2 col-md-6">
                                      <div className="main-status padding-fix">
                                            <p className="header-status">
                                              Status:{" "}
                                            </p>

                                            {value.objective[label] ===
                                            "worse" ? (
                                              <i
                                                class="fa fa-frown-o fa-2x text-primary-status ml-2"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                            {value.objective[label]  ===
                                            "better" ? (
                                              <i
                                                class="fa fa-smile-o fa-2x  text-primary-status ml-2"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                            {value.objective[label]  ===
                                            "same" ? (
                                              <i
                                                class="fa fa-meh-o fa-2x text-primary-status ml-2"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                            {value.objective[label]  ===
                                            "" ? (
                                              <p className="header-status font-italic ml-2 mt-5">
                                                No Status Added
                                              </p>
                                            ) : (
                                              <p className="header-status show-header-status ml-2">
                                                {value.objective[label]
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  value.objective[label]
                                                    .substr(1)
                                                    .toLowerCase()}
                                              </p>
                                            )}
                                          </div>
                                       
                                      </div>
                                  
                                  </div>
                                
                                )
                              )}{" "}
                            </div>
                          ) : (
                            ""
                          )}

                          {value.cannabisToday === "yes" ? (
                            <div className="row">
                              <div className="col-lg-12 col-12">
                                <p className="mt-2 heading-cmnts">
                                 <p> Comments: </p>{" "}
                                  {value.comment !== "" ? (
                                    <span className="cmnts">
                                      {value.comment}
                                    </span>
                                  ) : (
                                    <span className="font-italic ml-1">
                                      No Comment Added
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-lg-12 col-12">
                                <p className="mt-2 heading-cmnts">
                                  Comments: <br />
                                  <span className="text-secondary cmnts">
                                    {value.comment}
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
           
           ))}

          {this.state.visible < this.state.cannabisList.length && (
            <div class="container load-more">
              <div class="row">
                <div class="col col-lg-7 offset-lg-3 text-center">
                  <button
                    onClick={this.loadMore}
                    type="button"
                    className="btn btn-primary submit mb-4"
                  >
                    Load more
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.loading ? (
            <div className="col-lg-2">
              <div class="loading">Loading&#8230;</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
   
   );
  }
}

export default ViewSymptoms;
