import React, { Component } from "react";
import Stepper from "bs-stepper";
import PersonalInformation from "./PersonalInformation";
import Contact from "./Contact";
import Diagnosis from "./Diagnosis";
import TreatmentHistory from "./TreatmentHistory";
import MedicationSupplements from "./MedicationSupplements";
import FamilyHistory from "./FamilyHistory";
import PatientHistory from "./PatientHistory";
import Summary from "./Summary";
import CannabisUse from "./CannabisUse";
import MilitaryService from "./MilitaryService";
import Nutrition from "./Nutrition";
import MyDocuments from "./MyDocuments";
import Exercise from "./Exercise";
import axios from "axios";
import { environment } from "../../../../environment";

class MyHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "first",
      patientIsCompleted: false,
      patientStatus: "",
      contactIsCompleted: false,
      contactStatus: "",
      diagnosisIsCompleted: false,
      diagnosisStatus: "",
      allStatus: [],
      militaryService: {},
      cannabisUse: {},
      contacts: {},
      diagnosis: {},
      allergy: false,
      loading: false,
      token: localStorage.getItem("token"),
      medications: [
        {
          name: "",
          dose: "",
          frequency: "",
          phone: "",
        },
      ],
      allergies: [
        {
          name: "",
        },
      ],
      note: {},
      data: {},
      patientData: {},
      nutritionData: {},
      exerciseData: {},
      status: this.props.myPatintHeathStatus,
      hideShowHeadingPatient: this.props.hideShow,
      userID: this.props.userID,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
    this.getPatientIntakeStatus();
    if(this.props.myPatintHeathStatus !== "physician"){
      this.getRouting();
    }
  }


  next(e) {
    this.stepper.next();
  }

  prev(e) {
    this.stepper.previous();
  }

  getRouting(){
    if (this.props.location.pathname.includes("info-patient")) {
      document.getElementById("step-trigger-patient").click();
    } else if (this.props.location.pathname.includes("contact")) {
      document.getElementById("step-trigger-contact").click();
    } else if (this.props.location.pathname.includes("diagnosis")) {
      document.getElementById("step-trigger-diagnosis").click();
    } else if (this.props.location.pathname.includes("medications")) {
      document.getElementById("step-trigger-medications").click();
    } else if (this.props.location.pathname.includes("treatment")) {
      document.getElementById("step-trigger-treatment").click();
    } else if (this.props.location.pathname.includes("patient-history")) {
      document
        .getElementById("step-trigger-patient-history")
        .click();
    } else if (this.props.location.pathname.includes("family-history")) {
      document
        .getElementById("step-trigger-family-history")
        .click();
    } else if (this.props.location.pathname.includes("cannabis")) {
      document.getElementById("step-trigger-cannabis").click();
    } else if (this.props.location.pathname.includes("military")) {
      document.getElementById("step-trigger-military").click();
    } else if (
      this.props.location.pathname.includes("nutrition-exercise")
    ) {document.getElementById("step-trigger-nutrition-exercise").click(); 
    } else  if (this.props.location.pathname.includes("my-documents")) {
      document.getElementById("step-trigger-my-documents").click();
    } else  if (this.props.location.pathname.includes("exercise")) {
      document.getElementById("step-trigger-patient-exercise").click();
    } else  if (this.props.location.pathname.includes("summary")) {
      document.getElementById("step-trigger-patient-summary").click();
    }
  }

  patientClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "PatientInformation") {
        var patientsBtnOuter = document.getElementById("Patient").classList;
        if (patientsBtnOuter.contains("step")) {
          patientsBtnOuter.remove("active");
          patientsBtnOuter.add("patient-intake-active");
        } else {
          patientsBtnOuter.remove("active");
        }
      } else if (
        data.isCompleted !== true &&
        data.module !== "PatientInformation"
      ) {
        var patientsBtnInner = document.getElementById("Patient").classList;
        if (patientsBtnInner.contains("step")) {
          patientsBtnInner.remove("patient-intake-active");
          patientsBtnInner.add("active");
        } else {
          patientsBtnInner.add("active");
        }
      }
    });
    document.getElementById('test-l-1').scrollIntoView(true)
  }

  contactsClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "Contacts") {
        var ContactsInner = document.getElementById("Contacts").classList;
        if (ContactsInner.contains("step")) {
          ContactsInner.remove("active");
          ContactsInner.add("patient-intake-active");
        } else {
          ContactsInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "Contacts") {
        var ContactsOuter = document.getElementById("Contacts").classList;
        if (ContactsOuter.contains("step")) {
          ContactsOuter.remove("patient-intake-active");
          ContactsOuter.add("active");
        } else {
          ContactsOuter.add("active");
        }
      }
    });
    document.getElementById('test-l-2').scrollIntoView(true)
  }

  medicationsClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "Medications") {
        var medicationsInner = document.getElementById("medications").classList;
        if (medicationsInner.contains("step")) {
          medicationsInner.remove("active");
          medicationsInner.add("patient-intake-active");
        } else {
          medicationsInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "Medications") {
        var medicationsOuter = document.getElementById("medications").classList;
        if (medicationsOuter.contains("step")) {
          medicationsOuter.remove("patient-intake-active");
          medicationsOuter.add("active");
        } else {
          medicationsOuter.add("active");
        }
      }
    });
    document.getElementById('test-l-5').scrollIntoView(true)
  }

  diagonsisClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "Diagnosis") {
        var DiagnosisInner = document.getElementById("Diagnosis").classList;
        if (DiagnosisInner.contains("step")) {
          DiagnosisInner.remove("active");
          DiagnosisInner.add("patient-intake-active");
        } else {
          DiagnosisInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "Diagnosis") {
        var Diagnosis = document.getElementById("Diagnosis").classList;
        if (Diagnosis.contains("step")) {
          Diagnosis.remove("patient-intake-active");
          Diagnosis.add("active");
        } else {
          Diagnosis.add("active");
        }
      }
    });
    document.getElementById('test-l-3').scrollIntoView(true)
  }

  treatmentHistoryClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "TreatmentHistory") {
        var treatmentInner = document.getElementById("treatment").classList;
        if (treatmentInner.contains("step")) {
          treatmentInner.remove("active");
          treatmentInner.add("patient-intake-active");
        } else {
          treatmentInner.remove("active");
        }
      } else if (
        data.isCompleted !== true &&
        data.module !== "TreatmentHistory"
      ) {
        var treatment = document.getElementById("treatment").classList;
        if (treatment.contains("step")) {
          treatment.remove("patient-intake-active");
          treatment.add("active");
        } else {
          treatment.add("active");
        }
      }
    });
    document.getElementById('test-l-4').scrollIntoView(true)
  }

  patientHistoryClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "PatientHistory") {
        var patientInner = document.getElementById("patientHistory").classList;
        if (patientInner.contains("step")) {
          patientInner.remove("active");
          patientInner.add("patient-intake-active");
        } else {
          patientInner.remove("active");
        }
      } else if (
        data.isCompleted !== true &&
        data.module !== "PatientHistory"
      ) {
        var patient = document.getElementById("patientHistory").classList;
        if (patient.contains("step")) {
          patient.remove("patient-intake-active");
          patient.add("active");
        } else {
          patient.add("active");
        }
      }
    });
    document.getElementById('test-l-7').scrollIntoView(true)
  }

  summaryClickEvent(e) {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "Summary") {
        var patientInner = document.getElementById("summary").classList;
        if (patientInner.contains("step")) {
          patientInner.remove("active");
          patientInner.add("patient-intake-active");
        } else {
          patientInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "Summary") {
        var patient = document.getElementById("summary").classList;
        if (patient.contains("step")) {
          patient.remove("patient-intake-active");
          patient.add("active");
        } else {
          patient.add("active");
        }
      }
    });
    document.getElementById('test-l-10').scrollIntoView(true)
  }

  familyHistoryClickEvent(e) {
    e.preventDefault();
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "FamilyHistory") {
        var familyInner = document.getElementById("familyHistory").classList;
        if (familyInner.contains("step")) {
          familyInner.remove("active");
          familyInner.add("patient-intake-active");
        } else {
          familyInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "FamilyHistory") {
        var family = document.getElementById("familyHistory").classList;
        if (family.contains("step")) {
          family.remove("patient-intake-active");
          family.add("active");
        } else {
          family.add("active");
        }
      }
    });
    document.getElementById('test-l-6').scrollIntoView(true)
  }

  cannabisClickEvent(e) {
    e.preventDefault();
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "CannabisUse") {
        var familyInner = document.getElementById("cannabis").classList;
        if (familyInner.contains("step")) {
          familyInner.remove("active");
          familyInner.add("patient-intake-active");
        } else {
          familyInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "CannabisUse") {
        var family = document.getElementById("cannabis").classList;
        if (family.contains("step")) {
          family.remove("patient-intake-active");
          family.add("active");
        } else {
          family.add("active");
        }
      }
    });
    document.getElementById('test-l-8').scrollIntoView(true)
  }

  militaryClickEvent(e) {
    e.preventDefault();
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "MilitaryService") {
        var militaryInner = document.getElementById("military").classList;
        if (militaryInner.contains("step")) {
          militaryInner.remove("active");
          militaryInner.add("patient-intake-active");
        } else {
          militaryInner.remove("active");
        }
      } else if (
        data.isCompleted !== true &&
        data.module !== "MilitaryService"
      ) {
        var military = document.getElementById("military").classList;
        if (military.contains("step")) {
          military.remove("patient-intake-active");
          military.add("active");
        } else {
          military.add("active");
        }
      }
    });
    document.getElementById('test-l-9').scrollIntoView(true)
  }

  getPatientIntakeStatus = async() => {
    this.setState({
      loading: true
    })
    const data = this.state.status === "physician" ? "intake/status?userId="+ this.state.userID : "intake/status";

    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const intakeStatus = response.data.data;
          this.setState({ allStatus: intakeStatus,loading: false });
          // eslint-disable-next-line array-callback-return
          this.state.allStatus.forEach((data) => {
            if (
              data.isCompleted === true &&
              data.module === "PatientInformation"
            ) {
              var PatientsInner = document.getElementById("Patient").classList;
              if (PatientsInner.contains("step")) {
                PatientsInner.remove("active");
                PatientsInner.add("patient-intake-active");
              } else {
                PatientsInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "Contacts"
            ) {
              var ContactsInner = document.getElementById("Contacts").classList;
              if (ContactsInner.contains("step")) {
                ContactsInner.remove("active");
                ContactsInner.add("patient-intake-active");
              } else {
                ContactsInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "Diagnosis"
            ) {
              var DiagnosisInner = document.getElementById("Diagnosis")
                .classList;
              if (DiagnosisInner.contains("step")) {
                DiagnosisInner.remove("active");
                DiagnosisInner.add("patient-intake-active");
              } else {
                DiagnosisInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "Medications"
            ) {
              var medicationsInner = document.getElementById("medications")
                .classList;
              if (medicationsInner.contains("step")) {
                medicationsInner.remove("active");
                medicationsInner.add("patient-intake-active");
              } else {
                medicationsInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "TreatmentHistory"
            ) {
              var treatmentInner = document.getElementById("treatment")
                .classList;
              if (treatmentInner.contains("step")) {
                treatmentInner.remove("active");
                treatmentInner.add("patient-intake-active");
              } else {
                treatmentInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "PatientHistory"
            ) {
              var patientInner = document.getElementById("patientHistory")
                .classList;
              if (patientInner.contains("step")) {
                patientInner.remove("active");
                patientInner.add("patient-intake-active");
              } else {
                patientInner.remove("active");
              }
            } else if (data.isCompleted === true && data.module === "Summary") {
              var summaryInner = document.getElementById("summary").classList;
              if (summaryInner.contains("step")) {
                summaryInner.remove("active");
                summaryInner.add("patient-intake-active");
              } else {
                summaryInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "FamilyHistory"
            ) {
              var familyInner = document.getElementById("familyHistory")
                .classList;
              if (familyInner.contains("step")) {
                familyInner.remove("active");
                familyInner.add("patient-intake-active");
              } else {
                familyInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "CannabisUse"
            ) {
              var cannabisUseInner = document.getElementById("cannabis")
                .classList;
              if (cannabisUseInner.contains("step")) {
                cannabisUseInner.remove("active");
                cannabisUseInner.add("patient-intake-active");
              } else {
                cannabisUseInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "MilitaryService"
            ) {
              var militaryInner = document.getElementById("military").classList;
              if (militaryInner.contains("step")) {
                militaryInner.remove("active");
                militaryInner.add("patient-intake-active");
              } else {
                militaryInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "Nutrition"
            ) {
              var nutritionInner = document.getElementById("nutrition")
                .classList;
              if (nutritionInner.contains("step")) {
                nutritionInner.remove("active");
                nutritionInner.add("patient-intake-active");
              } else {
                nutritionInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "Exercise"
            ) {
              var ExerciseInner = document.getElementById("exercise").classList;
              if (ExerciseInner.contains("step")) {
                ExerciseInner.remove("active");
                ExerciseInner.add("patient-intake-active");
              } else {
                ExerciseInner.remove("active");
              }
            } else if (
              data.isCompleted === true &&
              data.module === "MyDocuments"
            ) {
              var myDocuments = document.getElementById("documents").classList;
              if (myDocuments.contains("step")) {
                myDocuments.remove("active");
                myDocuments.add("patient-intake-active");
              } else {
                myDocuments.remove("active");
              }
            }
          });
        }
      })
      .catch((e) => {
        //   if(e.response.status === 401) {
        //     this.props.history.push("/login")
        // }
      });
  }
  nutritionClickEvent(e) {
    e.preventDefault();
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "Nutrition") {
        var familyInner = document.getElementById("nutrition").classList;
        if (familyInner.contains("step")) {
          familyInner.remove("active");
          familyInner.add("patient-intake-active");
        } else {
          familyInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "Nutrition") {
        var family = document.getElementById("nutrition").classList;
        if (family.contains("step")) {
          family.remove("patient-intake-active");
          family.add("active");
        } else {
          family.add("active");
        }
      }
    });
    document.getElementById('test-l-11').scrollIntoView(true)

  }

  documentsClickEvent(e) {
    e.preventDefault();
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "MyDocuments") {
        var documentsInner = document.getElementById("documents").classList;
        if (documentsInner.contains("step")) {
          documentsInner.remove("active");
          documentsInner.add("patient-intake-active");
        } else {
          documentsInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "MyDocuments") {
        var documents = document.getElementById("documents").classList;
        if (documents.contains("step")) {
          documents.remove("patient-intake-active");
          documents.add("active");
        } else {
          documents.add("active");
        }
      }
    });
    document.getElementById('test-l-12').scrollIntoView(true)
  }
  exerciseClickEvent(e) {
    e.preventDefault();
    this.state.allStatus.forEach((data) => {
      if (data.isCompleted === true && data.module === "Exercise") {
        var exerciseInner = document.getElementById("exercise").classList;
        if (exerciseInner.contains("step")) {
          exerciseInner.remove("active");
          exerciseInner.add("patient-intake-active");
        } else {
          exerciseInner.remove("active");
        }
      } else if (data.isCompleted !== true && data.module !== "Exercise") {
        var exercise = document.getElementById("exercise").classList;
        if (exercise.contains("step")) {
          exercise.remove("patient-intake-active");
          exercise.add("active");
        } else {
          exercise.add("active");
        }
      }
    });
    document.getElementById('test-l-13').scrollIntoView(true)
  }
  render() {
    return (
      <div className="patient-intake mb-5">
        {this.state.status === "physician" ? (
          ""
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12 col-12">
                <h5 className=" text-center stepper-form-headings custom-font mt-2">
                  Health Intake
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-12">
                <p className="mt-3 text-center hide">
                  Complete each section of the health form below. <br /> You
                  will see a checkmark{" "}
                  <i
                    class="fa fa-2x fa-check-circle text-success mr-1"
                    aria-hidden="true"
                  ></i>
                  when a section is completed.
                </p>
              </div>
            </div>
          </>
        )}

        <div className="mt-2 mb-2">
          <div className="container">
            <div className="card card-border">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body p-0">
                    <div id="stepper1" class="bs-stepper">
                      <div className="row">
                        <div className="col-lg-4 col-md-3">
                          <div className="register-sidebar">
                            <div className=" d-lg-block">
                              <div className="bs-stepper-header ">
                                <div
                                  id="Patient"
                                  className="step"
                                  data-target="#test-l-1"
                                  
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-patient"
                                    onClick={this.patientClickEvent.bind(this)}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient Information
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="Contacts"
                                  className="step"
                                  data-target="#test-l-2"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-contact"
                                    onClick={this.contactsClickEvent.bind(this)}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Contacts
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="Diagnosis"
                                  className="step"
                                  data-target="#test-l-3"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-diagnosis"
                                    onClick={this.diagonsisClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Diagnosis
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="treatment"
                                  className="step"
                                  data-target="#test-l-4"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-treatment"
                                    onClick={this.treatmentHistoryClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Treatment History
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="medications"
                                  className="step"
                                  data-target="#test-l-5"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-medications"
                                    onClick={this.medicationsClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Medications & Supplements
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="familyHistory"
                                  className="step"
                                  data-target="#test-l-6"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-family-history"
                                    onClick={this.familyHistoryClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Family History
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="patientHistory"
                                  className="step"
                                  data-target="#test-l-7"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-patient-history"
                                    onClick={this.patientHistoryClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient History
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="cannabis"
                                  className="step"
                                  data-target="#test-l-8"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-cannabis"
                                    onClick={this.cannabisClickEvent.bind(this)}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Cannabis Use
                                    </span>
                                  </button>
                                </div>

                                <div
                                  id="military"
                                  className="step"
                                  data-target="#test-l-9"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-military"
                                    onClick={this.militaryClickEvent.bind(this)}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Military Service
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="nutrition"
                                  className="step"
                                  data-target="#test-l-11"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-nutrition-exercise"
                                    onClick={this.nutritionClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Nutrition
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="exercise"
                                  className="step"
                                  data-target="#test-l-13"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-patient-exercise"
                                    onClick={this.exerciseClickEvent.bind(this)}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Exercise
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="documents"
                                  className="step"
                                  data-target="#test-l-12"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-my-documents"
                                    onClick={this.documentsClickEvent.bind(
                                      this
                                    )}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      My Documents
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="summary"
                                  className="step"
                                  data-target="#test-l-10"
                                >
                                  <button
                                    className="step-trigger"
                                    id="step-trigger-patient-summary"
                                    onClick={this.summaryClickEvent.bind(this)}
                                  >
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Summary
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-9">
                          <div class="bs-stepper-content registration">
                            <div
                              className="bs-stepper-header content"
                              id="test-l-1"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-1">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient Information
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <PersonalInformation
                                next={this.next}
                                prev={this.prev}
                                status={this.state.status}
                                userID={this.state.userID}
                              ></PersonalInformation>
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-2"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Contacts
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Contact
                                id="test-l-2"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                contacts={this.state.contacts}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-3"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-3">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Diagnosis
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Diagnosis
                                id="test-l-3"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                diagnosis={this.state.diagnosis}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-4"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-4">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Treatment History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <TreatmentHistory
                                id="test-l-4"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-5"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-5">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Medications and Supplements
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <MedicationSupplements
                                id="test-l-5"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                allergies={this.state.allergies}
                                medications={this.state.medications}
                                allergy={this.state.allergy}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-6"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-6">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Family History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <FamilyHistory
                                id="test-l-6"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                data={this.state.data}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-7"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-7">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <PatientHistory
                                id="test-l-7"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                patientData={this.state.patientData}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-8"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-8">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Cannabis Use
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <CannabisUse
                                id="test-l-8"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                cannabisUse={this.state.cannabisUse}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-9"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-9">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Military Service
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <MilitaryService
                                id="test-l-9"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                militaryServiceData={this.state.militaryService}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-11"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-11">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Nutrition
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Nutrition
                                id="test-l-11"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                nutritionData={this.state.nutritionData}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-13"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-11">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Exercise
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Exercise
                                id="test-l-13"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                exerciseData={this.state.exerciseData}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-12"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-12">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      My Documents
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <MyDocuments
                                id="test-l-12"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-10"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-10">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Summary
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Summary
                                id="test-l-9"
                                class="content"
                                next={this.next}
                                prev={this.prev}
                                note={this.state.note}
                                status={this.state.status}
                                userID={this.state.userID}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MyHealth;
