import React, { Component } from "react";
import { Link } from "react-router-dom";

export class consent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
   
   <div className="auth-form mb-5">
        <form>
          <div className="custom-card consent-card">
            <h3 className="stepper-form-headings mt-5 text-left">Consent</h3>
            <div className="row mb-4 mt-4">
              <div className="col-lg-12 col-12">
                <div className="custom-form-row">
                  <p className="consent-paragraph">
                    You agree to have Octopiwellness share your health and
                    wellness information with the healthcare or wellness
                    professional(s) that you have selected. This includes
                    information and data that you have logged with
                    Octopiwellness, such as your medical condition, associated
                    symptoms, physical activities, vital signs, medications,
                    treatment, diagnostic and other questionnaires, food and
                    fluid intake, and medical appointments. Octopiwellness is
                    not responsible for the actions of any healthcare or
                    wellness professional(s) with whom you choose to share such
                    information. If you have any questions or concerns, you
                    should review the{" "}
                    <span>
                      <Link>Terms of Service</Link>
                    </span>{" "}
                    and{" "}
                    <span>
                      <Link>Privacy Policy</Link>
                    </span>
                    . You may also change your settings or deactivate the
                    sharing feature at any time by contacting Octopiwellness at
                    support@octopiwellness.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
   
   );
  }
}

export default consent;
