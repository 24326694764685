import React, { PureComponent } from "react";
import Stepper from "bs-stepper";
import PatientInformation from "./PatientInformation";
import Contacts from "./Contact";
import Diagnosis from "./Diagnosis";
import CannabisUse from "./CannabisUse";
import Comments from "./Comments";
import FamilyHistory from "./FamilyHistory";
import Exercise from "./Exercise";
import Nutrition from "./Nutrition";
import TreatmentHistory from "./TreatmentHistory";
import MedicaitionsSupplements from "./MedicaitionsSupplements";
import MilitaryService from "./MilitaryService";
import PatientHistory from "./PatientHistory";
import Documents from "./Documents";

export class MyHealthPatientListing extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      patientProfile: {},
      token: localStorage.getItem("token"),
    };
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#patient-profile"), {
      linear: false,
      animation: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }

  render() {
    const { patientProfile } = this.state;

    return (
   
   <div className="patient-intake mb-5">
        <div className="">
          <div className="container">
            <div className="card card-border">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body p-0">
                    <div id="patient-profile" class="bs-stepper">
                      <div className="row">
                        <div className="col-lg-4 col-md-3">
                          <div className="register-sidebar">
                            <div className=" d-lg-block">
                              <div className="bs-stepper-header ">
                                <div
                                  id="patientInformation"
                                  className="step"
                                  data-target="#test-l-1"
                                  onClick={() => document.getElementById('test-l-1').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient Information
                                    </span>
                                  </button>
                                </div>
                                <div
                                  
                                  className="step"
                                  data-target="#test-l-2"
                                  onClick={() => document.getElementById('test-l-2').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Contacts
                                    </span>
                                  </button>
                                </div>
                                <div
                                  className="step"
                                  data-target="#test-l-3"
                                  onClick={() => document.getElementById('test-l-3').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Diagnosis
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Treatment"
                                  className="step"
                                  data-target="#test-l-4"
                                  onClick={() => document.getElementById('test-l-4').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Treatment History
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Medications"
                                  className="step"
                                  data-target="#test-l-5"
                                  onClick={() => document.getElementById('test-l-5').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Medications & Supplements
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="patientInformation"
                                  className="step"
                                  data-target="#test-l-6"
                                  onClick={() => document.getElementById('test-l-6').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Family History
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="patientInformation"
                                  className="step"
                                  data-target="#test-l-12"
                                  onClick={() => document.getElementById('test-l-12').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient History
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Cannabis"
                                  className="step"
                                  data-target="#test-l-7"
                                  onClick={() => document.getElementById('test-l-7').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Cannabis Use
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Military"
                                  className="step"
                                  data-target="#test-l-10"
                                  onClick={() => document.getElementById('test-l-10').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Military Service
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Nutrition"
                                  className="step"
                                  data-target="#test-l-8"
                                  onClick={() => document.getElementById('test-l-8').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Nutrition
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Exercise"
                                  className="step"
                                  data-target="#test-l-9"
                                  onClick={() => document.getElementById('test-l-9').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Exercise
                                    </span>
                                  </button>
                                </div>
                                
                                <div
                                  id="Documents"
                                  className="step"
                                  data-target="#test-l-13"
                                  onClick={() => document.getElementById('test-l-13').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Documents
                                    </span>
                                  </button>
                                </div>
                                <div
                                  id="Summary"
                                  className="step"
                                  data-target="#test-l-11"
                                  onClick={() => document.getElementById('test-l-11').scrollIntoView(true)
                                }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Summary
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-9">
                          <div class="bs-stepper-content registration">
                            <div
                              className="bs-stepper-header content"
                              id="test-l-1"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient Information
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <PatientInformation
                                patientProfile={patientProfile}
                              />
                            </div>

                            <div
                              className="bs-stepper-header content"
                              id="test-l-2"
                            >
                              <div className="d-none ">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Contacts
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Contacts  patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-3"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Diagnosis
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Diagnosis patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-4"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Treatment History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <TreatmentHistory
                                patientProfile={patientProfile}
                              />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-5"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Medications & Supplements
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <MedicaitionsSupplements
                                patientProfile={patientProfile}
                              />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-6"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Family History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <FamilyHistory patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-12"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Patient History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <PatientHistory patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-7"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Cannabis Use
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <CannabisUse patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-10"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Military Service
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <MilitaryService
                                patientProfile={patientProfile}
                              />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-8"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Nutrition
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Nutrition patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-9"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Exercise
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Exercise patientProfile={patientProfile} />
                            </div>
                           
                            <div
                              className="bs-stepper-header content"
                              id="test-l-13"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Documents
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Documents patientProfile={patientProfile} />
                            </div>
                            <div
                              className="bs-stepper-header content"
                              id="test-l-11"
                            >
                              <div className="d-none">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Summary
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <Comments patientProfile={patientProfile} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
   );
  }
}

export default MyHealthPatientListing;
