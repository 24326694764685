import React, { PureComponent } from "react";
import moment from "moment";

export class Diagnosis extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      patientProfile: {},
    };
  }
  componentDidMount(){
    this.setState({
        patientProfile: this.props.patientProfile,
      });
}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }


  render() {
    const { patientProfile } = this.state;
    return (
      <div className="card-body custom-inner-content">
        <h5 className="stepper-form-headings ">Diagnosis</h5>
        <div className="row">
          <div className="col-lg-12 col-12">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <span className={`${patientProfile?.Diagnose?.DignoseList?.name === null ||
                       patientProfile?.Diagnose?.DignoseList?.name === "" ?
                        "field-not-found" : "field-label"}`}>Primary Diagnosis:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.Diagnose?.DignoseList?.name.charAt(0)
                              .toUpperCase() +
                              patientProfile?.Diagnose?.DignoseList?.name.substr(1).toLowerCase()}
                    </span>
                  </td>
                </tr>
                {patientProfile?.Diagnose?.DignoseList?.isCancer === true ? (
                  <tr>
                    <td>
                      <span className={`${patientProfile?.Diagnose?.cancerStage === null ||
                       patientProfile?.Diagnose?.cancerStage === ""  ?
                        "field-not-found" : "field-label"}`}>Stage of Cancer:</span>
                      <span className="field-text">
                        {" "}
                        {patientProfile?.Diagnose?.cancerStage}
                      </span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                <tr>
                  <td>
                    <span className={`${patientProfile?.Diagnose?.icdCode === null ||
                       patientProfile?.Diagnose?.icdCode === "" ?
                        "field-not-found" : "field-label"}`}>ICD-10 Code:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.Diagnose?.icdCode}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.Diagnose?.onsetDate === null ||
                       patientProfile?.Diagnose?.onsetDate === "" ?
                        "field-not-found" : "field-label"}`}>Date of Onset:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.Diagnose?.onsetDate ?
          moment(patientProfile?.Diagnose?.onsetDate).format(
            "MM-DD-YYYY"
          ) : "####"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.Diagnose?.duration === null ||
                       patientProfile?.Diagnose?.duration === "" ?
                        "field-not-found" : "field-label"}`}>Duration:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.Diagnose?.duration}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.Diagnose?.healthConcerns === null ||
                       patientProfile?.Diagnose?.healthConcerns === "" ?
                        "field-not-found" : "field-label"}`}>Concerns:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.healthConcerns}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Diagnosis;
