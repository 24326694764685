import React, { Component } from 'react'
import Validation from '../../../common/util';
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from '../../../../environment';

export class UpdatePassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: "password",
            confirmType: "password",
            fields: {},
            errors: {},
            digit: false,
            lowerCaseCheck: false,
            upperCaseCheck: false,
            specialCharactersCheck: false,
            checkPasswordLength: false,
            loading: false,
            showValidate: true,
            token: localStorage.getItem("token")
        }
    }
    prev = () => {
        this.props.prev();
    };

    showHide = () => {
        this.setState({
            type: this.state.type === "password" ? "input" : "password",
        });
    };

    confirmPassShowHide = () => {
        this.setState({
            confirmType: this.state.confirmType === "password" ? "input" : "password",
        });
    };
    handleUserInputChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            digit: Validation.passwordValidation(this.state.fields, "passwordProfile")
                .digit,
        });
        this.setState({
            upperCaseCheck: Validation.passwordValidation(
                this.state.fields,
                "passwordProfile"
            ).upperCaseCheck,
        });
        this.setState({
            lowerCaseCheck: Validation.passwordValidation(
                this.state.fields,
                "passwordProfile"
            ).lowerCaseCheck,
        });
        this.setState({
            specialCharactersCheck: Validation.passwordValidation(
                this.state.fields,
                "passwordProfile"
            ).specialCharactersCheck,
        });
        this.setState({
            checkPasswordLength: Validation.passwordValidation(
                this.state.fields,
                "passwordProfile"
            ).checkPasswordLength,
        });
        this.setState({ fields, showValidate: false });
    }
    onSubmit = async () => {
        this.setState({
            errors: Validation.passwordValidation(
                this.state.fields,
                "passwordProfile"
            ).error,
        });
        if (
            Validation.passwordValidation(this.state.fields, "passwordProfile")
                .formIsValid
        ) {

            this.setState({
                loading: true,
            });
            let updatePassword = this.state.fields;
            await axios.post(environment.endPoint + "dashboard/profile", updatePassword, {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                },
            })
                .then((response) => {
                    if (response.status === 200 || response.status !== undefined) {
                        toast.success("Update Password Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        this.props.next();
                        let fields = this.state.fields;
                        fields["password"] = "";
                        fields["confirmPassword"] = "";
                        this.setState({
                            loading: false,
                            showValidate: true,
                            digit: false,
                            lowerCaseCheck: false,
                            upperCaseCheck: false,
                            specialCharactersCheck: false,
                            checkPasswordLength: false
                        });
                    }
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        toast.error("Password Not Updated", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                });
        }
    };
    render() {
        return (
            <div className="card-body card-body-profile custom-inner-content">
                <h5 className="stepper-form-headings d-none d-lg-block">
                    Update Password
                </h5>

                <div className="row mb-4">
                    <div className="col-lg-6 col-6 ">
                        <div className="custom-form-row">
                            <input
                                className="form-control custom-form-control"
                                placeholder="New Password"
                                value={this.state.fields.password || ""}
                                onChange={this.handleUserInputChange.bind(this, "password")}
                                type={this.state.type}
                                autoComplete="new-password"
                            />
                            <label className="label-helper py-2 px-1">Password</label>
                            <span onClick={this.showHide}>
                                {this.state.type === "password" ? (
                                    <i className="fa fa-2x fa-eye-slash " aria-hidden="true"></i>
                                ) : (
                                    <i className="fa fa-2x fa-eye " aria-hidden="true"></i>
                                )}
                            </span>
                        </div>
                        <span className="text-danger">{this.state.errors["password"]}</span>
                    </div>
                    <div className="col-lg-6 col-6">
                        <div className="custom-form-row">
                            <input
                                className="form-control custom-form-control"
                                placeholder="Confirm Password"
                                value={this.state.fields.confirmPassword || ""}
                                onChange={this.handleUserInputChange.bind(
                                    this,
                                    "confirmPassword"
                                )}
                                type={this.state.confirmType}
                                autoComplete="new-password"
                            />
                            <label className="label-helper py-2 px-1">Confirm Password</label>
                            <span onClick={this.confirmPassShowHide}>
                                {this.state.confirmType === "password" ? (
                                    <i className="fa fa-2x fa-eye-slash " aria-hidden="true"></i>
                                ) : (
                                    <i className="fa fa-2x fa-eye " aria-hidden="true"></i>
                                )}
                            </span>
                        </div>
                        <span className="text-danger">
                            {this.state.errors["confirmPassword"]}
                        </span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-12 col-12">
                        <ul>
                            {this.state.digit === false ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-circle-o text-danger"></i>
                                    <span className="text-danger text-truncate ml-2  ">
                                        Should contain at least 1 Numeric
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.digit === true ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-check-circle-o text-success"></i>
                                    <span className="text-success text-truncate ml-2  ">
                                        Should contain at least 1 Numeric
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.lowerCaseCheck === false ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-circle-o text-danger"></i>
                                    <span className="text-danger text-truncate ml-2  ">
                                        Should contain at least 1 Lower Case
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.lowerCaseCheck === true ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-check-circle-o text-success"></i>
                                    <span className="text-success text-truncate ml-2  ">
                                        Should contain at least 1 Lower Case
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.upperCaseCheck === false ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-circle-o text-danger"></i>
                                    <span className="text-danger text-truncate ml-2  ">
                                        Should contain at least 1 Upper Case
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.upperCaseCheck === true ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-check-circle-o text-success"></i>
                                    <span className="text-success text-truncate ml-2  ">
                                        Should contain at least 1 Upper Case
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}
                            {this.state.specialCharactersCheck === false ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-circle-o text-danger"></i>
                                    <span className="text-danger ml-2  ">
                                        Should contain at least 1 Special Characters
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.specialCharactersCheck === true ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-check-circle-o text-success"></i>
                                    <span className="text-success ml-2  ">
                                        Should contain at least 1 Special Characters
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}

                            {this.state.checkPasswordLength === false ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-circle-o text-danger"></i>
                                    <span className="text-danger text-truncate ml-2  ">
                                        Should contain at least 8 to 16 characters
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}
                            {this.state.checkPasswordLength === true ? (
                                <li className="d-flex">
                                    <i className="fa fa-2x fa-check-circle-o text-success"></i>
                                    <span className="text-success text-truncate ml-2  ">
                                        Should contain at least 8 to 16 characters
                                    </span>
                                </li>
                            ) : (
                                ""
                            )}
                        </ul>
                    </div>
                </div>
                {this.state.loading ? (
                    <div className="col-lg-2">
                        <div class="loading">Loading&#8230;</div>
                    </div>
                ) : (
                    ""
                )}
                <div className="row">
                    <div className="col-lg-3 offset-lg-3 col-6">
                        <button
                            id="first"
                            type="button"
                            className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                            onClick={this.prev}
                        >
                            <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                            Back
                        </button>
                    </div>
                    <div className="col-lg-4 col-6">
                        <button
                            id="third"
                            type="button"
                            className="btn btn-primary border-radius py-1  px-3"
                            onClick={this.onSubmit}
                            disabled={this.state.showValidate ? true : false}
                        >
                            Update
                            <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdatePassword
