import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import authServices from "./services/authServices";
import erroremoji from "../../frontend/src/assets/images/404-emoji.png"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      role: "",
      name: ""
    };

  }
  componentDidMount() {
    const role = authServices.getUserRole();
    this.setState({
      role: role
    });
    if (role?.name === "physician") {
       this.setState({
         name: "Physician Dashboard"
       })
    } else if (role?.name === "patient") {
      this.setState({
        name: "Patient Dashboard"
      })
    } else if (this.props.location.pathname.includes("/login")) {
      this.setState({
        name: "Login"
      })
    } else if (this.props.location.pathname.includes("/signup")) {
      this.setState({
        name: "SignUp"
      })
    } else if (this.props.location.pathname.includes("/physician-signup")) {
      this.setState({
        name: "Physician SignUp"
      })
    } else {
      this.setState({
        name: "Login"
      })
    }

  }

  componentDidUpdate() {
  }

  redirect = () => {
    if (this.state?.role?.name === "physician") {
      this.props.history.push("/physician/dashboard");
       this.setState({
         name: "Physician Dashboard"
       })
    } else if (this.state?.role?.name === "patient") {
      this.props.history.push("/dashboard");
      this.setState({
        name: "Patient Dashboard"
      })
    } else if (this.props.location.pathname.includes("/login")) {
      this.props.history.push("/login");
      this.setState({
        name: "Login"
      })
    } else if (this.props.location.pathname.includes("/signup")) {
      this.props.history.push("/signup");
      this.setState({
        name: "SignUp"
      })
    } else if (this.props.location.pathname.includes("/physician-signup")) {
      this.props.history.push("/physician-signup");
      this.setState({
        name: "Physician SignUp"
      })
    } else {
      this.props.history.push("/login");
      this.setState({
        name: "Login"
      })
    }
  };

  render() {
    return (
      <>
        <div className="auth-form mb-5">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="error-template">
                    <div className="emoji">
                      <span className="text">4</span>
                      <img src={erroremoji} alt="erroremoji"></img>
                      <span className="text">4</span>
                    </div>
                    <div className="error-details">
                    The page you were looking for was moved or doesn't exist.
                </div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                <div className="action-text">
                Let's get you back
                </div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                <div class="vl"><span>I</span></div>
                    <div className="error-actions">
                      <button
                        id="third"
                        type="button"
                        className="btn btn-primary border-radius py-1  px-3"
                        onClick={this.redirect}
                      >
                        Go to {this.state.name}
                  {/* <i className="fa fa-pencil ml-2" aria-hidden="true"></i> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ErrorBoundary);
