import axios from "axios";
import React, { Component } from "react";
import { environment } from "../../../../environment";
import moment from "moment";
export class DosingHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      userID: this.props.userID,
      dosingHistory: [],
      // loading: true,
      visible: 2,
    };
  }

  loadMore = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 2 };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dosingHistory !== this.props.dosingHistory) {
      this.setState({
        dosingHistory: this.props.dosingHistory
      })
      this.getDosingHistoryData(this.props.dosingHistory)
    }
  }

  componentDidMount() {
    this.getDosingHistory();
  }

  getDosingHistory = async () => {
    const data = "physician/shoop?patientId=" + this.state.userID + "&isReady=" + 1
    await axios
      .get(
        environment.endPoint + data,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          this.setState({
            loading: false,
          });
          this.getDosingHistoryData(response.data.data)
        }
      })
      .catch((e) => { });
  };

  getDosingHistoryData(response) {
    let option = [];
    if (response.length > 0) {
      response.forEach((dosing) => {
        let dosingHistory = {};
        dosingHistory.primaryDiagnosis = dosing.User.Diagnose.DignoseList.name !== null ? dosing.User.Diagnose.DignoseList.name
          .charAt(0)
          .toUpperCase() +
          dosing.User.Diagnose.DignoseList.name
            .substr(1)
            .toLowerCase() : "No Data Available";
        dosingHistory.targetCannabinoid = dosing.targetCannabinoid;
        dosingHistory.updatedAt = dosing.updatedAt ?
          moment(dosing.updatedAt).format(
            "MM-DD-YYYY"
          ) : "####";

        dosingHistory.dob = moment().diff(dosing.User.dob, "years") === 0 ? moment().diff(
          dosing.User.dob,
          "months"
        ) +
          " Months " +
          moment().diff(
            dosing.User.dob,
            "days"
          ) +
          " Days "
          : moment().diff(
            dosing.User.dob,
            "years"
          ) + " Years ";
          dosingHistory.gender = dosing.User.gender !== null ?  dosing.User.gender.charAt(0)
          .toUpperCase() +
          dosing.User.gender
            .substr(1)
            .toLowerCase() : "No Data Available"; 
        dosingHistory.terpenes = Array.isArray(dosing.terpenes) === true ? dosing.terpenes
          .map((item) => item.value)
          .join() : dosing.terpenes
        dosingHistory.targetCannabinoid = dosing.targetCannabinoid;
        dosingHistory.targetDose = dosing.dailyDose + " " + dosing.dailyDoseUnit;
        dosingHistory.frequency = dosing.frequency + "x" + dosing.frequencyUnit;
        dosingHistory.ShoopDoses = dosing.ShoopDoses !== null || dosing.ShoopDoses.length > 0 ? dosing.ShoopDoses : "";
        dosingHistory.ingestionMethod = dosing.ingestionMethod;
        dosingHistory.note = dosing.note;
        option.push(dosingHistory);
      });
    }
    this.setState({
      dosingHistory: option,
    });
  }


  render() {
    return (
      <div className="card-body custom-inner-content">
        <h5 className="stepper-form-headings ml-3">
          Dosing History
        </h5>

        <div>
          <div className="dosing-history">
            <div className="custom-verify-modal">
              <form>
                <div class="modal-body">
                  {this.state.dosingHistory.slice(0, this.state.visible).map((value, index) => {
                    return (
                      <div class="card fade-in">
                        <div class="card-body">
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="daignose">
                                <h5>
                                  Primary Diagnosis:{" "}
                                  <span>
                                    {" "}
                                    {value.primaryDiagnosis}
                                  </span>
                                </h5>
                              </div>
                              <div className="mdcn">
                                <p>{value.targetCannabinoid}</p>
                              </div>
                            </div>

                          </div>

                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <div className="mdcn-status">
                                <p>
                                Date Recommended:{" "}
                                  <span className="text-primary">
                                    {" "}
                                    {value.updatedAt}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="age-gender">
                                <span className="age">
                                  Age:{" "}
                                  <span>
                                    {" "}
                                    {value.dob}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <div className="gender">
                                <span className="gender-child">
                                  Gender:{" "}
                                  <span>
                                    {" "}
                                    {value.gender}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <table class="table table-striped mt-2">
                            <tbody>
                              <tr>
                                <td>
                                  Target Cannabinoid:{" "}
                                  <span> {value.targetCannabinoid}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Tarpenes:{" "}
                                  <span>
                                    {" "}
                                    {value?.terpenes}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Target Dose:{" "}
                                  <span>
                                    {" "}
                                    {value.targetDose}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Frequency:{" "}
                                  <span>
                                    {" "}
                                    {value.frequency}
                                  </span>
                                </td>
                              </tr>
                              {value.ShoopDoses.map((dailyDose, index) => (
                                <tr>
                                  <td>
                                    Daily Dose {index + 1}: {dailyDose.dose}{" "}
                                    <span>
                                      {" "}
                                      {dailyDose.doseUnit}{" "}
                                      {dailyDose.doseTime}{" "}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td>
                                  Ingestion Method:{" "}
                                  <span> {value.ingestionMethod}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Dosage Note:  <span>{value.note !== null ? value.note : <p className="text-secondary font-italic d-inline"> Dosage notes not entered yet </p>}</span>
                                </td>
                              </tr>

                            </tbody>
                          </table>

                        </div>
                      </div>
                    );
                  })}
                </div>
                {this.state.visible < this.state.dosingHistory.length && (
                  <div class="container load-more">
                    <div class="row">
                      <div class="col col-lg-7 offset-lg-3 text-center">
                        <button
                          onClick={this.loadMore}
                          type="button"
                          className="btn btn-primary submit mb-4"
                        >
                          Load more
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              </form>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default DosingHistory;
