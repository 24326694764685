import React, { Component } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import Validation from "../../../common/util";
import saveAs from "file-saver";
import axios from "axios";
import { environment } from "../../../../environment";

const { Dragger } = Upload;
const documentTypes = [
  { value: "Cancer Markers", label: "Cancer Markers" },
  { value: "Medical/Article Search", label: "Medical/Article Search" },
  { value: "Medical Reports", label: "Medical Reports" },
  { value: "Pathology Reports", label: "Pathology Reports" },
  { value: "Scans", label: "Scans" },
  { value: "X-Rays", label: "X-Rays" },
  { value: "Other", label: "Other" },
];

export class MyDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      data: [],
      columns: [],
      pagination: {},
      loading: false,
      fileList: [],
      uploading: false,
      fileLists: [],
      updateID: 0,
      buttonName: "Add Document",
      documentsDetail: {},
      showHide: false,
      buttonIcon: "fa fa-plus",
      showValidate: false,
      valueButton: "ADD",
      patientStatus: this.props.status,
      userID: this.props.userID,
      token: localStorage.getItem("token"),
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  genericMethod = async (record, id, string) => {
    const data =
      this.state.patientStatus === "physician"
        ? "intake/documents?id=" + record.id + "&userId=" + this.state.userID
        : "intake/documents?id=" + record.id;

    await axios
      .delete(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const index = this.state.data.indexOf(record, 0);
          if (index > -1) {
            this.state.data.splice(index, 1);
            this.getMyDocumentService();
          }
          toast.success("Document Removed SuccessFully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error("Please Check your Service Provider", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      });
  };
  genericMethodUpdate = async (e, record) => {
    e.preventDefault();
    const data =
      this.state.patientStatus === "physician"
        ? "intake/documents/" + record.id + "?userId=" + this.state.userID
        : "intake/documents/" + record.id;
    await axios
      .get(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let myDocumentsDetail = response.data.data;
          let imageUpload = this.state.fileList;
          if (imageUpload.length > 0) {
            imageUpload = [];
          }
          imageUpload.push({
            id: myDocumentsDetail.id,
            url: myDocumentsDetail.url,
            name: myDocumentsDetail.documentTitle,
          });
          const buttonUpdateName = "Update Document";
          const buttonIcon = "fa fa-pencil";
          this.setState({
            fields: myDocumentsDetail,
            fileList: imageUpload,
            buttonName: buttonUpdateName,
            updateID: myDocumentsDetail.id,
            documentsDetail: myDocumentsDetail,
            buttonIcon: buttonIcon,
            showValidate: false,
            valueButton: "update",
          });
        }
      })
      .catch((e) => {});
  };

  componentDidUpdate(prevProps, prevState) {}
  next(e) {
    e.preventDefault();
    this.props.next();
  }

  async onDownload(record) {
    const downloadResult = await fetch(record.documenUrl);
    const blob = await downloadResult.blob();
    saveAs(blob, record.documentName);
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }
  componentDidMount() {
    this.getMyDocumentService();
    
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getMyDocumentService({
      results: pagination.pageSize,
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };
  columns = [
    {
      title: "Document Name",
      dataIndex: "documentTitle",
      render: (text, row) => (
        <div>
          <h5> {row.documentTitle}</h5>
          <p> {row.documentType} </p>
        </div>
      ),
      sorter: (a, b) => a.documentTitle.localeCompare(b.documentTitle),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "x",
      render: (id, record) => (
        <div className="up-del">
          <i className="fa fa-trash-o" aria-hidden="true">
            {" "}
          </i>
          <Link onClick={() => this.genericMethod(record, id, "delete")}>
            {" "}
            Delete
          </Link>
          <Link
            onClick={(e) => this.genericMethodUpdate(e, record, id, "update")}
            className="ml-5"
          >
            <i className="fa fa-refresh" aria-hidden="true"></i>
            Update
          </Link>
        </div>
      ),
    },
  ];

  getMyDocumentService = async (params = {}) => {
    this.setState({ loading: true });
    const data =
      this.state.patientStatus === "physician"
        ? "intake/documents?userId=" + this.state.userID
        : "intake/documents";

    await axios
      .get(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.data.data !== "") {
          if (response.data.data !== null) {
            const pagination = { ...this.state.pagination };
            pagination.total = response.data.data.info.results;

            this.setState({
              loading: false,
              data: response.data.data.result,
              pagination,
              columns: this.columns,
            });
          }
        }
      })
      .catch((e) => {});
  };

  handleUpload = async () => {
    const { fileList } = this.state;
    this.setState({
      errors: Validation.myDocumentsInformation(
        this.state.fields,
        "myDocumentsInformation"
      ).error,
    });

    if (
      Validation.myDocumentsInformation(
        this.state.fields,
        "myDocumentsInformation"
      ).formIsValid
    ) {
      this.setState({
        showValidate: true,
        uploading: true,
        loading: true,
      });
      if (this.state.updateID > 0) {
        this.setState({
          showValidate: true,
        });
        const formData = new FormData();
        formData.append("documentTitle", this.state.fields.documentTitle);
        formData.append("documentType", this.state.fields.documentType);
        formData.append("id", this.state.updateID);

        formData.append(
          "documentDescription",
          this.state.fields.documentDescription
        );
        if (!fileList[0].id) {
          formData.append("file", fileList[0]);
        }

        if (this.state.patientStatus === "physician") {
          formData.append("userId", this.state.userID);
        }
        await axios
          .put(environment.endPoint + "intake/documents", formData, {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              toast.success("My Document Update Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              let fields = this.state.fields;

              // this.callCompleted();
              // window.scrollTo(0, 0)
              const buttonUpdateName = "Add Document";
              const buttonIcon = "fa fa-plus";
              fields["documentTitle"] = "";
              fields["documentType"] = "";
              fields["documentDescription"] = "";
              this.setState({
                uploading: false,
                loading: false,
                fields: fields,
                fileList: [],
                updateID: 0,
                buttonName: buttonUpdateName,
                buttonIcon: buttonIcon,
              });
              this.getMyDocumentService();
              this.setState({
                showValidate: true,
              });
            } else if (response.status !== 200) {
              toast.error("My Document not Added", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              this.setState({
                loading: false,
                uploading:false,
                showValidate:false
              });
            }
          })
          .catch((e) => {
            // if (e.response.status === 400) {
            //   toast.error("Please Check your Service Provider", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 3000,
            //   });
            // } else if (e.response.status === 413) {
            //   toast.error("Your File is Too Large Please Add File Less Then 5mb", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 3000,
            //   });
            // } else if (e.response.status === 500) {
            //   toast.error("Internal Server Error", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 3000,
            //   });
            //   this.setState({
            //     loading: false,
            //   });
            // }
          });
      } else {
        const formData = new FormData();
        formData.append("documentTitle", this.state.fields.documentTitle);
        formData.append("documentType", this.state.fields.documentType);
        const documentDescription =
          this.state.fields.documentDescription !== undefined
            ? this.state.fields.documentDescription
            : "";
        formData.append("documentDescription", documentDescription);
        fileList.forEach((file) => {
          formData.append("file", file);
        });
        if (this.state.patientStatus === "physician") {
          formData.append("userId", this.state.userID);
        }
        await axios
          .post(environment.endPoint + "intake/documents", formData, {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          })
          .then((response) => { 
            if (response.status === 200 || response.status !== undefined) {
              toast.success("My Document Add Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              this.setState({
                showValidate: true,
              });
              let fields = this.state.fields;
              // this.callCompleted();
              // window.scrollTo(0, 0)
              fields["documentTitle"] = "";
              fields["documentType"] = "";
              fields["documentDescription"] = "";
              this.setState({
                fileList: [],
                uploading: false,
                loading: false,
                fields: fields,
              });
            } else if (response.status !== 200) {
              toast.error("My Document not Added", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              this.setState({
                loading: false,
                uploading:false,
                showValidate:false
              });
            }
            this.getMyDocumentService();
          })
          .catch((e) => {
            // if (e.response.status === 400) {
            //   this.setState({
            //     uploading: false,
            //     loading: false,
            //   });
            //   toast.error("My Document not Added", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 3000,
            //   });
            // } else if (e.response.status === 413) {
            //   this.setState({
            //     uploading: false,
            //     loading: false,
            //   });
            //   toast.error("Your File is Too Large Please Add File Less Then 5mb", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 3000,
            //   });
            // }
          });
      }
    }
  };

  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] =
      e.target.value !== undefined || e.target.value !== ""
        ? e.target.value
        : "";
    this.setState({ fields, showValidate: false });
  }

  render() {
    const { data, pagination, loading, columns } = this.state;
    // uploading,
    const { fileList } = this.state;
    const { valueButton } = this.state;

    const props = {
      name: "file",
      multiple: false,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
        }
        if (info.fileList.length > 1 && valueButton === "ADD") {
          toast.error("Please remove the file to add a new file", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else if (valueButton === "update") {
        }
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        this.setState((state) => {
          if (state.fileList.length > 1) {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          } else {
            this.setState({
              showHide: true,
            });
          }
        });
        return false;
      },
      fileList,
    };
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">My Documents</h5>
          {/* <h4 className="stepper-form-sub-headings mb-4">
                Please tell us in your own words what you feel is most important
              </h4> */}

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Document Title"
                  value={this.state.fields.documentTitle}
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "documentTitle"
                  )}
                />

                <label className="label-helper py-2 px-1">
                  Document Title <strong className="text-danger">*</strong>
                </label>
              </div>
              <span className="text-danger">
                {this.state.errors["documentTitle"]}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.documentType}
                  className={
                    this.state.fields.documentType === "" ||
                    this.state.fields.documentType === null ||
                    this.state.fields.documentType === undefined
                      ? "disabled-option form-control custom-form-control mb-2"
                      : "form-control custom-form-control mb-2"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "documentType"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Select Document Type
                  </option>
                  {documentTypes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Document Type <strong className="text-danger">*</strong>
                </label>
              </div>
              <span className="text-danger">
                {this.state.errors["documentType"]}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <textarea
                  value={this.state.fields.documentDescription}
                  className="form-control custom-form-control"
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "documentDescription"
                  )}
                  placeholder="Document Description"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>

                <label className="label-helper py-2 px-1">
                  Document Description
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <h4 className="stepper-form-sub-headings">
                Attach Document <strong className="text-danger">*</strong>
              </h4>
              <div className="custom-form-row">
                {this.state.url}
                <Dragger
                  {...props}
                  listType="picture,pdf,doc,docx"
                  defaultFileList={[...this.state.fileList]}
                >
                  <div className="d-block">
                    {/* <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p> */}
                    <p className="ant-upload-text">
                      <span className="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                      </span>
                      Click or drag file to this area to upload or
                      <span className="browse">
                        <Link href="#"> browse</Link>
                      </span>
                    </p>
                  </div>
                </Dragger>
                {/* <label className="label-helper py-2 px-1">
                  Attach Document <strong className="text-danger">*</strong>
                </label> */}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-3 offset-lg-2 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-3 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3 text-truncate"
                onClick={this.handleUpload}
                disabled={
                  this.state.showValidate || fileList.length === 0
                    ? true
                    : false
                }
              >
                {this.state.buttonName}
                <i
                  className={`${this.state.buttonIcon} ml-2 aria-hidden="true"`}
                ></i>
              </button>
            </div>

            {this.state.uploading ? (
              <div className="col-lg-2">
                <div class="loading">Loading&#8230;</div>
              </div>
            ) : (
              ""
            )}
          </div>

          {this.state.data.length > 0 ? (
            <div className="row">
              <div className="col-lg-12 col-12">
                <Table
                  style={{ whiteSpace: "pre" }}
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={data}
                  pagination={pagination}
                  loading={loading}
                  onRow={(record) =>
                    this.genericMethod.bind(this, record, record.id)
                  }
                  onChange={this.handleTableChange}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div className="row">
                        <div className="col-lg-6 col-6">
                          <p style={{ margin: 0 }} className="text-left">
                            {record.documentDescription === "" ? (
                              <p className="font-italic">
                                {" "}
                                No Description Added{" "}
                              </p>
                            ) : (
                              record.documentDescription
                            )}
                          </p>
                        </div>
                        <div className="col-lg-2 offset-lg-3 col-6">
                          <Link
                            onClick={this.onDownload.bind(this, record)}
                            className=""
                          >
                            <p className="text-primary">
                              {" "}
                              <i
                                className="fa fa-download text-primary mr-2"
                                aria-hidden="true"
                              ></i>
                              Download
                            </p>
                          </Link>
                        </div>
                      </div>
                    ),
                    rowExpandable: (record) =>
                      record.documentTitle !== "Not Expandable",
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    );
  }
}

export default MyDocuments;
