import React, { Component } from "react";
import Validation from "../../../common/util";
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from "../../../../environment";

const allergies = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const recentChanges = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
const snackDay = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
const cookYourMeals = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const dietaryRestrictions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const varietyOfFoods = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Unsure",
    label: "Unsure",
  },
];

const consumeSugar = [
  {
    value: "Daily",
    label: "Daily",
  },
  {
    value: "3/4 Times Week",
    label: "3/4 Times Week",
  },
  {
    value: "Occasionally",
    label: "Occasionally",
  },
  {
    value: "Seldom/less than 2 times weekly",
    label: "Seldom/less than 2 times weekly",
  },
  {
    value: "Never",
    label: "Never",
  },
];

const considerYourself = [
  {
    value: "Underweight",
    label: "Underweight",
  },
  {
    value: "Overweight",
    label: "Overweight",
  },
  {
    value: "Just Right",
    label: "Just Right",
  },
];

const drinkPerWeek = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "More Than 5",
    label: "More Than 5",
  },
];

export class Nutrition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      cardiovascularExerciseIntensity: "",
      regularStrengthTrainingIntensity: "",
      stretchingFlexibilityExercisesIntensity: "",
      eatingManners: {},
      showFoodAllergy: false,
      foodAllergies: "",
      foodAllergyFlag: "",

      showDietaryRestrictions: false,
      dietaryRestrictions: "",
      dietaryRestrictionsFlag: "",

      showRecentChangesDiet: false,
      recentDietChanges: "",
      recentChangesDietFlag: "",

      showSnackDay: false,
      snackDuringDay: "",
      snackDayFlag: "",

      showCookYourMeals: false,
      cookOwnMeals: "",
      cookYourMealsFlag: "",

      showCardiovascular: false,
      cardiovascularExercise: "",
      cardiovascularExerciseFlag: "",

      showregularStrengthTraining: false,
      regularStrengthTraining: "",
      regularStrengthTrainingFlag: "",

      showStretchingFlexibilityExercises: false,
      stretchingFlexibilityExercises: "",
      stretchingFlexibilityExercisesFlag: "",

      wideVariteyFoods: "",
      consumeSugar: "",
      weightPattern: "",

      intensityCardiovascular: "",
      intensityRegularStrength: "",
      intensityflexibility: "",

      disabled: true,
      loading: false,
      rateDiet: 1,
      currentDietaryRestrictions: "",
      nutritionDiets: [
        {
          breakfast: 0,
          lunch: 0,
          dinner: 0,
        },
      ],
      exercisePerWeek: 0,
      patientStatus: this.props.status,
      userID:  this.props.userID,
      token: localStorage.getItem("token"),

    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.handleInputBreakFastChange = this.handleInputBreakFastChange.bind(
      this
    );
    this.handleInputLunchChange = this.handleInputLunchChange.bind(this);
    this.handleInputDinnerChange = this.handleInputDinnerChange.bind(this);
    this.addNutritionDietList = this.addNutritionDietList.bind(this);
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }

  addNutritionDietList(e) {
    e.preventDefault();
    let nutritionDiets = this.state.nutritionDiets;
    nutritionDiets.push({
      id: nutritionDiets.length + 1,
      breakfast: 0,
      lunch: 0,
      dinner: 0,
    });
    this.setState({
      nutritionDiets: nutritionDiets,
    });
  }

  handleInputBreakFastChange(e, idx) {
    let nextBreakFast = this.state.nutritionDiets.slice();
    nextBreakFast[idx].breakfast = e.target.value;
    this.setState({ nutritionDiets: nextBreakFast, disabled: false });
  }

  handleInputLunchChange(e, idx) {
    let nextLunch = this.state.nutritionDiets.slice();
    nextLunch[idx].lunch = e.target.value;
    this.setState({ nutritionDiets: nextLunch, disabled: false });
  }

  handleInputDinnerChange(e, idx) {
    let nextDinner = this.state.nutritionDiets.slice();
    nextDinner[idx].dinner = e.target.value;
    this.setState({ nutritionDiets: nextDinner, disabled: false });
  }

  handleRemoveNutritionDietList(idx, e) {
    let nutritionDiets = this.state.nutritionDiets;
    nutritionDiets.splice(idx, 1);
    this.setState({ nutritionDiets: nutritionDiets });
  }

  handleUserInputChange(field, e) {
    if (field === "foodAllergies") {
      if (e.target.value === "yes") {
        this.setState({
          foodAllergies: e.target.value,
          showFoodAllergy: true,
          foodAllergyFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          foodAllergies: e.target.value,
          showFoodAllergy: false,
          foodAllergyFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          foodAllergies: null,
          showFoodAllergy: false,
          foodAllergyFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "dietaryRestrictions") {
      if (e.target.value === "yes") {
        this.setState({
          dietaryRestrictions: e.target.value,
          showDietaryRestrictions: true,
          dietaryRestrictionsFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          dietaryRestrictions: e.target.value,
          showDietaryRestrictions: false,
          dietaryRestrictionsFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          dietaryRestrictions: null,
          showDietaryRestrictions: false,
          dietaryRestrictionsFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "recentDietChanges") {
      if (e.target.value === "yes") {
        this.setState({
          recentDietChanges: e.target.value,
          showRecentChangesDiet: true,
          recentChangesDietFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          recentDietChanges: e.target.value,
          showRecentChangesDiet: false,
          recentChangesDietFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          recentDietChanges: null,
          showRecentChangesDiet: false,
          recentChangesDietFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "snackDuringDay") {
      if (e.target.value === "yes") {
        this.setState({
          snackDuringDay: e.target.value,
          showSnackDay: true,
          snackDayFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          snackDuringDay: e.target.value,
          showSnackDay: false,
          snackDayFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          snackDuringDay: null,
          showSnackDay: false,
          snackDayFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "cookOwnMeals") {
      if (e.target.value === "yes") {
        this.setState({
          cookOwnMeals: e.target.value,
          showCookYourMeals: true,
          cookYourMealsFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          cookOwnMeals: e.target.value,
          showCookYourMeals: false,
          cookYourMealsFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          cookOwnMeals: null,
          showCookYourMeals: false,
          cookYourMealsFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "wideVariteyFoods") {
      this.setState({
        wideVariteyFoods: e.target.value,
        disabled: false,
      });
    }
    if (field === "consumeSugar") {
      this.setState({
        consumeSugar: e.target.value,
        disabled: false,
      });
    }
    if (field === "weightPattern") {
      this.setState({
        weightPattern: e.target.value,
        disabled: false,
      });
    }
    if (field === "cardiovascularExercise") {
      if (e.target.value === "yes") {
        this.setState({
          cardiovascularExercise: e.target.value,
          showCardiovascular: true,
          cardiovascularExerciseFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          cardiovascularExercise: e.target.value,
          showCardiovascular: false,
          cardiovascularExerciseFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          cardiovascularExercise: null,
          showCardiovascular: false,
          cardiovascularExerciseFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "intensityCardiovascular") {
      this.setState({
        intensityCardiovascular: e.target.value,
        disabled: false,
      });
    }
    if (field === "intensityRegularStrength") {
      this.setState({
        intensityRegularStrength: e.target.value,
        disabled: false,
      });
    }

    if (field === "regularStrengthTraining") {
      if (e.target.value === "yes") {
        this.setState({
          regularStrengthTraining: e.target.value,
          showregularStrengthTraining: true,
          regularStrengthTrainingFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          regularStrengthTraining: e.target.value,
          showregularStrengthTraining: false,
          regularStrengthTrainingFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          regularStrengthTraining: null,
          showregularStrengthTraining: false,
          regularStrengthTrainingFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "stretchingFlexibilityExercises") {
      if (e.target.value === "yes") {
        this.setState({
          stretchingFlexibilityExercises: e.target.value,
          showStretchingFlexibilityExercises: true,
          stretchingFlexibilityExercisesFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          stretchingFlexibilityExercises: e.target.value,
          showStretchingFlexibilityExercises: false,
          stretchingFlexibilityExercisesFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          stretchingFlexibilityExercises: null,
          showStretchingFlexibilityExercises: false,
          stretchingFlexibilityExercisesFlag: null,
          disabled: false,
        });
      }
    }
    if (field === "intensityflexibility") {
      this.setState({
        intensityflexibility: e.target.value,
        disabled: false,
      });
    }
    if (field === "rateDiet") {
      this.setState({
        rateDiet: e.target.value,
        disabled: false,
      });
    }
    if (field === "currentDietaryRestrictions") {
      this.setState({
        currentDietaryRestrictions: e.target.value,
        disabled: false,
      });
    }
    if (field === "exercisePerWeek") {
      this.setState({
        exercisePerWeek: e.target.value,
        disabled: false,
      });
    }
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
      disabled: false
    });
  }

  handleCheckboxes(field, e) {
    let eatingManners = this.state.eatingManners;
    eatingManners[field] = e.target.checked;
    this.setState({ eatingManners, disabled: false });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      errors: Validation.nutritionExerciseInformation(
        this.state,
        "nutritionExerciseInformation"
      ).error,
    });
    if (
      Validation.nutritionExerciseInformation(
        this.state,
        "nutritionExerciseInformation"
      ).formIsValid
    ) {
      this.setState({
        loading: true,
      });
      let nutritionExerciseInformation = {};
      nutritionExerciseInformation = JSON.parse(
        JSON.stringify(this.state.fields)
      );
      nutritionExerciseInformation.rateDiet = this.state.rateDiet;
      nutritionExerciseInformation.currentDietaryRestrictions = this.state.currentDietaryRestrictions;
      nutritionExerciseInformation.foodAllergies = this.state.foodAllergyFlag;
      nutritionExerciseInformation.foodAllergiesDescription = this.state.fields.foodAllergiesDescription;
      nutritionExerciseInformation.recentDietChangesList = this.state.fields.recentDietChangesList;
      nutritionExerciseInformation.recentDietChanges = this.state.recentChangesDietFlag;
      nutritionExerciseInformation.snackDuringDay = this.state.snackDayFlag;
      nutritionExerciseInformation.daySnackList = this.state.fields.daySnackList;
      nutritionExerciseInformation.cookOwnMeals = this.state.cookYourMealsFlag;
      nutritionExerciseInformation.eatingManners = this.state.eatingManners;
      nutritionExerciseInformation.varietyOfFoods = this.state.fields.varietyOfFoods;
      nutritionExerciseInformation.sugarConsumptionFrequency = this.state.fields.sugarConsumptionFrequency;
      nutritionExerciseInformation.weightPattern = this.state.weightPattern;
      nutritionExerciseInformation.alcohol = this.state.fields.alcohol;
      nutritionExerciseInformation.coffee = this.state.fields.coffee;
      nutritionExerciseInformation.decafCoffee = this.state.fields.decafCoffee;
      nutritionExerciseInformation.dietDrinks = this.state.fields.dietDrinks;
      nutritionExerciseInformation.softDrinks = this.state.fields.softDrinks;
      nutritionExerciseInformation.blackTea = this.state.fields.blackTea;
      nutritionExerciseInformation.greenTea = this.state.fields.greenTea;
      nutritionExerciseInformation.sportDrinks = this.state.fields.sportDrinks;
      nutritionExerciseInformation.fruitJuice = this.state.fields.fruitJuice;
      nutritionExerciseInformation.water = this.state.fields.water;
      nutritionExerciseInformation.nutritionDiets = this.state.nutritionDiets;
      nutritionExerciseInformation.dietaryRestrictions = this.state.dietaryRestrictionsFlag;
      if(this.state.patientStatus === "physician"){
        nutritionExerciseInformation.userId = this.state.userID;
      }
      await axios.post(environment.endPoint+"intake/nutritions", nutritionExerciseInformation, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success("Nutrition Updated Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({
              disabled: true,
              loading: false,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
          // if (e.response.status === 400) {
          //   toast.error("Nutrition and Exercise Not Updated", {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 3000,
          //   });
          // }
        });
    }
  }

  callCompleted() {
    this.props.next();
    var myButtonClasses = document.getElementById("nutrition").classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }
  componentDidMount() {
    this.getNutritionExerciseService();
  }

  getNutritionExerciseService = async () => {
    const data = this.state.patientStatus === "physician" ? "intake/nutritions?userId="+this.state.userID : "intake/nutritions";

    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.data.data !== "") {
          if (response.data.data !== null) {
            this.setState({
              nutritionData: response.data.data,
              // disabled: false
            });
          }

          if (Object.keys(response.data.data).length !== 0) {
            let fields = this.state.fields;

            fields["recentDietChangesList"] =
              response.data.data.recentDietChangesList;
            fields["daySnackList"] = response.data.data.daySnackList;
            fields["varietyOfFoods"] = response.data.data.varietyOfFoods;
            fields["sugarConsumptionFrequency"] =
              response.data.data.sugarConsumptionFrequency;
            fields["alcohol"] = response.data.data.alcohol;
            fields["coffee"] = response.data.data.coffee;
            fields["decafCoffee"] = response.data.data.decafCoffee;
            fields["dietDrinks"] = response.data.data.dietDrinks;
            fields["softDrinks"] = response.data.data.softDrinks;
            fields["blackTea"] = response.data.data.blackTea;
            fields["greenTea"] = response.data.data.greenTea;
            fields["herbalTea"] = response.data.data.herbalTea;
            fields["sportDrinks"] = response.data.datasportDrinks;
            fields["fruitJuice"] = response.data.data.fruitJuice;
            fields["water"] = response.data.data.water;

            if (
              response.data.data.rateDiet === "" ||
              response.data.data.rateDiet === null
            ) {
              this.setState({
                rateDiet: 1,
              });
            } else if (
              response.data.data.rateDiet !== "" ||
              response.data.data.rateDiet !== null
            ) {
              this.setState({
                rateDiet: response.data.data.rateDiet,
              });
            }

            this.setState({
              exercisePerWeek: response.data.data.exercisePerWeek
                ? response.data.data.exercisePerWeek
                : 0,
              weightPattern: response.data.data.weightPattern,
            });
            if (response.data.data.foodAllergies === true) {
              fields["foodAllergiesDescription"] =
                response.data.data.foodAllergiesDescription;

              this.setState({
                foodAllergies: "yes",
                showFoodAllergy: true,
                foodAllergyFlag: true,
              });
            } else if (response.data.data.foodAllergies === false) {
              this.setState({
                foodAllergies: "no",
                showFoodAllergy: false,
                foodAllergyFlag: false,
              });
            } else if (response.data.data.foodAllergies === null) {
              this.setState({
                foodAllergies: "",
                showFoodAllergy: false,
                foodAllergyFlag: null,
              });
            }

            if (response.data.data.dietaryRestrictions === true) {
              this.setState({
                dietaryRestrictions: "yes",
                showDietaryRestrictions: true,
                dietaryRestrictionsFlag: true,
                currentDietaryRestrictions:
                  response.data.data.currentDietaryRestrictions,
              });
            } else if (response.data.data.dietaryRestrictions === false) {
              this.setState({
                dietaryRestrictions: "no",
                showDietaryRestrictions: false,
                dietaryRestrictionsFlag: false,
                currentDietaryRestrictions: "",
              });
            } else if (response.data.data.dietaryRestrictions === null) {
              this.setState({
                dietaryRestrictions: "",
                showDietaryRestrictions: false,
                dietaryRestrictionsFlag: null,
                currentDietaryRestrictions: "",
              });
            }

            if (response.data.data.recentDietChanges === true) {
              fields["recentDietChangesList"] =
                response.data.data.recentDietChangesList;
              this.setState({
                recentDietChanges: "yes",
                showRecentChangesDiet: true,
                recentChangesDietFlag: true,
              });
            } else if (response.data.data.recentDietChanges === false) {
              this.setState({
                recentDietChanges: "no",
                showRecentChangesDiet: false,
                recentChangesDietFlag: false,
              });
            } else if (response.data.data.recentDietChanges === null) {
              this.setState({
                recentDietChanges: "",
                showRecentChangesDiet: false,
                recentChangesDietFlag: null,
              });
            }

            if (response.data.data.snackDuringDay === true) {
              fields["daySnackList"] = response.data.data.daySnackList;
              this.setState({
                snackDuringDay: "yes",
                showSnackDay: true,
                snackDayFlag: true,
              });
            } else if (response.data.data.snackDuringDay === false) {
              this.setState({
                snackDuringDay: "no",
                showSnackDay: false,
                snackDayFlag: false,
              });
            } else if (response.data.data.snackDuringDay === null) {
              this.setState({
                snackDuringDay: "",
                showSnackDay: false,
                snackDayFlag: null,
              });
            }

            if (response.data.data.cookOwnMeals === true) {
              this.setState({
                cookOwnMeals: "yes",
                cookYourMealsFlag: true,
              });
            } else if (response.data.data.cookOwnMeals === false) {
              this.setState({
                cookOwnMeals: "no",
                cookYourMealsFlag: false,
              });
            } else if (response.data.data.cookOwnMeals === null) {
              this.setState({
                cookOwnMeals: "",
                cookYourMealsFlag: null,
              });
            }

            if (response.data.data.cardiovascularExercise === true) {
              fields["cardiovascularExerciseType"] =
                response.data.data.cardiovascularExerciseType;
              fields["cardiovascularExerciseOften"] =
                response.data.data.cardiovascularExerciseOften;
              fields["cardiovascularExerciseDuration"] =
                response.data.data.cardiovascularExerciseDuration;
              fields["cardiovascularExerciseIntensity"] =
                response.data.data.cardiovascularExerciseIntensity;
              this.setState({
                cardiovascularExercise: "yes",
                showCardiovascular: true,
                cardiovascularExerciseFlag: true,
              });
            } else if (response.data.data.cardiovascularExercise === false) {
              this.setState({
                cardiovascularExercise: "no",
                showCardiovascular: false,
                cardiovascularExerciseFlag: false,
              });
            } else if (response.data.data.cardiovascularExercise === null) {
              this.setState({
                cardiovascularExercise: "",
                cookYourMealsFlag: false,
                cardiovascularExerciseFlag: null,
              });
            }

            if (response.data.data.regularStrengthTraining === true) {
              fields["regularStrengthTrainingType"] =
                response.data.data.regularStrengthTrainingType;
              fields["regularStrengthTrainingIntensity"] =
                response.data.data.regularStrengthTrainingIntensity;
              this.setState({
                regularStrengthTraining: "yes",
                showregularStrengthTraining: true,
                regularStrengthTrainingFlag: true,
              });
            } else if (response.data.data.regularStrengthTraining === false) {
              this.setState({
                regularStrengthTraining: "no",
                showregularStrengthTraining: false,
                regularStrengthTrainingFlag: false,
              });
            } else if (response.data.data.regularStrengthTraining === null) {
              this.setState({
                regularStrengthTraining: "",
                showregularStrengthTraining: false,
                regularStrengthTrainingFlag: null,
              });
            }

            if (response.data.data.stretchingFlexibilityExercises === true) {
              fields["stretchingFlexibilityExercisesType"] =
                response.data.data.stretchingFlexibilityExercisesType;
              fields["stretchingFlexibilityExercisesType"] =
                response.data.data.stretchingFlexibilityExercisesOften;
              fields["stretchingFlexibilityExercisesIntensity"] =
                response.data.data.stretchingFlexibilityExercisesIntensity;
              this.setState({
                stretchingFlexibilityExercises: "yes",
                showStretchingFlexibilityExercises: true,
                stretchingFlexibilityExercisesFlag: true,
              });
            } else if (
              response.data.data.stretchingFlexibilityExercises === false
            ) {
              this.setState({
                stretchingFlexibilityExercises: "no",
                showStretchingFlexibilityExercises: false,
                stretchingFlexibilityExercisesFlag: false,
              });
            } else if (
              response.data.data.stretchingFlexibilityExercises === null
            ) {
              this.setState({
                stretchingFlexibilityExercises: "",
                showStretchingFlexibilityExercises: false,
                stretchingFlexibilityExercisesFlag: null,
              });
            }

            if (response.data.data.eatingManners !== "") {
              if (response.data.data.eatingManners !== null) {
                this.setState({
                  eatingManners: response.data.data.eatingManners,
                });
              }
            }
            if (
              response.data.data.nutritionDiets !== undefined ||
              response.data.data.nutritionDiets.length > 0
            ) {
              this.setState({
                nutritionDiets: response.data.data.nutritionDiets,
              });
            } else if (response.data.data.nutritionDiets.length === 0) {
              this.setState({
                nutritionDiets: this.state.nutritionDiets,
              });
            }
            this.setState({ fields: response.data.data });
          }
        }
      })
      .catch((e) => {
      });
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">Nutrition</h5>

          <div className="row">
            <div className="col-lg-12">
              <div className="custom-form-row">
                <h4 className="stepper-form-sub-headings">
                  <span className="bolder">
                    {" "}
                    How do you rate your diet?
                    <span className="text-danger">*</span>&nbsp;
                  </span>
                  <br />
                </h4>
                <div>
                  <input
                    id="typeinp"
                    type="range"
                    min="1"
                    max="10"
                    className="range-slider"
                    value={this.state.rateDiet}
                    onChange={this.handleUserInputChange.bind(this, "rateDiet")}
                    step="1"
                  />
                  <label className="stepper-form-sub-headings">
                    {this.state.rateDiet}
                  </label>
                  <span className="un-emph d-in-block">
                    On a scale of 1-10 <br /> (1-very unhealthy and 10-extremely
                    healthy)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.dietaryRestrictions}
                  className={
                    this.state.dietaryRestrictions === "" ||
                    this.state.dietaryRestrictions === null ||
                    this.state.dietaryRestrictions === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "dietaryRestrictions"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Do you have any Diet Restrictions?
                  </option>
                  {dietaryRestrictions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you have any Diet Restrictions?{" "}
                  <span className="text-danger">*</span>
                </label>
                <span className="text-danger">
                  {this.state.errors["dietaryRestrictions"]}
                </span>
              </div>
            </div>
          </div>

          {this.state.showDietaryRestrictions === true ? (
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="custom-form-row">
                  <textarea
                    className="form-control custom-form-control"
                    placeholder="Please describe any current dietary restrictions that you may have (vegan/vegetarian/celiac etc.)"
                    name=""
                    id=""
                    rows="3"
                    value={this.state.currentDietaryRestrictions}
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "currentDietaryRestrictions"
                    )}
                  ></textarea>

                  <label className="label-helper py-2 px-1 mr-2">
                    {" "}
                    Current dietary restrictions that you may have{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* showFoodAllergy */}

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.foodAllergies}
                  className={
                    this.state.foodAllergies === "" ||
                    this.state.foodAllergies === null ||
                    this.state.foodAllergies === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "foodAllergies"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Do you have any food allergies?
                  </option>
                  {allergies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you have any food allergies?{" "}
                  <span className="text-danger">*</span>
                </label>
                <span className="text-danger">
                  {this.state.errors["foodAllergies"]}
                </span>
              </div>
            </div>
          </div>

          {this.state.showFoodAllergy === true ? (
            <div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <textarea
                      className="form-control custom-form-control"
                      placeholder="Please Describe Food Allergy "
                      name=""
                      id=""
                      rows="3"
                      value={this.state.fields.foodAllergiesDescription}
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "foodAllergiesDescription"
                      )}
                    ></textarea>

                    <label className="label-helper py-2 px-1">
                      {" "}
                      Please Describe Food Allergy
                    </label>
                  </div>
              
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* recentChangesDiet */}

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.recentDietChanges}
                  className={
                    this.state.recentDietChanges === "" ||
                    this.state.recentDietChanges === null ||
                    this.state.recentDietChanges === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "recentDietChanges"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Have you made any recent changes to your diet?
                  </option>
                  {recentChanges.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Have you made any recent changes to your diet?{" "}
                </label>
              </div>
            </div>
          </div>
          {this.state.showRecentChangesDiet === true ? (
            <div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <textarea
                      className="form-control custom-form-control"
                      placeholder="Please Describe Recent Changes in Your Diet"
                      name=""
                      id=""
                      rows="3"
                      value={this.state.fields.recentDietChangesList}
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "recentDietChangesList"
                      )}
                    ></textarea>

                    <label className="label-helper py-2 px-1">
                      {" "}
                      Please Describe Recent Changes in Your Diet
                    </label>
                  </div>
                
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* snackDay */}

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.snackDuringDay}
                  className={
                    this.state.snackDuringDay === "" ||
                    this.state.snackDuringDay === null ||
                    this.state.snackDuringDay === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "snackDuringDay"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Do you snack during the day?
                  </option>
                  {snackDay.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you snack during the day?{" "}
                </label>
              </div>
            </div>
          </div>
          {this.state.showSnackDay === true ? (
            <div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <input
                      className="form-control custom-form-control"
                      placeholder="What do you snack on?"
                      value={this.state.fields.daySnackList}
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "daySnackList"
                      )}
                    />
                    <label className="label-helper py-2 px-1">
                      What do you snack on?
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <label className="text-center  stepper-form-sub-headings">
            How many days per week do you eat:
          </label>

          {this.state.nutritionDiets.map((value, index) => {
            return (
              <div key={`${value.id}-${index}`} className="">
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <label className="text-primary stepper-form-sub-headings">
                      BreakFast
                    </label>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max="7"
                      className="range-slider"
                      value={value.breakfast}
                      onChange={(e) =>
                        this.handleInputBreakFastChange(e, index)
                      }
                      step="1"
                    />
                    <label className="stepper-form-sub-headings">
                      {value.breakfast}
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-12">
                    <label className="text-primary stepper-form-sub-headings">
                      Lunch
                    </label>

                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max="7"
                      className="range-slider"
                      value={value.lunch}
                      onChange={(e) => this.handleInputLunchChange(e, index)}
                      step="1"
                    />
                    <label className="stepper-form-sub-headings">
                      {value.lunch}
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-12">
                    <label className="text-primary stepper-form-sub-headings">
                      Dinner
                    </label>

                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max="7"
                      className="range-slider"
                      value={value.dinner}
                      onChange={(e) => this.handleInputDinnerChange(e, index)}
                      step="1"
                    />
                    <label className="stepper-form-sub-headings">
                      {value.dinner}
                    </label>
                  </div>
                </div>

              </div>
            );
          })}

          {/* cook Your Meals */}

          <div className="row mt-4">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.cookOwnMeals}
                  className={
                    this.state.cookOwnMeals === "" ||
                    this.state.cookOwnMeals === null ||
                    this.state.cookOwnMeals === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "cookOwnMeals"
                  )}
                >
                  <option value="" disabled selected>
                    Do you generally cook your own meals?
                  </option>
                  {cookYourMeals.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you generally cook your own meals?{" "}
                </label>
              </div>
            </div>
          </div>

          {/* eatingYourMeals */}

          <div className="row mb-4">
            <div className="col-lg-12 col-12 mt-2">
              <h4 className="stepper-form-sub-headings">
                How are you usually eating your meals?
              </h4>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="relaxed"
                  value={"relaxed"}
                  checked={this.state.eatingManners.relaxed}
                  onChange={this.handleCheckboxes.bind(this, "relaxed")}
                />
                <label className="form-check-label" htmlFor="relaxed">
                  Relaxed
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Rushed"
                  value={"Rushed"}
                  checked={this.state.eatingManners.rushed}
                  onChange={this.handleCheckboxes.bind(this, "rushed")}
                />
                <label className="form-check-label" htmlFor="Rushed">
                  Rushed
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="StandinginfrontofTV"
                  value={"latino"}
                  checked={this.state.eatingManners.standingFront}
                  onChange={this.handleCheckboxes.bind(this, "standingFront")}
                />
                <label
                  className="form-check-label"
                  htmlFor="StandinginfrontofTV"
                >
                  Standing in front of TV
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="seatedattheable"
                  value={"Seated at the table"}
                  checked={this.state.eatingManners.seatedTable}
                  onChange={this.handleCheckboxes.bind(this, "seatedTable")}
                />
                <label className="form-check-label" htmlFor="seatedattheable">
                  Seated at the Table
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Inthecar"
                  value={"south"}
                  checked={this.state.eatingManners.inTheCar}
                  onChange={this.handleCheckboxes.bind(this, "inTheCar")}
                />
                <label className="form-check-label" htmlFor="Inthecar">
                  In the car
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Alone"
                  value={"middle"}
                  checked={this.state.eatingManners.alone}
                  onChange={this.handleCheckboxes.bind(this, "alone")}
                />
                <label className="form-check-label" htmlFor="Alone">
                  Alone
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="family-friends"
                  value={"native"}
                  checked={this.state.eatingManners.withFamilyAndFriends}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "withFamilyAndFriends"
                  )}
                />
                <label className="form-check-label" htmlFor="family-friends">
                  With family and friends
                </label>
              </div>
            </div>
          </div>

          {/*wideVariteyFoods  */}
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.varietyOfFoods}
                  className={
                    this.state.fields.varietyOfFoods === "" ||
                    this.state.fields.varietyOfFoods === null ||
                    this.state.fields.varietyOfFoods === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "varietyOfFoods"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Do you eat a wide variety of foods?
                  </option>
                  {varietyOfFoods.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you eat a wide variety of foods?{" "}
                </label>
              </div>
            </div>
          </div>

          {/* consumeSugar */}
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.sugarConsumptionFrequency}
                  className={
                    this.state.fields.sugarConsumptionFrequency === "" ||
                    this.state.fields.sugarConsumptionFrequency === null ||
                    this.state.fields.sugarConsumptionFrequency === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "sugarConsumptionFrequency"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    How often do you consume sugar?
                  </option>
                  {consumeSugar.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  How often do you consume sugar?{" "}
                </label>
              </div>
            </div>
          </div>

          {/* How many of the following do you drink per week?*/}
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.weightPattern}
                  className={
                    this.state.weightPattern === "" ||
                    this.state.weightPattern === null ||
                    this.state.weightPattern === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "weightPattern"
                  )}
                >
                  <option value="" disabled selected>
                    {" "}
                    Do you consider yourself?
                  </option>
                  {considerYourself.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you consider yourself?{" "}
                  <span className="text-danger">*</span>
                </label>
                <span className="text-danger">
                  {this.state.errors["weightPattern"]}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-12 mt-2">
              <h4 className="stepper-form-sub-headings">
                How many of the following do you drink per week?
              </h4>
              <div className="row">
                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.alcohol}
                      className={
                        this.state.fields.alcohol === "" ||
                        this.state.fields.alcohol === null ||
                        this.state.fields.alcohol === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "alcohol"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Alcohol
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">Alcohol </label>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.coffee}
                      className={
                        this.state.fields.coffee === "" ||
                        this.state.fields.coffee === null ||
                        this.state.fields.coffee === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={this.handleUserInputChange.bind(this, "coffee")}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Coffee
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">Coffee </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.decafCoffee}
                      className={
                        this.state.fields.decafCoffee === "" ||
                        this.state.fields.decafCoffee === null ||
                        this.state.fields.decafCoffee === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "decafCoffee"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Decaf Coffee
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Decaf Coffee{" "}
                    </label>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.dietDrinks}
                      className={
                        this.state.fields.dietDrinks === "" ||
                        this.state.fields.dietDrinks === null ||
                        this.state.fields.dietDrinks === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "dietDrinks"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Diet Drinks
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Diet Drinks{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.softDrinks}
                      className={
                        this.state.fields.softDrinks === "" ||
                        this.state.fields.softDrinks === null ||
                        this.state.fields.softDrinks === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "softDrinks"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Soft Drinks
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Soft Drinks{" "}
                    </label>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.blackTea}
                      className={
                        this.state.fields.blackTea === "" ||
                        this.state.fields.blackTea === null ||
                        this.state.fields.blackTea === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "blackTea"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Black Tea
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">Black Tea</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.greenTea}
                      className={
                        this.state.fields.greenTea === "" ||
                        this.state.fields.greenTea === null ||
                        this.state.fields.greenTea === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "greenTea"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Green Tea
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">Green Tea </label>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.herbalTea}
                      className={
                        this.state.fields.herbalTea === "" ||
                        this.state.fields.herbalTea === null ||
                        this.state.fields.herbalTea === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "herbalTea"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Herbal Tea
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Herbal Tea{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.sportDrinks}
                      className={
                        this.state.fields.sportDrinks === "" ||
                        this.state.fields.sportDrinks === null ||
                        this.state.fields.sportDrinks === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "sportDrinks"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Sport Drinks
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Sport Drinks{" "}
                    </label>
                  </div>
                </div>

                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.fruitJuice}
                      className={
                        this.state.fields.fruitJuice === "" ||
                        this.state.fields.fruitJuice === null ||
                        this.state.fields.fruitJuice === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "fruitJuice"
                      )}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Fruit Juice
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Fruit Juice{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-6">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.water}
                      className={
                        this.state.fields.water === "" ||
                        this.state.fields.water === null ||
                        this.state.fields.water === undefined
                          ? "disabled-option form-control custom-form-control mb-2"
                          : "form-control custom-form-control mb-2"
                      }
                      onChange={this.handleUserInputChange.bind(this, "water")}
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select Water
                      </option>
                      {drinkPerWeek.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">Water </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.loading ? (
            <div className="col-lg-2">
              <div class="loading">Loading&#8230;</div>
            </div>
          ) : (
            ""
          )}
          {/* EXERCISE  */}

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                disabled={this.state.disabled ? true : false}
                onClick={this.onSubmit.bind(this)}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Nutrition;
