import React, { Component } from "react";
import GroupLogo from "../../assets/images/Group.png";
import Stepper from "bs-stepper";
import Cloud from "../../assets/images/cloud_upload.png";
import Verify from "./registration/modal/Verify";
import { toast } from "react-toastify";
import AuthService from "../../services/authServices";
import PhoneInput from "react-phone-input-2";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DiagnoseModal from "./registration/modal/addDiagnose";
import Medication from "./registration/modal/medication";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactAutocomplete from "react-autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import authServices from "../../services/authServices";
import { Spinner } from "reactstrap";

toast.configure();
const tolerance = [
  { value: "unknown", label: "unknown" },
  { value: "lowtolerance", label: "low tolerance" },
  { value: "averagetolerance", label: "average tolerance" },
  { value: "hightolerance", label: "high tolerance" },
];
const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  { value: "Decline To Answer", label: "Decline To Answer" },
];
const interestedin = [
  {
    value: "productsConsultation",
    label: "Products & Consultation",
  },
  { value: "uganda", label: "Products" },
  { value: "thailand", label: "Consultation" },
];

const cannabis = [
  {
    value: "NeverTriedit",
    label: "Cannabis Naive (Never tried it)",
  },
  {
    value: "TriedItYears10Ago",
    label: "Tried more than 10 years ago",
  },
  {
    value: "TriedItYears5Ago",
    label: "Tried more than 5 years ago",
  },
  {
    value: "CurrentlyUseOccasionally",
    label: "Currently use occasionally",
  },
  {
    value: "CurrentlyUseDaily",
    label: "Currently use daily",
  },
];

const stageCancer = [
  {
    value: "stage0",
    label: "Stage 0",
  },
  {
    value: "stage1",
    label: "Stage 1",
  },
  {
    value: "stage2",
    label: "Stage 2",
  },
  {
    value: "stage3",
    label: "Stage 3",
  },
  {
    value: "stage4",
    label: "Stage 4",
  },
];

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      showMedication: false,
      gender: null,
      medicationId: "",
      interestedin: null,
      cancer: null,
      active: "first",
      cannabis: null,
      tolerance: null,
      addModalShow: false,
      addModalDiagnoseShow: false,
      addModalMedicationShow: false,
      skip: false,
      objective: {},
      objectiveFlag: false,
      secondAddress: "",
      diagnoseCheck: true,
      otpCode: "",
      personalPhone: "",
      isTick: false,
      successModalShow: false,
      dob: null,
      cancerStage: "",
      showLoader: false,
      index: "",
      personalInformation: {
        // latitude: "",
        // longitude: "",
        dob: null,
      },
      expiry_date: null,
      physicianInformation: {
        expiry_date: null,
      },
      medicalCannabisQuestions: {},
      initilizeMedication: [],
      medType: "",
      medications: [
        {
          name: "",
          dose: "",
          frequency: "",
          medicationId: ""
        },
      ],
      fields: {},
      errors: {},
      disabled: true,
      pharmaceuticalSupplements: [],
      phone_otp: "",
      isLoading: false,
      hideShow: false,
      isCancer: false,
      phoneLength: "",
      showCannabisFields: false,
      file: "",
      driverFile: "",
      imagePreviewUrl: "",
      imagePreviewUrlDriver: "",
      hideShowMedication: [
        {
          index: 0,
          value: false,
        },
      ],
      hideShowMedicationSelect: [
        {
          index: 0,
          selectValue: true,
        },
      ],
      initilizeDiagnose: [],
      type: "",
      user_id: "",
      country: "",
      region: "",
      physicianPhone: "",
      diagnosesList: {
        data: [
          {
            name: "",
            isCancer: "",
          },
        ],
      },
      medicationList: {
        data: [
          {
            name: "",
          },
        ],
      },
      visibility: "",
      diagnoseId: null,
      activeOption: 0,
      filteredOptions: [],
      filteredMedicationOptions: [],
      showOptions: false,
      medicationName: "",
      showField: false,
      showPrimaryDiagnose: false,
      termsCondition: false,
      name: "",
      showHideImage: true,
      addButtonDisabled: false,
      validateTrue: true,
      filterednamesShow: true
    };
    this.handleNextPersonalClick = this.handleNextPersonalClick.bind(this);
    this.handleNextPhysicianClick = this.handleNextPhysicianClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handlePersonalDateChange = this.handlePersonalDateChange.bind(this);
    this.onOk = this.onOk.bind(this);

    this.handlePhysicianDateChange = this.handlePhysicianDateChange.bind(this);
    this.addMedicationList = this.addMedicationList.bind(this);
    this.handleInputNameChange = this.handleInputNameChange.bind(this);
    this.handleInputDoseChange = this.handleInputDoseChange.bind(this);
    this.handleInputFrequencyChange = this.handleInputFrequencyChange.bind(
      this
    );
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.navigation = this.navigation.bind(this);
    this.handleChangePrimaryDiagnose = this.handleChangePrimaryDiagnose.bind(
      this
    );
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: true,
      animation: true,
    });
    this.setState({ skip: false });
  }

  searchDiagnose(name) {

    AuthService.getDiagnoseByName(name)
      .then((response) => {
        this.checkMethod(response.data,name)
        this.setState({
          diagnoseCheck : this.checkMethod(response.data,name)
        })
        this.setState({
          diagnosesList: response.data,
        });
        if (response.data.data.length === 0) {
          this.setState({
            hideShow: true,
            isCancer: false,
            visibility: "none",
            diagnoseId: null
          });
        }
        if (response.data.data.length > 0) {
          this.setState({
            hideShow: false,
            visibility: "block",
          });
        }
      })
      .catch((e) => { });
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  nextStep(e) {
    e.preventDefault();
    this.stepper.next();
  }

  checkMethod (data,name) {
      return data.data.some(function(el) {
        return el.name === name;
      }); 
    }

  //   var found = false;
  // for(var i = 0; i < data.data.length; i++) {
  //   if (data.data[i].name === name) {
  //     console.log(data.data[i].name)
  //     found = true;
  //       break;
  //   }
  //  }
  

  handleInputNameChange(e, object, idx) {
    let nextMedicalData = this.state.medications.slice();
    if (object.id !== "" || object.id !== undefined || object.id !== null) {
      nextMedicalData[idx].name = object.name;
      nextMedicalData[idx].medicationId = object.id;
      this.setState({
        medications: nextMedicalData,
        disabled: false,
        index: idx,
        medicationName: e,
        addButtonDisabled: false,
        validateTrue: true
      });
      let x = this.state.hideShowMedication;
            x[idx].value = false;
            let select = this.state.hideShowMedicationSelect;
            x[idx].selectValue = true;
          
            this.setState(
              {
                hideShowMedication: x,
                hideShowMedicationSelect: select,
              },
              () => { }
            );
          }
    
  }

  handleChangeKey(e, index) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[index].name = e.target.value;
    const medicationName = nextMedicalData[index].name;
    this.searchMedicationList(medicationName, index);
    this.setState({
      medications: nextMedicalData,
      index: index,
      addButtonDisabled: true,
      medicationName:  e.target.value
    });
    if (this.state.medicationList.data.length === 0) {
      this.setState({ visibility: "none" });
    } else {
      if (this.state.medicationList.data.length > 0) {
        this.setState({ visibility: "block" });
      }
    }
  }

  handleChangePrimaryDiagnose(e, object) {
    if (object.id !== "" || object.id !== undefined || object.id !== null) {
      this.setState({
        name: e,
        diagnoseId: object.id,
        disabled : false
      });
      if (object.isCancer === true) {
        this.setState({
          name: e,
          diagnoseId: object.id,
          isCancer: true,
          disabled : false
        });
      } else if (object.isCancer === false) {
        this.setState({
          name: e,
          diagnoseId: object.id,
          isCancer: false,
          disabled : false
        });
      }
    }
    // this.handleValidationMedication(object.id, e, object.isCancer);
  }
  changeMethod = () => {

  }

  handleValidationMedication(id, e, isCancer) {
    let medical = this.state;
    let errors = {};
    let formIsValid = true;
    if (medical["name"] !== null || medical["name"] !== "") {
      if (!medical["name"]) {
        formIsValid = false;
        errors["name"] = "This field is required";
      }
      if (this.state.type === "" && this.state.diagnosesList.data.length === 0) {
        formIsValid = false;
        this.setState({ showPrimaryDiagnose: true, hideShow: true , diagnoseId: null });
        errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
      } else if (this.state.diagnoseId === null) {
        formIsValid = false;
        errors["name"] = "";
        errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
        this.setState({ showPrimaryDiagnose: true, hideShow: true });
      } else if(this.state.diagnoseId !== null && id !== null) {
        formIsValid = true;
        errors["name"] = "";
        errors["notFound"] = "";
        this.setState({ showPrimaryDiagnose: false, hideShow: false });
      }
    }

    if (!medical["termsCondition"]) {
      formIsValid = false;
      errors["termsCondition"] = "This field is required";
    }

    if (this.state.isCancer === true) {
      formIsValid = false;
      errors["canncerStage"] = "This field is required";
      if (medical["cancerStage"] !== "") {
        formIsValid = true;
        errors["canncerStage"] = "";
        errors["notFound"] = "";
        this.setState({ showPrimaryDiagnose: false, hideShow: false });
      }
    }
    if (this.state.medType === "" && this.state.medicationList.data.length === 0) {
      formIsValid = false;
      this.setState({ showMedication: true});
      errors["notFoundMedication"] =
        "Please choose any from Suggested Medication or";
    } else if (this.state.medType === "AddMedication" && this.state.initilizeMedication.length > 0) {
      formIsValid = true;
      this.setState({ showMedication: false,notFoundMedication: false });
      errors["notFoundMedication"] =
        "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  objectiveValidation() {
    let medical = this.state;
    let errors = {};
    let formIsValid = true;
    if (Object.keys(medical.objective).length === 0) {
        this.setState({
          objectiveFlag: true
        })
        formIsValid = false;
      } else if (Object.keys(medical.objective).length > 0) {
        this.setState({
          objectiveFlag: false
        })
        formIsValid = true;
      }
      this.setState({ errors: errors });
      return formIsValid;
  }

  handleValidation(string, id, e, isCancer) {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (string === "Physical") {
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "This field is required";
      }
      if (typeof fields["web_address"] !== "undefined") {
        const webSite = new RegExp(
          /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
        );
        const checkWebsite = !webSite.test(fields["web_address"]);
        if (checkWebsite) {
          formIsValid = false;
          errors["web_address"] = "Please enter valid url";
        }
      }

      if (this.state.physicianPhone) {
        if (
          this.state.physicianPhone.startsWith("1") &&
          this.state.physicianPhone.length !== 11
        ) {
          formIsValid = false;
          errors["physicianPhone"] = "Please enter valid phone number.";
        }
      }
    }

    if (string === "Personal") {
      if (!this.state.personalPhone) {
        formIsValid = false;
        errors["personalPhone"] = "This field is required";
      }
      if (this.state.personalPhone) {
        if (
          this.state.personalPhone.startsWith("1") &&
          this.state.personalPhone.length !== 11
        ) {
          formIsValid = false;
          errors["personalPhone"] = "Please enter valid phone number.";
        }
      }

      if (!fields["lastName"]) {
        formIsValid = false;
        errors["lastName"] = "This field is required";
      }

      if (!fields["lastName"]) {
        formIsValid = false;
        errors["lastName"] = "This field is required";
      }
      if (!fields["zip_code"]) {
        formIsValid = false;
        errors["zip_code"] = "This field is required";
      }
      if (fields["zip_code"] !== "") {
        if (fields["zip_code"] !== null) {
          if (typeof fields["zip_code"] !== "undefined") {
            const numeric = new RegExp(/^[0-9]*$/);
            const check = !numeric.test(fields["zip_code"]);
            if (check) {
              formIsValid = false;
              errors["zip_code"] = "Please enter a Positive Whole value";
            }
          }
        }
      }



      // if (typeof fields["firstName"] !== "undefined") {
      //   if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
      //     formIsValid = false;
      //     errors["firstName"] = "Please Use Alphabets";
      //   } else if (fields["firstName"].length > 15) {
      //     formIsValid = false;
      //     errors["firstName"] = "Please use less then 15 Character";
      //   }
      // }
      if (!fields["firstName"]) {
        formIsValid = false;
        errors["firstName"] = "This field is required";
      }

      if (fields["email"] !== fields["confirmEmail"]) {
        formIsValid = false;
        errors["confirmEmail"] = "Email Does Not Match";
      }
      //Email
      if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = "This field is required";
      }
      if (!fields["confirmEmail"]) {
        formIsValid = false;
        errors["confirmEmail"] = "This field is required";
      }
      if (typeof fields["email"] !== "undefined") {
        const pattern = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!pattern.test(fields["email"])) {
          formIsValid = false;
          errors["email"] = "Please enter valid email address.";
        } else if (fields["email"].length > 255) {
          errors["email"] = "Please enter a short email";
        }
      }
      if (typeof fields["confirmEmail"] !== "undefined") {
        const confirmEmailpattern = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if (!confirmEmailpattern.test(fields["confirmEmail"])) {
          formIsValid = false;
          errors["confirmEmail"] = "Please enter valid email address.";
        } else if (fields["confirmEmail"].length > 255) {
          errors["confirmEmail"] = "Please enter a short email";
        }
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(e, string) {
    if (string === "state") {
      this.setState({
        state: e.target.value,
      });
    }
    if (string === "country") {
      this.setState({
        country: e.target.value,
      });
    }
    if (string === "gender") {
      this.setState({
        gender: e.target.value,
      });
    }
    if (string === "interestedin") {
      this.setState({
        interestedin: e.target.value,
      });
    }
    if (string === "cancer") {
      this.setState({
        cancerStage: e.target.value,
      });
    }
    if (string === "cannabis") {
      this.setState({
        cannabis: e.target.value,
      });
      if (e.target.value === "NeverTriedit") {
        this.setState({
          showCannabisFields: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          showCannabisFields: false,
        });
      } else {
        this.setState({
          showCannabisFields: true,
        });
      }
    }
    if (string === "tolerance") {
      this.setState({
        tolerance: e.target.value,
      });
    }
    if (string === "diagnoseId") {
      this.searchDiagnose(e.target.value);
      this.setState({
        name: e.target.value,
        disabled: false
      });
      if (this.state.diagnosesList.data.length === 0) {
        this.setState({ isCancer: false, visibility: "none" });
      } else {
        if (this.state.diagnosesList.data.length > 0) {
          this.state.diagnosesList.data.forEach(
            // eslint-disable-next-line array-callback-return
            (optionName, index) => {
              if (optionName.isCancer === true) {
                this.setState({
                  name: e.target.value,
                  isCancer: true,
                  visibility: "block",
                });
              } else if (optionName.isCancer === false) {
                this.setState({
                  isCancer: false,
                  name: e.target.value,
                  visibility: "none",
                });
              }
            }
          );
        }
      }
    }
  }

  handleChangeChk(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.checked;
    this.setState({ fields });
    if (e.target.checked === true) {
      this.setState({ skip: true });
    }
    if (e.target.checked === false) {
      this.setState({ skip: false });
    }
  }

  addMedicationList(e) {
    e.preventDefault();
    if(this.state.medType === "AddMedication" && this.state.initilizeMedication.length > 0) {
      let array = this.state.medications;
       array.push({
        id: array.length + 1,
        name: "",
        dose: "",
        frequency: "",
        medicationId: "",
      });
      let x = this.state.hideShowMedication;
      x.push({ index: x.length + 1, value: false });
      let select = this.state.hideShowMedicationSelect;
      select.push({ index: select.length + 1, selectValue: true });
      this.setState({
        medications: array,
        hideShowMedication: x,
        hideShowMedicationSelect: select,
        // disabled: true,
        addButtonDisabled: true,
      });
     } else if(this.state.medType === "") {
      let array = this.state.medications;
      array.push({
        id: array.length + 1,
        name: "",
        dose: "",
        frequency: "",
        medicationId: "",
      });
      let x = this.state.hideShowMedication;
      x.push({ index: x.length + 1, value: false });
      let select = this.state.hideShowMedicationSelect;
      select.push({ index: select.length + 1, selectValue: true });
      this.setState({
        medications: array,
        hideShowMedication: x,
        hideShowMedicationSelect: select,
        // disabled: true,
        addButtonDisabled: true,
      });
     } else {
         if(this.state.medicationList.data.length === 0) {
          this.handleValidation();
         }
     }
  }


  searchMedicationList(name, idx) {
    AuthService.getMedicationByName(name)
      .then((response) => {
        this.setState({
          medicationList: response.data,
        });
        let nextMedicalList = response.data.data;
        let errors = {}
        const string1 = name;
        const string2 = nextMedicalList[idx]?.name;
        const result = string1.localeCompare(string2);
        const finalResult = result === 0 ? true : false;

        
        let nextMedicalData = this.state.medications.slice();
        nextMedicalData[idx].medicationId = nextMedicalList[idx]?.id;

        if (response.data.data.length === 0) {
          let x = this.state.hideShowMedication;
          x[idx].value = true;
          this.setState(
            {
              hideShowMedication: x,
            },
            () => { }
          );
        } else if (response.data.data.length > 0) {
          if (finalResult === false && string1 !== "") {
            let x = this.state.hideShowMedication;
            x[idx].value = true;

            errors["notFound"] =
              "Please choose any from Suggested Medication or";
            this.setState(
              {
                hideShowMedication: x,
                validateTrue: false,
                medications: nextMedicalData
              },
              () => { }
            );
          } else {
            let x = this.state.hideShowMedication;
            x[idx].value = false;
            let select = this.state.hideShowMedicationSelect;
            x[idx].selectValue = true;
          
            this.setState(
              {
                hideShowMedication: x,
                hideShowMedicationSelect: select,
                validateTrue: true,
                medications: nextMedicalData
              },
              () => { }
            );
          }
        } 

        this.setState({ errors: errors });
      })
      .catch((e) => { });
  }

  handleInputDoseChange(e, idx) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[idx].dose = e.target.value;
    this.setState({ medications: nextMedicalData });
  }

  handleInputFrequencyChange(e, idx) {
    let nextMedicalData = this.state.medications.slice();
    nextMedicalData[idx].frequency = e.target.value;
    this.setState({ medications: nextMedicalData });
  }

  handleRemoveSocial(idx) {
    let someArray = this.state.medications;
    someArray.splice(idx, 1);
    this.setState({ medications: someArray, addButtonDisabled: false });
  }

  handleNextPersonalClick(e) {
    this.setState({
      user_id: localStorage.getItem("userId"),
    });
    //  localStorage.removeItem("userId");
    if (this.handleValidation("Personal")) {
      let personalInformation = this.state.fields;
      personalInformation.country = this.state.country;
      personalInformation.state = this.state.region;
      personalInformation.gender = this.state.gender;
      personalInformation.interested_in = this.state.interestedin;
      personalInformation.phone = this.state.personalPhone;
      personalInformation.email = this.state.fields.email;
      personalInformation.firstName = this.state.fields.firstName;
      personalInformation.lastName = this.state.fields.lastName;
      personalInformation.address = this.state.fields.address;
      personalInformation.city = this.state.fields.city;
      personalInformation.confirmEmail = this.state.fields.confirmEmail;
      personalInformation.user_id = this.state.user_id;
      personalInformation.addressTwo = this.state.fields.addressTwo;
      personalInformation.recommendationRequired = this.state.fields.recommendationRequired;
      personalInformation.zip_code = this.state.fields.zip_code;
     
      if(this.state.dob !== null) {
        var fullDate = moment(this.state.dob, "MM-DD-YYYY");
        var date = fullDate.format("DD");
        var year = fullDate.format("YYYY");
        var month = fullDate.format("MM");
        const selectedDate = month + "-" + date + "-" + year;
        personalInformation.dob = this.state.dob !== null || this.state.dob !== "" ?  selectedDate : null;
      } else {
        personalInformation.dob = null
      }
     

      if (this.state.user_id === "" || this.state.user_id === null) {
        e.preventDefault();
        const params = {
          phone: this.state.personalPhone,
          email: this.state.fields.email,
          name: "registration",
        };
        authServices
          .resendPhoneOtp(params)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              this.setState({ fields: personalInformation });
              this.setState({
                addModalShow: true,
                user_id: localStorage.getItem("userId"),
              });
            }
          })
          .catch((e) => {
            if (e.response.status !== undefined) {
              if (e.response.status === 400) {
                // this.setState({ addModalShow: true });
                toast.error(e.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              }
            }
          });
      } else if (this.state.user_id !== "" || this.state.user_id !== null) {
        authServices
          .personalInformation(personalInformation)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              this.setState({ addModalShow: false ,user_id : response.data.user_id });
              this.stepper.next();
              document.getElementById("Input").setAttribute("readonly", "true");
              document
                .getElementById("confirmEmail")
                .setAttribute("readonly", "true");
              document
                .querySelector(".react-tel-input")
                .children[1].setAttribute("readonly", "true");
            }
          })
          .catch((e) => {
            if (e.response.status === 400) {
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          });
      }
    }
  }
  _handleImageChangeDriverLicense(e) {
    e.preventDefault();
    this.setState({
      showLoader: true,
    });
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        driverFile: file,
        imagePreviewUrlDriver: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
      this.getFileUpload(file, "DriverLicence");
    }
  }

  _handleImageChangePhysicalRecomendation(e) {
    this.setState({
      showLoader: true,
    });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        // showLoader: false
      });
    };
    if (file) {
      reader.readAsDataURL(file);
      this.getFileUpload(file, "physicianRecommendation");
    }
  }

  removeImage = () => {
    document.getElementById("image").style.display = "none";
  };

  getFileUpload(file, type) {
    const formData = new FormData();

    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("type", type);
    formData.append("file", file);
    AuthService.fileUpload(formData)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            showLoader: false,
          });
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.setState({
            showLoader: false,
          });
        } else if (e.response.status === 413) {
          toast.error("Your File is Too Large", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      });
  }

  handleNextPhysicianClick(e) {
    e.preventDefault();
    this.setState({
      user_id: localStorage.getItem("userId"),
    });
    if (this.handleValidation("Physical")) {
      this.setState({ fields: this.state.fields });
      let physicianInformation = this.state.fields;
      physicianInformation.id_number = this.state.fields.id_number;
      physicianInformation.name = this.state.fields.name;
      
      if(this.state.expiry_date !== null) {
        var fullDate = moment(this.state.expiry_date, "MM-DD-YYYY");
        var date = fullDate.format("DD");
        var year = fullDate.format("YYYY");
        var month = fullDate.format("MM");
        const selectedDate = month + "-" + date + "-" + year;
        physicianInformation.expiry_date = this.state.expiry_date !== null || this.state.expiry_date === " " ? selectedDate : null;
      } else {
        physicianInformation.expiry_date = null
      }

      physicianInformation.phone = this.state.physicianPhone;
      physicianInformation.user_id = localStorage.getItem("userId")
      physicianInformation.recommendation_quantity = this.state.fields.recommendation_quantity;
      this.setState({ fields: physicianInformation });

      AuthService.physicianInformation(physicianInformation)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({
              user_id: localStorage.getItem("userId"),
            });
            this.setState({ fields: physicianInformation });
            this.stepper.next();
          } else if (response.status === 202) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({
              user_id: localStorage.getItem("userId"),
            });
            this.stepper.next();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    } else if (this.state.skip === false) {
      this.setState({ skip: false });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (
      this.handleValidationMedication(
        this.state.diagnoseId,
        this.state.name,
        this.state.isCancer
      ) && this.objectiveValidation()
    ) {
      let medicalInformation = this.state.fields;
      medicalInformation.diagnoseId = this.state.diagnoseId;
      medicalInformation.note = this.state.fields.note;
      medicalInformation.previous_experience = this.state.cannabis;
      medicalInformation.tolerance = this.state.tolerance;
      medicalInformation.objective = this.state.objective;
      medicalInformation.diagnosStage = this.state.cancerStage;
      medicalInformation.medications = this.state.medications;
      medicalInformation.user_id = localStorage.getItem("userId");
      medicalInformation.termsCondition = this.state.termsCondition;
      medicalInformation.objectiveOther = this.state.fields.objectiveOther;
      this.setState({ fields: medicalInformation });
      AuthService.medicalInformation(medicalInformation)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({ fields: medicalInformation });
            this.setState({ successModalShow: true });
            this.props.history.push("/checkMail");
            localStorage.removeItem("userId");
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  }

  handleCheckboxes(field, e) {
    let objective = this.state.objective;
    objective[field] = e.target.checked;

    if (field === "other") {
      this.setState({ showField: !this.state.showField });
    }
    // if (field !== "other") {
    //   this.setState({ showField: false });
    // }
    if (field === "termsCondition") {
      this.setState({ termsCondition: e.target.checked });
    }
    this.setState({ objective , disabled: false });
  }

  handleUserInputPhoneChange(field, e) {
    if (field === "personalPhone") {
      this.setState({
        personalPhone: e,
      });
    }
    if (field === "physicianPhone") {
      this.setState({
        physicianPhone: e,
      });
    }
    this.searchPhone(this.state.personalPhone);
  }

  handleUserInputChange(field, e) {
    if (field === "personalPhone") {
      this.setState({
        personalPhone: e,
      });
    } else if (field === "physicianPhone") {
      this.setState({
        physicianPhone: e,
      });
    } else {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields});
    }
  }

  handleBackClick(e) {
    this.stepper.previous();
    if (this.state.user_id !== "" || this.state.user_id !== null) {
      this.setState({
        user_id: localStorage.getItem("userId"),
      });
    }
    const clicked = e.target.id;
    if (this.state.active === clicked) {
      this.setState({ active: "" });
    } else {
      this.setState({ active: clicked });
    }
  }

  addModalMedication(e) {
    e.preventDefault();
    this.setState({ addModalMedicationShow: true });
  }

  addModalDiagnose(e) {
    e.preventDefault();
    this.setState({ addModalDiagnoseShow: true });
  }

  searchPhone(e) {
    e.preventDefault();
    this.setState({ isLoading: true, isTick: false });
    AuthService.searchPhone(this.state.personalPhone)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.city === null) {
            this.setState({ isLoading: true, isTick: false });
          } else {
            this.setState({ isLoading: false, isTick: true });
          }
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
        }
      });
  }

  handlePersonalDateChange(value, dateString) {
    let personalInformation = this.state;
    personalInformation.dob = value
    this.setState({
      personalInformation: personalInformation,
      dob: value
    });
  }


  onOk(value) { }

  handlePhysicianDateChange(value, expiry_date) {
    let physicianInformation = this.state;
    physicianInformation.expiry_date = value;
    this.setState({
      physicianInformation: physicianInformation,
      expiry_date: value
    });
  }

  navigation() {
    this.props.history.push("/login");
  }

  next(e) {
    this.stepper.next();
  }

  addModalMedicationClose = () =>
    this.setState({ addModalMedicationShow: false ,disabled: false });
  addModalDiagnoseClose = () => this.setState({ addModalDiagnoseShow: false });
  diagnoseSearchStatus = () => {
    this.setState({ showPrimaryDiagnose: false, hideShow: false });
  };
  diagnoseId = (id, isCancer,name) => {
    console.log(id)
    this.setState({ diagnoseId: id, isCancer: isCancer ,name: name});
  };
  initiliazeDiagnoseList = (diagnoseList,string) => {
    this.setState({
     initilizeDiagnose: diagnoseList,
     type: string
    });
  }

  initiliazeMedicationList = (medicationList,string) => {
    this.setState({
      initilizeMedication: medicationList,
      medType: string
    })
  }
  medicationSearchStatus = () => {
    this.setState({ addButtonDisabled: false });
  };
  medicationId = (medications,hideShowMedication) => {
    this.setState({ medications: medications, hideShowMedication: hideShowMedication });
  };

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });

    const { country, region } = this.state;
    let { imagePreviewUrl, imagePreviewUrlDriver } = this.state;

    let $imagePreview = null;
    let $imagePreviewDriver = null;

    if (imagePreviewUrl) {
      $imagePreview =
        this.state.showLoader === true ? (
          <Spinner color="primary mt-5" />
        ) : (
          <img alt={"recommendation"} src={imagePreviewUrl} />
        );
    } else {
      // $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }
    if (imagePreviewUrlDriver) {
      $imagePreviewDriver =
        this.state.showLoader === true ? (
          <Spinner color="primary mt-5" />
        ) : (
          <img alt={"license "} src={imagePreviewUrlDriver} />
        );
    } else {
      // $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }

    return (
      <div className="patient-singup">
        <div className="container">
          <div className="card card-border">
            <div className="row">
              <div className="col-lg-12">
                <div className="card-body p-0">
                  <div id="stepper1" className="bs-stepper">
                    <div className="row">
                      <div className="col-lg-4 ">
                        <div className="register-sidebar">
                          <h4 className="reg-heading"> Registration </h4>
                          <div className="d-none d-lg-block">
                            <div className="bs-stepper-header ">
                              <div className="step" data-target="#test-l-1">
                                <button className="step-trigger">
                                  <span className="bs-stepper-circle custom-stepper-circle"></span>
                                  <span className="bs-stepper-label custom-stepper-circle ">
                                    Personal Information
                                  </span>
                                </button>
                              </div>

                              <div className="step" data-target="#test-l-2">
                                <button className="step-trigger">
                                  <span className="bs-stepper-circle custom-stepper-circle"></span>
                                  <span className="bs-stepper-label custom-stepper-circle ">
                                    Physician's Information
                                  </span>
                                </button>
                              </div>

                              <div className="step" data-target="#test-l-3">
                                <button className="step-trigger">
                                  <span className="bs-stepper-circle custom-stepper-circle"></span>
                                  <span className="bs-stepper-label custom-stepper-circle ">
                                    Medical Cannabis Questions
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="group-card card-border d-none d-lg-block">
                            <div className="card-body">
                              <img
                                src={GroupLogo}
                                className="rounded mx-auto d-block"
                                alt="..."
                              />
                              <h3 className="text-white text-center">
                                <strong> HIPAA COMPLIANT</strong>
                              </h3>
                              <p className="text-white text-center">
                                In compliance with the U.S. Health Insurance
                                Portability and Accountability Act of 1996
                                (HIPAA), Octopi Wellness takes rigorous steps to
                                protect your privacy whether consulting a
                                doctor, selecting a medicine, or browsing our
                                site.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-8 ">
                        <div className="bs-stepper-content registration">
                          <div className="bs-stepper">
                            <div className="">
                              <div className="bs-stepper-header ">
                                <div className="d-lg-none d-sm-block">
                                  <div
                                    className={`step  ${this.state.active === "first"
                                      ? "custom-active"
                                      : ""
                                      }`}
                                    data-target="#test-l-1"
                                  >
                                    <button className="step-trigger">
                                      <span className="bs-stepper-circle custom-stepper-circle"></span>
                                      <span className="bs-stepper-label custom-stepper-circle ">
                                        Personal Information
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <form>
                                  <div id="test-l-1" className="content">
                                    <div className="">
                                      <div className="card-body custom-inner-content  ">
                                        <h5 className="stepper-form-headings ">
                                          Personal Information
                                        </h5>
                                        <h6 className="stepper-form-paragraph mb-4">
                                          It just takes a few minutes to
                                          Registration and get fast, easy access
                                          to care, 24/7.
                                        </h6>

                                        <div className="row">
                                          <div className="col-lg-6 col-6 ">
                                            <div className="custom-form-row">
                                              <input
                                                className="form-control custom-form-control"
                                                placeholder="First Name*"
                                                value={
                                                  this.state.fields.firstName
                                                }
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "firstName"
                                                )}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                First Name{" "}
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                              </label>
                                              <span className="text-danger">
                                                {this.state.errors["firstName"]}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                className="form-control custom-form-control"
                                                placeholder="Last Name*"
                                                value={
                                                  this.state.fields.lastName
                                                }
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "lastName"
                                                )}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Last Name{" "}
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                              </label>
                                              <span className="text-danger">
                                                {this.state.errors["lastName"]}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                id="Input"
                                                className="form-control custom-form-control"
                                                placeholder="Best Email*"
                                                value={this.state.fields.email}
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "email"
                                                )}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Best Email{" "}
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                              </label>
                                              <span className="text-danger">
                                                {this.state.errors["email"]}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                id="confirmEmail"
                                                className="form-control custom-form-control"
                                                placeholder="Confirm Email*"
                                                value={
                                                  this.state.fields.confirmEmail
                                                }
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "confirmEmail"
                                                )}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Confirm Email{" "}
                                                <strong className="text-danger">
                                                  *
                                                </strong>
                                              </label>
                                              <span className="text-danger">
                                                {
                                                  this.state.errors[
                                                  "confirmEmail"
                                                  ]
                                                }
                                              </span>
                                              <span className="text-danger">
                                                {
                                                  this.state.errors[
                                                  "checkEmail"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row ">
                                          <div className="col-lg-6 col-6">
                                            <div className="wrapper ">
                                              <div className={
                                                this.state.personalPhone !== ""
                                                  ? "has-number custom-form-row"
                                                  : "custom-form-row"
                                              } >
                                                <PhoneInput
                                                  id="Input"

                                                  country={"us"}
                                                  inputProps={{
                                                    required: true,
                                                  }}
                                                  value={
                                                    this.state
                                                      .personalPhone
                                                  }
                                                  disableDropdown
                                                  onChange={this.handleUserInputChange.bind(
                                                    this,
                                                    "personalPhone"
                                                  )}
                                                />
                                                <label
                                                  className="verify-label text-uppercase"
                                                  onClick={this.searchPhone.bind(
                                                    this
                                                  )}
                                                ></label>

                                                <Verify
                                                  show={this.state.addModalShow}
                                                  phone={
                                                    this.state.personalPhone
                                                  }
                                                  onHide={addModalClose}
                                                  // onSubmit={this.addModalSubmit}
                                                  fields={this.state.fields}
                                                  textHeading="Please enter the OTP we send you"
                                                  wrongSignup={true}
                                                  registration={true}
                                                  next={this.next}
                                                />

                                                <label className="label-helper py-2 px-1">
                                                  Phone{" "}
                                                  <strong className="text-danger">
                                                    *
                                                  </strong>
                                                </label>
                                                <span className="text-danger">
                                                  {
                                                    this.state.errors[
                                                    "personalPhone"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className={
                                              this.state.dob !== null
                                                ? "has-number custom-form-row"
                                                : "custom-form-row"
                                            }>
                                              <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                              >
                                                <DatePicker
                                                  placeholder="Date of Birth"

                                                  openTo="date"
                                                  value={this.state.dob}
                                                  format="MM-dd-yyyy"
                                                  views={[
                                                    "year",
                                                    "month",
                                                    "date",
                                                  ]}
                                                  onChange={
                                                    this
                                                      .handlePersonalDateChange
                                                  }
                                                  disableFuture
                                                />
                                              </MuiPickersUtilsProvider>

                                              <label className="label-helper py-2 px-1">
                                                Date of Birth
                                              </label>
                                              <label
                                                className={`label-helper ${this.state.dob === "" ||
                                                  this.state.dob === null ||
                                                  this.state.dob === undefined
                                                  ? "label-helper-opacity-0"
                                                  : "label-helper-opacity-1"
                                                  } py-2 px-1`}
                                              >
                                                Date of Birth
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                className="form-control custom-form-control"
                                                placeholder="Address Line1"
                                                value={
                                                  this.state.fields.address
                                                }
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "address"
                                                )}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Address Line1{" "}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                value={
                                                  this.state.fields.addressTwo
                                                }
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "addressTwo"
                                                )}
                                                className="form-control custom-form-control"
                                                placeholder="Address Line2"
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Address Line2{" "}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                className="form-control custom-form-control"
                                                placeholder="City"
                                                value={this.state.fields.city}
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "city"
                                                )}
                                              />
                                              <label
                                                htmlFor="country_code"
                                                className="label-helper py-2 px-1"
                                              >
                                                City{" "}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <input
                                                className="form-control custom-form-control"
                                                placeholder="Zip/Postal Code*"
                                                type="number"
                                                min="0"
                                                step="1"
                                                value={
                                                  this.state.fields.zip_code
                                                }
                                                onChange={this.handleUserInputChange.bind(
                                                  this,
                                                  "zip_code"
                                                )}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Zip/Postal Code <span className="text-danger">*</span>{" "}
                                              </label>
                                              <span className="text-danger">
                                                {this.state.errors["zip_code"]}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <CountryDropdown
                                                className={
                                                  this.state.country === "" ||
                                                    this.state.country === null ||
                                                    this.state.country ===
                                                    undefined
                                                    ? "form-control custom-form-control disabled-option"
                                                    : "form-control custom-form-control"
                                                }
                                                defaultOptionLabel="Select a Country"
                                                value={country}
                                                onChange={this.selectCountry}
                                                priorityOptions={["US"]}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                Country{" "}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <RegionDropdown
                                                className={
                                                  this.state.region === "" ||
                                                    this.state.region === null ||
                                                    this.state.region ===
                                                    undefined
                                                    ? "form-control custom-form-control disabled-option"
                                                    : "form-control custom-form-control"
                                                }
                                                blankOptionLabel="No Country Selected"
                                                defaultOptionLabel="Now select a Region"
                                                country={country}
                                                value={region}
                                                onChange={this.selectRegion}
                                              />
                                              <label className="label-helper py-2 px-1">
                                                State/Province/Region{" "}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <select
                                                className={
                                                  this.state.gender === "" ||
                                                    this.state.gender === null ||
                                                    this.state.gender ===
                                                    undefined
                                                    ? "form-control custom-form-control disabled-option"
                                                    : "form-control custom-form-control"
                                                }
                                                onChange={(e) => {
                                                  this.handleChange(
                                                    e,
                                                    "gender"
                                                  );
                                                }}
                                              >
                                                <option value="">
                                                  {" "}
                                                  What sex were you assigned at
                                                  birth ?
                                                </option>
                                                {gender.map((option) => (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </select>

                                              <label className="label-helper py-2 px-1">
                                                What sex were you assigned at
                                                birth ?{" "}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-6">
                                            <div className="custom-form-row">
                                              <select
                                                className={
                                                  this.state.interestedin ===
                                                    "" ||
                                                    this.state.interestedin ===
                                                    null ||
                                                    this.state.interestedin ===
                                                    undefined
                                                    ? "form-control custom-form-control disabled-option"
                                                    : "form-control custom-form-control"
                                                }
                                                onChange={(e) => {
                                                  this.handleChange(
                                                    e,
                                                    "interestedin"
                                                  );
                                                }}
                                              >
                                                <option value="">
                                                  {" "}
                                                  Select Membership Options
                                                </option>
                                                {interestedin.map((option) => (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </select>

                                              <label className="label-helper py-2 px-1">
                                                Membership options{" "}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mb-4">
                                          <div className="col-lg-10 col-6">
                                            <p className="text-left">
                                              Is a medical recommendation
                                              required in your location?
                                            </p>
                                          </div>
                                          <div className="col-lg-2 col-1 text-right">
                                            <div className="switch">
                                              <input
                                                id="switch-1"
                                                type="checkbox"
                                                className="switch-input"
                                                value={
                                                  this.state.fields
                                                    .recommendationRequired
                                                }
                                                onChange={this.handleChangeChk.bind(
                                                  this,
                                                  "recommendationRequired"
                                                )}
                                              />
                                              <label
                                                htmlFor="switch-1"
                                                className="switch-label"
                                              >
                                                Switch
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-lg-4 col-6 offset-lg-5">
                                            <button
                                              id="second"
                                              type="button"
                                              className="btn btn-primary border-radius py-1  px-3"
                                              onClick={
                                                this.handleNextPersonalClick
                                              }
                                            >
                                              Next
                                              <i
                                                className="fa fa-arrow-right ml-2"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="bs-stepper">
                            <div className="bs-stepper-header">
                              <div className="d-lg-none d-sm-block">
                                <div
                                  className={`step ${this.state.active === "second"
                                    ? "custom-active"
                                    : ""
                                    }`}
                                  data-target="#test-l-2"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Physician's Information
                                    </span>
                                  </button>
                                </div>
                              </div>

                              <div id="test-l-2" className="content">
                                <div className="">
                                  <div className=" card-body custom-inner-content custom-physician-inner-content ">
                                    <div className="row">
                                      <div className="col-lg-9 col-9">
                                        <h5 className="stepper-form-headings">
                                          Physician's Information
                                        </h5>
                                      </div>

                                      <div className="col-lg-3 col-3">
                                        {this.state.skip === false ? (
                                          <button
                                            className="btn btn-outline-primary skip  border-radius pull-right "
                                            onClick={this.nextStep}
                                          >
                                            {" "}
                                            Skip
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <h6 className="stepper-form-paragraph mb-4">
                                      It just takes a few minutes to
                                      Registration and get fast, easy access to
                                      care, 24/7.
                                    </h6>

                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="custom-form-row">
                                          <input
                                            className="form-control custom-form-control"
                                            placeholder="Physician Name*"
                                            value={this.state.fields.name}
                                            onChange={this.handleUserInputChange.bind(
                                              this,
                                              "name"
                                            )}
                                          />
                                          <label className="label-helper py-2 px-1">
                                            Physician Name{" "}
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                          </label>
                                          {this.state.skip === false ? (
                                            ""
                                          ) : (
                                            <span className="text-danger">
                                              {this.state.errors["name"]}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="wrapper ">
                                          <div className={
                                            this.state.physicianPhone !== ""
                                              ? "has-number custom-form-row"
                                              : "custom-form-row"
                                          } >
                                            <PhoneInput
                                              country={"us"}
                                              value={
                                                this.state.physicianPhone
                                              }
                                              disableDropdown
                                              onChange={this.handleUserInputChange.bind(
                                                this,
                                                "physicianPhone"
                                              )}
                                            />

                                            <label className="label-helper py-2 px-1">
                                              Phone number of physician who
                                              wrote recommendation{" "}
                                            </label>
                                            {this.state.skip === false ? (
                                              ""
                                            ) : (
                                              <span className="text-danger">
                                                {
                                                  this.state.errors[
                                                  "physicianPhone"
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="custom-form-row">
                                          <input
                                            className="form-control custom-form-control"
                                            placeholder="Medical Marijuana Recommendation Number"
                                            value={
                                              this.state.fields
                                                .recommendation_quantity
                                            }
                                            onChange={this.handleUserInputChange.bind(
                                              this,
                                              "recommendation_quantity"
                                            )}
                                          />
                                          <label className="label-helper py-2 px-1">
                                            Medical Marijuana Recommendation
                                            Number{" "}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className={
                                          this.state.expiry_date !== null
                                            ? "has-number custom-form-row"
                                            : "custom-form-row"
                                        }>

                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <DatePicker

                                              openTo="date"
                                              placeholder="Expiry Date"
                                              value={this.state.expiry_date}
                                              format="MM-dd-yyyy"
                                              views={["year", "month", "date"]}
                                              onChange={
                                                this.handlePhysicianDateChange
                                              }
                                              disablePast
                                            />
                                          </MuiPickersUtilsProvider>
                                          <label className="label-helper py-2 px-1">
                                            Expiry Date
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="custom-form-row">
                                          <input
                                            className="form-control custom-form-control"
                                            placeholder="Physician or Clinic Verification Website"
                                            value={
                                              this.state.fields.web_address
                                            }
                                            onChange={this.handleUserInputChange.bind(
                                              this,
                                              "web_address"
                                            )}
                                          />
                                          <label className="label-helper py-2 px-1">
                                            Physician or Clinic Verification
                                            Website{" "}
                                          </label>
                                        </div>
                                        <span className="text-danger">
                                          {this.state.errors["web_address"]}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-lg-12">
                                        <h4 className="stepper-form-sub-headings">
                                          Photo of Physician Recommendation
                                        </h4>
                                        <p className="text-success">
                                          TIP: Use your mobile phone to take a
                                          picture of your drivers license.
                                        </p>

                                        <div className="file-drop-area">
                                          <div className="mx-auto d-block">
                                            <img src={Cloud} alt="..." />
                                            <span className="file-msg">
                                              Drag files to upload, or
                                            </span>
                                            <span className="fake-btn">
                                              Choose files
                                            </span>
                                            <input
                                              className="file-input"
                                              type="file"
                                              accept=".jpg, .jpeg, .png"
                                              onChange={(e) =>
                                                this._handleImageChangePhysicalRecomendation(
                                                  e
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="imgPreview" id="image">
                                          {/* <button className="btn btn-success" onClick={this.removeImage}>x</button> */}
                                          {$imagePreview}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="custom-form-row">
                                          <input
                                            className="form-control custom-form-control"
                                            placeholder="Driver's License or I.D. Number"
                                            value={this.state.fields.id_number}
                                            onChange={this.handleUserInputChange.bind(
                                              this,
                                              "id_number"
                                            )}
                                          />
                                          <label className="label-helper py-2 px-1">
                                            Driver's License or I.D. Number{" "}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-lg-12">
                                        <h4 className="stepper-form-sub-headings">
                                          Photo of Drivers License or I.D
                                        </h4>
                                        <p className="text-success">
                                          TIP: Use your mobile phone to take a
                                          picture of your drivers license.
                                        </p>
                                        <div className="file-drop-area">
                                          <div className="mx-auto d-block">
                                            <img src={Cloud} alt="..." />
                                            <span className="file-msg">
                                              Drag files to upload, or
                                            </span>
                                            <span className="fake-btn">
                                              Choose files
                                            </span>
                                            <input
                                              className="file-input"
                                              type="file"
                                              accept=".jpg, .jpeg, .png"
                                              onChange={(e) =>
                                                this._handleImageChangeDriverLicense(
                                                  e
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="imgPreview" id="image">
                                          {/* <button className="btn btn-success" onClick={this.removeImage}>x</button> */}
                                          {$imagePreviewDriver}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-3 offset-lg-4 col-6">
                                        <button
                                          id="first"
                                          type="button"
                                          className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                                          onClick={this.handleBackClick}
                                        >
                                          <i
                                            className="fa fa-arrow-left mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-lg-4 col-6">
                                        {this.state.skip === false ? (
                                          <button
                                            className="btn btn-primary border-radius py-1  px-3"
                                            onClick={this.nextStep}
                                          >
                                            {" "}
                                            Skip
                                          </button>
                                        ) : (
                                          <button
                                            id="third"
                                            type="button"
                                            className="btn btn-primary border-radius py-1  px-3"
                                            onClick={
                                              this.handleNextPhysicianClick
                                            }
                                          >
                                            Next
                                            <i
                                              className="fa fa-arrow-right ml-2"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="bs-stepper">
                            <div className="bs-stepper-header">
                              <div className="d-lg-none d-sm-block">
                                <div
                                  className={`step ${this.state.active === "third"
                                    ? "custom-active"
                                    : ""
                                    }`}
                                  data-target="#test-l-3"
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Medical Cannabis Questions
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div id="test-l-3" className="content">
                                <div className="">
                                  <div className="card-body custom-inner-content custom-medical-inner-content  custom-card-body ">
                                    <h5 className="stepper-form-headings">
                                      Medical Cannabis Questions ~ New Patient
                                    </h5>
                                    <h6 className="stepper-form-paragraph mb-4 ">
                                      It just takes a few minutes to
                                      Registration and get fast, easy access to
                                      care, 24/7.
                                    </h6>
                                    <div className="row mt-3">
                                      <div className="col-lg-12 col-12">
                                        <div className="custom-form-row autocomplete-input" >
                                          <ReactAutocomplete
                                            menuStyle={{
                                              display: this.state.visibility,
                                            }}
                                            items={
                                              this.state.diagnosesList.data
                                            }
                                            getItemValue={(item) => item.name}
                                            shouldItemRender={(item, value) =>
                                              item.name
                                                .toLowerCase()
                                                .indexOf(value.toLowerCase()) >
                                              -1
                                            }
                                            inputProps={{
                                              placeholder: "Primary Diagnosis *",
                                              className:
                                                "form-control custom-form-control",
                                                onFocus: (e) => {
                                                  const input = e.target;
                                                  const value = input.value;
                      
                                                  setTimeout(() => input.select(), 0);
                                                  this.changeMethod(input, value);
                                                },
                                                onBlur: (e, item) => {
                                                  console.log()
                                                  const input = e.target;
                                                  const value = input.value;
                                                  this.changeMethod(input, value, item);
                                                }
                                            }}
                                            renderItem={(
                                              item,
                                              isHighlighted,
                                              styles
                                            ) => (
                                              <div>
                                                <div
                                                  key={item.id}
                                                  style={{
                                                    background: isHighlighted
                                                      ? "#1473E6"
                                                      : "white",
                                                    color: isHighlighted
                                                      ? "white"
                                                      : "#2C2D2F",
                                                  }}
                                                >
                                                  {item?.name}
                                                </div>
                                              </div>
                                            )}
                                            value={this.state.name}
                                            onChange={(e) => {
                                              this.handleChange(
                                                e,
                                                "diagnoseId"
                                              );
                                            }}
                                            onSelect={(value, object) =>
                                              this.handleChangePrimaryDiagnose(
                                                value,
                                                object
                                              )
                                            }
                                          />
                                          <i
                                            className="fa fa-2x fa-question-circle text-primary"
                                            aria-hidden="true"
                                            title="What is your primary diagnosis?"
                                          ></i>
                                          <label className=" label-helper py-2 px-1">
                                            Principal / Primary Diagnosis{" "}
                                            <strong className="text-danger">
                                              *
                                            </strong>
                                          </label>
                                          <span className="text-danger">
                                            {this.state.errors["name"]}
                                          </span>
                                        </div>

                                        {this.state.hideShow === true ? (
                                          <p className="pull-right mb-0">
                                            {this.state.showPrimaryDiagnose ===
                                              true ? (
                                              <span className="text-danger">
                                                {this.state.errors["notFound"]}
                                              </span>
                                            ) : (
                                              <span>
                                                Please Choose any from Suggested
                                                Diagnosis or
                                              </span>
                                            )}
                                            <Link
                                              href={this.state.url}
                                              onClick={this.addModalDiagnose.bind(
                                                this
                                              )}
                                            >
                                              {" "}
                                              Add New Diagnose
                                            </Link>{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        <DiagnoseModal
                                          addModalDiagnoseShow={
                                            this.state.addModalDiagnoseShow
                                          }
                                          onHideModal={
                                            this.addModalDiagnoseClose
                                          }
                                          name={this.state.name}
                                          onUpdate={this.diagnoseSearchStatus}
                                          diagnoseId={this.diagnoseId}
                                          initiliazeDiagnoseList = {this.initiliazeDiagnoseList}
                                        />
                                      </div>
                                    </div>
                                    {this.state.isCancer === true ? (
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="custom-form-row">
                                            <select
                                              value={this.state.cancerStage}
                                              className={
                                                this.state.cancerStage === "" ||
                                                  this.state.cancerStage ===
                                                  null ||
                                                  this.state.cancerStage ===
                                                  undefined
                                                  ? "form-control custom-form-control disabled-option"
                                                  : "form-control custom-form-control"
                                              }
                                              onChange={(e) => {
                                                this.handleChange(e, "cancer");
                                              }}
                                            >
                                              <option value="">
                                                {" "}
                                                Select Stage of Cancer
                                              </option>
                                              {stageCancer.map(
                                                (option, idx) => (
                                                  <option
                                                    key={idx}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                )
                                              )}
                                            </select>

                                            <label className="label-helper py-2 px-1">
                                              Stage of Cancer{" "}
                                              <strong className="text-danger">
                                                *
                                              </strong>
                                            </label>
                                            <span className="text-danger">
                                              {
                                                this.state.errors[
                                                "canncerStage"
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <div className="row mb-4">
                                      <div className="col-lg-12">
                                        <div className="custom-form-row">
                                          <select
                                            className={
                                              this.state.cannabis === "" ||
                                                this.state.cannabis === null ||
                                                this.state.cannabis === undefined
                                                ? "form-control custom-form-control disabled-option"
                                                : "form-control custom-form-control"
                                            }
                                            onChange={(e) => {
                                              this.handleChange(e, "cannabis");
                                            }}
                                          >
                                            <option value="">
                                              {" "}
                                              What is your previous experience
                                              with cannabis?
                                            </option>
                                            {cannabis.map((option) => (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                          </select>

                                          <label className="label-helper py-2 px-1">
                                            What is your previous experience
                                            with cannabis?{" "}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.showCannabisFields === true ? (
                                      <div className="row mb-4">
                                        <div className="col-lg-12">
                                          <div className="custom-form-row">
                                            <select
                                              className="form-control custom-form-control "
                                              onChange={(e) => {
                                                this.handleChange(
                                                  e,
                                                  "tolerance"
                                                );
                                              }}
                                            >
                                              <option value="">
                                                {" "}
                                                How would you describe your
                                                tolerance?
                                              </option>
                                              {tolerance.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>

                                            <label className="label-helper py-2 px-1">
                                              How would you describe your
                                              tolerance?{" "}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <div className="row mb-1">
                                      <div className="col-lg-12">
                                        <h4 className="stepper-form-sub-headings">
                                          Please list your current
                                          pharmaceuticals and supplements
                                        </h4>
                                        <p className="text-success">
                                          Type one per line, press (+) for
                                          additional entries.
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-4 col-4">
                                        <label className="text-primary stepper-form-sub-headings">
                                          Medication Name
                                        </label>
                                      </div>
                                      <div className="col-lg-3 col-3">
                                        <label className="text-primary stepper-form-sub-headings">
                                          Dose
                                        </label>
                                      </div>
                                      <div className="col-lg-3 col-3">
                                        <label className="text-primary stepper-form-sub-headings">
                                          Frequency
                                        </label>
                                      </div>
                                    </div>

                                    {this.state.medications.map(
                                      (value, index) => {
                                        return (
                                          <div
                                            key={`${value.id}-${index}`}
                                            className="row mb-2"
                                          >
                                            <div className="col-lg-4 col-4 ">
                                              {this.state
                                                .hideShowMedicationSelect[index]
                                                .selectValue === true ? (
                                                <div className="custom-form-row autocomplete-input">
                                                  <ReactAutocomplete
                                                    menuStyle={{ display: this.state.visibility }}
                                                    items={this.state.medicationList.data}
                                                    getItemValue={(item) => item.name}
                                                    shouldItemRender={(item, value) =>
                                                      item.name.toLowerCase().indexOf(value.toLowerCase()) >
                                                      -1
                                                    }
                                                    inputProps={{
                                                      className: "form-control custom-form-control",
                                                    }}
                                                    renderItem={(item, isHighlighted, styles) => (
                                                      <div>
                                                        <div
                                                          key={item.id}
                                                          style={{
                                                            background: isHighlighted ? "#1473E6" : "white",
                                                            color: isHighlighted ? "white" : "#2C2D2F",
                                                          }}
                                                        >
                                                          {item?.name}
                                                        </div>
                                                      </div>
                                                    )}
                                                    value={value.name || ""}
                                                    onChange={(e) => this.handleChangeKey(e, index)}
                                                    onSelect={(value, object) =>
                                                      this.handleInputNameChange(value, object, index)
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              
                                            </div>

                                            <div className="col-lg-3 col-3">
                                              <input
                                                className="form-control custom-form-control py-4"
                                                value={value.dose || ""}
                                                onChange={(e) =>
                                                  this.handleInputDoseChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-lg-3 col-3">
                                              <input
                                                className="form-control custom-form-control py-4"
                                                value={value.frequency || ""}
                                                onChange={(e) =>
                                                  this.handleInputFrequencyChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          
                                            <div className="col-lg-1 col-2">
                                              <label></label>
                                              <div className="custom-btn">
                                                <button
                                                  onClick={this.handleRemoveSocial.bind(
                                                    this,
                                                    index
                                                  )}
                                                  className="btn btn-danger child-btn border-radius mb-1"
                                                >
                                                  <i className="fa fa-minus"></i>
                                                </button>
                                              </div>
                                            </div>
                                            {this.state.hideShowMedication[
                                              index
                                            ]?.value === true ? (
                                              <p className="pull-right ml-3">
                                                <span className="text-danger">
                                                  Please choose any from
                                                  Suggested Medication or
                                                </span>
                                                <Link
                                                  onClick={this.addModalMedication.bind(
                                                    this
                                                  )}
                                                >
                                                  {" "}
                                                  Add New Medication
                                                </Link>{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            <Medication
                                              addModalMedicationShow={
                                                this.state
                                                  .addModalMedicationShow
                                              }
                                              hideModal={
                                                this.addModalMedicationClose
                                              }
                                              user_id={this.state.user_id}

                                              medicationId= {this.medicationId}
                                              medicationName = {this.state.medicationName}
                                              onUpdate={this.medicationSearchStatus}
                                              initiliazeMedicationList = {this.initiliazeMedicationList}
                                              medications = {this.state.medications}   
                                              index = {this.state.index}    
                                              hideShowMedication = {this.state.hideShowMedication}
                                            />
                                          </div>
                                        );
                                      }
                                    )}

                                    <div className="row mb-4">
                                      <div className="col-lg-1 col-1">
                                        <button
                                          // disabled={this.state.addButtonDisabled === true ? true : false}
                                          className="btn btn-primary child-btn border-radius pull-left plus-button"
                                          onClick={this.addMedicationList}
                                        >
                                          <i className="fa fa-plus"></i>
                                        </button>
                                      </div>
                                    </div>

                                    <div className="row mb-4">
                                      <div className="col-lg-12 col-12 mt-2">
                                        <h4 className="stepper-form-sub-headings">
                                          What is your objective? <strong className="text-danger">*</strong>
                                        </h4>

                                        <div className="row">
                                          <div
                                            className="objective-input"
                                            data-toggle="buttons"
                                          >
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective
                                                    ?.antiCancerTherapy
                                                }
                                                value={"antiCancerTherapy"}
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "antiCancerTherapy"
                                                )}
                                              />
                                              Anti-cancer Therapy
                                            </label>
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective
                                                    ?.manageSideEffectsofChemo
                                                }
                                                value={
                                                  "manageSideEffectsofChemo"
                                                }
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "manageSideEffectsofChemo"
                                                )}
                                              />
                                              Manage Side Effects of Chemo
                                            </label>
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective
                                                    ?.mentalHealth
                                                }
                                                value={"mentalHealth"}
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "mentalHealth"
                                                )}
                                              />
                                              Mental Health
                                            </label>
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective
                                                    ?.painRelief
                                                }
                                                value={"painRelief"}
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "painRelief"
                                                )}
                                              />
                                              Pain Relief
                                            </label>
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective
                                                    ?.pharmaceuticalReplacement
                                                }
                                                value={
                                                  "pharmaceuticalReplacement"
                                                }
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "pharmaceuticalReplacement"
                                                )}
                                              />
                                              Pharmaceutical Replacement
                                            </label>
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective
                                                    ?.seizureControl
                                                }
                                                value={"seizureControl"}
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "seizureControl"
                                                )}
                                              />
                                              Seizure Control
                                            </label>
                                            <label className="btn btn-outline-primary border-radius ml-1">
                                              <input
                                                type="checkbox"
                                                id="options"
                                                name="options"
                                                autoComplete="off"
                                                checked={
                                                  this.state.objective?.other
                                                }
                                                value={"other"}
                                                onChange={this.handleCheckboxes.bind(
                                                  this,
                                                  "other"
                                                )}
                                              />
                                              Other
                                            </label>
                                          </div>

                                        </div>
                                        {this.state.objectiveFlag === true ?
                                          <span className="text-danger"> This field is Required </span>
                                          : ""}

                                      </div>
                                    </div>

                                    {this.state.showField === true ? (
                                      <div className="row mb-4">
                                        <div className="col-lg-12 col-12">
                                          <div className="custom-form-row">
                                            <input
                                              className="form-control custom-form-control"
                                              placeholder="Your Objective"
                                              value={
                                                this.state.fields.objectiveOther
                                              }
                                              onChange={this.handleUserInputChange.bind(
                                                this,
                                                "objectiveOther"
                                              )}
                                            />
                                            <label className="label-helper py-2 px-1">
                                              Your Objective{" "}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}


                                    <div className="row mb-4">
                                      <div className="col-lg-12">
                                        <h4 className="stepper-form-sub-headings">
                                          Tell us anything else you would like
                                          us to know: (optional)
                                        </h4>
                                        <textarea
                                          className="form-control custom-form-control"
                                          rows="4"
                                          value={
                                            this.state.medicalCannabisQuestions[
                                            "note"
                                            ]
                                          }
                                          onChange={this.handleUserInputChange.bind(
                                            this,
                                            "note"
                                          )}
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="row mb-3">
                                      <div className="col-lg-12">
                                        <h4 className="stepper-form-sub-headings">
                                          Terms & Conditions <strong className="text-danger">*</strong>
                                        </h4>

                                        <div className="d-flex">
                                          <a href={this.state.url}>
                                            Privacy Policy -&nbsp;
                                          </a>
                                          <a href={this.state.url}>
                                            &nbsp;Terms of Service&nbsp;
                                          </a>
                                          <a href={this.state.url}>
                                            &nbsp;- HIPAA Statement
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-lg-12">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                            value={
                                              this.state.fields.termsCondition
                                            }
                                            onChange={this.handleCheckboxes.bind(
                                              this,
                                              "termsCondition"
                                            )}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="exampleCheck1"
                                          >
                                            I agree to the Privacy Policy, Terms
                                            of Service, and HIPAA Statement
                                          </label>
                                        </div>
                                        <span className="text-danger">
                                          {this.state.errors["termsCondition"]}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-3 offset-lg-4 col-6">
                                        <button
                                          id="second"
                                          type="button"
                                          className="btn btn-white border-radius py-1 px-3 custom-back-btn "
                                          onClick={this.handleBackClick}
                                        >
                                          <i
                                            className="fa fa-arrow-left mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-lg-4 col-6">
                                        <button
                                          className="btn btn-primary border-radius py-1  px-3"
                                          type="button"
                                          disabled={this.state.disabled === true ? true : false}
                                          onClick={this.onSubmit}
                                        >
                                          Submit{" "}
                                          <i
                                            className="fa fa-location-arrow"
                                            aria-hidden="true"
                                          ></i>
                                        </button>

                                        {/* <SuccessModal
                                          onSubmit={this.navigation}
                                          show={this.state.successModalShow}
                                          headingText="Thank You For Registering"
                                          innerHeadingText="Welcome to Octopi Wellness"
                                          hide={true}
                                        ></SuccessModal> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="group-card card-border ml-3 d-lg-none d-sm-block">
              <div className="card-body">
                <div className="d-flex custom-group">
                  <img
                    src={GroupLogo}
                    className="rounded mx-auto d-block"
                    alt="..."
                  />

                  <h3 className="text-white text-center">
                    <strong> HIPAA COMPLIANT</strong>
                  </h3>
                </div>

                <p className="text-white text-center">
                  In compliance with the U.S. Health Insurance Portability and
                  Accountability Act of 1996 (HIPAA), Octopi Wellness takes
                  rigorous steps to protect your privacy whether consulting a
                  doctor, selecting a medicine, or browsing our site.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
