import React, { PureComponent } from "react";

export class Nutrition extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }

  render() {
    const { patientProfile } = this.state;

    return (
      <div className="card-body custom-inner-content disabled-input ">
        <h5 className="stepper-form-headings ">Nutrition</h5>

        {patientProfile?.Nutrition === null ? (
          <h4 className="font-italic">No Nutrition Information Added</h4>
        ) : (
          <>
            <div className="row mb-4">
              <div className="col-lg-12 col-12">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <span className="field-label">How do you rate your diet?</span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.rateDiet}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          Do you have any Diet Restrictions?
                        </span>
                        <span className="field-text">
                          {" "}
                         
                                                {patientProfile?.Nutrition?.dietaryRestrictions === true
                        ? "Yes"
                        : patientProfile?.Nutrition?.dietaryRestrictions === null
                        
                        ? <p className="font-italic px-4">Diet Restrictions not added</p>
                        : "No"}
                        </span>
                      </td>
                    </tr>
                    {patientProfile?.Nutrition?.dietaryRestrictions === true ? (
                      <tr>
                        <td>
                          <span className="field-label">
                            Current Dietary Restrictions:
                          </span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Nutrition
                                ?.currentDietaryRestrictions
                            }
                          </span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td>
                        <span className="field-label">
                          Do you have any food allergies?
                        </span>
                        <span className="field-text">
                          {" "}
                          

{patientProfile?.Nutrition?.foodAllergies === true
                        ? "Yes"
                        : patientProfile?.Nutrition?.foodAllergies === null
                       
                        ?  <p className="font-italic px-4">Food Allergies not Added</p>
                        : "No"}
                        </span>
                      </td>
                    </tr>
                    {patientProfile?.Nutrition?.foodAllergies === true ? (
                      <tr>
                        <td>
                          <span className="field-label">
                            Food Allergies Description:
                          </span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Nutrition
                                ?.foodAllergiesDescription
                            }
                          </span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td>
                        <span className="field-label">
                          Have you made any recent changes to your diet?
                        </span>
                        <span className="field-text">
                          {" "}
                          

{patientProfile?.Nutrition?.recentDietChanges === true
                        ? "Yes"
                        : patientProfile?.Nutrition?.recentDietChanges === null
                        ? <p className="font-italic px-4"> Recent Diet Changes not added</p>
                        : "No"}
                        </span>
                      </td>
                    </tr>
                    {patientProfile?.Nutrition?.recentDietChanges === true ? (
                      <tr>
                        <td>
                          <span className="field-label">
                            Recent Diet Changes:
                          </span>
                          <span className="field-text">
                            {" "}
                            {patientProfile?.Nutrition?.recentDietChangesList}
                          </span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td>
                        <span className="field-label">
                          What do you snack on?
                        </span>
                        <span className="field-text">
                          {" "}
                         

{patientProfile?.Nutrition?.snackDuringDay === true
                        ? "Yes"
                        : patientProfile?.Nutrition?.snackDuringDay === null
                        ? <p className="font-italic px-4">Snack During Day not added</p>
                        : "No"}
                        </span>
                      </td>
                    </tr>
                    {patientProfile?.Nutrition?.snackDuringDay === true ? (
                      <tr>
                        <td>
                          <span className="field-label">Day Snack:</span>
                          <span className="field-text">
                            {" "}
                            {patientProfile?.Nutrition?.daySnackList}
                          </span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td>
                        <span className="field-label">
                          {" "}
                          Days per week Patient Eat BreakFast:
                        </span>
                        <span className="field-text">
                          {" "}
                          {
                            patientProfile?.Nutrition?.nutritionDiets[0]
                              ?.breakfast
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          {" "}
                          Days per week Patient Eat Lunch:
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.nutritionDiets[0]?.lunch}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          Days per week Patient Eat Dinner:
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.nutritionDiets[0]?.dinner}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          Do you generally cook your own meals?
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.cookOwnMeals === true
                            ? "Yes"
                            : "No"}

                            
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mb-4 ml-2">
              <div className="col-lg-12 col-12 mt-2">
                <h4 className="stepper-form-sub-headings">
                  How are you usually eating your meals?
                </h4>
              {patientProfile?.Nutrition?.eatingManners?.relaxed === true ? <li> Relaxed</li> : "" }
              {patientProfile?.Nutrition?.eatingManners?.rushed === true ? <li> Rushed</li> : "" }
              {patientProfile?.Nutrition?.eatingManners?.standingFront === true ? <li>  Standing in front of TV</li> : "" }
              {patientProfile?.Nutrition?.eatingManners?.seatedTable === true ? <li>Seated at the Table</li> : "" }
              {patientProfile?.Nutrition?.eatingManners?.inTheCar === true ? <li>In the car</li> : "" }
              {patientProfile?.Nutrition?.eatingManners?.alone === true ? <li>Alone</li> : "" }
              {patientProfile?.Nutrition?.eatingManners
                        ?.withFamilyAndFriends === true ? <li> With family and friends</li> : "" }

{patientProfile?.Nutrition?.eatingManners?.relaxed === false && 
patientProfile?.Nutrition?.eatingManners?.rushed === false && 
patientProfile?.Nutrition?.eatingManners?.standingFront === false && 
patientProfile?.Nutrition?.eatingManners?.seatedTable === false  && 
patientProfile?.Nutrition?.eatingManners?.inTheCar === false  && 
patientProfile?.Nutrition?.eatingManners?.alone === false  && 
patientProfile?.Nutrition?.eatingManners?.withFamilyAndFriends === false}
           <p className="font-italic px-4">Your Meal not Added</p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12 col-12">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <span className="field-label">
                          Do you eat a wide variety Of Foods:
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.varietyOfFoods}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          How often do you consume sugar?
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.sugarConsumptionFrequency}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          Do you consider yourself?
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.Nutrition?.weightPattern}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            
            <div className="row mb-4 ml-2">
            <h4 className="stepper-form-sub-headings mb-4 ml-3">
              How many of the following do you drink per week?
            </h4>
              <div className="col-lg-12 col-12">
                <table class="table table-stripped bg-white">
                  <tbody>
                    {/* <tr>
                      <td className="table-td-th" >
                        <table className="table table-striped">
 
                       
                        </table>
                      </td>
                    </tr> */}
                       
                       <tr className="bg-grey">
                            <td>
                              <span className="field-label">Alcohol:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.alcohol}
                              </span>
                            </td>
                            <td>
                              <span className="field-label">Coffee:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.coffee}
                              </span>
                            </td>
                           
                          </tr>
                          <tr>
                          <td>
                              <span className="field-label">Decaf Coffee:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.decafCoffee}
                              </span>
                            </td>
                            <td> 
                              <span className="field-label">Diet Drinks:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.dietDrinks}
                              </span>
                            </td>
                          </tr>
                          <tr className="bg-grey">
                            <td>
                              <span className="field-label">Soft Drinks:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.softDrinks}
                              </span>
                            </td>
                            <td>
                              <span className="field-label">Black Tea:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.blackTea}
                              </span>
                            </td>
                          </tr>
                          <tr>
                          <td>
                              <span className="field-label">Green Tea:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.greenTea}
                              </span>
                            </td>
                            <td>
                              <span className="field-label">Herbal Tea:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.herbalTea}
                              </span>
                            </td>
                          </tr>
                          <tr className="bg-grey">
                            <td>
                              <span className="field-label">Sport Drinks:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.sportDrinks}
                              </span>
                            </td>
                            <td> 
                              <span className="field-label">Fruit Juice:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.fruitJuice}
                              </span>
                            </td>
                          </tr>
                          <tr>
                          <td>
                              <span className="field-label">Water:</span>
                              <span className="field-text">
                                {" "}
                                {patientProfile?.Nutrition?.water}
                              </span>
                            </td>
                          </tr>
                       
                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Nutrition;
