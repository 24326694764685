import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { environment } from '../../../environment';
import MetroCheckmark from "../../../assets/images/metro-checkmark.svg";
import MetroCross from "../../../assets/images/metro-cross.svg";
import moment from "moment";



export class Notes extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            note: "",
            disabled: true,
            token: localStorage.getItem("token"),
            patientID: this.props.userID,
            sendToPationt: false,
            hideShow: [{
              open: true
            }],
            notes:[],
            visible: 5,

        }
        
    }
    
      componentDidMount(){
        this.getPatientConsultationNotes(this.state.patientID);
      }   
      handleUserInputChange(field, e) {
          this.setState({ note: e.target.value ,disabled: false});
        
      }
      handleCheckboxes(field, e) {
        this.setState({ sendToPationt: e.target.checked, disabled: false });
      }
      createNotes = async () =>{
        const params = {
          note: this.state.note,
          patientId: this.state.patientID,
          sendToPationt: this.state.sendToPationt
        }
        await axios
          .post(environment.endPoint + "physician/notes", params, {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {   
                  
              this.setState({
                disabled: true,
                note: "",
              })
            
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              this.getPatientConsultationNotes(this.state.patientID)
            }
          })
          .catch((e) => {});
      };
      loadMore = () => {
        this.setState((prev) => {
          return { visible: prev.visible + 2 };
        });
      }
      getPatientConsultationNotes = async (patientID) =>{
        await axios
          .get(environment.endPoint + "physician/notes?patientId=" + patientID , {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200 || response.status !== undefined) {
                   
              this.setState({
                notes: response.data.data
              })
              this.getNotes(response.data.data)
            }
          })
          .catch((e) => {
          });
      }

      getNotes(response) {
        if (response.length > 0) {
          response.forEach((value, index) => {
            let x = this.state.hideShow;
            x.push({ index: index, open: false, className: "card-link" });
            this.setState({
              hideShow: x,
            });
          });
        }
      }


      onClickAccordion = (index) => {
        let select = this.state.hideShow;
        select[index].open = !select[index].open;
    
        if (select[index]?.open === true) {
          this.setState(
            {
              hideShow: select,
            },
            () => {}
          );
        } else if (select[index]?.open === false) {
          select[index].className = "";
          this.setState(
            {
              hideShow: select,
            },
            () => {}
          );
        }
      };

    render() {
        return (
          
            <div className="physicain-patient-profile">
              <div className="pc-notes">
               <div className="container">
               <div className="card">
              <div className="card-body ">
                <form>
                  <div className="form-group">
                    <label for="comment">
                      <h3>Patient and Consultation Notes</h3>
                    </label>
                    <textarea
                      className="form-control notes"
                      rows="5"
                      id="comment"
                      name="text"
                      value= {this.state.note}
                      placeholder="Enter your notes here...."
                      onChange={this.handleUserInputChange.bind(this, "note")}
                    ></textarea>
                  </div>
                  <div className="d-flex mt-4">
                    <div className="p-2 mr-auto ml-3">
                      <label className="form-check-label" for="check2">
                             <input type="checkbox" className="form-check-input"
                              checked={this.state.sendToPationt}
                              onChange={this.handleCheckboxes.bind(
                                this,
                                "sendToPationt"
                              )}
                             id="check2" name="vehicle2" value="something" />
                             <h5 className=" btn-all-notes mt-1">Send Notes to Patient?</h5>
                           </label>
                    </div>
                    <div className="p-2 padding">
                      <button type="button" disabled={this.state.disabled === true ? true : false} className="btn btn-save-notes" onClick={this.createNotes}>
                        Save Notes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container">
          
        <label for="comment">
                      <h3> All Patient and Consultation Notes</h3>
                    </label>

                    {this.state.notes
            .slice(0, this.state.visible)
            .map((value, index) => (
           <div className="add-symptoms-body">
                                 <div className="card profile-symptoms fade-in">
                    <div className="card-header">
                      <div className="row">
                        <div class="col-md-8">
                        <Link
                            className={
                              this.state.hideShow[index]?.open === true
                                ? "collapsed-minus"
                                : "card-link"
                            }
                            onClick={(e) => this.onClickAccordion(index)}
                          ></Link>

                          <div className="header-date">
                            <p>
                            {moment(value.createdAt)
                                .local()
                                .format("MMMM-DD-YYYY")}{" "}
                              ~ {moment(value.createdAt).local().format("LTS")}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="padding-fix ml-5">
                            <p className="header-status"> Send to Patients:</p>
                            {
                              value?.sendToPationt === true ?  <button className="btn btn-primary physician-notes-btn" title="Yes">
                              <img src={MetroCheckmark} alt=""/>
                                </button> :   <button className="btn btn-secondary physician-notes-btn" title="No">
                            <img src={MetroCross} alt=""/>
                              </button>
                            }
                           
                            
                          </div>
                        </div>
   
                      </div>
                     
                    </div>
                    {this.state.hideShow[index]?.open === true ? (
                      <div>
                        <div className="card-body">
 

                            <div className="row">
                              <div className="col-lg-12 col-12">
                                <p className="heading-cmnts">
                                 <p className="notes-heading "> Notes: </p>{" "}
                                 <p className=" notes-paragraph">
                                {value?.note}
                                </p>
                                </p>
                                
                              </div>
                            </div>
                       
                            
                        </div>
                      </div>
                   ) : (
                    ""
                  )}
                  </div>
               
              </div>
           
           ))}
 {this.state.visible < this.state.notes.length && (
            <div class="container load-more">
              <div class="row">
                <div class="col col-lg-7 offset-lg-3 text-center">
                  <button
                    onClick={this.loadMore}
                    type="button"
                    className="btn btn-primary submit mb-4"
                  >
                    Load more
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
   
            </div>
        )
    }
}

export default Notes
