import React, { Component } from "react";
// import { DatePicker } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { environment } from "../../../../environment";
const historyTreatments = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
export class TreatmentHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      treatments: [
        {
          name: "",
          date: null,
        },
      ],
      hideShowTreatmentHistory: [
        {
          index: 0,
          value: false,
        },
      ],
      hideShowTreatmentHistorySelect: [
        {
          index: 0,
          selectValue: true,
        },
      ],
      showField: false,
      dateString: "",
      disabled: true,
      previousTreatment: "",
      isFormValid: false,
      validationDate: "",
      validationName: "",
      validationIndex: "",
      showDate: false,
      passValidation: false,
      previousTreatmentFlag: "",
      index: 0,
      showHide: false,
      patientStatus: this.props.status,
      userID:  this.props.userID,
      token: localStorage.getItem("token"),
      treatmentIndex: 0
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.handleInputNameChange = this.handleInputNameChange.bind(this);
    this.addTreamentHistoryList = this.addTreamentHistoryList.bind(this);
    this.handleInputMonthChange = this.handleInputMonthChange.bind(this);
  }

  addTreamentHistoryList(e) {
    e.preventDefault();
    let array = this.state.treatments;
    array.push({ id: array.length + 1, name: "", date: null });
    let x = this.state.hideShowTreatmentHistory;
    x.push({ index: x.length + 1, value: false });
    let select = this.state.hideShowTreatmentHistorySelect;
    select.push({ index: select.length + 1, selectValue: true });
    this.setState({
      treatments: array,
      hideShowTreatmentHistory: x,
      hideShowTreatmentHistorySelect: select,
      disabled: false
    });
  }
  handleInputMonthChange(date, dateString, index) {    
    let nextMonthData = this.state.treatments.slice();
    const nextDate = moment(date, "YYYY-MMMM");
    nextMonthData[index].date =  nextDate.toLocaleString(); 
    this.setState({
      treatments: nextMonthData,
      validationDate: nextMonthData[index].date,
      disabled: false,
      index:index
    });
    this.handleValidation(index)
  }

  handleInputNameChange(e, idx) {
    let nexttreatmentHistoryData = this.state.treatments.slice();
    nexttreatmentHistoryData[idx].name = e.currentTarget.value;
    this.setState({
      treatments: nexttreatmentHistoryData,
      validationName: nexttreatmentHistoryData[idx].name,
      disabled: false,
      index: idx
    });
    this.handleValidation()
  }

  handleUserInputChange(field, e) {
    if (e.target.value === "yes") {
      this.setState({
        showField: true,
        previousTreatment: e.target.value,
        previousTreatmentFlag: true,
        disabled: false
      });
    } else if (e.target.value === "no") {
      this.setState({
        showField: false,
        previousTreatment: e.target.value,
        previousTreatmentFlag: false,
        disabled: false
      });
    } else if (e.target.value === "") {
      this.setState({
        showField: false,
        previousTreatment: null,
        previousTreatmentFlag: null,
        disabled: false
      });
    }
  }
  remove(idx, e) {
    let treatments = this.state.treatments;
    treatments.splice(idx, 1);
    this.setState({ treatments: treatments ,      disabled: false
    });
        let x = this.state.hideShowTreatmentHistory;
          x[idx].value = false;
    this.setState({
      hideShowTreatmentHistory:  x,
      disabled: false
    })
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
    var myButtonClasses = document.getElementById("treatment").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
  }
  componentDidMount() {
    this.getTreatmentHistory();
  }

  getTreatmentHistory = async () => {
    const data = this.state.patientStatus === "physician" ? "intake/treatmentHistory?userId="+this.state.userID : "intake/treatmentHistory";
    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        const data = response.data.data.treatments;
        if (data.length === 0) {
          if (response.data.data.previousTreatment === true) {
            this.setState({
              treatments: this.state.treatments,
              previousTreatment: "yes",
              showField: true,
              previousTreatmentFlag: true,
            });
          } else if (response.data.data.previousTreatment === null) {
            this.setState({
              treatments: this.state.treatments,
              previousTreatment: "",
              previousTreatmentFlag: null,
            });
          } else if (response.data.data.previousTreatment === false) {
            this.setState({
              treatments: this.state.treatments,
              previousTreatment: "no",
              previousTreatmentFlag: false,
            });
          }
        }
        if (data.length > 0) {
          if (data !== undefined) {
            if (response.data.data.previousTreatment === true) {
              
              const newTreament = []

              data.forEach((data,index)=> {
                let treament = {};

                treament.name = data.name;
                treament.date = moment(data.date, "YYYY-MMMM").toLocaleString() ;
                newTreament.push(treament)
              });
              this.setState({
                treatments: newTreament ,
                previousTreatment: "yes",
                showField: true,
                previousTreatmentFlag: true,
              });
            } else if (response.data.data.previousTreatment === false) {
              this.setState({
                previousTreatment: "no",
                previousTreatmentFlag: false,
              });
            } else if (response.data.data.previousTreatment === null) {
              this.setState({
                previousTreatment: "",
                previousTreatmentFlag: null,
              });
            }
            if (data !== undefined) {
              if (data.length > 0) {
                data.forEach((data, index) => {
                  let x = this.state.hideShowTreatmentHistory;
                  let select = this.state.hideShowTreatmentHistorySelect;
                  if (index > 0) {
                    select.push({ index: index, selectValue: true });
                    x.push({ index: index, value: false });
                  }
                  this.setState(
                    {
                      hideShowTreatmentHistory: x,
                      hideShowTreatmentHistorySelect: select,
                      // validationDate: data.date,
                      // validationName: data.name
                    },
                    () => {}
                  );
                });
              }
            }
          }
        }
      })
      .catch((e) => {});
  }


  handleValidation() {
    let field = this.state;
    let errors = {};
    let formIsValid = true;
    this.state.treatments.forEach((data, index) => {
 

      if (field["validationDate"] !== "" || data.date !== null) {
        formIsValid = false;
        errors["validationDate"] = "This field is required";
        let x = this.state.hideShowTreatmentHistory;
        x[index].value = true;
        this.setState(
          {
            hideShowTreatmentHistory: x,
            passValidation: false
          },
          () => {}
        );
      } else {
        formIsValid = true;
        errors["validationDate"] = "";
      }

        if(data.name !== "") {
          formIsValid = true;

          let x = this.state.hideShowTreatmentHistory;
          x[index].value = false;
          let select = this.state.hideShowTreatmentHistorySelect;
          x[index].selectValue = true;
          this.setState(
            {
              hideShowTreatmentHistory: x,
              hideShowTreatmentHistorySelect: select,
              passValidation: true
            },
            () => {}
          );
        } else {
        }
    });

    if(this.state.treatments[this.state.index].name !== "") {

    }


    this.setState({ errors: errors });
    return formIsValid;
  }
  onSubmit = async(e) => {
    e.preventDefault();
    const treatmentHistory = this.state.fields;
    treatmentHistory.previousTreatment = this.state.previousTreatmentFlag;
    if(this.state.patientStatus === "physician"){
      treatmentHistory.userId = this.state.userID;
    }

    const newTreament = [];
    this.state.treatments.forEach((data, index) => {
      let object = {};
      object.name = data.name;
      object.date = moment(data.date).format('YYYY-MMMM');
      newTreament.push(object)
    });
    if (this.handleValidation()) {
      treatmentHistory.treatments = newTreament
 
      this.setState({ treatmentHistory: treatmentHistory });

      await axios.post(environment.endPoint+"intake/treatmentHistory", treatmentHistory, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
          if (e.response !== undefined) {
            if (e.response.status === 400) {
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        });
    }
  }

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true
    })
    var myButtonClasses = document.getElementById("treatment").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings ">
            Treatment History
          </h5>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.previousTreatment}
                  className={
                    this.state.previousTreatment === "" ||
                    this.state.previousTreatment ===  null ||
                    this.state.previousTreatment ===  undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(
                    this,
                    "previousTreatment"
                  )}
                >
                  <option value="">
                    {" "}
                    Have you had any surgeries/treatments?
                  </option>
                  {historyTreatments.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Have you had any surgeries/treatments?{" "}
                </label>
              </div>
            </div>
          </div>
          {this.state.showField === true ? (
            <div>
              <label className="text-center  stepper-form-sub-headings">
                List any past Surgeries and Treatments:
              </label>
              <div className="row">
                <div className="col-lg-6 col-6">
                  <label className="text-primary stepper-form-sub-headings">
                    Surgery/Treatment
                  </label>
                </div>
                <div className="col-lg-6 col-6">
                  <label className="text-primary stepper-form-sub-headings">
                    Select Month & Year
                  </label>
                </div>
              </div>
              {this.state.treatments.map((value, index) => {
                return (
                  <div key={`${value.id}-${index}`} className="row mb-2">
                    <div className="col-lg-6 col-6">
                      <input
                        key={index}
                        type="text"
                        placeholder="Enter Surgery/Treatment Name"
                        value={value?.name || ""}
                        className="form-control custom-form-control"
                        onChange={(e) => this.handleInputNameChange(e, index)}
                      />
                      
                      {this.state.hideShowTreatmentHistory[index]?.value ===
                      true ? (
                        <span className="text-danger">
                          Please Enter Surgery/Treatment
                      </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-5 col-6">
                      {this.state.hideShowTreatmentHistorySelect[index]
                        ?.selectValue === true ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            key={index}
                            
                            className="form-control custom-form-control"
                            placeholder="Month Year"
                            value={value.date}
                            views={["year", "month"]}
                            onChange={(date, dateString) =>
                              this.handleInputMonthChange(
                                date,
                                dateString,
                                index
                              )
                            }
                            disableFuture
                          />
                        </MuiPickersUtilsProvider>
                    
                     ) : (
                       
                        ""
                      )}
                    </div>

                    {this.state.treatments.length > 1 ? (
                      <div className="col-lg-1 col-2">
                        <label></label>
                        <div className="custom-btn">
                          <button
                            onClick={this.remove.bind(this, index)}
                            className="btn btn-danger child-btn border-radius mb-1"
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
              <div className="row mb-4">
                <div className="col-lg-1 col-1">
                  <button
                    className="btn btn-primary child-btn border-radius pull-left plus-button"
                    onClick={this.addTreamentHistoryList}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true: false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default TreatmentHistory;
