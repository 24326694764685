import React, { Component } from "react";
import { toast } from "react-toastify";
import Validation from "../../common/util";
import PhoneInput from "react-phone-input-2";
import Verify from "../../auth/registration/modal/Verify";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import authServices from "../../../services/authServices";
import { environment } from "../../../environment";
import axios from "axios";

const prefixSuffix = [
  { value: "ms", label: "Ms" },
  { value: "miss", label: "Miss" },
  { value: "mrs", label: "Mrs" },
  { value: "mr", label: "Mr" },
  { value: "master", label: "Master" },
  { value: "fr", label: "Fr (Father)" },
  { value: "dr", label: "Dr (Doctor)" },
  { value: "atty", label: "Atty (Attorny)" },
  { value: "prof", label: "Prof (Professor)" },
  { value: "hon", label: "Hon (Honourable)" },
  { value: "press", label: "Pres (President)" },
  { value: "gov", label: "Gov (Governor)" },
  { value: "coach", label: "Coach" },
  { value: "ofc", label: "ofc (Officer)" },
];
export class PersonalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        address: this.props.profileDetail.address,
        addressTwo: this.props.profileDetail.addressTwo,
        email: this.props.profileDetail.email,
        firstName: this.props.profileDetail.firstName,
        lastName: this.props.profileDetail.lastName,
        gender: this.props.profileDetail.gender,
        suffix: this.props.profileDetail.suffix,
        phone: this.props.profileDetail.phone,
        city: this.props.profileDetail.city === null ? "" :  this.props.profileDetail.city ,
      },
      errors: {},
      addModalShow: false,
      loading: false,
      showValidate: true,
      country: this.props.profileDetail.country,
      state: this.props.profileDetail.state,
      token: localStorage.getItem("token"),
      focusPhone: false
    };
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.next = this.next.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profileDetail !== this.props.profileDetail) {
      this.setState({
        fields: this.props.profileDetail,
        country: this.props.profileDetail.country,
        state: this.props.profileDetail.state,
      });
    }
  }

  handleUserInputChange(e, field) {
    if (field === "personalPhone") {
      this.setState({
        phone: e,
        showValidate: false,
        focusPhone: true
      });
    } else {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields, showValidate: false });
    }
}
  onSubmit = async () => {
    this.setState({
      errors: Validation.personalInformation(
        this.state.fields,
        "personalInformation"
      ).error,
    });
    if (
      Validation.personalInformation(this.state.fields, "personalInformation")
        .formIsValid
    ) {
      if(this.state.focusPhone === true){
        this.setState({
          loading: true,
        });
        this.setState({
          disabled: false
        })
        const params = {
          phone: this.state.phone,
          name: "registration",
        };
        authServices
          .resendPhoneOtp(params)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
                this.setState({ addModalShow: true,loading: false,showValidate: true});
            }
          })
          .catch((e) => {
            if (e.response.status !== undefined) {
              if (e.response.status === 400) {
                // this.setState({ addModalShow: true });
                toast.error(e.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
                this.setState({
                  loading: false,

                })
              }
            }
          });
      } else {
        let profileInformation = this.state.fields;
        profileInformation.country = this.state.country;
        profileInformation.state = this.state.state;
    await axios.post(environment.endPoint + "dashboard/profile", profileInformation, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
    .then((response) => {
      if (response.status === 200 || response.status !== undefined) {
        toast.success("Personal Information Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.setState({
          showValidate: true
        })
        this.props.next();
      }
    })
    .catch((e) => {
      if (e.response.status === 400) {
        toast.error("Personal Information Not Updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.setState({
          loading: false,
        })
      }
    });
      }
     
    }
  };

  selectCountry(val) {
    this.setState({ country: val,showValidate: false });
  }

  selectRegion(val) {
    this.setState({ state: val,showValidate: false });
  }
  next(e) {
     this.props.next();
  }



  render() {
    let addModalClose = () => this.setState({ addModalShow: false });
    let { country, state } = this.state;

    return (
      <div className="card-body card-body-profile custom-inner-content">
        <h5 className="stepper-form-headings d-none d-lg-block">
          Personal Information
        </h5>

        <div className="row mb-4">
          <div className="col-lg-12 col-12">
            <div className="custom-form-row">
              <select
                value={this.state.fields.suffix}
                className={
                  this.state.fields.suffix === "" ||
                  this.state.fields.suffix === null
                    ? "disabled-option form-control custom-form-control mb-2"
                    : "form-control custom-form-control mb-2"
                }
                onChange={(e) => this.handleUserInputChange(e, "suffix")}
              >
                <option value="" selected disabled>
                  Select Prefix / Suffix
                </option>
                {prefixSuffix.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <label className="label-helper py-2 px-1">Prefix / Suffix </label>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-6 col-6 ">
            <div className="custom-form-row">
              <input
                className="form-control custom-form-control"
                placeholder="First Name"
                value={this.state.fields.firstName}
                onChange={(e) => this.handleUserInputChange(e, "firstName")}
              />
              <label className="label-helper py-2 px-1">
                First Name <strong className="text-danger">*</strong>{" "}
              </label>
            </div>
            <span className="text-danger">
              {this.state.errors["firstName"]}
            </span>
          </div>
          <div className="col-lg-6 col-6">
            <div className="custom-form-row">
              <input
                className="form-control custom-form-control"
                placeholder="Last Name"
                value={this.state.fields.lastName}
                onChange={(e) => this.handleUserInputChange(e, "lastName")}
              />
              <label className="label-helper py-2 px-1">
                Last Name <strong className="text-danger">*</strong>{" "}
              </label>
            </div>
            <span className="text-danger">{this.state.errors["lastName"]}</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-6 ">
            <div className="custom-form-row">
              <input
                className="form-control custom-form-control"
                placeholder="Email"
                value={this.state.fields.email}
                readOnly
              />
              <label className="label-helper py-2 px-1">Email</label>
            </div>
          </div>

          <div className="col-lg-6 col-6">
            <div className="wrapper ">
              <div className="custom-form-row">
                <PhoneInput
                  id="Input"
                  className="form-control custom-form-control"
                  country={"us"}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  value={this.state.fields.phone}
                  disableDropdown
                  
                  onChange={(e) => this.handleUserInputChange(e, "personalPhone")}

                />
                <label
                  className="verify-label text-uppercase"
                  // onClick={this.searchPhone.bind(
                  //   this
                  // )}
                ></label>

                <Verify
                  show={this.state.addModalShow}
                  phone={this.state.phone}
                  onHide={addModalClose}
                  profileFields={this.state.fields}
                  country={this.state.country}
                  state={this.state.state}

                  textHeading="Please enter the OTP we send you"
                  wrongSignup={true}
                  myProfile={true}
                  nextProfile={this.next}
                />

                <label className="label-helper py-2 px-1">
                  Phone <strong className="text-danger">*</strong>
                </label>
              </div>

              <span className="text-danger">
                {this.state.errors["personalPhone"]}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-6">
            <div className="custom-form-row">
              <input
                className="form-control custom-form-control"
                placeholder="Address Line1"
                value={this.state.fields.address}
                onChange={(e) => this.handleUserInputChange(e, "address")}
              />
              <label className="label-helper py-2 px-1">Address Line1 </label>
            </div>
          </div>
          <div className="col-lg-6 col-6">
            <div className="custom-form-row">
              <input
                className="form-control custom-form-control"
                placeholder="Address Line2"
                value={this.state.fields.addressTwo || ""}
                onChange={(e) => this.handleUserInputChange(e, "addressTwo")}
              />
              <label className="label-helper py-2 px-1">Address Line2 </label>
            </div>
          </div>
        </div>

<div className="row mb-4">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="City"
                  autoComplete="new-password" 
                  value={this.state.fields.city || ""}
                  onChange={(e) => this.handleUserInputChange(e, "city")}
                  />
                <label
                  htmlFor="country_code"
                  className="label-helper py-2 px-1"
                >
                  City{" "}
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Zip/Postal Code"
                  value={this.state.fields.zipCode || ""}
                  
                  onChange={(e) => this.handleUserInputChange(e, "zipCode")}
                />
                <label className="label-helper py-2 px-1">
                  Zip/Postal Code{" "}
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <CountryDropdown
                  className="form-control custom-form-control "
                  defaultOptionLabel="Select a Country"
                  value={country || ""}
                  onChange={this.selectCountry}
                  priorityOptions={["US"]}
                />
                <label className="label-helper py-2 px-1">Country </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <RegionDropdown
                  className="form-control custom-form-control "
                  blankOptionLabel="No Country Selected"
                  defaultOptionLabel="Now select a Region"
                  country={country || ""}
                  value={state || ""}
                  onChange={this.selectRegion}
                />
                <label className="label-helper py-2 px-1">
                  State/Province/Region{" "}
                </label>
              </div>
            </div>
          </div>

        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-lg-4 col-6 offset-lg-5">
            <button
              id="second"
              type="button"
              className="btn btn-primary border-radius py-1  px-3"
              onClick={this.onSubmit}
              disabled={this.state.showValidate ? true : false}
            >
              Update
              <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalInformation;
