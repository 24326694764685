import axios from "axios";
import { toast } from "react-toastify";
// import { environment } from "./environment";
// import swal from "sweetalert";
import { environment } from "./environment";

// axios.interceptors.request.use( config => {
//     const token = localStorage.getItem("token"); 
//     if (token) {
//       config.headers["Authorization"] = 'Bearer ' + token;
//     }
//     return config;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );
axios.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    // swal({
    //   title: "Your session has expired. Please reload the page by click to get new session?",
    //   icon: "warning",
    //   // buttons: true,
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     window.location.reload();

    //     // window.location = '/'
    //   }
    // });

    refreshToken()
    window.location = '/#/'
    localStorage.clear()
  } else if (error.response.status === 500) {
    toast.error(error.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  }
  return error;
});

const refreshToken = async () => {
  const params = {
    refreshToken: localStorage.getItem("refreshToken"),
    token: localStorage.getItem("token")
  }
  try {
    const res = await axios.put(environment.endPoint + "userAuth/refresh", params);
    if (res.status === 200) {
      // console.log(console.log(res.data.data.accessToken.replace("Bearer", "")))
      localStorage.setItem("token", res.data.data.accessToken);
      localStorage.setItem("refreshToken", res.data.data.refreshToken);
    }
  } catch (err) {
    console.log(err);
  }
};

export default axios.create({
  baseURL: "https://app.octopiwellness.com/api",
  headers: {
    "Content-type": "application/json",
  },
});