import http from "../http-common";

class AuthService {
  personalInformation(data) {
    return http.post("/register", data);
  }

  personalUpdateInformation(data ,id) {
    return http.post("/register/" + id , data);
  }
  physicianInformation(data) {
    return http.post("/register/physician", data);
  }

  medicalInformation(data) {
    return http.post("/register/medical", data);
  }
  getMedicationByName(name) {
    return http.get("/register/medication?name=" + name);
  }
  getDiagnoseByName(name) {
    return http.get("/register/diagnose?name=" + name);
  }
  verifyPhone(data) {
    return http.post("/userAuth/verifyPhone", data);
  }
  postDiagnose(data) {
    return http.post("/register/diagnose", data);
  }
  postMedication(data) {
    return http.post("/register/medication", data);
  }
  searchPhone(data) {
    return http.get("/register/byPhone?phone=" + data);
  }
  searchEmail(data) {
    return http.get("/register/byEmail?email=" + data);
  }
  resendPhoneOtp(data) {
    return http.post("/userAuth/resendOtp", data);
  }
  login(data) {
    return http.post("/userAuth/login", data);
  }
  logout(data) {
    return http.post("/userAuth/logout", data);
  }
  setPassword(data) {
    return http.post("/userAuth/setPassword", data);
  }
  forgotPassword(data) {
    return http.post("/userAuth/password", data);
  }

  physicianRegistrationInformation(data) {
    return http.post("/physician/register", data);
  }

  verifyPassCode(data) {
    return http.post("/userAuth/verifyCode", data);
  }

  fileUpload(data) {
    return http.post("/files/upload", data);
  }

  refreshToken(data) {
    return http.put("/userAuth/refresh", data);
  }


  /**
   * Set User into local storage to avoid queris again n again.
   * @param profile
   */
  setCurrentUser(profile) {
    const user = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      dob: profile.dob,
      gender: profile.gender,
      profileImage: profile.profileImage,
      diagnosName: profile.diagnosName,
    };
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
 /**
   * Set User into local storage to avoid queris again n again.
   * @param profile
   */
  setUserRole(role) {
    const userRole = {
        name: role.name
    };
    localStorage.setItem('role', JSON.stringify(userRole));
  }

  /**
   * Set User into local storage to avoid queris again n again.
   * @param profile
   */
   getUserRole() {
    const userRole = localStorage.getItem('role');
    if ( userRole !== null ) {
      return JSON.parse(userRole);
    }
    return null;
  }

  /**
   * Set User into local storage to avoid queris again n again.
   * @param profile
   */
  getCurrentUser() {
    const profileData = localStorage.getItem('currentUser');
    if ( profileData !== null ) {
      return JSON.parse(profileData);
    }
    return null;
  }

  isAuthenticated() {  
    return new Promise((resolve, reject)=>{
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        if (!loginData)
            resolve({isAuthenticated: false, user: null, roleId: null});
        else
            resolve({isAuthenticated: true, user: loginData, roleId: loginData.user.roleId});
    });
}}

export default new AuthService();
