import React, { Component } from "react";
import Select from "react-select";
import GroupUser from "../../../assets/images/Group-User.svg";
import swal from "sweetalert";
import { environment } from "../../../environment";
import axios from "axios";
import { toast } from "react-toastify";
import Trash from "../../../assets/images/trash.svg";

export class Physician extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      physicians: [],
      searchList: [],
      name: "",
      id: "",
      disabled: true,
      loading: false,
      token: localStorage.getItem("token"),
    };
    this.addPhysiciansList = this.addPhysiciansList.bind(this);
  }
  prev = () => {
    this.props.prev();
  };
  onSubmit = () => {
    this.props.next();
  };

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption,
      name: selectedOption.label,
      id: selectedOption.value,
      disabled: false,
    });
  };

  componentDidMount() {
    this.getPhysician(this.state.token);
    this.getUserPhysician(this.state.token);
  }

  getPhysician = async (token) => {
    await axios
      .get(environment.endPoint + "dashboard/physicians", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.getPhysicianData(response);
      })
      .catch((e) => {});
  };

  getUserPhysician = async (token) => {
    await axios
      .get(environment.endPoint + "dashboard/physician", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.getUserPhysicianData(response.data.data);
      })
      .catch((e) => {});
  };

  getUserPhysicianData(response) {
    let option = [];
    if (response.length > 0) {
      response.forEach((physician) => {
        let physicianData = {};
        physicianData.value = physician.id;
        physicianData.name = physician.firstName + " " + physician.lastName;
        if(physician.profileImage !==null){
          physicianData.profileImage = physician.profileImage
        } else {
          physicianData.profileImage = GroupUser
        }
        option.push(physicianData);
      });
    }
    this.setState({
      physicians: option,
    });
  }

  getPhysicianData(response) {
    let option = [];
    if (response.data.data.length > 0) {
      response.data.data.forEach((physician) => {
        let physicianData = {};
        physicianData.value = physician.id;
        physicianData.label = physician.firstName + " " + physician.lastName;
        option.push(physicianData);
      });
    }
    this.setState({
      searchList: option,
    });
  }

  addPhysiciansList(e) {
    e.preventDefault();
    let physicians = this.state.physicians;
    physicians.push({
      value: this.state.id,
      name: this.state.name,
      profileImage: "",
    });
    var resArr = [];
    physicians.filter(function(item){
  var i = resArr.findIndex(x => (x.value === item.value && x.name === item.name));
  if(i <= -1){
        resArr.push(item);
  }
  return null;
});
        this.postPhysician(this.state.token)

    this.setState({
      physicians: resArr,
    });
  }

  postPhysician = async (token) => {
    // eslint-disable-next-line no-self-compare
    const data = {
      physicianId: this.state.id,
    };
    await axios
      .post(environment.endPoint + "dashboard/addPhysiction", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.setState({
            selectedOption: null,
            disabled: true,
            loading: false,
          });
          this.getUserPhysicianData(response.data.data)
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  removePhysician = async (e, idx, id,name) => {
    swal({
      title: "Please confirm you no longer wants to take services of Physician " + name ,
      // text:
      //   "Once deleted, you will not be able to recover this imaginary file!",
      icon: Trash,
      imageUrl: {Trash},
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(
            environment.endPoint + "dashboard/physician?physicianId=" + id,
            {
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              let physicians = this.state.physicians;
              physicians.splice(idx, 1);
              this.setState({ physicians: physicians });
              toast.success("Deleted Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          })
          .catch((e) => {});

        // swal("Poof! Your Physician has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your Physician is safe!");
      }
    });
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <div className="card-body card-body-profile custom-inner-content physician-profile">
        <h5 className="stepper-form-headings d-none d-lg-block">Physician</h5>

        <div className="row mb-4">
          <div className="col-lg-9">
            <Select
              placeholder="Select Physician"
              value={selectedOption}
              onChange={this.handleChange}
              options={this.state.searchList}
            />
          </div>
          <div className="col-lg-3">
            <button
              id="second"
              type="button"
              className="btn btn-primary border-radius py-1  px-3"
              disabled={this.state.disabled ? true : false}
              onClick={this.addPhysiciansList}
            >
              Add
              <i className="fa fa-plus ml-2" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        {this.state.physicians.length > 0 ? (
          <h2 className="stepper-form-headings"> Physician(s)</h2>
        ) : (
          ""
        )}

        <div className="row">
          {this.state.physicians.map((value, index) => (
            <div className="col-lg-4">
              <button
                className="btn btn-physician"
                onClick={(e) => this.removePhysician(e, index, value.value,value.name)}
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <img
                src={value.profileImage}
                height="100"
                width="100"
                className="img-fluid"
                alt=""
              />
              <p className="mt-2">{value.name}</p>
            </div>
          ))}
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Physician;
