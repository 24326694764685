import { DRAFT_SHOOP } from '../actions/actionsTypes';

const initialState = {
  draftShoops: {},
  name: ""
};

const draftShoops = (state, action) => {
  return { ...state, draftShoops: action.draftShoops, name: action.name }
}

const draftReduers = (state = initialState, action) => {
  switch(action.type) {
    case DRAFT_SHOOP: return draftShoops(state, action);
    default: return state;
  }
};

export default draftReduers;