import axios from 'axios';
import React, { Component } from 'react'
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { environment } from '../../../../environment';
import { toast } from "react-toastify";
import Validation from '../../../common/util';

export class PersonalInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: {
                firstName: this.props.profileDetail.firstName,
                lastName: this.props.profileDetail.lastName,
                email: this.props.profileDetail.email,
                officeNumber: this.props.profileDetail.officeNumber,
                specialization: this.props.profileDetail.specialization,
                officeEmail: this.props.profileDetail.officeEmail,
                phone: this.props.profileDetail.phone,

            },
            errors: {},
            addModalShow: false,
            loading: false,
            focusPhone: false,
            showValidate: true,
            token: localStorage.getItem("token"),
            specializations: [],
            officeNumber: this.props.profileDetail.officeNumber,
            phone: this.props.profileDetail.phone,
            selected: null,
        }
    }

    componentDidMount() {
        this.getGlobalSpecializations()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.profileDetail !== this.props.profileDetail) {
            this.setState({
                fields: this.props.profileDetail,
                officeNumber: this.props.profileDetail.officeNumber,
                phone: this.props.profileDetail.phone,
            });
        }
    }

    getGlobalSpecializations = async () => {

        await axios.get(environment.endPoint + "global/specializations", {
            headers: {
                Authorization: `Bearer ${this.state.token}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        specializations: response.data.data[1]
                    });
                    this.getSpecializationsData(response.data.data[1])
                }
            })
            .catch((e) => {
                //   if(e.response.status === 401) {
                //     this.props.history.push("/login")
                // }
            });
    }
    getSpecializationsData(response) {
        let option = [];
        if (response.length > 0) {
            response.forEach((product) => {
                let specilizationData = {};
                specilizationData.value = product.id;
                specilizationData.label = product.name;
                option.push(specilizationData);
            });
        }
        this.setState({
            specializations: option,
        });
    }
    handleUserInputChange(e, field) {
        if (field === "personalPhone") {
            this.setState({
                phone: e,
                disabled: false

            });
        } else if (field === "officeNumber") {
            this.setState({
                officeNumber: e,
                showValidate: false
            });
        } else {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            this.setState({
                fields, showValidate: false
            });
        }
    }

    handleChange = value => {
        let fields = this.state.fields;
        fields["specialization"] = value.label;
        this.setState({
            selected: value,
            fields,
            showValidate: false
        });
    };

    onSubmit = async () => {



        this.setState({
            errors: Validation.physicianSigUpvalidationProfile(
                this.state.fields,
                this.state.phone,
                this.state.officeNumber,
                "physicianSignup"
            ).error,
        });
        if (
            Validation.physicianSigUpvalidationProfile(
                this.state.fields,
                this.state.phone,
                this.state.officeNumber,
                "physicianSignup"
            ).formIsValid
        ) {
            let profileInformation = this.state.fields;
            const params = {
                firstName: profileInformation.firstName,
                lastName: profileInformation.lastName,
                email: profileInformation.email,
                officeNumber: this.state.officeNumber,
                specialization: profileInformation.specialization,
                officeEmail: profileInformation.officeEmail,
                phone: this.state.phone,
            }
            await axios.post(environment.endPoint + "dashboard/profile", params, {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                },
            })
                .then((response) => {
                    if (response.status === 200 || response.status !== undefined) {
                        toast.success("Personal Information Update Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        this.setState({
                            showValidate: true
                        })
                        this.props.next();
                    }
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        toast.error("Personal Information Not Updated", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                        this.setState({
                            loading: false,
                        })
                    }
                });
        }

    }

    render() {
        return (
            <div className="card-body card-body-profile custom-inner-content">
                <h5 className="stepper-form-headings d-none d-lg-block">
                    Personal Information
                </h5>

                <div className="row mb-4">
                    <div className="col-lg-6 col-6 ">
                        <div className="custom-form-row">
                            <input
                                className="form-control custom-form-control"
                                placeholder="First Name *"
                                value={this.state.fields.firstName}
                                onChange={(e) => this.handleUserInputChange(e, "firstName")}
                            />
                            <label className="label-helper py-2 px-1">
                                First Name <strong className="text-danger">*</strong>{" "}
                            </label>
                        </div>
                        <span className="text-danger">
                            {this.state.errors["firstName"]}
                        </span>
                    </div>
                    <div className="col-lg-6 col-6">
                        <div className="custom-form-row">
                            <input
                                className="form-control custom-form-control"
                                placeholder="Last Name *"
                                value={this.state.fields.lastName}
                                onChange={(e) => this.handleUserInputChange(e, "lastName")}
                            />
                            <label className="label-helper py-2 px-1">
                                Last Name <strong className="text-danger">*</strong>{" "}
                            </label>
                        </div>
                        <span className="text-danger">{this.state.errors["lastName"]}</span>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-6 col-6 ">
                        <div className="custom-form-row">
                            <input
                                className="form-control custom-form-control"
                                placeholder="Email"
                                value={this.state.fields.email}
                                readOnly
                            />
                            <label className="label-helper py-2 px-1">Email </label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-6">
                        <div className={
                            this.state.fields.specialization !== "" || this.state.fields.specialization !== null
                                ? "has-number custom-form-row"
                                : "custom-form-row"
                        }>
                            <Select
                                classNamePrefix='my-className-prefix'
                                placeholder={this.state.fields.specialization !== "" || this.state.fields.specialization !== null ? this.state.fields.specialization : "Select Specialization *"}
                                name="form-field-name"
                                onChange={this.handleChange.bind(
                                    this)}
                                selected={this.state.selected}
                                menuPortalTarget={document.body}
                                // value={this.state.fields.specialization}
                                options={this.state.specializations}
                            />
                            <label className="label-helper py-2 px-1">
                                Specialization{" "}
                                <strong className="text-danger">*</strong>
                            </label>
                            <span className="text-danger">
                                {this.state.errors["specialization"]}
                            </span>
                        </div>
                    </div>


                </div>
                <div className="row mb-4">
                    <div className="col-lg-6 col-6">
                        <div className="wrapper ">
                            <div className={
                                this.state.fields.phone !== ""
                                    ? "has-number custom-form-row"
                                    : "custom-form-row"
                            }>
                                <PhoneInput
                                    id="Input"
                                    className="form-control custom-form-control"
                                    country={"us"}
                                    inputProps={{
                                        required: true,
                                        readOnly: true,
                                        // autoFocus: true
                                    }}
                                    value={this.state.phone}
                                    disableDropdown

                                    onChange={(e) => this.handleUserInputChange(e, "personalPhone")}

                                />
                                <label
                                    className="verify-label text-uppercase"
                                ></label>


                                <label className="label-helper py-2 px-1">
                                    Phone
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-6">
                        <div className="wrapper ">
                            <div className={
                                this.state.fields.officeNumber !== ""
                                    ? "has-number custom-form-row"
                                    : "custom-form-row"
                            }>
                                <PhoneInput
                                    id="Input"
                                    className="form-control custom-form-control"
                                    country={"us"}
                                    inputProps={{
                                        required: true,
                                        // autoFocus: this.state.fields.officeNumber !== "" ? true : false
                                    }}
                                    value={this.state.officeNumber}
                                    disableDropdown

                                    onChange={(e) => this.handleUserInputChange(e, "officeNumber")}

                                />
                                <label
                                    className="verify-label text-uppercase"
                                ></label>
                              

                                <label className="label-helper py-2 px-1">
                                    Office Number {" "}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-6 ">
                        <div className="custom-form-row">
                            <input
                                className="form-control custom-form-control"
                                placeholder="Office Email"
                                value={this.state.fields.officeEmail}
                                onChange={(e) => this.handleUserInputChange(e, "officeEmail")}
                            />
                            <label className="label-helper py-2 px-1">Office Email </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-6 offset-lg-5">
                        <button
                            id="second"
                            type="button"
                            className="btn btn-primary border-radius py-1  px-3"
                            onClick={this.onSubmit}
                            disabled={this.state.showValidate ? true : false}
                        >
                            Update
                            <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PersonalInformation
