import React, { Component } from "react";
import AuthService from "../../services/authServices";
import { toast } from "react-toastify";
import Validation from "../common/util";
import authServices from "../../services/authServices";
// import SuccessModal from "./registration/modal/success-modal";

class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      fields: {},
      errors: {},
      type: "password",
      confirmType: "password",
      addModalShow: false,
      setPassword: "",
      forgotPassword: "",
      digit: false,
      lowerCaseCheck: false,
      upperCaseCheck: false,
      specialCharactersCheck: false,
      checkPasswordLength: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.confirmPassShowHide = this.confirmPassShowHide.bind(this);
    // this.navigation = this.navigation.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { setPassword, forgotPassword },
      },
    } = this.props;
    this.setState({ setPassword: setPassword, forgotPassword: forgotPassword });
    this.verifyCode()
  }


  
  verifyCode(){
    const {
      match: {
        params: { code },
      },
    } = this.props;
    const params = {
       code : code
    }
    this.setState({
      disabled: true
    });
    authServices.verifyPassCode(params)
    .then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.setState({
          disabled: false,
          email: response.data.data.email,
          code: code
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 400) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.setState({
          disabled: true
        });
        this.props.history.push("/expiredlink");
      }
    });
  }


  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  confirmPassShowHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      confirmType: this.state.confirmType === "password" ? "input" : "password",
    });
  }

  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({digit:  Validation.passwordValidation(
      this.state.fields,
      "setPassword"
    ).digit});
    this.setState({upperCaseCheck:  Validation.passwordValidation(
      this.state.fields,
      "setPassword"
    ).upperCaseCheck});
    this.setState({lowerCaseCheck:  Validation.passwordValidation(
      this.state.fields,
      "setPassword"
    ).lowerCaseCheck})
    this.setState({specialCharactersCheck:  Validation.passwordValidation(
      this.state.fields,
      "setPassword"
    ).specialCharactersCheck});
    this.setState({checkPasswordLength:  Validation.passwordValidation(
      this.state.fields,
      "setPassword"
    ).checkPasswordLength})
    this.setState({ fields });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      errors: Validation.passwordValidation(this.state.fields, "setPassword")
        .error,
    });

    if (
      Validation.passwordValidation(this.state.fields, "setPassword")
        .formIsValid
    ) {
      const {
        match: {
          params: { code, setPassword, forgotPassword },
        },
      } = this.props;
      this.setState({
        setPassword: setPassword,
        forgotPassword: forgotPassword,
      });

      const params = {
        code: code,
        password: this.state.fields.password,
        confirmPassword: this.state.confirmPassword,
      };
      AuthService.setPassword(params)
        .then((response) => {
          if (response.status === 200) {
            if(response.data.data.roleId === 2) {
              this.props.history.push("/complete-step");
            } else {
              this.props.history.push("/login");
            }
            // toast.success(response.data.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 3000,
            // });
            // this.setState({ addModalShow: true });
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  }

  // navigation() {
  //   this.props.history.push("/login");
  // }

  render() {
    return (
      <div>
        <div className="auth-form mb-5">
          <form onSubmit={this.onSubmit}>
            {this.state.setPassword === "setPassword" ? (
              <h3 className="text-center">Thank For Verifying the Email!</h3>
            ) : (
              ""
            )}
            {this.state.forgotPassword === "forgotPassword" ? "" : ""}

            <h1 className="text-primary text-center stepper-form-headings custom-welcome-heading">
              Welcome to Octopi Wellness
            </h1>
            <div className="custom-card card-width">
              <h3 className="text-center stepper-form-headings">
                Set Password
              </h3>
              <div className="row mb-4 mt-3">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <input
                      className="form-control custom-form-control py-4"
                      placeholder="Password"
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "password"
                      )}
                      value={this.state.fields.password}
                      type={this.state.type}
                    />
                    <label className="label-helper py-2 px-1">
                      Set Password <strong className="text-danger">*</strong>
                    </label>
                    <span onClick={this.showHide}>
                      {this.state.type === "password" ? (
                        <i
                          className="fa fa-2x fa-eye-slash "
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i className="fa fa-2x fa-eye " aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <span className="text-danger">
                    {this.state.errors["password"]}
                  </span>
                </div>
              </div>
              <div className="row mb-4 mt-4">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <input
                      className="form-control custom-form-control py-4"
                      placeholder="Confirm Password"
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "confirmPassword"
                      )}
                      value={this.state.fields.confirmPassword}
                      type={this.state.confirmType}
                    />
                    <label className="label-helper py-2 px-1">
                      Confirm Password{" "}
                      <strong className="text-danger">*</strong>
                    </label>
                    <span onClick={this.confirmPassShowHide}>
                      {this.state.confirmType === "password" ? (
                        <i
                          className="fa fa-2x fa-eye-slash "
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i className="fa fa-2x fa-eye " aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <span className="text-danger">
                    {this.state.errors["confirmPassword"]}
                  </span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12 col-12">
                  <ul>
                    {this.state.digit === false ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-circle-o text-danger"></i>
                        <span className="text-danger text-truncate ml-2  ">
                          Should contain at least 1 Numeric
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.digit === true ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-check-circle-o text-success"></i>
                        <span className="text-success text-truncate ml-2  ">
                          Should contain at least 1 Numeric
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.lowerCaseCheck === false ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-circle-o text-danger"></i>
                        <span className="text-danger text-truncate ml-2  ">
                          Should contain at least 1 Lower Case
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.lowerCaseCheck === true ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-check-circle-o text-success"></i>
                        <span className="text-success text-truncate ml-2  ">
                          Should contain at least 1 Lower Case
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.upperCaseCheck === false ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-circle-o text-danger"></i>
                        <span className="text-danger text-truncate ml-2  ">
                          Should contain at least 1 Upper Case
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.upperCaseCheck === true ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-check-circle-o text-success"></i>
                        <span className="text-success text-truncate ml-2  ">
                          Should contain at least 1 Upper Case
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.specialCharactersCheck === false ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-circle-o text-danger"></i>
                        <span className="text-danger ml-2  ">
                          Should contain at least 1 Special Characters
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.specialCharactersCheck === true ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-check-circle-o text-success"></i>
                        <span className="text-success ml-2  ">
                          Should contain at least 1 Special Characters
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.checkPasswordLength === false ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-circle-o text-danger"></i>
                        <span className="text-danger text-truncate ml-2  ">
                          Should contain at least 8 to 16 characters
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.checkPasswordLength === true ? (
                      <li className="d-flex">
                        <i className="fa fa-2x fa-check-circle-o text-success"></i>
                        <span className="text-success text-truncate ml-2  ">
                          Should contain at least 8 to 16 characters
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-2 offset-lg-4 col-2 offset-3 mt-4">
                  <button className="btn btn-primary border-radius py-2  px-4">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* <SuccessModal
          onSubmit={this.navigation}
          show={this.state.addModalShow}
          headingText="Thank You For Verifying the Email"
          innerHeadingText="Welcome to Octopi Wellness"
        ></SuccessModal> */}
      </div>
    );
  }
}

export default SetPassword;
