import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
class SuccessModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  navigation() {
    this.props.history.push("/login");
  }

  render() {
    return (
      <div>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-verify-modal"
          backdrop="static"
        >
          <Modal.Header>
            <h3 className="col-12 modal-title text-center">
              <i className="fa fa-3x fa-check-circle text-success"></i>
            </h3>
          </Modal.Header>

          <Modal.Body>
            <h5 className="text-center mb-2 text-dark" id="exampleModalLabel">
              <strong>{this.props.headingText}</strong>
            </h5>
            <h6 className="verify-modal-title verify-inner-content mt-3">
              {" "}
              {this.props.innerHeadingText}
            </h6>
            {this.props.hide === true ? (
              <div>
                <p className="text-center paragraph-help">
                  Help is not the Way !
                </p>
                <p className="text-center ">
                  Please check your email (and Spam folder) to confirm your
                  registration to OCTOPI Wellness
                </p>{" "}
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer className="verify-modal-footer">
            {this.props.hide === true ? (
              <button
                type="button"
                className="btn btn-primary border-radius py-1  px-5"
                onClick={this.props.onSubmit}
              >
                OK
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary border-radius py-1  px-5"
                onClick={this.props.onSubmit}
              >
                Go to Login
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SuccessModal);
