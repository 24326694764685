import React, { Component } from "react";
import { Link } from "react-router-dom";

export class ExpireLink extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  redirect = () => {
      this.props.history.push("/login")
  }

  render() {
    return (

      <div className="auth-form mb-5">
    <h3 className="stepper-form-headings mt-5 text-center">This email set password link is expired</h3>

      <form>
        <div className="custom-card consent-card">
          <div className="row mb-4 mt-4">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <p className="consent-paragraph text-left">
                  Your request for password reset text has expired.Please try resetting by clicking on forgot password <Link  to="/forgotPassword">
                  link
                </Link>.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
                <div className="col-lg-5 offset-lg-5 col-6 offset-5 mt-4">
                  <button
                    onClick={this.redirect}
                    className="btn btn-primary border-radius py-2  px-4 expire-margin-left"
                  >
                    Go to Login
                  </button>
                </div>
              </div>
        </div>
      </form>
    </div>
 
   );
  }
}

export default ExpireLink;
