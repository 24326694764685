import React, { Component } from "react";

export class Exercise extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }

  render() {
    const { patientProfile } = this.state;

    return (
      <div className="card-body custom-inner-content  ">
        <h5 className="stepper-form-headings">Exercise</h5>
        {patientProfile?.Exercise === null ? (
          <h4 className="font-italic">No Exercise Information Added</h4>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-12">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <span className="field-label">
                        How many days per week did you exercise, In the past 3
                        months?
                      </span>
                      <span className="field-text">
                        {" "}
                        {patientProfile?.Exercise?.exercisePerWeek}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="field-label">
                        Do you partipate in regular Cardiovascular exercise?{" "}
                      </span>
                      <span className="field-text">
                        {" "}
                       

{patientProfile?.Exercise?.cardiovascularExercise === true
                        ? "Yes"
                        : patientProfile?.Exercise?.cardiovascularExercise === null
                        
                        ? <p className="font-italic px-4">Regular Cardiovascular Exercise not Added</p>
                        : "No"}
                      </span>
                    </td>
                  </tr>
                  {patientProfile?.Exercise?.cardiovascularExercise === true ? (
                    <>
                      <tr>
                        <td>
                          <span className="field-label"> What Type?</span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.cardiovascularExerciseType
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="field-label">How often?</span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.cardiovascularExerciseOften
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="field-label">Duration?</span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.cardiovascularExerciseDuration
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="field-label">
                            How would you describe your intensity?
                          </span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.cardiovascularExerciseIntensity
                            }
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td>
                      <span className="field-label">
                        Do you partipate in regular strength training exercise?
                      </span>
                      <span className="field-text">
                       
                          {patientProfile?.Exercise?.regularStrengthTraining === true
                        ? "Yes"
                        : patientProfile?.Exercise?.regularStrengthTraining === null
                        ?  <p className="font-italic px-4">Regular Strength Training not Added</p>
                        : "No"}
                      </span>
                    </td>
                  </tr>
                  {patientProfile?.Exercise?.regularStrengthTraining ===
                  true ? (
                    <>
                      <tr>
                        <td>
                          <span className="field-label">What kind ?</span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.regularStrengthTrainingType
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="field-label">
                            How would you describe your intensity?
                          </span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.regularStrengthTrainingIntensity
                            }
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td>
                      <span className="field-label">
                        Do you partipate in flexibility exercises and
                        stretching?
                      </span>
                      <span className="field-text">
                        {" "}
                      
{patientProfile?.Exercise?.stretchingFlexibilityExercises === true
                        ? "Yes"
                        : patientProfile?.Exercise?.stretchingFlexibilityExercises === null
                        ? <p className="font-italic px-4">Flexibility Exercises and Stretching not Added</p>
                        : "No"}
                      </span>
                    </td>
                  </tr>
                  {patientProfile?.Exercise?.stretchingFlexibilityExercises ===
                  true ? (
                    <>
                      <tr>
                        <td>
                          <span className="field-label">What kind ?</span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.stretchingFlexibilityExercisesType
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="field-label">How often?</span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.stretchingFlexibilityExercisesOften
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="field-label">
                            How would you describe your intensity?
                          </span>
                          <span className="field-text">
                            {" "}
                            {
                              patientProfile?.Exercise
                                ?.stretchingFlexibilityExercisesIntensity
                            }
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Exercise;
