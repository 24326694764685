import React, { Component } from "react";
import Verify from "./registration/modal/Verify";
import PhoneInput from "react-phone-input-2";
import authServices from "../../services/authServices";
import { toast } from "react-toastify";

export class setPasswordPhysician extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      fields: {},
      errors: {},
      addModalShow: false,
      disabled: true,
      phone: "",
      email: "",
      code: ""
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.verifyCode()
  }


  verifyCode(){
    const {
      match: {
        params: { code },
      },
    } = this.props;
    const params = {
       code : code
    }
    this.setState({
      disabled: true
    });
    authServices.verifyPassCode(params)
    .then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.setState({
          disabled: false,
          email: response.data.data.email,
          code: code
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 400) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.setState({
          disabled: true
        });
        this.props.history.push("/expiredlink");
      }
    });
  }


  handleUserInputChange(field, e) {
    if (field === "personalPhone") {
      this.setState({
        phone: e,
        disabled: false
      });
    }
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.phone) {
      formIsValid = false;
      errors["personalPhone"] = "This field is required";
    }
    if (this.state.phone) {
      if (this.state.phone.startsWith("1") && this.state.phone.length !== 11) {
        formIsValid = false;
        errors["personalPhone"] = "Please enter valid phone number.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  onSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      const params = {
        phone: this.state.phone,
        email : this.state.email,
        name: "registration",
      };
      authServices
        .resendPhoneOtp(params)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
              this.setState({ addModalShow: true,disabled: true});
          }
        })
        .catch((e) => {
          if (e.response.status !== undefined) {
            if (e.response.status === 400) {
              // this.setState({ addModalShow: true });
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        });
    }
  }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });

    return (
      <div>
        <div className="auth-form mb-5">
          <form>
            <h1 className="text-primary text-center stepper-form-headings custom-welcome-heading">
              Welcome to Octopi Wellness
            </h1>
            <div className="custom-card card-width">
              <h3 className="text-center stepper-form-headings">
                Verify Phone Number
              </h3>
              <div className="row mb-4 mt-3">
                <div className="col-lg-12 col-12">
                  <div className="wrapper ">
                    <div className="custom-form-row">
                      <PhoneInput
                        id="Input"
                        className="form-control custom-form-control"
                        country={"us"}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        value={this.state.fields.phone}
                        disableDropdown
                        onChange={this.handleUserInputChange.bind(
                          this,
                          "personalPhone"
                        )}
                      />
                      <label
                        className="verify-label text-uppercase"
                        // onClick={this.searchPhone.bind(
                        //   this
                        // )}
                      ></label>

                      <Verify
                        show={this.state.addModalShow}
                        phone={this.state.phone}
                        onHide={addModalClose}
                        patientProfile={this.state.fields}
                        textHeading="Please enter the OTP we send you"
                        wrongSignup={true}
                        completeSignUp={true}
                        code ={this.state.code}
                        email = {this.state.email}
                      />

                      <label className="label-helper py-2 px-1">
                        Phone <strong className="text-danger">*</strong>
                      </label>
                    </div>

                    <span className="text-danger">
                      {this.state.errors["personalPhone"]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 offset-lg-4 col-2 offset-3 mt-4">
                  <button
                    disabled={this.state.disabled ? true : false}
                    onClick={this.onSubmit}
                    className="btn btn-primary border-radius py-2  px-4"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default setPasswordPhysician;
