import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import authServices from "../../services/authServices";
import { logout } from "../../services/globalService";
import GroupUser from "../../assets/images/Group-User.svg";
import Notification from "./Notification";
import axios from "axios";
import { environment } from "../../environment";

function Dashboard(props) {
  const profile = authServices.getCurrentUser();
  const role = authServices.getUserRole();
  const myFunction = (e) => {
    var elems = document.querySelectorAll(".nav-link");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
      el.classList.add("link-active");
    });
    const object = {
      patientDashboard: [],
    };
    object.patientDashboard.push(e.target.className);
    if (object.patientDashboard[0].split(" ")[1] === "link-active") {
      e.target.className = "nav-link active";
    }
    // console.log( e.target.className)
  };
  return (
    <>
      {role?.name === "patient" ? (
        <div className="d-flex">
          <li className="nav-item">
            <NavLink
              to="/dashboard"
              className="nav-link link-active"
              onClick={(e) => myFunction(e)}
              data-toggle="auth"
            >
              Dashboard
            </NavLink>
          </li>

          <li className="nav-item dropdown" onClick={(e) => myFunction(e)}>
            <Link
              className="nav-link dropdown-toggle link-dropdown-active"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              My Health
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link to="/myHealth" className="dropdown-item">
                Health Intake
              </Link>
              <Link className="dropdown-item" to="/health-journey">
                Health Journey
              </Link>
              <Link className="dropdown-item" to="/dosing-history">
                Dosing History
              </Link>
            </div>
          </li>

          <li className="nav-item dropdown" onClick={(e) => myFunction(e)}>
            <Link
              className="nav-link dropdown-toggle link-dropdown-active"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {profile?.firstName} {profile?.lastName}
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/profile">
                Profile
              </Link>
              <Link className="dropdown-item" onClick={props.logoutHandler}>
                logout
              </Link>
            </div>
          </li>
          <li className="nav-item" onClick={props.toggle}>
            <Link className="nav-link"  >
              <i class="fa fa-bell" aria-hidden="true">
                {
                  props.unReadNotification === 0 ? "" : <span class="badge-red"></span>

                }
              </i>
            </Link>
          </li>
        </div>
      ) : (
        ""
      )}
      {role?.name === "physician" ? (
        <div className="d-flex">
          <li className="nav-item">
            <NavLink
              to="/physician/dashboard"
              className="nav-link"
              data-toggle="auth"
              onClick={(e) => myFunction(e)}
            >
              Dashboard
            </NavLink>
          </li>

          <li className="nav-item dropdown" onClick={(e) => myFunction(e)}>
            <Link
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Patient
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link to="/physician/add-patient" className="dropdown-item">
                Add New Patient
              </Link>
              <Link className="dropdown-item" to="/physician/list-patient">
                Patient Listing
              </Link>
            </div>
          </li>

          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {profile?.firstName} {profile?.lastName}
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link to="/physician/profile" className="dropdown-item">
                Physician Profile
              </Link>
              <Link className="dropdown-item" onClick={props.logoutHandler}>
                logout
              </Link>
            </div>
          </li>
          <li>
            <img
              src={
                profile.profileImage !== null ? profile.profileImage : GroupUser
              }
              width="50px"
              alt="pp-img"
              className="pp-img"
            />
          </li>
          <li className="nav-item" onClick={props.toggle}>
            <Link className="nav-link"  >
              <i class="fa fa-bell" aria-hidden="true">
                {
                  props.unReadNotification === 0 ? "" : <span class="badge-red"></span>

                }
              </i>

            </Link>
          </li>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      showhide: false,
      notifications: [],
      unReadNotification: 0,
    };
  }

  logoutHandler = () => {
    this.props.logdedInHandler(false);
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("role");
    localStorage.removeItem("status");
    this.props.history.push("/login");
    logout();
  };

  toggle = () => {
    this.setState({
      showhide: true
    });
    var body = document.body;

    body.classList.add("overflow-hidden");
  }

  hide = () => {
    var body = document.body;

    body.classList.remove("overflow-hidden");

    this.setState({
      showhide: false
    });
    if (this.props.isLoggedIn === true) {
      const role = authServices.getUserRole();
      if (role.name === "physician") {
        this.getNotifications()
      } else if (role.name === "patient") {
        this.getNotifications()
      }
    }
  }

  componentDidMount() {
    if (this.props.isLoggedIn !== undefined && this.props.isLoggedIn === true) {
      const role = authServices.getUserRole();
      if (role.name === "physician") {
        this.getNotifications()
      } else if (role.name === "patient") {
        this.getNotifications()
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (this.props.isLoggedIn === true) {
        const role = authServices.getUserRole();
        if (role.name === "physician") {
          this.getNotifications()
        } else if (role.name === "patient") {
          this.getNotifications()
        }
      }
    }
  }
  getNotifications = async () => {
    await axios
      .get(environment.endPoint + "dashboard/notifications", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.setState({
          notifications: response.data.data.data,
          unReadNotification: response.data.data.unreadCount
        })
      })
      .catch((e) => {
      });
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-light custom-bg-light justify-content-between navbar-expand-lg ">
          <div className="container">
            <div className="navbar-brand">
              <img src={Logo} className="rounded mx-auto d-block" alt="..." />
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            {this.props.location.pathname.includes("/setPassword") ||
              this.props.location.pathname.includes("/completeSignUp") ||
              this.props.location.pathname.includes("/expiredlink") ||
              this.props.location.pathname.includes("/consent") ||
              this.props.location.pathname.includes("/checkMail") ? null : (
              <div
                className="form-inline collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="nav nav-pills  ml-auto">
                  {!this.props.isLoggedIn ? (
                    <li className="nav-item">
                      <NavLink
                        to="/login"
                        href="#login"
                        className="nav-link mr-2"
                        data-toggle="auth"
                      >
                        {
                          this.props.location.pathname.includes("/login") ? <span className="text-green"> Log in</span> : <span> Log in</span>
                        }
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {!this.props.isLoggedIn ? (

                    <li className="dropdown mr-3 physician-singnp-btn">
                      <Link
                        href="#"
                        className=" sign-up nav-link dropdown-toggle "
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {
                          this.props.location.pathname.includes("/signup") || this.props.location.pathname.includes("/physician-signup") ? <span className="text-green"> Sign up</span> : <span> Sign up</span>
                        }
                      </Link>
                      <ul className="dropdown-menu">
                        <li className="">
                          <Link to="/signup" className="nav-link text-truncate">
                            as a Patient
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to="/physician-signup"
                            className="nav-link text-truncate"
                          >
                            as a Physician
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>

                {this.props.isLoggedIn ? (
                  <>
                    <ul class="nav nav-pills ml-auto ">
                      <Dashboard
                        role={this.state.role}
                        logoutHandler={this.logoutHandler}
                        toggle={this.toggle}
                        showhide={this.state.showhide}
                        unReadNotification={this.state.unReadNotification}
                      />
                    </ul>
                    <Notification notifications={this.state.notifications} notificationHideShow={this.state.showhide} onHide={() => this.hide()} type={"patient"}> </Notification>

                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </nav>


      </div>
    );
  }
}


export default withRouter(Header);
