import React, { Component } from "react";
import { toast } from "react-toastify";
import moment from "moment";
// import { DatePicker } from "antd";
import Validation from "../../../common/util";
import DateFnsUtils from "@date-io/date-fns";
import {
  isIOS,isSafari
} from "react-device-detect";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { environment } from "../../../../environment";
const militaryBranchService = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
const branchOfService = [
  {
    value: "Army",
    label: "Army",
  },
  {
    value: "AirForce",
    label: "AirForce",
  },
  {
    value: "Marines",
    label: "Marines",
  },
  {
    value: "Navy",
    label: "Navy",
  },
  {
    value: "Coast Guard",
    label: "Coast Guard",
  },
  {
    value: "National Guard",
    label: "National Guard",
  },
];
const exposedChemicals = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
const warZone = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const stressDisorder = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];
export class MilitaryService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      militaryService: {},
      startWarzonePeriod : null,
      endWarzonePeriod: null,

      intrusiveMemoriesSymptoms: {},
      negativeThinkingSymptoms: {},
      avoidanceSymptoms: {},
      physicalEmotionalReactions: {},
      servedMilitary: "",
      exposedToChemicals: null,
      exposedToChemicalsFlag: "",
      showBranchMilitaryService: false,
      showChemical: false,
      showWarZoneCombat: false,
      exposedToWarzone: "",
      exposedToWarzoneFlag: "",
      shoWarZone: false,
      showStressDisorder: false,
      stressDisorders: null,
      symptomOfPtsd: "",
      symptomOfPtsdFlag: "",
      branchOfService: "",
      token: localStorage.getItem("token"),
      disabled: true,
      chemicals: [
        {
          name: "",
        },
      ],
      hideShowChemical: [
        {
          index: 0,
          value: false,
        },
      ],
      hideShowChemicalSelect: [
        {
          index: 0,
          selectValue: true,
        },
      ],
      showMilitaryServiceQuestionFlag: "",
      patientStatus: this.props.status,
      userID: this.props.userID,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.handleInputExposedChemicals = this.handleInputExposedChemicals.bind(
      this
    );
    this.addchemicals = this.addchemicals.bind(this);
    this.handleInputExposedChemicals = this.handleInputExposedChemicals.bind(
      this
    );
    this.handleWarZoneChange = this.handleWarZoneChange.bind(this);
    this.handleEndWarZoneChange = this.handleEndWarZoneChange.bind(this);

  }
  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }
  componentDidMount() {
    this.getMilitarySerives();
  }

  getMilitarySerives = async () => {
    const data =
      this.state.patientStatus === "physician"
        ? "intake/militaryService?userId=" + this.state.userID
        : "intake/militaryService";

    await axios
      .get(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        this.setState({
          militaryService: response.data.data,
        });

        let fields = this.state.fields;
        if (Object.keys(response.data.data).length !== 0) {
          fields["branchOfService"] = response.data.data.branchOfService;
          fields["highestRank"] = response.data.data.highestRank;
          fields["warzoneName"] = response.data.data.warzoneName;

          this.formatDate(response.data.data.startWarzonePeriod,response.data.data.endWarzonePeriod)

          if (
            response.data.data.chemicals !== undefined &&
            response.data.data.chemicals.length > 0
          ) {
            this.setState({
              fields,
              chemicals: response.data.data.chemicals,
            });
          }

          if (response.data.data.chemicals !== undefined) {
            if (response.data.data.chemicals.length > 0) {
              response.data.data.chemicals.forEach((data, index) => {
                let x = this.state.hideShowChemical;
                let select = this.state.hideShowChemicalSelect;
                if (index > 0) {
                  select.push({ index: index, selectValue: true });
                  x.push({ index: index, value: false });
                }
                this.setState(
                  {
                    hideShowChemical: x,
                    hideShowChemicalSelect: select,
                  },
                  () => {}
                );
              });
            }
          }

          if (response.data.data.servedMilitary === true) {
            this.setState({
              showBranchMilitaryService: true,
              servedMilitary: "yes",
              showMilitaryServiceQuestionFlag: true,
            });
          } else if (response.data.data.servedMilitary === false) {
            this.setState({
              showBranchMilitaryService: false,
              servedMilitary: "no",
              showMilitaryServiceQuestionFlag: false,
            });
          } else if (response.data.data.servedMilitary === null) {
            this.setState({
              showBranchMilitaryService: false,
              servedMilitary: "",
              showMilitaryServiceQuestionFlag: null,
            });
          }

          if (response.data.data.exposedToChemicals === true) {
            this.setState({
              showChemical: true,
              exposedToChemicals: "yes",
              exposedToChemicalsFlag: true,
            });
          } else if (response.data.data.exposedToChemicals === null) {
            this.setState({
              showChemical: false,
              exposedToChemicals: "",
              exposedToChemicalsFlag: null,
            });
          } else if (response.data.data.exposedToChemicals === false) {
            this.setState({
              exposedToChemicals: "no",
              showChemical: false,
              exposedToChemicalsFlag: false,
            });
          }

          if (response.data.data.exposedToWarzone === true) {
            this.setState({
              exposedToWarzone: "yes",
              shoWarZone: true,
              exposedToWarzoneFlag: true,
            });
          } else if (response.data.data.exposedToWarzone === null) {
            this.setState({
              shoWarZone: false,
              exposedToWarzone: "",
              exposedToWarzoneFlag: null,
            });
          } else if (response.data.data.exposedToWarzone === false) {
            this.setState({
              exposedToWarzone: "no",
              shoWarZone: false,
              exposedToWarzoneFlag: false,
            });
          }
          if (response.data.data.symptomOfPtsd === true) {
            this.setState({
              showStressDisorder: true,
              symptomOfPtsd: "yes",
              symptomOfPtsdFlag: true,
            });
          } else if (response.data.data.symptomOfPtsd === null) {
            this.setState({
              showStressDisorder: false,
              symptomOfPtsd: "",
              symptomOfPtsdFlag: null,
            });
          } else if (response.data.data.symptomOfPtsd === false) {
            this.setState({
              symptomOfPtsd: "no",
              showStressDisorder: false,
              symptomOfPtsdFlag: false,
            });
          }

          if (
            response.data.data.avoidanceSymptoms !== "" ||
            response.data.data.intrusiveMemoriesSymptoms !== "" ||
            response.data.data.negativeThinkingSymptoms !== "" ||
            response.data.data.physicalEmotionalReactions !== ""
          ) {
            if (
              response.data.data.avoidanceSymptoms !== null ||
              response.data.data.intrusiveMemoriesSymptoms !== null ||
              response.data.data.negativeThinkingSymptoms !== null ||
              response.data.data.physicalEmotionalReactions !== null
            ) {
              this.setState({
                avoidanceSymptoms: response.data.data.avoidanceSymptoms,
                intrusiveMemoriesSymptoms:
                  response.data.data.intrusiveMemoriesSymptoms,
                negativeThinkingSymptoms:
                  response.data.data.negativeThinkingSymptoms,
                physicalEmotionalReactions:
                  response.data.data.physicalEmotionalReactions,
              });
            }
          }
        }
      })
      .catch((e) => {});
  };

 formatDate(startWarzonePeriod,endWarzonePeriod) {
  let start = moment(startWarzonePeriod, "YYYY-MMMM");
  let end = moment(endWarzonePeriod, "YYYY-MMMM");

  let startDate = start.toLocaleString();
  let endDate = end.toLocaleString()     
    
    this.setState({
        startWarzonePeriod: startDate,
        endWarzonePeriod: endDate
      })

}

  handleChange(e, string) {
    if (string === "servedMilitary") {
      if (e.target.value === "yes") {
        this.setState({
          servedMilitary: e.target.value,
          showBranchMilitaryService: true,
          showMilitaryServiceQuestionFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          servedMilitary: e.target.value,
          showBranchMilitaryService: false,
          showMilitaryServiceQuestionFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          servedMilitary: null,
          showBranchMilitaryService: false,
          showMilitaryServiceQuestionFlag: null,
          disabled: false,
        });
      }
    }
    if (string === "exposedToChemicals") {
      if (e.target.value === "yes") {
        this.setState({
          exposedToChemicals: e.target.value,
          showChemical: true,
          exposedToChemicalsFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          exposedToChemicals: e.target.value,
          showChemical: false,
          exposedToChemicalsFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          exposedToChemicals: null,
          showChemical: false,
          exposedToChemicalsFlag: null,
          disabled: false,
        });
      }
    }
    if (string === "symptomOfPtsd") {
      if (e.target.value === "yes") {
        this.setState({
          symptomOfPtsd: e.target.value,
          showStressDisorder: true,
          symptomOfPtsdFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          symptomOfPtsd: e.target.value,
          showStressDisorder: false,
          symptomOfPtsdFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          symptomOfPtsd: null,
          showStressDisorder: false,
          symptomOfPtsdFlag: null,
          disabled: false,
        });
      }
    }
    if (string === "exposedToWarzone") {
      if (e.target.value === "yes") {
        this.setState({
          exposedToWarzone: e.target.value,
          shoWarZone: true,
          exposedToWarzoneFlag: true,
          disabled: false,
        });
      } else if (e.target.value === "no") {
        this.setState({
          exposedToWarzone: e.target.value,
          shoWarZone: false,
          exposedToWarzoneFlag: false,
          disabled: false,
        });
      } else if (e.target.value === "") {
        this.setState({
          exposedToWarzone: e.target.value,
          shoWarZone: false,
          exposedToWarzoneFlag: null,
          disabled: false,
        });
      }
    }
    if (string === "branchOfService") {
      this.setState({
        branchOfService: e.target.value,
      });
      let fields = this.state.fields;
      fields["branchOfService"] = e.target.value;
      this.setState({ fields, disabled: false });
    }
  }

  addchemicals(e) {
    e.preventDefault();
    let array = this.state.chemicals;
    array.push({ id: array.length + 1, name: "" });
    let x = this.state.hideShowChemical;
    x.push({ index: x.length + 1, value: false });
    let select = this.state.hideShowChemicalSelect;
    select.push({ index: select.length + 1, selectValue: true });
    this.setState({
      chemicals: array,
      hideShowChemical: x,
      hideShowChemicalSelect: select,
      disabled: false,
    });
  }

  handleInputExposedChemicals(e, index) {
    let nextExposedChemicalsData = this.state.chemicals.slice();
    nextExposedChemicalsData[index].name = e.currentTarget.value;
    this.checkChemicalName(nextExposedChemicalsData[index].name, index);
    this.setState({ chemicals: nextExposedChemicalsData, disabled: false });
  }

  checkChemicalName(name, index) {
    let fields = this.state.fields;
    fields.chemicalName = name;
    let isValid = Validation.militaryInformation(
      this.state.fields,
      "militaryInformation"
    ).formIsValid;
    if (isValid === false) {
      let x = this.state.hideShowChemical;
      x[index].value = true;
      this.setState(
        {
          hideShowChemical: x,
          disabled: false,
        },
        () => {}
      );
    }
    if (isValid === true) {
      let x = this.state.hideShowChemical;
      x[index].value = false;
      let select = this.state.hideShowChemicalSelect;
      x[index].selectValue = true;
      this.setState(
        {
          hideShowChemical: x,
          hideShowChemicalSelect: select,
          disabled: false,
        },
        () => {}
      );
    }
  }

  remove(idx, e) {
    let nextExposedChemicalsData = this.state.chemicals;
    nextExposedChemicalsData.splice(idx, 1);
    this.setState({ chemicals: nextExposedChemicalsData, disabled: false });
  }
  handleWarZoneChange(value, dateString) {
    let militaryServiceInformation = this.state;
    var startWarzonePeriod = moment(value, "YYYY-MMMM");
    militaryServiceInformation.startWarzonePeriod =  startWarzonePeriod.toLocaleString();
    this.setState({
      militaryServiceInformation: militaryServiceInformation,
      disabled: false,
    });
  }
  handleEndWarZoneChange(value, dateString) {
    let militaryServiceInformation = this.state;
    var endWarzonePeriod = moment(value, "YYYY-MMMM");
    militaryServiceInformation.endWarzonePeriod =  endWarzonePeriod.toLocaleString();
    this.setState({
      disabled: false,
      militaryServiceInformation: militaryServiceInformation,
    });
  }
  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields, disabled: false });
  }

  handleCheckboxesIntrusive(field, e) {
    let intrusiveMemoriesSymptoms = this.state.intrusiveMemoriesSymptoms;
    intrusiveMemoriesSymptoms[field] = e.target.checked;
    this.setState({ intrusiveMemoriesSymptoms, disabled: false });
  }

  handleCheckboxesPhysical(field, e) {
    let physicalEmotionalReactions = this.state.physicalEmotionalReactions;
    physicalEmotionalReactions[field] = e.target.checked;
    this.setState({ physicalEmotionalReactions, disabled: false });
  }

  handleCheckboxesNegative(field, e) {
    let negativeThinkingSymptoms = this.state.negativeThinkingSymptoms;
    negativeThinkingSymptoms[field] = e.target.checked;
    this.setState({ negativeThinkingSymptoms, disabled: false });
  }

  handleCheckboxAvoidance(field, e) {
    let avoidanceSymptoms = this.state.avoidanceSymptoms;
    avoidanceSymptoms[field] = e.target.checked;
    this.setState({ avoidanceSymptoms, disabled: false });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const militaryServiceInformation = this.state.fields;
    militaryServiceInformation.warzoneName = this.state.fields.warzoneName;

    if(this.state.startWarzonePeriod !== null) {
      const startDate = moment(this.state.startWarzonePeriod);
      militaryServiceInformation.startWarzonePeriod = startDate.format('YYYY-MMMM');
    } else {
      militaryServiceInformation.endWarzonePeriod = null
    }

    if(this.state.endWarzonePeriod !== null) {
      const endDate = moment(this.state.endWarzonePeriod);
      militaryServiceInformation.endWarzonePeriod = endDate.format('YYYY-MMMM')
    } else {
      militaryServiceInformation.endWarzonePeriod = null
    }

    militaryServiceInformation.intrusiveMemoriesSymptoms = this.state.intrusiveMemoriesSymptoms;
    militaryServiceInformation.avoidanceSymptoms = this.state.avoidanceSymptoms;
    militaryServiceInformation.physicalEmotionalReactions = this.state.physicalEmotionalReactions;
    militaryServiceInformation.negativeThinkingSymptoms = this.state.negativeThinkingSymptoms;
    militaryServiceInformation.branchOfService = this.state.fields.branchOfService;
    militaryServiceInformation.highestRank = this.state.fields.highestRank;
    militaryServiceInformation.chemicals = this.state.chemicals;
    militaryServiceInformation.exposedToWarzone = this.state.exposedToWarzoneFlag;
    militaryServiceInformation.exposedToChemicals = this.state.exposedToChemicalsFlag;
    militaryServiceInformation.symptomOfPtsd = this.state.symptomOfPtsdFlag;
    militaryServiceInformation.servedMilitary = this.state.showMilitaryServiceQuestionFlag;
    if (this.state.patientStatus === "physician") {
      militaryServiceInformation.userId = this.state.userID;
    }
    this.setState({
      fields: militaryServiceInformation,
    });
    await axios
      .post(
        environment.endPoint + "intake/militaryService",
        militaryServiceInformation,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.callCompleted();
        }
      })
      .catch((e) => {
        if (e.response !== undefined) {
          if (e.response.status === 400) {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }
      });
  };

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true,
    });
    var myButtonClasses = document.getElementById("military").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings ">
            Military Service
          </h5>

          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="custom-form-row">
                <select
                  value={this.state.servedMilitary}
                  className={
                    this.state.servedMilitary === "" ||
                    this.state.servedMilitary === null ||
                    this.state.servedMilitary === undefined
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={(e) => {
                    this.handleChange(e, "servedMilitary");
                  }}
                >
                  <option value="">
                    {" "}
                    Do you or have you served in the Military?
                  </option>
                  {militaryBranchService.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Do you or have you served in the Military?{" "}
                </label>
              </div>
            </div>
          </div>

          {this.state.showBranchMilitaryService === true ? (
            <div>
              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.fields.branchOfService}
                      className={
                        this.state.fields.branchOfService === "" ||
                        this.state.fields.branchOfService === null ||
                        this.state.fields.branchOfService === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "branchOfService");
                      }}
                    >
                      <option value=""> Select Branch Of Service</option>
                      {branchOfService.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Branch Of Service{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <input
                      className="form-control custom-form-control"
                      placeholder="Highest Rank"
                      value={this.state.fields.highestRank}
                      onChange={this.handleUserInputChange.bind(
                        this,
                        "highestRank"
                      )}
                    />
                    <label className="label-helper py-2 px-1">
                      Highest Rank{" "}
                    </label>
                  </div>
                  {/* <span className="text-danger">
                    {this.state.errors["highestRank"]}
                  </span> */}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.exposedToChemicals}
                      className={
                        this.state.exposedToChemicals === "" ||
                        this.state.exposedToChemicals === null ||
                        this.state.exposedToChemicals === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "exposedToChemicals");
                      }}
                    >
                      <option value="">
                        {" "}
                        Are you aware if you have been exposed to any chemicals?
                      </option>
                      {exposedChemicals.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Are you aware if you have been exposed to any chemicals?{" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showChemical === true ? (
                <div>
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <label className="text-center  stepper-form-sub-headings">
                        Please list which chemicals you were exposed to
                      </label>
                    </div>
                  </div>
                  {this.state.chemicals.map((value, index) => {
                    return (
                      <div key={`${value.id}-${index}`} className="row mb-4">
                        <div className="col-lg-11 col-12">
                          {this.state.hideShowChemicalSelect[index]
                            .selectValue === true ? (
                            <input
                              key={index}
                              type="text"
                              value={value.name || ""}
                              placeholder=""
                              className="form-control custom-form-control"
                              onChange={(e) =>
                                this.handleInputExposedChemicals(e, index)
                              }
                            />
                          ) : (
                            ""
                          )}
                          {/* {this.state.hideShowChemical[index].value === true ? (
                            <span className="text-danger">
                              {this.state.errors["chemicalName"]}
                            </span>
                          ) : (
                            ""
                          )} */}
                        </div>

                        {this.state.chemicals.length > 1 ? (
                          <div className="col-lg-1 col-2">
                            <label></label>
                            <div className="custom-btn">
                              <button
                                onClick={this.remove.bind(this, index)}
                                className="btn btn-danger child-btn border-radius mb-1"
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                  <div className="row mb-4">
                    <div className="col-lg-1 col-1">
                      <button
                        className="btn btn-primary child-btn border-radius pull-left plus-button"
                        onClick={this.addchemicals}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.exposedToWarzone}
                      className={
                        this.state.exposedToWarzone === "" ||
                        this.state.exposedToWarzone === null ||
                        this.state.exposedToWarzone === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "exposedToWarzone");
                      }}
                    >
                      <option value="">
                        {" "}
                        Have you been exposed to a war zone or combat?
                      </option>
                      {warZone.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Have you been exposed to a war zone or combat?{" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.shoWarZone === true ? (
                <div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-12">
                      <div className="custom-form-row">
                        <input
                          className="form-control custom-form-control"
                          placeholder="Name of Warzone You Were Exposed to"
                          value={this.state.fields.warzoneName}
                          onChange={this.handleUserInputChange.bind(
                            this,
                            "warzoneName"
                          )}
                        />
                        <label className="label-helper py-2 px-1">
                          Name of Warzone You Were Exposed to{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                   
                     <div className="col-lg-6 col-6">
                       <h4 className="stepper-form-sub-headings">
                       Start Date of Month & Year Spent in WarZone
                      </h4>
                      <div className="custom-form-row">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            
                            className={
                              this.state.startWarzonePeriod  === null
                                ? "form-control"
                                : "form-control"
                            }
                            placeholder="Start Date of Month & Year Spent in WarZone"
                            value={this.state.startWarzonePeriod}
                            views={["year", "month"]}
                            onChange={this.handleWarZoneChange}
                            disableFuture
                          />
                        </MuiPickersUtilsProvider>
                        <label className="label-helper py-2 px-1">
                          Start Date of Month & Year Spent in WarZone
                        </label>
                      </div>
                    </div>
                    
                     <div className="col-lg-6 col-6">
                     <h4 className="stepper-form-sub-headings">
                       End Date Month & Year Spent in WarZone
                      </h4>
                      <div className="custom-form-row">
                      
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            
                            className={
                              this.state.endWarzonePeriod === null
                                ? "form-control"
                                : "form-control"
                            }
                            placeholder="End Date Month & Year Spent in WarZone"
                            value={this.state.endWarzonePeriod}
                            views={["year", "month"]}
                            onChange={this.handleEndWarZoneChange}
                            disableFuture
                          />
                        </MuiPickersUtilsProvider>
                        <label className="label-helper py-2 px-1">
                         End Date Month & Year Spent in WarZone
                        </label>
                      </div>
                    </div>
                
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="custom-form-row">
                    <select
                      value={this.state.symptomOfPtsd}
                      className={
                        this.state.symptomOfPtsd === "" ||
                        this.state.symptomOfPtsd === null ||
                        this.state.symptomOfPtsd === undefined
                          ? "disabled-option form-control custom-form-control"
                          : "form-control custom-form-control"
                      }
                      onChange={(e) => {
                        this.handleChange(e, "symptomOfPtsd");
                      }}
                    >
                      <option value="">
                        {" "}
                        Do you think you have any symptoms of Post traumatic
                        stress disorder (PTSD)
                      </option>
                      {stressDisorder.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <label className="label-helper py-2 px-1">
                      Do you think you have any symptoms of Post traumatic
                      stress disorder (PTSD){" "}
                    </label>
                  </div>
                </div>
              </div>

              {this.state.showStressDisorder === true ? (
                <div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-12 mt-2">
                      <h4 className="stepper-form-sub-headings">
                        Intrusive memories symptoms:
                      </h4>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check11"
                          value={"flashbacks"}
                          checked={
                            this.state.intrusiveMemoriesSymptoms?.flashbacks
                          }
                          onChange={this.handleCheckboxesIntrusive.bind(
                            this,
                            "flashbacks"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check11">
                          Flashbacks
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check12"
                          value={"upsettingNightmares"}
                          checked={
                            this.state.intrusiveMemoriesSymptoms
                              ?.upsettingNightmares
                          }
                          onChange={this.handleCheckboxesIntrusive.bind(
                            this,
                            "upsettingNightmares"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check12">
                          Upsetting nightmares
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check23"
                          value={"unwantedMemories"}
                          checked={
                            this.state.intrusiveMemoriesSymptoms
                              ?.unwantedMemories
                          }
                          onChange={this.handleCheckboxesIntrusive.bind(
                            this,
                            "unwantedMemories"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check23">
                          Unwanted memories
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check31"
                          value={"emotionalReactionsToEventTriggers"}
                          checked={
                            this.state.intrusiveMemoriesSymptoms
                              ?.emotionalReactionsToEventTriggers
                          }
                          onChange={this.handleCheckboxesIntrusive.bind(
                            this,
                            "emotionalReactionsToEventTriggers"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check31">
                          Extreme/emotional reactions to event triggers
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-12 mt-2">
                      <h4 className="stepper-form-sub-headings">
                        Negative changes in thinking and mood symptoms:
                      </h4>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check1333"
                          value={"difficultywithmemory"}
                          checked={
                            this.state.negativeThinkingSymptoms
                              ?.difficultywithmemory
                          }
                          onChange={this.handleCheckboxesNegative.bind(
                            this,
                            "difficultywithmemory"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check1333">
                          Difficulty with memory
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check13331"
                          value={"hopelessness"}
                          checked={
                            this.state.negativeThinkingSymptoms?.hopelessness
                          }
                          onChange={this.handleCheckboxesNegative.bind(
                            this,
                            "hopelessness"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Check13331"
                        >
                          Hopelessness
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check2222"
                          value={"negativeThoughts"}
                          checked={
                            this.state.negativeThinkingSymptoms
                              ?.negativeThoughts
                          }
                          onChange={this.handleCheckboxesNegative.bind(
                            this,
                            "negativeThoughts"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check2222">
                          Negative thoughts
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check333311"
                          value={"difficultyWithRelationships"}
                          checked={
                            this.state.negativeThinkingSymptoms
                              ?.difficultyWithRelationships
                          }
                          onChange={this.handleCheckboxesNegative.bind(
                            this,
                            "difficultyWithRelationships"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Check333311"
                        >
                          Difficulty with relationships
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check34444"
                          value={"detachment"}
                          checked={
                            this.state.negativeThinkingSymptoms?.detachment
                          }
                          onChange={this.handleCheckboxesNegative.bind(
                            this,
                            "detachment"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Check34444"
                        >
                          Detachment
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check344441"
                          value={"lackOfInterest"}
                          checked={
                            this.state.negativeThinkingSymptoms?.lackOfInterest
                          }
                          onChange={this.handleCheckboxesNegative.bind(
                            this,
                            "lackOfInterest"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Check344441"
                        >
                          Lack of interest
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-12 mt-2">
                      <h4 className="stepper-form-sub-headings">
                        Avoidance symptoms:
                      </h4>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check34444155"
                          value={"tryingToAvoid"}
                          checked={this.state.avoidanceSymptoms?.tryingToAvoid}
                          onChange={this.handleCheckboxAvoidance.bind(
                            this,
                            "tryingToAvoid"
                          )}
                        />
                        <label
                          className="form-check-label text-truncate"
                          htmlFor="Check34444155"
                        >
                          Trying to avoid thinking or talking about the
                          traumatic event
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check3444415555"
                          value={"avoidingPlaces"}
                          checked={this.state.avoidanceSymptoms?.avoidingPlaces}
                          onChange={this.handleCheckboxAvoidance.bind(
                            this,
                            "avoidingPlaces"
                          )}
                        />
                        <label
                          className="form-check-label text-truncate"
                          htmlFor="Check3444415555"
                        >
                          Avoiding places, activities or people that remind you
                          of the traumatic event
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-12 col-12 mt-2">
                      <h4 className="stepper-form-sub-headings">
                        Changes in physical and emotional reactions:
                      </h4>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check344441555522"
                          value={"beingOnGuard"}
                          checked={
                            this.state.physicalEmotionalReactions?.beingOnGuard
                          }
                          onChange={this.handleCheckboxesPhysical.bind(
                            this,
                            "beingOnGuard"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Check344441555522"
                        >
                          Being on guard
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check3444415555211"
                          value={"beingEasilyStartled"}
                          checked={
                            this.state.physicalEmotionalReactions
                              ?.beingEasilyStartled
                          }
                          onChange={this.handleCheckboxesPhysical.bind(
                            this,
                            "beingEasilyStartled"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Check3444415555211"
                        >
                          Being easily startled
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check155"
                          value={"insomnia"}
                          checked={
                            this.state.physicalEmotionalReactions?.insomnia
                          }
                          onChange={this.handleCheckboxesPhysical.bind(
                            this,
                            "insomnia"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check155">
                          Insomnia
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check156"
                          value={"difficultyConcentrating"}
                          checked={
                            this.state.physicalEmotionalReactions
                              ?.difficultyConcentrating
                          }
                          onChange={this.handleCheckboxesPhysical.bind(
                            this,
                            "difficultyConcentrating"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check156">
                          Difficulty concentrating
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check158"
                          value={"guiltOrShame"}
                          checked={
                            this.state.physicalEmotionalReactions?.guiltOrShame
                          }
                          onChange={this.handleCheckboxesPhysical.bind(
                            this,
                            "guiltOrShame"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check158">
                          Guilt or shame
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Check159"
                          value={"destructiveBehavior"}
                          checked={
                            this.state.physicalEmotionalReactions
                              ?.destructiveBehavior
                          }
                          onChange={this.handleCheckboxesPhysical.bind(
                            this,
                            "destructiveBehavior"
                          )}
                        />
                        <label className="form-check-label" htmlFor="Check159">
                          Destructive behavior
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true : false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default MilitaryService;
