import React, { Component } from "react";
import authServices from "../../../../services/authServices";
import DiagnosisModal from "../../../../components/auth/registration/modal/addDiagnose";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import ReactAutocomplete from "react-autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { environment } from "../../../../environment";
const stageCancer = [
  {
    value: "stage0",
    label: "Stage 0",
  },
  {
    value: "stage1",
    label: "Stage 1",
  },
  {
    value: "stage2",
    label: "Stage 2",
  },
  {
    value: "stage3",
    label: "Stage 3",
  },
  {
    value: "stage4",
    label: "Stage 4",
  },
];
class Diagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      onsetDate: null,
      hideShow: false,
      addModalDiagnoseShow: false,
      isCancer: false,
      cancerStage: "",
      fields: {},
      errors: {},
      showPrimaryDiagnose: false,
      diagnoseId: null,
      name: "",
      value: "",
      index: "",
      visibility: "",
      patientStatus: this.props.status,
      userID: this.props.userID,
      disabled: true,
      initilizeDiagnose: [],
      type: "",
      diagnosesList: {
        data: [
          {
            name: "",
            id: "",
          },
        ],
      },
      concerns: [
        {
          name: "",
        },
      ],
      token: localStorage.getItem("token"),
      validateTrue: true,
      filterednamesShow: true
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.addConcernsList = this.addConcernsList.bind(this);
    this.handleInputNameChange = this.handleInputNameChange.bind(this);
    this.handlePersonalDateChange = this.handlePersonalDateChange.bind(this);
    this.handleChangePrimaryDiagnose = this.handleChangePrimaryDiagnose.bind(
      this
    );
    this.handleChangeKey = this.handleChangeKey.bind(this);
  }

  componentDidMount() {
    this.diagnosisGetInformation()
  }


  diagnosisGetInformation = async () => {
    const data = this.state.patientStatus === "physician" ? "intake/diagnosis?userId=" + this.state.userID : "intake/diagnosis";

    await axios.get(environment.endPoint + data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        const responses = response.data.data;

        if (responses) {
          this.setState({
            onsetDate: responses.onsetDate
          })
          if (
            responses.name !== null ||
            responses.name !== ""
          ) {
            this.setState({
              name: responses.name,
              diagnoseId: responses.diagnoseId,
            });
          }
          let fields = this.state.fields;
          fields["icdCode"] = responses.icdCode;
          fields["name"] = responses.name;
          if (responses.isCancer === true) {
            this.setState({
              cancerStage: responses.cancerStage,
              isCancer: true
            });
          } else {
            this.setState({
              isCancer: false
            });
          }

          if (

            responses.concerns.length > 0
          ) {
            this.setState({ concerns: responses.concerns });
          } else if (response.concerns.length === 0) {
            this.setState({
              concerns: [
                {
                  name: "",
                },
              ]
            })
          }

          this.setState({ fields: responses });
        }

      })
      .catch((e) => {
      });
  }


  addConcernsList(e) {
    e.preventDefault();
    let array = this.state.concerns;
    array.push({ id: array.length + 1, name: "" });
    this.setState({
      concerns: array, disabled: false,
    });
  }
  handleInputNameChange(e, idx) {
    let nextConcernsData = this.state.concerns.slice();
    nextConcernsData[idx].name = e.currentTarget.value;
    this.setState({ concerns: nextConcernsData, disabled: false });
  }
  remove(idx, e) {
    let someArray = this.state.concerns;
    someArray.splice(idx, 1);
    this.setState({ concerns: someArray, disabled: false });
  }

  handleChange(e, string) {
    if (string === "cancerStage") {
      this.setState({
        cancerStage: e.target.value,
        disabled: false
      });
    }
  }
  handleChangeKey(e, string) {
    this.searchDiagnose(e.target.value);
    this.setState({
      name: e.target.value,
      disabled: false
    });
    if (this.state.diagnosesList.data.length === 0) {

      this.setState({ isCancer: false, visibility: "none", disabled: false });
    } else {
      if (this.state.diagnosesList.data.length > 0) {
        this.state.diagnosesList.data.forEach(
          // eslint-disable-next-line array-callback-return
          (optionName, index) => {
            if (optionName.isCancer === true) {
              this.setState({
                name: e.target.value,
                isCancer: true,
                visibility: "block",
                disabled: false
              });

            } else if (optionName.isCancer === false) {
              this.setState({ isCancer: false, name: e.target.value, visibility: "none", disabled: false });
            }
          }
        );
      }

    }
  }

  searchDiagnose(name) {
    authServices
      .getDiagnoseByName(name)
      .then((response) => {
        this.setState({
          diagnosesList: response.data,
        });
      if (response.data.data.length === 0) {
          this.setState({
            hideShow: true,
            isCancer: false,
            visibility: "none",
            disabled: false
          });
        } else if (response.data.data.length > 0) {
          var filterednames = response.data.data.filter(obj => {
            return (obj.name === this.state.name);
        });
        if(filterednames.length === 0) {
          this.setState({
            hideShow: true,
            isCancer: false,
            disabled: false,
            visibility: "block",
            filterednamesShow: false
          });
        } else {
          this.setState({
            hideShow: false,
            disabled: false,
            visibility: "block",
            filterednamesShow: true,
            diagnoseId : filterednames[0]?.id,
            isCancer: filterednames[0]?.isCancer
          });
        }       
        
        }
      })

      .catch((e) => {
        console.log(e);
      });
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
    var myButtonClasses = document.getElementById("Contacts").classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }

  addModalDiagnose(e) {
    e.preventDefault();
    this.setState({ addModalDiagnoseShow: true });
  }

  handlePersonalDateChange(value, dateString) {
    let diagnosisInformation = this.state;
    diagnosisInformation.onsetDate = value;
    this.setState({
      diagnosisInformation: diagnosisInformation,
      onsetDate: value,
      disabled: false
    });
  }

  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields, disabled: false });
  }
  handleValidation(key, name, isCancer) {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields["name"] === "") {
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "This field is required";
      }
    } else if(this.state.filterednamesShow === false && this.state.diagnoseId === null){
      formIsValid = false;
      this.setState({ showPrimaryDiagnose: true, hideShow: true });
      errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
    } else if (this.state.type === "" && this.state.diagnosesList.data.length === 0) {
      formIsValid = false;
      this.setState({ showPrimaryDiagnose: true, hideShow: true });
      errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
    }  else if (this.state.type === "AddDiagnose" && this.state.initilizeDiagnose.length > 0) {

      formIsValid = true;
      this.setState({ showPrimaryDiagnose: false, hideShow: false });
      errors["notFound"] = "";
    } else if (this.state.diagnoseId !== null || fields["name"] !== "") {
      formIsValid = true;
      errors["name"] = "";
      errors["notFound"] = "";
      this.setState({ showPrimaryDiagnose: false, hideShow: false });
    }

    if (isCancer === true) {
      formIsValid = false;
      errors["canncerStage"] = "This field is required";
      if (fields["cancerStage"] !== "") {
        formIsValid = true;
        errors["canncerStage"] = "";
        errors["notFound"] = "";
        this.setState({ showPrimaryDiagnose: false, hideShow: false });
      }
    }


    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChangePrimaryDiagnose(e, object) {
    if (object.id !== "" || object.id !== undefined || object.id !== null) {
      this.setState({
        name: e,
        diagnoseId: object.id,
        disabled: false
      });
      if (object.isCancer === true) {
        this.setState({
          name: e,
          diagnoseId: object.id,
          isCancer: true,
          disabled: false
        });
      } else if (object.isCancer === false) {
        this.setState({
          name: e,
          diagnoseId: object.id,
          isCancer: false,
          disabled: false
        });
      }
    }
    this.handleValidation(object.id, e, object.isCancer)
  }
  onSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidation(this.state.diagnoseId, this.state.name, this.state.isCancer)) {

      const diagnosisInformation = this.state.fields;
      diagnosisInformation.concerns = this.state.concerns;
      if (this.state.isCancer === true) {
        diagnosisInformation.cancerStage = this.state.cancerStage;
      } else if (this.state.isCancer === false) {
        diagnosisInformation.cancerStage = "";
      }
      diagnosisInformation.name = this.state.name;
      diagnosisInformation.diagnoseId = this.state.diagnoseId;

      if(this.state.onsetDate !== null) {
        var fullDate = moment(this.state.onsetDate, "MM-DD-YYYY");
        var date = fullDate.format("DD");
        var year = fullDate.format("YYYY");
        var month = fullDate.format("MM");
        const selectedDate = month + "-" + date + "-" + year;
        diagnosisInformation.onsetDate = this.state.onsetDate !== null || this.state.onsetDate === " " ? selectedDate : null;
      } else {
        diagnosisInformation.onsetDate = null
      }

      if (this.state.patientStatus === "physician") {
        diagnosisInformation.userId = this.state.userID;
      }
      this.setState({ diagnosisInformation: diagnosisInformation });

      await axios.post(environment.endPoint + "intake/diagnosis", diagnosisInformation, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success("Diagnosis Information Update Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({
              concerns: this.state.concerns
            })
            this.callCompleted();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error("Diagnosis Information Not Updated", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  }

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true
    })
    var myButtonClasses = document.getElementById("Diagnosis").classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }

  addModalDiagnoseClose = () =>
    this.setState({ addModalDiagnoseShow: false });
  diagnoseSearchStatus = () => {
    this.setState({ showPrimaryDiagnose: false, hideShow: false });
  }
  diagnoseId = (id, isCancer,name) => {
    console.log(name)
    this.setState({ diagnoseId: id, isCancer: isCancer,name: name });
  }
  initiliazeDiagnoseList = (diagnoseList, string) => {
    this.setState({
      initilizeDiagnose: diagnoseList,
      type: string
    });
  }


  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings ">Diagnosis { }</h5>
          <div className="row mt-3">
            <div className="col-lg-12 col-12">
              <div
                className={
                  this.state.name !== ""
                    ? "has-number custom-form-row autocomplete-input"
                    : "custom-form-row autocomplete-input"
                }>
                <ReactAutocomplete
                  menuStyle={{ display: this.state.visibility }}
                  items={this.state.diagnosesList.data}
                  getItemValue={(item) => item.name}
                  shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                  inputProps={{ placeholder: 'Principal / Primary Diagnosis', className: "form-control custom-form-control",
                 
                }}
                
                  renderItem={(item, isHighlighted, styles) => (
                    <div tabindex="0">
                      <div
                        key={item.id}
                        tabindex="0"
                        style={{
                          background: isHighlighted ? "#1473E6" : "white",
                          color: isHighlighted ? "white" : "#2C2D2F"
                        }}

                      >
                        {item?.name}
                      </div>
                    </div>

                  )}
                  value={this.state.name}
                  onChange={this.handleChangeKey}
                  onSelect={(value, object) => this.handleChangePrimaryDiagnose(value, object)}
                />
                <i
                  className="fa fa-2x fa-question-circle text-primary"
                  aria-hidden="true"
                  title="What is your primary diagnosis?"
                ></i>
                <label className=" label-helper py-2 px-1">
                  Principal / Primary Diagnosis{" "}
                  <strong className="text-danger">*</strong>
                </label>
                <span className="text-danger">
                  {this.state.errors["name"]}
                </span>
                {/* <span className="text-danger">
                  {this.state.errors["diagnoseId"]}
                </span> */}

                {this.state.hideShow === true ? (
                  <p className="pull-right mb-0 mb-4">
                    {this.state.showPrimaryDiagnose === true ? (
                      <span className="text-danger">
                        {this.state.errors["notFound"]}
                      </span>
                    ) : (
                      <span>Please Choose any from Suggested Diagnosis or</span>
                    )}
                    <Link
                      href={this.state.url}
                      onClick={this.addModalDiagnose.bind(this)}
                    >
                      {" "}
                      Add New Diagnose
                    </Link>{" "}
                  </p>
                ) : (
                  ""
                )}
                <DiagnosisModal
                  addModalDiagnoseShow={this.state.addModalDiagnoseShow}
                  onHideModal={this.addModalDiagnoseClose}
                  name={this.state.name}
                  onUpdate={this.diagnoseSearchStatus}
                  diagnoseId={this.diagnoseId}
                  initiliazeDiagnoseList={this.initiliazeDiagnoseList}
                />
              </div>

            </div>
          </div>
          {this.state.isCancer === true ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="custom-form-row">
                  <select
                    value={this.state.cancerStage}
                    className={
                      this.state.cancerStage === "" 
                        ? "disabled-option form-control custom-form-control"
                        : "form-control custom-form-control"
                    }
                    onChange={(e) => {
                      this.handleChange(e, "cancerStage");
                    }}
                  >
                    <option value="" disabled selected> Select Stage of Cancer</option>
                    {stageCancer.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <label className="label-helper py-2 px-1">
                    Stage of Cancer <strong className="text-danger">*</strong>
                  </label>
                  <span className="text-danger">
                    {this.state.errors["canncerStage"]}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="ICD-9 or ICD-10 Code (if known)"
                  value={this.state.fields.icdCode}
                  onChange={this.handleUserInputChange.bind(this, "icdCode")}
                />
                <label className="label-helper py-2 px-1">
                  ICD-9 or ICD-10 Code (if known){" "}
                </label>
                <i
                  className="fa fa-2x fa-question-circle text-primary"
                  aria-hidden="true"
                  title="This is the statistical classification for your
                        condition used by medical professionals. You are not
                        required to answer this question."
                ></i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className={
                this.state.onsetDate !== null
                  ? "has-number custom-form-row"
                  : "custom-form-row"
              }>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                >
                  <DatePicker
                    
                    placeholder="Date of Onset"
                    openTo="date"
                    value={this.state.onsetDate}
                    format="MM-dd-yyyy"
                    views={[
                      "year",
                      "month",
                      "date",
                    ]}
                    onChange={
                      this
                        .handlePersonalDateChange
                    }
                    disableFuture
                  />
                </MuiPickersUtilsProvider>

                <label className="label-helper py-2 px-1">Date of Onset</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row autocomplete-input">
                <input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="Duration"
                  value={this.state.fields.duration}
                  onChange={this.handleUserInputChange.bind(this, "duration")}
                />
                <label className="label-helper py-2 px-1">Duration</label>

                <i
                  className="fa fa-2x fa-question-circle text-primary"
                  aria-hidden="true"
                  title="How long has it been since you were diagnosed with
                        your primary disease or condition?"
                ></i>
              </div>
            </div>
          </div>
          <label className="text-left stepper-form-sub-headings">
            OTHER HEALTH CONCERNS - e.g. Diabetes, epilepsy, depression,
            anxiety, ADHD, etc.
          </label>
          <label className="text-primary stepper-form-sub-headings concerns-text">
            Concerns
          </label>
          {this.state.concerns.map((value, index) => {
            return (
              <div key={`${value.id}-${index}`} className="row mb-4">
                <div className="col-lg-11 col-11">
                  <input
                    key={index}
                    type="text"
                    value={value.name || ""}
                    className="form-control custom-form-control"
                    onChange={(e) => this.handleInputNameChange(e, index)}
                  />
                </div>
                {this.state.concerns.length > 1 ? <div className="col-lg-1 col-2">
                  <label></label>
                  <div className="custom-btn">
                    <button
                      onClick={this.remove.bind(this, index)}
                      className="btn btn-danger child-btn border-radius mb-1"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                </div> : ""}

              </div>
            );
          })}
          <div className="row mb-4">
            <div className="col-lg-1 col-1">
              <button
                className="btn btn-primary child-btn border-radius pull-left plus-button"
                onClick={this.addConcernsList}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true : false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Diagnosis;
