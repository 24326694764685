import React from "react";
import { PureComponent } from "react";
import Stepper from "bs-stepper";
import CreateShoop from "./create-shoop";
import DosingHistory from "./dosing-History";
import axios from "axios";
import { environment } from "../../../../environment";
export class ViewShoop extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.userID,
      dosingHistory: [],
      draftHistory: [],
      token: localStorage.getItem("token"),
    };
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#view-patient-shoop"), {
      linear: false,
      animation: true,
    });
  }

   dosingShoop(e){
   e.preventDefault();
   document
   .getElementById("test-l-1")
   .scrollIntoView(true);
    this.getDosingHistory()
  }

  getDosingHistory = async () => {
    const data = "physician/shoop?patientId=" + this.state.userId + "&isReady=" + 1
    await axios
      .get(
        environment.endPoint + data,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          this.setState({
            dosingHistory: response.data.data,
          });
        }
      })
      .catch((e) => {});
  };


  render() {
    return (
      <div className="patient-intake mb-5">
        <div className="">
          <div className="container">
            <div className="card card-border">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body p-0">
                    <div id="view-patient-shoop" class="bs-stepper">
                      <div className="row">
                        <div className="col-lg-4 col-md-3">
                          <div className="register-sidebar">
                            <div className=" d-lg-block">
                              <div className="bs-stepper-header">
                                <div
                                  className="step"
                                  data-target="#test-l-1"
                                  onClick={
                                    this.dosingShoop.bind(this)
                                  }
                                >
                                  <button className="step-trigger" id="dosingShoop">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Dosing History
                                    </span>
                                  </button>
                                </div>
                           
                                <div
                                  className="step"
                                  data-target="#test-l-3"
                                  id="createNewShoop"
                                  onClick={() =>
                                    document
                                      .getElementById("test-l-3")
                                      .scrollIntoView(true)
                                  }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                      Create New Shoop
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-9">
                          <div class="bs-stepper-content registration">
                            <div
                              className="bs-stepper-header content"
                              id="test-l-1"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                    Dosing History
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <DosingHistory userID={this.state.userId} dosingHistory={this.state.dosingHistory}></DosingHistory>
                            </div>
                            
                            <div
                              className="bs-stepper-header content"
                              id="test-l-3"
                            >
                              <div className="d-lg-none d-sm-block">
                                <div data-target="#test-l-2">
                                  <button className="step-trigger">
                                    <span className="bs-stepper-circle custom-stepper-circle circle-green"></span>
                                    <span className="bs-stepper-label custom-stepper-circle ">
                                    Create New Shoop
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <CreateShoop userID={this.state.userId}></CreateShoop>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewShoop;
