import React, { Component } from "react";
import moment from "moment";

export class MilitaryService extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }

  render() {
    const { patientProfile } = this.state;
    const check = moment(
      patientProfile?.MilitaryService?.startWarzonePeriod,
      "YYYY-MM-DD"
    );
    const StartWarzonePeriodMonth = check.format("MMMM");
    const StartWarzonePeriodYear = check.format("YYYY");

    const startDate = StartWarzonePeriodMonth + " - " + StartWarzonePeriodYear 

    const EndCheck = moment(
      patientProfile?.MilitaryService?.endWarzonePeriod,
      "YYYY-MM-DD"
    );
    const endWarzonePeriodMonth = EndCheck.format("MMMM");
    const endWarzonePeriodYear = EndCheck.format("YYYY");

    const endDate = endWarzonePeriodMonth + " - " +  endWarzonePeriodYear 

    return (
      <div className="card-body custom-inner-content disabled-input  ">
        <h5 className="stepper-form-headings">
          Military Service
        </h5>

        {patientProfile?.MilitaryService === null ? (
          <h4 className="font-italic">No Military Service Information Added</h4>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12 col-12">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <span className="field-label">
                          Served in the Military:
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile?.MilitaryService?.servedMilitary ===
                          true ? (
                            "Yes"
                          ) : patientProfile?.MilitaryService
                              ?.servedMilitary === null ? (
                            <p className="font-italic px-4">
                              Served in Military not Added
                            </p>
                          ) : (
                            "No"
                          )}
                        </span>
                      </td>
                    </tr>
                    {patientProfile?.MilitaryService?.servedMilitary ===
                    true ? (
                      <>
                        <tr>
                          <td>
                            <span className="field-label">
                              Branch of Service:{" "}
                            </span>
                            <span className="field-text">
                              {" "}
                              {patientProfile?.MilitaryService?.branchOfService}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="field-label">Highest Rank: </span>
                            <span className="field-text">
                              {" "}
                              {patientProfile?.MilitaryService?.highestRank}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="field-label">
                              Exposed to any chemicals:{" "}
                            </span>
                            <span className="field-text">
                              {" "}
                              {patientProfile.MilitaryService
                                ?.exposedToChemicals === true ? (
                                "Yes"
                              ) : patientProfile.MilitaryService
                                  ?.exposedToChemicals === null ? (
                                <p className="font-italic px-4">
                                  Exposed to any Chemicals not Added
                                </p>
                              ) : (
                                "No"
                              )}
                            </span>
                          </td>
                        </tr>
                        <h5 className="ml-2  mb-4">
                          {patientProfile?.MilitaryService
                            ?.exposedToChemicals === true
                            ? "Please list which chemicals you were exposed to:"
                            : ""}
                        </h5>
                        <div className="row">
                          <div className="col-lg-12 col-12">
                            <table class="table table-striped">
                              <tbody>
                                {patientProfile?.MilitaryService
                                  ?.exposedToChemicals === true ? (
                                  <>
                                    {patientProfile?.MilitaryService?.chemicals?.map(
                                      (value, index) => (
                                        <tr>
                                          <td>
                                            <span className="field-label">
                                              Chemicals you were exposed to:
                                            </span>
                                            <span className="field-text">
                                              {" "}
                                              {value.name}
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <tr>
                          <td>
                            <span className="field-label">
                              Exposed to a war zone or combat:
                            </span>
                            <span className="field-text">
                              {" "}
                              {patientProfile.MilitaryService
                                ?.exposedToWarzone === true ? (
                                "Yes"
                              ) : patientProfile.MilitaryService
                                  ?.exposedToWarzone === null ? (
                                <p className="font-italic px-4">
                                  War Zone or Combat not Added
                                </p>
                              ) : (
                                "No"
                              )}
                            </span>
                          </td>
                        </tr>
                        {patientProfile?.MilitaryService?.exposedToWarzone ===
                        true ? (
                          <>
                            <tr>
                              <td>
                                <span className="field-label">
                                  Name of Warzone You Were Exposed to:{" "}
                                </span>
                                <span className="field-text">
                                  {" "}
                                  {patientProfile?.MilitaryService?.warzoneName}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="field-label">
                                Start Date of Month & Year Spent in WarZone:{" "}
                                </span>
                                <span className="field-text">
                                  {" "}
                                  {startDate}
                                 
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="field-label">
                                End Date Month & Year Spent in WarZone:{" "}
                                </span>
                                <span className="field-text">
                                  {" "}
                                  {endDate}
                                 
                                </span>
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        <tr>
                          <td>
                            <span className="field-label">
                              Symptoms of Post traumatic stress disorder (PTSD):{" "}
                            </span>
                            <span className="field-text">
                              {" "}
                              {patientProfile.MilitaryService?.symptomOfPtsd ===
                              true ? (
                                "Yes"
                              ) : patientProfile.MilitaryService
                                  ?.symptomOfPtsd === null ? (
                                <p className="font-italic px-4">
                                  PTSD not Added
                                </p>
                              ) : (
                                "No"
                              )}
                            </span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {patientProfile?.MilitaryService?.symptomOfPtsd === true ? (
              <>
                <div className="row mb-4 ml-2">
                  <div className="col-lg-12 col-12 mt-2">
                    <h4 className="stepper-form-sub-headings">
                      Intrusive memories symptoms:
                    </h4>
                    <ul class="ml-4">
                      {patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms?.flashbacks === true ? (
                        <li> Flashbacks</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms?.upsettingNightmares ===
                      true ? (
                        <li> Upsetting nightmares</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms?.unwantedMemories ===
                      true ? (
                        <li> Unwanted memories</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms
                        ?.emotionalReactionsToEventTriggers === true ? (
                        <li> Extreme/emotional reactions to event triggers</li>
                      ) : (
                        ""
                      )}
                    </ul>
                        {patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms
                        ?.emotionalReactionsToEventTriggers === false 
                        && patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms?.upsettingNightmares === false &&
                        patientProfile?.MilitaryService
                        ?.intrusiveMemoriesSymptoms?.unwantedMemories ===
                      false && patientProfile?.MilitaryService
                      ?.intrusiveMemoriesSymptoms
                      ?.emotionalReactionsToEventTriggers === false  ? 

                          <p className="font-italic px-4">Intrusive Memories Symptoms not Added</p> : "" }
                  </div>
                </div>
                <div className="row mb-4 ml-2">
                  <div className="col-lg-12 col-12 mt-2">
                    <h4 className="stepper-form-sub-headings">
                      Negative changes in thinking and mood symptoms:
                    </h4>
                    <ul class="ml-4">
                      {patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.difficultywithmemory === true ? (
                        <li> Difficulty with memory</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.hopelessness === true ? (
                        <li> Hopelessness</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.negativeThoughts === true ? (
                        <li> Negative thoughts</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.difficultyWithRelationships === true ? (
                        <li> Difficulty with relationships</li>
                      ) : (
                        ""
                      )}

                      {patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.detachment === true ? (
                        <li> Detachment</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.lackOfInterest === true ? (
                        <li> Lack of interest</li>
                      ) : (
                        ""
                      )}
                    </ul>
                    {
                      patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.difficultywithmemory === false &&
                        patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.lackOfInterest === false && patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.detachment === false && patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.difficultyWithRelationships === false && patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.negativeThoughts === false && patientProfile?.MilitaryService?.negativeThinkingSymptoms
                        ?.hopelessness === false  


                        ? <p className="font-italic px-4">Negative changes in thinking and mood symptoms not Added</p> : ""
                    }
                    
                  </div>
                </div>
                <div className="row mb-4 ml-2">
                  <div className="col-lg-12 col-12 mt-2">
                    <h4 className="stepper-form-sub-headings">
                      Avoidance symptoms:
                    </h4>
                    <ul class="ml-4">
                      {patientProfile?.MilitaryService?.avoidanceSymptoms
                        ?.tryingToAvoid === true ? (
                        <li>
                          {" "}
                          Trying to avoid thinking or talking about the
                          traumatic event
                        </li>
                      ) : (
                        ""
                      )}

                      {patientProfile?.MilitaryService?.avoidanceSymptoms
                        ?.avoidingPlaces === true ? (
                        <li>
                          {" "}
                          Avoiding places, activities or people that remind you
                          of the traumatic event
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    {
                      patientProfile?.MilitaryService?.avoidanceSymptoms
                      ?.tryingToAvoid === false &&
                      patientProfile?.MilitaryService?.avoidanceSymptoms
                      ?.avoidingPlaces === false  
                        ? <p className="font-italic px-4">Avoidance symptoms not Added</p> : ""
                    }
                  </div>
                </div>
                <div className="row mb-4 ml-2">
                  <div className="col-lg-12 col-12 mt-2">
                    <h4 className="stepper-form-sub-headings">
                      Changes in physical and emotional reactions:
                    </h4>

                    <ul class="ml-4">
                      {patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.beingOnGuard === true ? (
                        <li> Being on guard</li>
                      ) : (
                        ""
                      )}

                      {patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.beingEasilyStartled ===
                      true ? (
                        <li> Being easily startled</li>
                      ) : (
                        ""
                      )}

                      {patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.insomnia === true ? (
                        <li> Insomnia</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions
                        ?.difficultyConcentrating === true ? (
                        <li> Difficulty concentrating</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.guiltOrShame === true ? (
                        <li> Guilt or shame</li>
                      ) : (
                        ""
                      )}
                      {patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.destructiveBehavior ===
                      true ? (
                        <li> Destructive behavior</li>
                      ) : (
                        ""
                      )}
                    </ul>

                    {
                      patientProfile?.MilitaryService
                      ?.physicalEmotionalReactions?.destructiveBehavior === false &&
                      patientProfile?.MilitaryService
                      ?.physicalEmotionalReactions?.guiltOrShame === false && 
                      patientProfile?.MilitaryService
                      ?.physicalEmotionalReactions
                      ?.difficultyConcentrating === false && 
                      patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.insomnia === false &&
                        patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.beingEasilyStartled === false && 
                        patientProfile?.MilitaryService
                        ?.physicalEmotionalReactions?.beingOnGuard === false
                        ? <p className="font-italic px-4"> Changes in physical and emotional reactions not Added</p> : ""
                    }
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  }
}

export default MilitaryService;
