import React, { Component } from "react";

export class checkMail extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="row mb-5">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <form>
            <div className="">
              <h1 className="text-center stepper-form-headings">
                <i className="fa fa-5x fa-check-circle-o text-success"></i>
              </h1>
              <div className="row mb-3">
                <div className="col-lg-12 col-12">
                  <div className="">
                    <h3
                      className="text-center mb-2 text-dark"
                      id="exampleModalLabel"
                    >
                      <strong> Thank You For Registering</strong>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <h1 className="verify-modal-title verify-inner-content text-center text-primary">
                    Welcome to Octopi Wellness
                  </h1>
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-12 col-12">
                  <div>
                    <h3 className="text-center  paragraph-help">
                      Help is on the Way!
                    </h3>
                    <p className="text-center ">
                      Please check your email (and Spam folder) to confirm your
                      registration to OCTOPI Wellness
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default checkMail;
