import React, { Component } from "react";
import moment from "moment"
export class CannabisUse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientProfile: {},
      useMethod: {},
      useMethodMessage: "",
      cannabis: {
        NeverTriedit: "Cannabis Naive (Never tried it)",
        TriedItYears10Ago: "Tried more than 10 years ago",
        TriedItYears5Ago: "Tried more than 5 years ago",
        CurrentlyUseOccasionally: "Currently use occasionally",
        CurrentlyUseDaily: "Currently use daily",
      },
    };
  }

  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }



  render() {
    const { patientProfile } = this.state;

    return (
      <div className="card-body custom-inner-content disabled-input ">
        <h5 className="stepper-form-headings ">
          Cannabis Use
        </h5>
        <div className="row">
          <div className="col-lg-12 col-12">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <span className="field-label">
                      Previous Experince with Cannabis:{" "}
                    </span>
                    <span className="field-text">
                      {" "}
                      {
                        this.state.cannabis[
                          patientProfile.CannabisUse?.previousExperience
                        ]
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="field-label">Tolerance: </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.CannabisUse?.tolerance}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="field-label">Amount: </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.CannabisUse?.amount} (mg)
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="field-label">Frequency: </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.CannabisUse?.frequency} (per day)
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="field-label">
                      How you Feel when using cannabis:{" "}
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.CannabisUse?.feelingUsingCannabis}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="field-label">
                      Do you have a favorite cultivar (strain) of cannabis?
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.CannabisUse?.favoriteCultivar === true
                        ? "Yes"
                        : patientProfile.CannabisUse?.favoriteCultivar === null
                       
                        ?  <p className="font-italic px-4">Favorite Cultivar not Added</p>
                        : "No"}
                    </span>
                  </td>
                </tr>
                {patientProfile.CannabisUse?.favoriteCultivar === false ||
                patientProfile.CannabisUse?.favoriteCultivar === null ? (
                  ""
                ) : (
                  <>
                    <tr>
                      <td>
                        <span className="field-label">
                          Favorite Cultivar Name:{" "}
                        </span>
                        <span className="field-text">
                          {" "}
                          {patientProfile.CannabisUse?.cultivarName}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="field-label">
                          Why you Like this Cultivar:{" "}
                        </span>
                        <span className="field-text">
                          {patientProfile.CannabisUse?.favoriteCultivarReason}
                        </span>
                      </td>
                    </tr>{" "}
                  </>
                )}

                <tr>
                  <td>
                    <span className="field-label">
                      Are you currently using cannabis for your symptoms?
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile.CannabisUse?.cannabisForSymptoms === true
                        ? "Yes"
                        : patientProfile.CannabisUse?.cannabisForSymptoms ===
                          null
                        ? <p className="font-italic px-4">Cannabis Symptoms not Added</p>
                        : "No"}
                    </span>
                  </td>
                </tr>
                {patientProfile.CannabisUse?.cannabisForSymptoms === null ||
                patientProfile.CannabisUse?.cannabisForSymptoms === false ? (
                  ""
                ) : (
                  <tr>
                    <td>
                      <span className="field-label">Product Type: </span>
                      <span className="field-text">
                        {patientProfile.CannabisUse?.productType}
                      </span>
                    </td>
                  </tr>
                )}

                <tr>
                  <td>
                    <span className="field-label">
                      Are you currently using the Product(s) under medical
                      supervision?
                    </span>
                    <span className="field-text font-size-10 ">
                      {" "}
                      {patientProfile.CannabisUse?.medicalSupervision === true
                        ? "Yes"
                        : patientProfile.CannabisUse?.medicalSupervision ===
                          null
                        ? <p className="font-italic px-4">Medical Supervision not Added</p>
                        : "No"}
                    </span>
                  </td>
                </tr>

                {patientProfile.CannabisUse?.medicalSupervision === false ||
                patientProfile.CannabisUse?.medicalSupervision === null ? (
                  ""
                ) : (
                  <tr>
                    <td>
                      <span className="field-label">
                        Start Date Of Treatment:{" "}
                      </span>
                      <span className="field-text">
                        {patientProfile.CannabisUse?.treatmentStartDate ?
          moment(patientProfile.CannabisUse?.treatmentStartDate).format(
            "MM-DD-YYYY"
          ) : "No Data Available"}
                      </span>
                    </td>
                  </tr>
                )}

                <tr>
                  <td>
                    <span className="field-label">
                      Cannabis Regimen Commitment:{" "}
                    </span>
                    <span className="field-text">
                      {patientProfile.CannabisUse?.cannabisRegimenCommitment}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="field-label">Medicine Source: </span>
                    <span className="field-text">
                      {patientProfile.CannabisUse?.medicineSource}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="field-label">
                      Receive Treatment Location:{" "}
                    </span>
                    <span className="field-text">
                      {patientProfile.CannabisUse?.treatmentLocatin}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 mt-2">
            <h4 className="stepper-form-sub-headings">
              What methods of administration have you tried?
            </h4>
            <ul class="ml-4">
              {patientProfile?.CannabisUse?.useMethod?.smokedflower === true ? <li> Smoked flower</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.vaporizedFlower === true ? <li> Vaporized flower</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.vaporizedOil === true ? <li> Vaporized oil</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.sublingual === true ? <li>Sublingual</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.suppository === true ? <li>Suppository</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.topical === true ? <li>Topical</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.tincture === true ? <li>Tincture</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.capsules === true ? <li>Capsules</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.edibles === true ? <li>Edibles</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.extract === true ? <li>Extract</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.oil === true ? <li>Oil</li> : "" }
              {patientProfile?.CannabisUse?.useMethod?.otherAdministration === true ? <li>Other</li> : "" }
            </ul>
{patientProfile?.CannabisUse?.useMethod?.smokedflower === false &&
 patientProfile?.CannabisUse?.useMethod?.vaporizedFlower === false &&
 patientProfile?.CannabisUse?.useMethod?.vaporizedOil === false && 
 patientProfile?.CannabisUse?.useMethod?.sublingual === false &&
 patientProfile?.CannabisUse?.useMethod?.suppository === false && 
 patientProfile?.CannabisUse?.useMethod?.topical === false && 
 patientProfile?.CannabisUse?.useMethod?.tincture === false && 
 patientProfile?.CannabisUse?.useMethod?.capsules === false  && 
 patientProfile?.CannabisUse?.useMethod?.edibles === false && 
 patientProfile?.CannabisUse?.useMethod?.extract === false && 
 patientProfile?.CannabisUse?.useMethod?.oil === false && 
 patientProfile?.CannabisUse?.useMethod?.otherAdministration === false ?   <p className="font-italic px-4">Methods of Administration not Added</p> : ""  }
          



          </div>
        </div>

        {patientProfile?.CannabisUse?.useMethod?.otherAdministration === true ? (
          <div className="row">
            <div className="col-lg-12 col-12">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <span className="field-label">
                        Your Method Of Administration :{" "}
                      </span>
                      <span className="field-text">
                        {" "}
                        {patientProfile?.CannabisUse?.useMethod?.otherAdministration === true
                          ? patientProfile?.CannabisUse?.methodAdministration
                          : ""}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-lg-12 col-12 mt-2">
            <h4 className="stepper-form-sub-headings">
              What is your objective?
            </h4>
            <ul class="ml-4">
              {patientProfile?.CannabisUse?.objective?.antiCancerTherapy === true ? <li> Anti-cancer Therapy</li> : "" }
              {patientProfile?.CannabisUse?.objective
                    ?.manageSideEffectsofChemo === true ? <li> Manage Side Effects of Chemo</li> : "" }
              {patientProfile?.CannabisUse?.objective?.painRelief === true ? <li> Pain Relief</li> : "" }
              {patientProfile?.CannabisUse?.objective?.mentalHealth === true ? <li> Mental Health</li> : "" }
              { patientProfile?.CannabisUse?.objective
                    ?.pharmaceuticalReplacement === true ? <li>Pharmaceutical Replacement</li> : "" }
              {patientProfile?.CannabisUse?.objective?.seizureControl === true ? <li>Seizure Control</li> : "" }
              {patientProfile?.CannabisUse?.objective?.other === true ? <li>Other</li> : "" }
            </ul>

{patientProfile?.CannabisUse?.objective?.antiCancerTherapy === false &&
patientProfile?.CannabisUse?.objective
?.manageSideEffectsofChemo === false &&
patientProfile?.CannabisUse?.objective?.painRelief === false &&
patientProfile?.CannabisUse?.objective?.mentalHealth === false &&
patientProfile?.CannabisUse?.objective
                    ?.pharmaceuticalReplacement === false &&
                    patientProfile?.CannabisUse?.objective?.seizureControl === false && patientProfile?.CannabisUse?.objective?.other ===  false
                     ?    <p className="font-italic px-4">Objective not Added</p> : "" }


         


          </div>
        </div>

        {patientProfile?.CannabisUse?.objective?.other === true ? (
          <div className="row">
            <div className="col-lg-12 col-12">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <span className="field-label">Patient Objective: </span>
                      <span className="field-text">
                        {" "}
                        {patientProfile?.CannabisUse?.objective?.other === true
                          ? patientProfile?.CannabisUse?.objectiveOther
                          : ""}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CannabisUse;
