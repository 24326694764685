import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {environment} from '../../../environment';

export class Setting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        twoFA: this.props.profileDetail.twoFA
        
      },
      loading: false,
      showValidate: true,
      token: localStorage.getItem("token")

    };
  }

  prev = () => {
    this.props.prev();
  };

  componentDidUpdate(prevProps, prevState){
    if(prevProps.profileDetail !== this.props.profileDetail){
      this.setState({
        fields : this.props.profileDetail
      });
    }

  }

  handleCheckboxes(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.checked;
    this.setState({ fields ,      showValidate: false,
    });
  }


  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    let twoFA = this.state.fields.twoFA; 
    const params = {
      twoFA: twoFA
    }
    await axios.post(environment.endPoint+"dashboard/profile", params, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
        })
    .then((response) => {
      if (response.status === 200 || response.status !== undefined) {
        toast.success("Settings Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        this.props.next();
        this.setState({
          loading: false,
          showValidate: true
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 400) {
        toast.error("Settings Not Updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    });
  };

  render() {
    return (
      <div className="card-body card-body-profile custom-inner-content">
        <h5 className="stepper-form-headings d-none d-lg-block">Settings</h5>

        <div className="row mb-4 mt-3">
          <div className="col-lg-6 col-12">
            <p className="">2 Factor Authentication</p>
          </div>
          <div className="col-lg-6 col-12">
            <div className="switch">
              <input
                id="two-Factor"
                type="checkbox"
                className="switch-input"
                checked={this.state.fields?.twoFA}
                onChange={this.handleCheckboxes.bind(
                  this,
                  "twoFA"
                )}
              />
              <label htmlFor="two-Factor" className="switch-label">
                Switch
              </label>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-lg-3 offset-lg-3 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit}
                disabled={
                  this.state.showValidate
                    ? true
                    : false
                }
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        
      </div>
    );
  }
}

export default Setting;
