import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from "../../../../environment";

export class PatientHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientHistory: {},
      data: {},
      patientStatus: this.props.status,
      userID:  this.props.userID,
      token: localStorage.getItem("token"),
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }
  prev(e) {
    e.preventDefault();
    this.props.prev();
  }

  handleCheckboxes(field, e) {
    let data = this.state.data;
    data[field] = e.target.checked;
    this.setState({ data });
  }

  componentDidMount() {

    this.getPatientHistory();
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const patientHistory = this.state.patientHistory;
    patientHistory.data = this.state.data;
    if(this.state.patientStatus === "physician"){
      let data = this.state.data;
      this.state.data.userId = this.state.userID;
      patientHistory.data = data;

    }
    this.setState({ patientHistory: patientHistory });

    await axios.post(environment.endPoint+"intake/patientHistory", patientHistory, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.callCompleted();
        }
      })
      .catch((e) => {
      });
  }

  getPatientHistory = async () => {
    const data = this.state.patientStatus === "physician" ? "intake/patientHistory?userId="+this.state.userID : "intake/patientHistory";

    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
    .then((response) => {
        if (response.data.data !== "") {
          if (response.data.data !== null) {
            this.setState({
              data: response.data.data,
            });
          }
        }
      })
      .catch((e) => {
        // if (e.response.status === 400) {
        //   toast.success("Please Check your Service Provider", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 3000,
        //   });
        // }
      });
  }


  callCompleted() {
    this.props.next();
    var myButtonClasses = document.getElementById("patientHistory").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">
            Patient History
          </h5>
          <label className="text-left stepper-form-sub-headings">
           Have you had any of the following problems in the past year?
          </label>
          <div className="row mb-4 mt-3">
            <div className="col-lg-4 col-12">
              <p className="text-left">Abdominal pain/bloating</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-4"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.abdominal}
                  onChange={this.handleCheckboxes.bind(this, "abdominal")}
                />
                <label htmlFor="switch-4" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
            {/* (heartburn) */}
              <p className="text-left">Acid reflux </p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-5"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.acid}
                  onChange={this.handleCheckboxes.bind(this, "acid")}
                />
                <label htmlFor="switch-5" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Alcoholism</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-6"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.alcoholism}
                  onChange={this.handleCheckboxes.bind(this, "alcoholism")}
                />
                <label htmlFor="switch-6" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Allergies</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-7"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.allergies}
                  onChange={this.handleCheckboxes.bind(this, "allergies")}
                />
                <label htmlFor="switch-7" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Anxiety</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-8"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.anxiety}
                  onChange={this.handleCheckboxes.bind(this, "anxiety")}
                />
                <label htmlFor="switch-8" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Asthma</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-9"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.asthma}
                  onChange={this.handleCheckboxes.bind(this, "asthma")}
                />
                <label htmlFor="switch-9" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Atrial fibrillation</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-10"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.atrialFibrillation}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "atrialFibrillation"
                  )}
                />
                <label htmlFor="switch-10" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Bladder/Kidney/Urinary problems</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-11"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.bladderKidneyUrinaryProblem}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "bladderKidneyUrinaryProblem"
                  )}
                />
                <label htmlFor="switch-11" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Breast problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-12"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.breastProblem}
                  onChange={this.handleCheckboxes.bind(this, "breastProblem")}
                />
                <label htmlFor="switch-12" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Cancer</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-13"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.cancer}
                  onChange={this.handleCheckboxes.bind(this, "cancer")}
                />
                <label htmlFor="switch-13" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">
              {/* (bleeding or clotting) */}
                Coagulation  problems
              </p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-14"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.coagulationProblem}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "coagulationProblem"
                  )}
                />
                <label htmlFor="switch-14" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <p className="text-left">Cholesterol problem</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-15"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.cholesterolProblem}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "cholesterolProblem"
                  )}
                />
                <label htmlFor="switch-15" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Chronic low back pain</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-16"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.chronicLowBackPain}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "chronicLowBackPain"
                  )}
                />
                <label htmlFor="switch-16" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <p className="text-left">Constipation/diarrhea</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-17"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.constipationDiarrhea}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "constipationDiarrhea"
                  )}
                />
                <label htmlFor="switch-17" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Coordination problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-18"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.coordinationProblem}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "coordinationProblem"
                  )}
                />
                <label htmlFor="switch-18" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <p className="text-left">Depression</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-19"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.depression}
                  onChange={this.handleCheckboxes.bind(this, "depression")}
                />
                <label htmlFor="switch-19" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Diabetes mellitus</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-20"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.diabetesMellitus}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "diabetesMellitus"
                  )}
                />
                <label htmlFor="switch-20" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Dizziness</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-21"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.dizziness}
                  onChange={this.handleCheckboxes.bind(this, "dizziness")}
                />
                <label htmlFor="switch-21" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Erectile dysfunction</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-22"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.erectileDysfunction}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "erectileDysfunction"
                  )}
                />
                <label htmlFor="switch-22" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Fatigue/weakness</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-23"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.fatigueWeakness}
                  onChange={this.handleCheckboxes.bind(this, "fatigueWeakness")}
                />
                <label htmlFor="switch-23" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Headaches</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-24"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.headaches}
                  onChange={this.handleCheckboxes.bind(this, "headaches")}
                />
                <label htmlFor="switch-24" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">
                Heart disease/chest pain/rhythm disturbance
              </p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-25"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.heartDisease}
                  onChange={this.handleCheckboxes.bind(this, "heartDisease")}
                />
                <label htmlFor="switch-25" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Hypertension (high blood pressure)</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-26"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.hypertension}
                  onChange={this.handleCheckboxes.bind(this, "hypertension")}
                />
                <label htmlFor="switch-26" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Irritable bowel syndrome</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-27"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.irritableBowelSyndrome}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "irritableBowelSyndrome"
                  )}
                />
                <label htmlFor="switch-27" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Memory loss</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-28"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.memoryLoss}
                  onChange={this.handleCheckboxes.bind(this, "memoryLoss")}
                />
                <label htmlFor="switch-28" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Migraines</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-29"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.migraines}
                  onChange={this.handleCheckboxes.bind(this, "migraines")}
                />
                <label htmlFor="switch-29" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Muscle/joint pain or swelling</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-30"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.musclePain}
                  onChange={this.handleCheckboxes.bind(this, "musclePain")}
                />
                <label htmlFor="switch-30" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Numbness</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-31"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.numbness}
                  onChange={this.handleCheckboxes.bind(this, "numbness")}
                />
                <label htmlFor="switch-31" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Osteopenia or Osteoporosis</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-32"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.osteopenia}
                  onChange={this.handleCheckboxes.bind(this, "osteopenia")}
                />
                <label htmlFor="switch-32" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Pain</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-33"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.pain}
                  onChange={this.handleCheckboxes.bind(this, "pain")}
                />
                <label htmlFor="switch-33" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Prostate problem</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-34"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.prostateProblem}
                  onChange={this.handleCheckboxes.bind(this, "prostateProblem")}
                />
                <label htmlFor="switch-34" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Sexual function problems</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-35"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.sexualFunctionProblems}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "sexualFunctionProblems"
                  )}
                />
                <label htmlFor="switch-35" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Skin problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-36"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.skinProblems}
                  onChange={this.handleCheckboxes.bind(this, "skinProblems")}
                />
                <label htmlFor="switch-36" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Sleep problems</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-37"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.sleepProblems}
                  onChange={this.handleCheckboxes.bind(this, "sleepProblems")}
                />
                <label htmlFor="switch-37" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Substance abuse</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-38"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.substanceAbuse}
                  onChange={this.handleCheckboxes.bind(this, "substanceAbuse")}
                />
                <label htmlFor="switch-38" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <p className="text-left">Thyroid problem</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-39"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.thyroidProblem}
                  onChange={this.handleCheckboxes.bind(this, "thyroidProblem")}
                />
                <label htmlFor="switch-39" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Vision/hearing problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-40"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.visionHearingProblem}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "visionHearingProblem"
                  )}
                />
                <label htmlFor="switch-40" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Weight loss/gain</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-41"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.weightLossGain}
                  onChange={this.handleCheckboxes.bind(this, "weightLossGain")}
                />
                <label htmlFor="switch-41" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Other problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-42"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.otherProblems}
                  onChange={this.handleCheckboxes.bind(this, "otherProblems")}
                />
                <label htmlFor="switch-42" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        
        
        </div>
      </form>
    );
  }
}

export default PatientHistory;
