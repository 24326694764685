import React, { Component } from 'react'

export class OneSetupCloser extends Component {
    render() {
        return (
    
          <div className="auth-form mb-5">    
          <form>
            <div className="custom-card consent-card">
              <div className="row mb-4 mt-4">
                <div className="col-lg-12 col-12">
                  <div className="custom-form-row">
                    <p className="consent-paragraph text-left">
                    Thank you for joining Octopi Wellness. You are one step closer to helping more patients utilize cannabis safely. You will receive notification once your application has been processed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
     
       );
      }
}

export default OneSetupCloser
