import React, { Component } from "react";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "bs-stepper/dist/css/bs-stepper.min.css";
import "./assets/scss/Stepper.scss";
import "./App.scss";
import { Spinner } from "reactstrap";
import Signup from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import SetPassword from "./components/auth/SetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import decode from "jwt-decode";
import { logout } from "./services/globalService";
import checkMail from "./components/auth/registration/template/checkMail";
import AddSymptoms from "./components/views/dashboard/health-checkup/AddSymptoms";
import Profile from "./components/views/profile-details/profile";
import ViewSymptoms from "./components/views/dashboard/health-checkup/ViewSymptoms";
import PhysicianSignup from "./components/auth/physician/PhysicianSignup";
import PhysicianPortalDashboard from "./components/views/physician-portal/physician-portal-dashboard";
import AddPatient from "./components/views/physician-portal/add-patient";
import setPasswordPhysician from "./components/auth/setPasswordPhysician";
import authServices from "./services/authServices";
import ListPatient from "./components/views/physician-portal/List-Patient";
import ExpireLink from "./components/auth/registration/template/epiredLinkPage";
import consent from "./components/auth/registration/template/consent";
import { createHashHistory } from 'history'
import IdleTimer from "react-idle-timer";

import ErrorBoundary from "./ErrorBoundary";
import PhysicianPatientProfile from "./components/views/physician-portal/physician-patient-profile";
import DosingHistory from "./components/views/dashboard/Dosing-History";
import PatientPhysicianProfile from "./components/views/dashboard/patient-physician-profile";
import PhysicianProfile from "./components/views/physician-portal/profile/Physician-Profile";
import OneSetupCloser from "./components/auth/registration/template/OneSetupCloser";
// import axios from "axios";
// import { environment } from "./environment";

const loading = (
  <div className="pt-3 text-center">
    <Spinner color="dark" />
  </div>
);
const Dashboard = React.lazy(() =>
  import("./components/views/dashboard/Dashboard")
);
const MyHealth = React.lazy(() =>
  import("./components/views/dashboard/myHealth/MyHealth")
);

const checkAuth = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    logout();
    return false;
  }

  try {
    const { exp } = decode(token);
    const date = Date.now().valueOf() / 1000;
    if (date > exp) {
      localStorage.removeItem("token");
      logout();
      // refreshToken();
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
};

// const refreshToken = async () => {
//   const params = {
//    refreshToken: localStorage.getItem("refreshToken"),
//    token: localStorage.getItem("token")
//   }
//  try {
//    const res = await axios
//    .put(environment.endPoint +"userAuth/refresh", params);
//    if (res.status === 200) {
//      // console.log(console.log(res.data.data.accessToken.replace("Bearer", "")))
//       localStorage.setItem("token", res.data.data.accessToken);
//       localStorage.setItem("refreshToken", res.data.data.refreshToken);
//    }
//  } catch (err) {
//    console.log(err);
//  }
// };


const ErrorBoundaryRole = ({userType, redirectPatient}) => {
  return (
    <Route
      render={(props) => (
        <div className="auth-form mb-5">
          <div className="custom-card">
            <h3 className="text-center stepper-form-headings">
            Unauthorized Access
            </h3>
            <h5 className="text-center mt-4 mb-4">
              <p>{userType === "patient" ? "Sorry,You, must be logged in as Physician to access Physician Portal" : "Sorry,You, must be logged in as Patient to access Patient Portal"}</p>
            </h5>
           
           <h5 className="text-center mt-4 mb-4">
           <p>{userType === "patient" ? "Click here to go back to Patient Dashboard" : "Click here to go back Physician Dashboard"}</p>

           </h5>

            <div className="row">
            <div className="col-lg-3 offset-lg-3 col-6">
              <button
                id="first"
                type="button"
                 onClick={() => redirectPatient()}
                className="btn btn-primary border-radius py-2  px-5 auth-role-button"
              >
                Dashboard
              </button>
            </div>
          </div>
       
          </div>
        </div>
      )}
    />
  );
};

const Authorization = ({
  component: Component,
  allowedRoles,
  userType,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          allowedRoles.includes(userType) ? (
            <Component {...props} />
          ) : (
               <ErrorBoundaryRole userType = {userType} redirectPatient = {()=> {
                 const data = userType === "patient" ? "/dashboard" : "/physician/dashboard";
                  history.push(data)
               }}/> 
          )
        ) : (
          <Redirect to={`/login?nextpath=${props.location.pathname}`} />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, allowedRoles,
  userType,  ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? userType === "patient" ? <Redirect to="/dashboard" /> : <Redirect to="/physician/dashboard" /> :  <Component {...props} />
    }
  />
);

const SignUpRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? <Redirect to="/dashboard" /> : <Component {...props} />
    }
  />
);

const PhysicianSignUpRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? <Redirect to="/dashboard" /> : <Component {...props} />
    }
  />
);


class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.state = {
      user: null,
      isLoggedIn: false,
      role: "",
      timeOut: false,
      userType: "none",
      patientDashboard: ["patient"],
      phyisicianDashboards: ["physician"],
    };
  }
  componentDidMount() {
    checkAuth()
      ? this.setState({ isLoggedIn: true })
      : this.setState({ isLoggedIn: false });
    const role = authServices.getUserRole();
    if (role === null) {
      this.setState({
        role: "",
      });
    } else {
      this.setState({
        role: role.name,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.role !== this.state.role) {
      this.setState({
        role: this.state.role,
      });
    }
  }

  render() {
    const { patientDashboard, phyisicianDashboards } = this.state;

    return (

      <div className="App">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 15}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />

        <HashRouter>
          <React.Suspense fallback={loading}>
            <Header
              isLoggedIn={this.state.isLoggedIn}
              logdedInHandler={(isLoggedIn) => this.setState({ isLoggedIn })}
              role={this.state.role}
            ></Header>
            <div>
              <Switch>
            

                <SignUpRoute
                  exact
                  path="/signup"
                  name="SignUp Page"
                  component={(props) => (
                    <Signup
                      userHandler={(user, isLoggedIn) =>
                        this.setState({ user, isLoggedIn })
                      }
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/completeSignUp/:code"
                  component={setPasswordPhysician}
                />
                <PhysicianSignUpRoute
                  exact
                  path="/physician-signup"
                  name="physician-signup"
                  component={(props) => (
                    <PhysicianSignup
                      userHandler={(user, isLoggedIn) =>
                        this.setState({ user, isLoggedIn })
                      }
                      {...props}
                    />
                  )}
                />
                <LoginRoute
                  exact
                  path="/login"
                  name="Login Page"
                  userType={this.state.role}
                  component={(props) => (
                    <div>
                      <Login
                        userHandler={(user, isLoggedIn) =>
                          this.setState({ user, isLoggedIn })
                        }
                        userHandlerRole={(role) => this.setState({ role })}
                        {...props}
                      />
                    </div>
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Redirect to="/login" />;
                  }}
                />

                <Route
                  exact
                  path="/setPassword/:code/:setPassword"
                  component={SetPassword}
                />

                <Route
                  exact
                  path="/setPassword/:code/:forgotPassword"
                  component={SetPassword}
                />
                <Route
                  exact
                  path="/forgotPassword"
                  component={ForgotPassword}
                />
                <Route exact path="/checkMail" component={checkMail} />
                <Route exact path="/expiredlink" component={ExpireLink} />
                <Route exact path="/complete-step" component={OneSetupCloser} />

                <Route exact path="/consent" component={consent} />

                <Authorization
                  exact
                  path="/profile"
                  component={Profile}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/dashboard"
                  name="dashboard"
                  component={Dashboard}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />

                <Authorization
                  path="/add-symptoms"
                  name="AddSymptoms"
                  component={AddSymptoms}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/health-journey"
                  name="ViewSymptoms"
                  component={ViewSymptoms}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/dosing-history"
                  name="dosingHistory"
                  component={DosingHistory}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/patient-physician-profile/:id"
                  name="PhysicianPatientProfile"
                  component={PatientPhysicianProfile}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />

                <Authorization
                  path="/myHealth"
                  name="myHealth"
                  component={MyHealth}
                  allowedRoles={[...patientDashboard]}
                  userType={this.state.role}
                />

                <Authorization
                  path="/physician/dashboard"
                  name="physicianDashboard"
                  component={PhysicianPortalDashboard}
                  allowedRoles={[...phyisicianDashboards]}
                  userType={this.state.role}
                />

                <Authorization
                  path="/physician/add-patient"
                  name="add-Patient"
                  component={AddPatient}
                  allowedRoles={[...phyisicianDashboards]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/physician/list-patient"
                  name="list-Patient"
                  component={ListPatient}
                  allowedRoles={[...phyisicianDashboards]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/physician/patient-profile/:id"
                  name="patient-profile"
                  component={PhysicianPatientProfile}
                  allowedRoles={[...phyisicianDashboards]}
                  userType={this.state.role}
                />
                <Authorization
                  path="/physician/update-profile/:id"
                  name="patient-profile"
                  component={PhysicianPatientProfile}
                  allowedRoles={[...phyisicianDashboards]}
                  userType={this.state.role}
                />
                  <Authorization
                  path="/physician/profile"
                  name="physician-profile"
                  component={PhysicianProfile}
                  allowedRoles={[...phyisicianDashboards]}
                  userType={this.state.role}
                />
                <Route
                  path="*"
                  render={(props) => <ErrorBoundary {...props} />}
                />
              </Switch>
            </div>

            <Footer />
          </React.Suspense>
        </HashRouter>
      </div>
    );
  }

  handleOnAction(event) {
    // console.log('user did something', event)
  }

  handleOnActive(event) {
    // console.log("user is active", event);
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle(event) {
    // console.log("user is idle");

    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("role");
    localStorage.removeItem("status");
    this.setState({
      isLoggedIn: false,
    });
    // console.log('last active', this.idleTimer.getLastActiveTime())
  }
}

export default App;
export const history = createHashHistory()
