import React, { Component } from "react";
import { toast } from "react-toastify";
import AuthService from "../../services/authServices";
import SuccessModal from "../auth/registration/modal/success-modal";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      fields: {},
      errors: {},
      addModalShow: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.navigation = this.navigation.bind(this);
  }

  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  HandleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "This field is required";
    }

    if (typeof fields["email"] !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      } else if (fields["email"].length > 255) {
        errors["email"] = "Please enter a short email";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.HandleValidation()) {
      const params = {
        email: this.state.fields.email,
      };
      AuthService.forgotPassword(params)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.setState({ addModalShow: true });
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else if (e.response.status === 500) {
            toast.error("Internal Server Error", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  }

  navigation() {
    this.props.history.push("/login");
  }

  render() {
    return (
      <div className="auth-form mb-5">
        <form onSubmit={this.onSubmit}>
          <div className="custom-card">
            <h3 className="text-center stepper-form-headings">
              Forgot Password
            </h3>
            <p className="text-center mt-1 ">
              Enter the email address associated with your account.
            </p>
            <div className="row mb-4 mt-3">
              <div className="col-lg-12 col-12">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control py-4"
                    placeholder="Email Address"
                    onChange={this.handleUserInputChange.bind(this, "email")}
                    value={this.state.fields.email}
                    type="text"
                  />
                  <label className="label-helper py-2 px-1">
                    Email Address <strong className="text-danger">*</strong>
                  </label>
                </div>
                <span className="text-danger">
                  {this.state.errors["email"]}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2 offset-lg-4 col-2 offset-4 mt-4">
                <button className="btn btn-primary border-radius py-2  px-4">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
        <SuccessModal
          onSubmit={this.navigation}
          show={this.state.addModalShow}
          headingText="Email Sent Successfully"
          innerHeadingText=""
          hide={false}
        ></SuccessModal>
      </div>
    );
  }
}

export default ForgotPassword;
