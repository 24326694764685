import React, { Component } from "react";
import "../../../assets/scss/physician.scss";
import totalPatient from "../../../assets/images/total-patients.svg";
import examination from "../../../assets/images/examination.svg";
import product from "../../../assets/images/product-arrow.svg";
import { Line, Bar, Pie } from "react-chartjs-2";
import axios from "axios";
import { environment } from "../../../environment";
import authServices from "../../../services/authServices";

const options = {
  // title: {
  //   display: true,
  //   text: 'Chart.js Line Chart',
  //   align: 'start',
  //   position: "top",

  // // },
  // layout: {
  //   margin: {
  //     left: 150,
  //     right: 150,
  //     top: 150,
  //     bottom: 150,
  //   },
  // },
  maintainAspectRatio: true,
  plugins: {
    datalabels: {
      display: true,
      color: "white",
      formatter: function (value, context) {
        console.log(value);
        // ctx.textAlign = "center";
        return context.dataset.label + "\n" + Math.round(value.x);
      },
    },
  },
  legend: {
    display: true,
    formatter: function (value, context) {
      console.log(value);
      // ctx.textAlign = "center";
      return context.dataset.label + "\n" + Math.round(value.x);
    },
    position: "top",
    align: "end",
    labels: {
      padding: 40,
      boxWidth: 60,
      boxHeight: 60,
      fontSize: 18,
      fontColor: "#444444",
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: true,
          display: true,
        },
        ticks: {
          display: true, //this will remove only the label
          fontColor: "#308C57",
          fontSize: 16,
          fontStyle: 'bold',
          fontFamily: "arial",
          beginAtZero: true,
          padding: 10,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: true,
          display: true,
        },
        ticks: {
          display: true, //this will remove only the label
          fontColor: "#1473E6",
          fontSize: 15,
          // fontStyle: "bold",
          fontFamily: "arial",
          stepSize: 1,
          // beginAtZero: true,
          padding: 25,
        },
      },
    ],
  },
};

export class PhysicianPortalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      physicianPortalDetail: {},
      topDiagnosis: [],
      firstDiagnose: "",
      secondDiagnose: "",
      thirdDiagnose: "",
      diagnosisValue: [],
      genderLabel: [],
      genderValue: [],
      patientMonthValue: [],
      patientCountValue: [],
      loading: false,
      top_objectives: [],
      objectivesLabel: [],
      objectivesCount: [],
      topProducts: [],
      notificationHideShow: false,

    };
  }

  componentDidMount() {
    const role = authServices.getUserRole();
      if(role.name === "physician"){
        this.getDashboardDetail();
      }
  }


  hide = () => {
    var body = document.body;

    body.classList.remove("overflow-hidden");

    this.setState({
      notificationHideShow: false
    })
  }
  getDashboardDetail = async () => {
    await axios
      .get(environment.endPoint + "physician/dashboard", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const physicianPortalDetail = response.data.data;
          const top_dignose = Object.keys(physicianPortalDetail.top_dignose);
          const genderStats = Object.keys(physicianPortalDetail.genderStats);
          const top_objectives = Object.keys(physicianPortalDetail.objectives);
          this.setState({
            physicianPortalDetail: physicianPortalDetail,
            firstDiagnose: physicianPortalDetail.top_dignose[top_dignose[0]],
            secondDiagnose: physicianPortalDetail.top_dignose[top_dignose[1]],
            thirdDiagnose: physicianPortalDetail.top_dignose[top_dignose[2]],
            loading: false,
            topProducts: physicianPortalDetail.top_products
          });

          this.getValueDiagosisData(physicianPortalDetail, top_dignose);
          this.getGenderValueData(physicianPortalDetail, genderStats);
          this.getPatientRegisteredData(physicianPortalDetail);
          this.getValueLableObjectives(physicianPortalDetail,top_objectives)
        }
      })
      .catch((e) => {});
  };

  getValueLableObjectives(physicianPortalDetail, top_objectives){
   let objectivesLabel = [];
   let objectivesCount = [];
   let objectiveslabels = {
    antiCancerTherapy: "Anti-cancer Therapy",
    pharmaceuticalReplacement: "Pharmaceutical Replacement",
    manageSideEffectsofChemo: "Manage Side Effects of Chemo",
    mentalHealth: "Mental Health",
    seizureControl: "Seizure Control",
    painRelief: "Pain Relief",
  }
   if(top_objectives.length > 0){
    top_objectives.forEach((label,index) => {
      objectivesLabel.push(objectiveslabels[label] ? objectiveslabels[label] : label );
      objectivesCount.push(physicianPortalDetail.objectives[top_objectives[index]]);
    })
   }
   this.setState({
    objectivesCount: objectivesCount,
    objectivesLabel: objectivesLabel
   })
  }

  getValueDiagosisData(physicianPortalDetail, top_dignose) {
    let option = [];
    let diagnosisLabel = [];
    if (top_dignose.length > 0) {
      top_dignose.forEach((label, index) => {
        if(index <= 4){
          diagnosisLabel.push(
            label.charAt(0).toUpperCase() + label.substr(1).toLowerCase()
          );
  
          option.push(physicianPortalDetail.top_dignose[top_dignose[index]]);
        }

      });
    } 
      this.setState({
        diagnosisValue: option,
        topDiagnosis: diagnosisLabel,
      });
     
   
  }

  getGenderValueData(physicianPortalDetail, genderStats) {
    console.log(physicianPortalDetail)
    console.log(genderStats)

    let option = [];
    let newlabel = {
      male: "Male",
      female: "Female",
      other: "Other",
      declineToAnswer: "Decline To Answer",
      notAnswered: "Haven't Answered"
    };
    let genderLabel = [];
    if (genderStats.length > 0) {
      genderStats.forEach((label, index) => {
        // if(physicianPortalDetail.genderStats[genderStats[index]] > 0) {
          option.push(physicianPortalDetail.genderStats[genderStats[index]]);
        // }
        genderLabel.push(newlabel[label]);
      });
    }

    this.setState({
      genderValue: option,
      genderLabel: genderLabel,
    });
  }

  getPatientRegisteredData(physicianPortalDetail) {
    let patientMonth = [];
    let patientCount = [];
    if (physicianPortalDetail.reg.length > 0) {
      physicianPortalDetail.reg.forEach((label, index) => {
        patientMonth.push(label.month);
        patientCount.push(parseInt(label.count));
      });
    }
    this.setState({
      patientMonthValue: patientMonth,
      patientCountValue: patientCount,
    });
  }

  barChartOptions = {
    legend: {
      display: false,
      position: "top",
      align: "end",
      color: "red",
      maxHeight: "100px",
      labels: {
        padding: 40,
        boxWidth: 60,
        boxHeight: 60,
        fontSize: 18,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: true,
          },
          ticks: {
            display: true, //this will remove only the label
            fontColor: "#308C57",
            fontSize: 15,
            fontFamily: "arial",
            beginAtZero: true,
            padding: 25,
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: true,
          },
          ticks: {
            display: true, //this will remove only the label
            fontColor: "#1473E6",
            fontSize: 14,
            // stepSize: 1,
          //   callback: function(label, index, labels) {
          // },
          
            beginAtZero: true,
            padding: 25,
            // maxRotation: 90,
            //         minRotation: 90
          },
        },
      ],
    },
  };
  pieOptions = {
    maintainAspectRatio: true,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 25,
        boxWidth: 20,
        boxHeight: 50,
        fontSize: 15,
        fontFamily: "arial",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          //get the concerned dataset
          var dataset = data.datasets[tooltipItem.datasetIndex];
          //calculate the total of this data set
          var total = dataset.data.reduce(function (
            previousValue,
            currentValue,
            currentIndex,
            array
          ) {
            return previousValue + currentValue;
          });
          //get the current items value
          var currentValue = dataset.data[tooltipItem.index];
          //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
          var percentage = Math.floor((currentValue / total) * 100 + 0.5);

          return percentage + "%";
        },
      },
    },
    elements: {
      margin: 10,
      arc: {
        borderWidth: 0,
      },
    },
  };

  redirectToAddPatient = () => {
    this.props.history.push("/physician/add-patient");
  };

  render() {
    const barData = {
      labels:
        this.state.objectivesLabel !== undefined ? this.state.objectivesLabel : "",
      datasets: [
        {
          data: this.state.objectivesCount,
          backgroundColor: "#1473E6",
          borderColor: "#1473E6",
          borderWidth: 1,
        },
      ],
    };
    const pieChartData = (canvas) => {
      const ctx = canvas.getContext("2d");
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#75C6ED");

      gradientStroke.addColorStop(1, "#FF8F3F");
      return {
        maintainAspectRatio: false,
        responsive: false,
        labels:
          this.state.genderLabel !== undefined ? this.state.genderLabel : "",

        datasets: [
          {
            data:
              this.state.genderValue !== undefined
                ? this.state.genderValue
                : "",
            backgroundColor: ["#75C6ED", "#308C57", "#FF8F3F", "#868687"],
            hoverBackgroundColor: [
              "#75C6ED",
              "#308C57",
              "#FF8F3F",
              "#868687",
            ],
          },
        ],
      };
    };

    const data = {
      labels:
        this.state.patientMonthValue !== undefined
          ? this.state.patientMonthValue
          : "",
      datasets: [
        {
          label: "# of Patients",
          fill: "-2",
          tension: 0.1,
          backgroundColor: "#B7D4F8",
          borderColor: "#1473E6",
          borderCapStyle: "square",
          borderDash: [], // try [5, 15] for instance
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#1473E6",
          pointBackgroundColor: "white",
          pointBorderWidth: 3,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: "white",
          pointHoverBorderColor: "#1473E6",
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data:
            this.state.patientCountValue !== undefined
              ? this.state.patientCountValue
              : "",
          spanGaps: true,
        },
      ],
    };

    return (
      <div className="physician-dasboard-start">
        <div className="bg-img">
          <div className="container">
            <div className="">
              <div className="row">
                <div className="col-md-4">
                  <div className="total-patient">
                    <div className="row">
                      <div className="col-sm-7">
                        <h4>Total Patients</h4>
                        <div className="d-flex">
                        <span className="count">
                          {this.state.physicianPortalDetail.patientsCount}
                        </span>
                        <button
                          className="btn add-new"
                          onClick={() => this.redirectToAddPatient()}
                        >
                          <p className="text-truncate">
                          <i class="fa fa-plus mt-1" aria-hidden="true"></i> 
                          &nbsp; Add
                          New
                          </p>
                          
                        </button>
                        </div>
                        
                      </div>
                      <div className="col-sm-5">
                        <div className="icon">
                          <img
                            src={totalPatient}
                            alt="TotalPatient"
                            className=""
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="top-diagnosis">
                    <div className="row">
                      <div className="col-sm-7">
                        <h4>Common Diagnosis</h4>
                        <ul>
                          <li>
                            {this.state.topDiagnosis[0] !== undefined
                              ? this.state.topDiagnosis[0]
                                  .charAt(0)
                                  .toUpperCase() +
                                this.state.topDiagnosis[0]
                                  .substr(1)
                                  .toLowerCase()
                              : ""}{" "}
                            {this.state.topDiagnosis[0] !== undefined ? (
                              <span> {this.state.firstDiagnose} </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            {this.state.topDiagnosis[1] !== undefined
                              ? this.state.topDiagnosis[1]
                                  .charAt(0)
                                  .toUpperCase() +
                                this.state.topDiagnosis[1]
                                  .substr(1)
                                  .toLowerCase()
                              : ""}{" "}
                            {this.state.topDiagnosis[1] !== undefined ? (
                              <span> {this.state.secondDiagnose} </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            {this.state.topDiagnosis[2] !== undefined
                              ? this.state.topDiagnosis[2]
                                  .charAt(0)
                                  .toUpperCase() +
                                this.state.topDiagnosis[2]
                                  .substr(1)
                                  .toLowerCase()
                              : ""}
                            {this.state.topDiagnosis[2] !== undefined ? (
                              <span> {this.state.thirdDiagnose} </span>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-5">
                        <div className="icon">
                          <img
                            src={examination}
                            alt="diagnosis"
                            className=""
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="top-products">
                    <div className="row">
                      <div className="col-sm-7">
                        <h4>Top Products</h4>
                        <ul>
                          {this.state.topProducts?.map((value,index) => 
                          <li>{value}</li>
                          )}
                        </ul>
                      </div>
                      <div className="col-sm-5">
                        <div className="icon">
                          <img src={product} alt="Product" className=""></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="charts-start">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="card border-0">
                  <div class="card-body">
                    <div class="chart">
                      <h5 className="stepper-form-headings line-charts-margins">
                        Registered Patients
                      </h5>

                      {this.state.patientMonthValue.length > 0 ||
                      this.state.patientCountValue.length > 0 ? (
                        <Line ref="chart" data={data} options={options}  />
                      ) : (
                        <h4 className="font-italic stepper-form-sub-headings text-center mt-4">
                          No Record Found For Registered Patients
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card border-0">
                  <div class="card-body">
                    <div class="chart pie-chart">
                      <h5 className="stepper-form-headings mb-4">
                        Gender Stats
                      </h5>

                      {/* {this.state.genderLabel.length > 0 ||
                      this.state.genderValue.length > 0 ? ( */}
                        <Pie
                          ref="chart"
                          data={pieChartData}
                          width={200}
                          height={200}
                          options={this.pieOptions}
                        />
                      {/* ) : (
                        <h4 className="font-italic stepper-form-sub-headings text-center">
                          No Record Found For Gender Stats
                        </h4>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
            <div class="row mb-4">
              <div class="col-md-12">
                <div class="card border-0 mb-5">
                  <div class="card-body">
                    <div class="chart">
                      <h5 className="stepper-form-headings mb-4 ml-4">
                        Top Objectives
                      </h5>
                      {this.state.objectivesLabel.length > 0 ||
                      this.state.objectivesCount.length > 0 ? (
                        <Bar
                          ref="chart"
                          data={barData}
                          options={this.barChartOptions}
                        />
                      ) : (
                        <h4 className="font-italic stepper-form-sub-headings text-center">
                          No Record Found For Top Objectives
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            

            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PhysicianPortalDashboard;
