import React, { Component } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { environment } from "../../../environment";
import { toast } from "react-toastify";
import User from "../../../assets/images/user.png";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import {Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
// const truncate = (input) => input.length > 5 ? `${input.substring(0, 5)}...` : input;


export class ListPatient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      data: [],
      token: localStorage.getItem("token"),
      loading: true,
      pagination: {},
      searchText: '',
      searchedColumn: '',
    };
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        {/* <Space> */}
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        {/* </Space> */}
      </div>
    ),
  
  
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    // render: text =>
    //   this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  


  componentDidMount() {
    this.getPatients();
  }

  redirectToPatientDetail = (id) => {
    this.props.history.push("/physician/patient-profile/" + id);
  };

  redirectToPatientUpdate = (id) => {
    this.props.history.push("/physician/update-profile/" + id);
  };
  
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getPatients({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  redirectToAddPatient = () => {
    this.props.history.push("/physician/add-patient");
  };

  getPatients = async (params = {}) => {
    await axios
      .get(environment.endPoint + "physician/patients", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          if (data !== null || data !== "") {
            const pagination = { ...this.state.pagination };
            pagination.total = response.data.data.info.results;
            this.setState({
              // data: data.patients,
              columns: this.columns,
              loading: false,
            });
            this.getPatientsData(data.patients)
          }
        }
      })
      .catch((e) => {});
  };

  resendInvite = async (record) =>{
 
     const params = {
       patientId: record.id
     }

      await axios
        .post(environment.endPoint + "physician/patient", params, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } 
        })
        .catch((error) => console.log( error.response.request._response ) );

    };


  genericMethod = async (record, id, string) => {
    swal({
      title: "Are you sure you want to Delete Patient?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(environment.endPoint + "physician/patients?patientId=" + id, {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const index = this.state.data.indexOf(record, 0);
              if (index > -1) {
                let patients = this.state.data;
                patients.splice(index, 1);
                this.setState({ patients: patients });
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
                this.getPatients();
              }
            }
          })
          .catch((e) => {});

        swal("Poof! Your Patient has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Patient is safe!");
      }
    });
  };

  getPatientsData(response) {
    let option = [];
    if (response.length > 0) {
      response.forEach((patient) => {
        let patients = {};
        patients.id = patient.id;
        patients.profileImage = patient.profileImage;
        patients.fullName = patient.firstName + " " + patient.lastName;
        patients.email = patient.email;
        patients.primaryDiagnosis = patient.primaryDiagnosis;
        patients.phone = patient.phone;
        patients.status = patient.status;
        option.push(patients);
      });
    }
    this.setState({
      data: option,
    });
  }
  columns = [
    {
      title: "Patient",
      width: '5%',
      dataIndex: "profileImage",
      render: (text, row) => (
        <div className="d-flex ml-3" onClick={() => this.redirectToPatientDetail(row.id, "detail")}>
          {row.profileImage === null ? (
            <img src={User} alt="Preview" height="50" width="50" />
          ) : (
            <img src={row.profileImage} alt="Preview" height="50" width="50" />
          )}
        </div>
      ),
    },
    {
      title: "Name",      
      dataIndex: "fullName",
      key: 'fullName',
      ...this.getColumnSearchProps('fullName'),
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['descend', 'ascend'],
      render: (text, row) => (
        <div className="patient-list-email" onClick={() => this.redirectToPatientDetail(row.id, "detail")}>
          <p> {row.fullName}</p>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      ...this.getColumnSearchProps('email'),
      sorter: (a, b) => a.email.localeCompare(b.email),
      key: 'email',
      sortDirections: ['descend', 'ascend'],
      render: (text, row) => (
        <div className="patient-list-email" onClick={() => this.redirectToPatientDetail(row.id, "detail")}>
          <p> {row.email}</p>
        </div>
      ),
    },
    {
      title: "Phone #",
      dataIndex: "phone",
      ...this.getColumnSearchProps('phone'),
      sorter: (a, b) =>  a.phone !== null ? a.phone.localeCompare(b.phone) : "",
      sortDirections: ['descend', 'ascend'],
      key: 'phone',
      render: (text, row) => (
        <div className="patient-list-phone" onClick={() => this.redirectToPatientDetail(row.id, "detail")}>
          <p> {row.phone}</p>
        </div>
      ),
    },
    {
      title: "Primary Diagnosis",
      dataIndex: "primaryDiagnosis",
      ...this.getColumnSearchProps('primaryDiagnosis'),
      sorter: (a, b) => a.primaryDiagnosis.localeCompare(b.primaryDiagnosis),
      sortDirections: ['descend', 'ascend'],
      key: 'primaryDiagnosis',
      render: (text, row) => (
        <div className="patient-list-diagnosis" onClick={() => this.redirectToPatientDetail(row.id, "detail")}>
          <p> {row.primaryDiagnosis}</p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      ...this.getColumnSearchProps('status'),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      key: 'status',
      render: (text, row) => (
        <div onClick={() => this.redirectToPatientDetail(row.id, "detail")}>
          <p> {row.status}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      // fixed: 'right',
      key: "x",
      width: 100,
      render: (id, record) => (
        <div className="up-del">
          {record.status === "pending" ? (
            <>
            <Link onClick={() => this.genericMethod(record, id, "delete")}>
              <i
                className="fa fa-times text-danger ml-2"
                aria-hidden="true"
                title="Delete"
              >
                {" "}
              </i>
            </Link>
            <Link onClick={() => this.resendInvite(record)}> 
            <i className="fa fa-refresh text-primary ml-2" aria-hidden="true"></i>
             </Link>
            </>
          ) : (
            <>
              <Link className="update">
                <i
                  onClick={() => this.redirectToPatientUpdate(id, "update")}
                  className="fa	fa-pencil"
                  aria-hidden="true"
                  title="Update"
                ></i>
              </Link>
              <Link
                className=""
                onClick={() => this.redirectToPatientDetail(id, "detail")}
              >
                <i
                  class="fa	fa-eye text-dark ml-2"
                  aria-hidden="true"
                  title="View"
                ></i>
              </Link>
              <Link onClick={() => this.genericMethod(record, id, "delete")}>
              <i
                className="fa fa-times text-danger ml-2"
                aria-hidden="true"
                title="Delete"
              >
                {" "}
              </i>
            </Link>
            </>
          )}
        </div>
      ),
    },
  ];
  
  render() {
    const { pagination, loading, data ,columns } = this.state;

    return (
      <div className="container auth-form-phyiscian-patient physician-patient-listing mb-5">
        <form>
          <div className="custom-card-patient">
            <h3 className="text-center stepper-form-headings">
              Patient Listing
            </h3>

            <div className="row mb-4">
              <div className="col-lg-12 mt-4">
                <button
                  className="btn physician-btn-success border-radius pull-right py-2  px-2"
                  onClick={this.redirectToAddPatient}
                >
                  <span className="btn-text">
                    <i class="fa fa-plus" aria-hidden="true"></i> Add New
                    Patient
                  </span>
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-12">
                <Table
                  style={{ whiteSpace: "pre" }}
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={data}
                  pagination={pagination}
                  loading={loading}
                  scroll={{ x: 1500, y: 500 }}
                  onRow={(record) =>
                    this.genericMethod.bind(this, record, record.id)
                  }
                />
              </div>
            </div>
          </div>
        </form>

        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(ListPatient);
