import { IMPORT_SHOOP } from '../actions/actionsTypes';

const initialState = {
    importShoops: {
    },
    name: "",
};

const importShoops = (state, action) => {
  return { ...state, importShoops: action.importShoops , name: action.name }
}

const shoopReduers = (state = initialState, action) => {
  switch(action.type) {
    case IMPORT_SHOOP: return importShoops(state, action);
    default: return state;
  }
};

export default shoopReduers;