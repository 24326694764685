import React, { PureComponent } from "react";
import moment from "moment";

export class PatientInformation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
    }
  }
  render() {
    const { patientProfile } = this.state;

    return (
      <div className="card-body custom-inner-content disabled-input">
        <h5 className="stepper-form-headings">
          Patient Information
        </h5>

        <div className="row">
          <div className="col-lg-12 col-12">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <span className={`${patientProfile?.suffix === null ||
                       patientProfile?.suffix === "" ? "field-not-found" : "field-label"}`}>Prefix / Suffix:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.suffix}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.firstName === null ||
                       patientProfile?.firstName === "" ? "field-not-found" : "field-label"}`}>First Name:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.firstName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.lastName === null ||
                       patientProfile?.lastName === "" ? "field-not-found" : "field-label"}`}>Last Name:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.lastName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.occupation === null ||
                       patientProfile?.occupation === "" ? "field-not-found" : "field-label"}`}>Occupation:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.occupation}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.education === null ||
                       patientProfile?.education === "" ? "field-not-found" : "field-label"}`}>
                      Highest Level of Education:
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.education}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.address === null ||
                       patientProfile?.address === "" ? "field-not-found" : "field-label"}`}>Address Line 1:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.address}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.addressTwo === null ||
                       patientProfile?.addressTwo === "" ? "field-not-found" : "field-label"}`}>Address Line 2:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.addressTwo}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.city === null ||
                       patientProfile?.city === "" ? "field-not-found" : "field-label"}`}>City:</span>
                    <span className="field-text"> {patientProfile?.city}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.zipCode === null ||
                       patientProfile?.zipCode === "" ? "field-not-found" : "field-label"}`}>Zip / Postal Code:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.zipCode}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.country === null ||
                       patientProfile?.country === "" ? "field-not-found" : "field-label"}`}>Country:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.country}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.state === null ||
                       patientProfile?.state === "" ? "field-not-found" : "field-label"}`}>
                      State / Province / Region:
                    </span>
                    <span className="field-text"> {patientProfile?.state}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className={`${patientProfile?.gender === null ||
                       patientProfile?.gender === "" ? "field-not-found" : "field-label"}`}>
                      What sex were you assigned at birth ?
                    </span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.gender}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.phone === null ||
                       patientProfile?.phone === "" ? "field-not-found" : "field-label"}`}>Cell Phone:</span>
                    <span className="field-text"> {patientProfile?.phone}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.homePhone === null ||
                       patientProfile?.homePhone === "" ? "field-not-found" : "field-label"}`}>Home Phone:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.homePhone}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.dob === null ||
                       patientProfile?.dob === "" ? "field-not-found" : "field-label"}`}>Date of Birth:</span>
                    <span className="field-text"> {patientProfile?.dob ?
          moment(patientProfile?.dob).format(
            "MM-DD-YYYY"
          ) : "No Data Available"}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.heightFeet === null ||
                       patientProfile?.heightFeet === "" ? "field-not-found" : "field-label"}`}>Height in ft(Feet):</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.heightFeet}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.heightInches === null ||
                       patientProfile?.heightInches === "" ? "field-not-found" : "field-label"}`}>Height in in(Inches):</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.heightInches}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.weight === null ||
                       patientProfile?.weight === "" ? "field-not-found" : "field-label"}`}>Weight:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.weight} {patientProfile?.weightUnit}
                      {patientProfile?.weightUnit === "kg" ? "KG" : ""}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={`${patientProfile?.bloodGroup === null ||
                       patientProfile?.bloodGroup === "" ? "field-not-found" : "field-label"}`}>Blood Type:</span>
                    <span className="field-text">
                      {" "}
                      {patientProfile?.bloodGroup}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12 mt-2">
            <h4 className="stepper-form-sub-headings">
              Which of the following best represents your racial or ethnic
              heritage?
            </h4>

            <ul class="ml-4">
              {patientProfile?.ethnicity?.hispanic === true ? (
                <li>Non-Hispanic White or Euro-American</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.black === true ? (
                <li>Black, Afro-Caribbean, or African American</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.latino === true ? (
                <li>Latino or Hispanic American</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.east === true ? (
                <li>East Asian or Asian American</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.south === true ? (
                <li>South Asian or Indian American</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.middle === true ? (
                <li>Middle Eastern or Arab American</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.native === true ? (
                <li>Native American or Alaskan Native</li>
              ) : (
                ""
              )}
              {patientProfile?.ethnicity?.other === true ? <li>Other</li> : ""}
            </ul>

            {patientProfile?.ethnicity?.hispanic === false &&
            patientProfile?.ethnicity?.black === false &&
            patientProfile?.ethnicity?.latino === false &&
            patientProfile?.ethnicity?.east === false &&
            patientProfile?.ethnicity?.south === false &&
            patientProfile?.ethnicity?.middle === false &&
            patientProfile?.ethnicity?.native === false &&
            patientProfile?.ethnicity?.other === false ? (
              <p className="font-italic px-4">Racial or Ethnic
              Heritage not Added</p>
            ) : (
              ""
            )}
            {/* {Object.keys(patientProfile?.ethnicity).length === 0 ? <p className="font-italic px-4">Racial or Ethnic
              Heritage not Added</p> : "" } */}
          </div>
        </div>
      </div>
    );
  }
}

export default PatientInformation;
