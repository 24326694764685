import axios from "axios";

export const logout = async () => {
  try {
    const res = await axios.post("https://app.octopiwellness.com/api/userAuth/logout", {
      refreshToken: localStorage.getItem("refreshToken")
    });
    if (res.status === 200) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("token");
    }
  } catch (err) {
    console.log(err);
  }
};

