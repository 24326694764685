import React, { Component } from "react";
import "../../../assets/scss/physician.scss";
import user from "../../../assets/images/user.png";
import MyHealthPatientListing from "./myHealthPatient/MyHealthPatientListing";
import Alert from "../../../assets/images/exclamation-triangle-solid.svg"
import axios from "axios";
import { environment } from "../../../environment";
import MyHealth from "../dashboard/myHealth/MyHealth";
// import Pusher from 'pusher-js';
import ViewShoop from "./shoop/view-shoop";
import { toast } from "react-toastify";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Notes from "./notes";
import ViewSymptoms from "../dashboard/health-checkup/ViewSymptoms";
import store from "../../../store/alertStore";
import metroprofile from "../../../assets/images/metro-profile.svg";
import profileedit from "../../../assets/images/profile-edite.svg";
import viewshoop from "../../../assets/images/view-shoop.svg";
import viewnote from "../../../assets/images/veiw-notes.svg";
import viewsymptoms from "../../../assets/images/view-symptoms.svg";
import { Collapse } from "react-bootstrap";

export class PhysicianPatientProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      patientProfile: {},
      patientAge: "",
      cellphone: "",
      loading: true,
      homePhone: "",
      patientID: "",
      patientProfileFlag: false,
      patientShoopHealthIntake: false,
      patientHealthIntake: true,
      physicanNotes: false,
      patientViewSymptoms: false,
      status: "physician",
      day: "",
      month: "",
      year: "",
      text: '',
      username: '',
      chats: [],
      disabledDeceased: true,
      deceasedDate: null,
      deceasedCause: "",
      deceased: false,
      alertP450: {},
      defaultvalue:false,
      patientValue: ""
    };
  }


  componentDidMount(){
    const {
      match: {
        params: { id },
      },
    } = this.props;
     this.setState({
       patientID: id
     })
    this.getPatientProfile(id);
    this.showAlerts()
    // this.getRoutingActive();
    // const username = window.prompt('Username: ', 'Anonymous');
    // this.setState({ username });
    // Pusher.logToConsole = true;

    // const pusher = new Pusher('e0b40973c357be29a0b9', {
    //   cluster: 'ap2',
    //   encrypted: true
    // });
    // console.log(pusher)

    // const channel = pusher.subscribe('my-channel');
    // channel.bind('my-event', data => {
    //   console.log(data)
    //   // this.setState({ chats: [...this.state.chats, data], test: '' });
    // });
  }

  getRoutingActive(){
    if (this.props.location.pathname.includes("update-profile")) {
      document.getElementById("update-profile").click();
    }
  }

  componentDidUpdate(prevProps, prevState){
       if(prevProps.patientProfile !== this.state) {
        this.showAlerts()
       }
  }
  showAlerts(){
    store.subscribe(() => { 
     const alerts = store.getState().alertP450.alertP450
     this.setState({
      patientProfile: alerts
     })
    });

  }

  getPatientProfile = async (id) => {
    try {
      const response = await axios
        .get(environment.endPoint + "physician/patient?patientId=" + id, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        })
        if (response.status === 200 ) {
          // alert(response.data.data.deceasedDate !== null && response.data.data.deceasedCause != null)
          // if(response.data.data.deceasedDate !== null && response.data.data.deceasedCause != null){
          //   this.setState({ deceased: true ,patientValue : true });

          // }
          this.setState({
            patientProfile: response.data.data,
            loading: false,
            deceasedDate:response.data.data.deceasedDate,
            deceasedCause:response.data.data.deceasedCause,
            deceased:response.data.data.deceased,
            patientValue: response.data.data.deceased === false ? "" : "show",
            disabledDeceased: response.data.data.deceasedDate === "" || response.data.data.deceasedDate !== null || response.data.data.deceasedCause !== "" ||  response.data.data.deceased !== "" ? false : true 

          }); 
          this.callCompleted(response.data.data.dob,response.data.data.phone,response.data.data.homePhone)
        }
    } catch(err){
    }
  };

  callCompleted(dateString,cellPhone,HomePhone){
    this.formatPhoneNumber(cellPhone,HomePhone)
  }

 formatPhoneNumber(cellNumberString , homeNumberString) {

  var cleaned = ('' + cellNumberString).replace(/\D/g, '');

  var match = cleaned.match(/^(\d{3})(\d{3})(\d{5})$/);
  if (match) {
    this.setState({
      cellPhone: '(' + match[1] + ') ' + match[2] + '-' + match[3]
    })
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  var homeNumberStringcleaned = ('' + homeNumberString).replace(/\D/g, '');

  var homeNumberStringmatch = homeNumberStringcleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (homeNumberStringmatch) {
    this.setState({
     homePhone: '+' + homeNumberStringmatch[1] + ' (' + homeNumberStringmatch[2] + ')-' + homeNumberStringmatch[3] + '-' +  homeNumberStringmatch[4]
    })
    return '(' + homeNumberStringmatch[1] + ') ' + homeNumberStringmatch[2] + '-' + homeNumberStringmatch[3] + '-' +  homeNumberStringmatch[4];
  }
  return null;
}

handleCheckboxes(field, e) { 
  const deceasedValue = e.target.checked ? "" : "show"
  console.log(deceasedValue)

  this.setState({ deceased: e.target.checked, patientValue: deceasedValue, disabled: false });
}

showPatientProfile =(Flag,id) => {

  this.setState({
    patientHealthIntake: Flag,
    loading: true,
    patientShoopHealthIntake: false,
    patientProfileFlag: false
  });

  this.getPatientProfile(id)
}

getEditIntakePatient = (Flag) => {   
  this.setState({
    patientProfileFlag: Flag,
    patientHealthIntake: false,
    patientShoopHealthIntake: false,
    physicanNotes: false,
    patientViewSymptoms: false
  });
}

getShoopIntakePatient(Flag){
  this.setState({
    patientShoopHealthIntake: Flag,
    patientProfileFlag: false,
    patientHealthIntake: false,
    physicanNotes: false,
    patientViewSymptoms: false

  })
}

getCreateNotes(Flag){
  this.setState({
    physicanNotes: Flag,
    patientProfileFlag: false,
    patientHealthIntake: false,
    patientShoopHealthIntake: false,
    patientViewSymptoms: false
  })
}

getViewSymptoms(Flag){
  this.setState({
    patientViewSymptoms: Flag,
    patientProfileFlag: false,
    patientHealthIntake: false,
    patientShoopHealthIntake: false,
    physicanNotes: false
  })
}

handleUserInputChange(field, e) {
     this.setState({ deceasedCause: e.target.value , disabledDeceased: false}); 
    
}

handleDeceasedDateChange(value, dateString) { 

  let deceasedDate = this.state.deceasedDate;
  deceasedDate = value
  this.setState({
    deceasedDate: deceasedDate,
    disabledDeceased: false
  });
}

patientDeceased = async () => {
  let deceasedDate
  if(this.state.deceasedDate !== null) {
    var fullDate = moment(this.state.deceasedDate, "MM-DD-YYYY");
    var date = fullDate.format("DD");
    var year = fullDate.format("YYYY");
    var month = fullDate.format("MM");
    const selectedDate = month + "-" + date + "-" + year;
    deceasedDate = this.state.deceasedDate !== null || this.state.deceasedDate === " " ? selectedDate : null;
  } else {
    deceasedDate = null
  }

  const params = {
    patientId: this.state.patientID,
    deceased: this.state.deceased,
    deceasedDate: this.state.deceasedDate,
    deceasedCause: this.state.deceasedCause
  }
   
  await axios
    .post(environment.endPoint + "physician/patient/deceased", params, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
    //     this.setState({
    //       disabledDeceased: true,
    //       patientId: "",
    // deceased: "",
    // deceasedDate: null,
    // deceasedCause: ""
    //     })
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    })
    .catch((e) => {});
};



  render() {
    const {patientProfile} = this.state
    return (
      <div className="physicain-patient-profile">
        <div className="top-bar">
          <div className="container">
            <div className="content">
              <div className="row">
                {
                  patientProfile?.substrates !== "" || patientProfile?.inducers !== "" || patientProfile?.inhibitors !== "" ? <div><img src={Alert} alt={"blink_me"} className="blink_me"/></div> :
                  ""
               }
               
                <div className="col-md-11">
                  {
                    patientProfile?.substrates === "" ? "" : <div className="substrates">
                    <h4>Substrates:</h4>
                    <span> {patientProfile?.substrates}</span>
                     
                      <div
                       className="tooltips"
                      >
                        <span className="tooltiptext"><strong>SUBSTRATES DETECTED!</strong> 
                        <br />{patientProfile?.substrates}<br />
                        These drugs are metabolized by the cytochrome P450 system. 
                        Cannabinoids partially inhibit the cytochrome P450 system, and may delay 
                        the metabolism of these drugs, and, hence, enhance their pharmacologic activity.
                         When appropriate, consider the possibility of lowering the dosage of these drugs.</span>



                      </div>
                  </div>
                  }
                   {
                    patientProfile?.inducers === "" ? "" : <div className="substrates">
                    <h4>Inducers:</h4>
                    <span> {patientProfile?.inducers}</span>
                    <div
                       className="tooltips"
                      >
                        <span className="tooltiptext"><strong>INDUCERS DETECTED!</strong> 
                        <br />{patientProfile?.inducers}<br />
                        These drugs are metabolized by the cytochrome P450 system. 
                        Cannabinoids partially inhibit the cytochrome P450 system, and may delay 
                        the metabolism of these drugs, and, hence, enhance their pharmacologic activity.
                         When appropriate, consider the possibility of lowering the dosage of these drugs.</span>
                      </div>
                  </div>
                  }
                  {
                    patientProfile?.inhibitors === "" ? "" : <div className="substrates">
                    <h4>Inhibitors:</h4>
                    <span> {patientProfile?.inhibitors}</span>
                    <div
                       className="tooltips"
                      >
                        <span className="tooltiptext"><strong>INHIBITORS DETECTED!</strong> 
                        <br />{patientProfile?.inhibitors}<br />
                        These drugs are metabolized by the cytochrome P450 system. 
                        Cannabinoids partially inhibit the cytochrome P450 system, and may delay 
                        the metabolism of these drugs, and, hence, enhance their pharmacologic activity.
                         When appropriate, consider the possibility of lowering the dosage of these drugs.</span>
                      </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bg">
          <div className="container">
            <div className="basic-details">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <div className="dp">
                    <img class="img-fluid" src={this.state.patientProfile?.profileImage === null ? user : this.state.patientProfile?.profileImage } alt="user" width="200" />
                  </div>
                </div>
                <div className="col-lg-9 col-md-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="patient-name">
                        <div className="row">
                          <div className="col-sm-5">
                            <h3 className="text-truncate">{this.state.patientProfile?.firstName} {this.state.patientProfile?.lastName}</h3>

                          </div>
                          <div className="col-sm-6 mt-1">
                            {
                              this.state.patientProfile?.dob === null || 
                              this.state.patientProfile?.dob === "" ||
                              this.state.patientProfile?.gender === null ||
                              this.state.patientProfile?.gender === "" ? "" : 
                              <div className="age-gander">
                              <span>{moment().diff(this.state.patientProfile?.dob, 'years') === 0 ? moment().diff(this.state.patientProfile?.dob, 'months') + " M " + moment().diff(this.state.patientProfile?.dob, 'days') + " D " : moment().diff(this.state.patientProfile?.dob, 'years') + " Y "}</span> - <span>{this.state.patientProfile?.gender?.charAt(0)
                                  .toUpperCase() + this.state.patientProfile?.gender?.substr(1).toLowerCase()}</span>
                            </div>

                            }
                         
                          </div>
                        </div>


                        {/* <h3>{this.state.patientProfile?.firstName?.length > 7 || this.state.patientProfile?.lastName?.length > 7  ? this.state.patientProfile?.firstName?.substring(0, 8)+"..." + this.state.patientProfile?.lastName?.substring(0, 8)+"..." : this.state.patientProfile?.firstName +" "+ this.state.patientProfile?.lastName}</h3> */}
                        
                      </div>
                      <div className="health-issues">
                       
                        <div className="health-issue-one">
                          <p>Primary Diagnosis: </p> <span>{this.state.patientProfile?.Diagnose?.DignoseList?.name.charAt(0)
                              .toUpperCase() +
                              this.state.patientProfile?.Diagnose?.DignoseList?.name.substr(1).toLowerCase()}</span>
                        </div>
                        {this.state.patientProfile?.mmrNumber === null || this.state.patientProfile?.mmrExp === null ? "" :
                         <div className="health-issue-one">
                          <p>MMR Number: </p> <span>{this.state.patientProfile?.mmrNumber} <sup>EXP: <span>{this.state.patientProfile?.mmrExp}</span></sup></span>
                        </div> }
                        
                        <div className="health-issue-two">
                          <p>Other Health Concerns: </p> <span>{this.state.patientProfile?.healthConcerns !== "" ? this.state.patientProfile?.healthConcerns : <span> No Concerns Added </span> }</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="conatct-details">
                        <p className="heading">Contact Detail</p>
                        <div className="phone">
                          <p>Home Phone:</p>
                          <span> {this.state.homePhone} </span>
                        </div>
                        <div className="cell">
                          <p>Cell Phone: </p>
                          <span>{this.state.cellPhone !== null ? this.state.patientProfile?.phone : this.state.cellPhone }</span>
                        </div>
                        <div className="email">
                          <p>Email: </p>
                          <span>{this.state.patientProfile?.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div className="tab-btns">
                      <div className="btn-group-container d-inline-flex">
                        
                        <div className="row">
                          <div className="col-lg-12">
                        
                          <div className data-toggle="buttons">
                            <label className="btn btn-primary view-patient-profile form-check-label active intake-btns">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="options"
                                id="option1"
                                autoComplete="off"
                                defaultChecked
                                onClick={() => this.showPatientProfile(true ,this.state.patientID)}
                              />
                              <img src={metroprofile} alt="metroprofile" className="pr-1" width="25"/>

                              Patient Profile
                            </label>

                            <label className="btn btn-primary edit-intake form-check-label intake-btns">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="options"
                                id="update-profile"
                                autoComplete="off"
                                
                                onClick={() => this.getEditIntakePatient(true)}
                              />{" "}
                              <img src={profileedit} alt="profileedit" className="pr-1" width="25"/>{" "}
                              Edit Health Intake
                            </label>

                            <label className="btn btn-primary view-shoop form-check-label intake-btns">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="options"
                                id="option3"
                                autoComplete="off"
                                onClick={() => this.getShoopIntakePatient(true)}
                              />{" "}
                              <img src={viewshoop} alt="viewshoop" className="pr-1" width="25"/>{" "}
                              View Patient Shoop
                            </label>

                            <label className="btn view-notes btn-primary form-check-label intake-btns">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="options"
                                id="option3"
                                autoComplete="off"
                                onClick={() => this.getCreateNotes(true)}
                              />{" "}
                              <img src={viewnote} alt="viewnote" className="pr-1" width="25"/>
{" "}
                               Notes
                            </label>

                            <label className="btn health-journy btn-primary form-check-label intake-btns">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="options"
                                id="option3"
                                autoComplete="off"
                                onClick={() => this.getViewSymptoms(true)}
                              />{" "}
                            <img src={viewsymptoms} alt="viewsymptoms" className="pr-1" width="25"/>

{" "}
                              Health Journey
                            </label>
                          </div>
                       
                          </div>

                        </div>
                      
                      </div>
                    </div>
                 
               
            </div>
          </div>
        </div>
        <div className="patient-intake-for-physician">
          {this.state.patientHealthIntake === true ?  <MyHealthPatientListing patientProfile = {patientProfile} /> : 
          ""}
          {this.state.patientProfileFlag === true ? <MyHealth myPatintHeathStatus={this.state.status} hideShow= {false} userID={this.state.patientID}></MyHealth> : ""}
          {
            this.state.patientShoopHealthIntake === true ? <ViewShoop userID={this.state.patientID}></ViewShoop> : 
            <> 
            </>
          } 
          {
            this.state.physicanNotes === true ? <Notes userID={this.state.patientID}></Notes> : 
            <> 
            </>
          } 
           {
            this.state.patientViewSymptoms === true ? <ViewSymptoms myPatintHeathStatus={this.state.status} userID={this.state.patientID}></ViewSymptoms> : 
            <> 
            </>
          } 
        </div>
       

       
        <div className="pc-notes mb-5">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="patient-deceased-start">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-check-inline">
                           <label className="form-check-label" for="check2">
                             <input type="checkbox" className="form-check-input"
                              checked ={this.state.deceased}
                              onChange={this.handleCheckboxes.bind(
                                this,
                                "patientDeceased"
                              )}
                             id="check2" name="vehicle2" value="something" data-toggle="collapse" data-target="#demo" />
                             <h5 className="">Patient Deceased</h5>
                           </label>
                        </div>
                      </div>
                    </div>
                    <div className={`row  collapse ${this.state.patientValue}`} id="demo">
                      <div className="col-lg-4">
                      <div className="custom-form-row patient-deceased-date">

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    
                    className={
                      this.state.deceasedDate
                        ? "has-date form-control custom-form-control"
                        : "form-control custom-form-control"
                    }
                    openTo="date"
                    value={this.state.deceasedDate}
                    format="MM-dd-yyyy"
                    views={["year", "month", "date"]}
                    onChange={this.handleDeceasedDateChange.bind(this)}
                    disableFuture
                    required
                  />
                </MuiPickersUtilsProvider>
                                 <label className="label-helper py-2 px-1">Deceased Date<strong className="text-danger"></strong></label>
                            </div>
                      </div>
                      <div className="col-lg-8">
                      <div className="custom-form-row patient-deceased-date">
                                 <input className="form-control custom-form-control py-4" 
                                  onChange={this.handleUserInputChange.bind(this, "causeofDeath")}
                                 required
                                 placeholder="Cause of Death" type="text"
                                 value={this.state.deceasedCause}/>
                                 <label className="label-helper py-2 px-1">Cause of Death<strong className="text-danger"></strong></label>
                            </div>
                      </div>
                    </div>
                    <div className={`row  collapse ${this.state.patientValue}`} id="demo">
                      <div className="col-lg-12">
                        <button className="btn btn-primary patient-deceased-confirm" disabled={this.state.disabledDeceased === true ? true :false}
                        onClick={this.patientDeceased}
                        >Confirm</button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="col-lg-2">
            <div className="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}


export default PhysicianPatientProfile
