import React, { Component } from "react";
import GroupUser from "../../../assets/images/Group-User.svg";
import MedicalShoop from "../../../assets/images/notes-medical.svg";
import Notes from "../../../assets/images/ionic-md-text.svg";
import axios from "axios";
import { environment } from "../../../environment";
import moment from "moment";
import { Link } from "react-router-dom";

export class PatientPhysicianProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      recommendedShoop: true,
      notes: false,
      physicianId: "",
      officeNumber: "",
      physicianProfile: {},
      recommendedShoops: [],
      physicianNotes: [],
      cellPhone: "",
      hideShowNotes: [
        {
          open: true,
        },
      ],
      hideShow: [
        {
          open: true,
        },
      ],
      visible: 5,
      visibleNotes: 5,

    };
  }
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({
      physicianId: id,
    });
    this.getPhysicianRecommendedShoop(id);
    this.getPhysicianProfile(id);
  }
  getShoop = (Flag) => {
    this.setState({
      recommendedShoop: Flag,
      notes: false,
    });
  };

  loadMore = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 5 };
    });
  };

  loadMoreNotes = () => {
    this.setState((prev) => {
      return { visibleNotes: prev.visibleNotes + 5 };
    });
  };

  getPhysicianProfile = async (id) => {
    await axios
      .get(environment.endPoint + "dashboard/physician/" + id, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          this.setState({
            physicianProfile: data,
          });
          this.formatofficeNumberNumber(data.officeNumber)
        }
      })
      .catch((e) => {});
  };


  getPhysicianNotes = async (id,Flag) => {
    this.setState({
      recommendedShoop: false,
      notes: Flag,
    });
    // return;
    await axios
      .get(environment.endPoint + "dashboard/physician/" + id + "/notes", {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          this.setState({
            physicianNotes: data,
          });
          this.getPhysicianNotesList(data)
        }
      })
      .catch((e) => {});
  };

  getPhysicianNotesList(response) {
    if (response.length > 0) {
      response.forEach((value, index) => {
        let x = this.state.hideShowNotes;
        x.push({ index: index, open: false, className: "card-link" });
        this.setState({
          hideShowNotes: x,
        });
      });
    }
  }

  formatofficeNumberNumber(officeNumberString) {

    var cleaned = ('' + officeNumberString).replace(/\D/g, '');
  
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{5})$/);
    if (match) {
      this.setState({
        officeNumber: '(' + match[1] + ') ' + match[2] + '-' + match[3]
      })
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  getPhysicianRecommendedShoop = async (id) => {
    await axios
      .get(
        environment.endPoint + "dashboard/shoopByPhysician?physicianId=" + id,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          this.setState({
            recommendedShoops: data,
          });
          this.getPhysicianRecommendedShoopList(data);
        }
      })
      .catch((e) => {});
  };

  getPhysicianRecommendedShoopList(response) {
    if (response.length > 0) {
      response.forEach((value, index) => {
        let x = this.state.hideShow;
        x.push({ index: index, open: false, className: "card-link" });
        this.setState({
          hideShow: x,
        });
      });
    }
  }

  onClickAccordionNotes = (index) => {
    let select = this.state.hideShowNotes;
    select[index].open = !select[index].open;

    if (select[index].open === true) {
      this.setState(
        {
          hideShowNotes: select,
        },
        () => {}
      );
    } else if (select[index]?.open === false) {
      select[index].className = "";
      this.setState(
        {
          hideShowNotes: select,
        },
        () => {}
      );
    }
  };


  onClickAccordion = (index) => {
    let select = this.state.hideShow;
    select[index].open = !select[index].open;

    if (select[index].open === true) {
      this.setState(
        {
          hideShow: select,
        },
        () => {}
      );
    } else if (select[index]?.open === false) {
      select[index].className = "";
      this.setState(
        {
          hideShow: select,
        },
        () => {}
      );
    }
  };

  render() {
    return (
      <div className="physicain-patient-profile patient-physicain-profile">
        <div className="header-bg">
          <div className="container">
            <div className="basic-details">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <div className="dp ml-5">
                    <img
                      class="img-fluid"
                      src={this.state.physicianProfile?.profileImage === null ? GroupUser : this.state.physicianProfile?.profileImage }
                      alt="user"
                      width="200"
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="patient-name">
                        <div className="row">
                          <div className= "col-sm-12"
                           >
                           
                            <h3 className="text-truncate">{this.state.physicianProfile?.firstName?.charAt(0).toUpperCase() + this.state.physicianProfile?.firstName?.substr(1).toLowerCase()} {this.state.physicianProfile?.lastName?.charAt(0).toUpperCase() + this.state.physicianProfile?.lastName?.substr(1).toLowerCase()}</h3>
                          </div>
                         
                          
                        </div>

                        {/* <h3>{this.state.patientProfile?.firstName?.length > 7 || this.state.patientProfile?.lastName?.length > 7  ? this.state.patientProfile?.firstName?.substring(0, 8)+"..." + this.state.patientProfile?.lastName?.substring(0, 8)+"..." : this.state.patientProfile?.firstName +" "+ this.state.patientProfile?.lastName}</h3> */}
                      </div>
                      <div className="health-issues">
                        <div className="health-issue-one">
                          <div className="d-flex">
                            <h5>
                              {" "}
                              <strong> Specialization:</strong>{" "}
                              <span>{this.state.physicianProfile?.specialization}</span>{" "}
                            </h5>
                          </div>
                        </div>
                        <div className="btn-group-container d-inline-flex mt-3">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className data-toggle="buttons">
                                <label className={`btn btn-primary form-check-label ${this.state.recommendedShoop === true ? "active" : ""} intake-btns`}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="option1"
                                    autoComplete="off"
                                    defaultChecked
                                    onClick={() => this.getShoop(true)}
                                  />
                                  <img src={MedicalShoop} alt="" />

                                  <p className="ml-2"> Recommended Shoop</p>
                                </label>
                               
                                <label className={`btn btn-primary form-check-label ${this.state.recommendedShoop === false ? "active" : ""} intake-btns`}>
                              
                                  <input  className="form-check-input"
                                    type="button"
                                    name="options"
                                    id="update-profile"
                                    autoComplete="off"
                                    onClick={() => this.getPhysicianNotes(this.state.physicianId,true)}
                                   
                                    />
                                  <img src={Notes} alt="" />
                                  <p className="ml-3">Notes</p>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="conatct-details">
                        <p className="heading">Contact Detail</p>
                        <div className="cell">
                          <p>Office Phone: </p>
                          <span>{this.state.officeNumber}</span>
                        </div>
                        <div className="email">
                          <p>Office Email: </p>
                          <span>{this.state.physicianProfile?.officeEmail === null || this.state.physicianProfile?.officeEmail === "undefined" || this.state.physicianProfile?.officeEmail === "" ? "Not Set" : this.state.physicianProfile?.officeEmail}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.recommendedShoop === true ? (
          <>
            <div className="patient-intake-for-physician">
              <div className="auth-form-phyiscian-patient patient-physician">
                <div className="container">
                  <label>
                    <h3> Recommended Shoop</h3>
                  </label>

                  {this.state.recommendedShoops.length === 0 ? (
                    <h3 className="font-italic text-center">
                      <strong className="text-secondary">
                        No Recommended Shoop added Yet
                      </strong>
                    </h3>
                  ) : (
                    <>
                      {this.state.recommendedShoops
                        .slice(0, this.state.visible)
                        .map((value, index) => (
                          <div className="add-symptoms-body">
                            <div>
                              <div className="card profile-symptoms fade-in">
                                <div className="card-header">
                                  <div className="row">
                                    <div class="col-md-8">
                                      <Link
                                        className={
                                          this.state.hideShow[index]?.open ===
                                          true
                                            ? "collapsed-minus"
                                            : "card-link"
                                        }
                                        onClick={(e) =>
                                          this.onClickAccordion(index)
                                        }
                                      ></Link>

                                      <div className="header-date">
                                        <p>{value?.targetCannabinoid}</p>
                                      </div>
                                      <h5 className="text-success">
                                        <strong className="ml-1"> Date Recommended: </strong>
                                        {moment(value.updatedAt)
                                          .local()
                                          .format("MMMM-DD-YYYY")}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                {this.state.hideShow[index]?.open === true ? (
                                  <div>
                                    <div className="card-body">
                                      <table class="table table-striped">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <strong>
                                                Target Cannabinoid:
                                              </strong>{" "}
                                              {value?.targetCannabinoid}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Tarpenes:</strong>{" "}
                                              {value?.terpenes
                                                ?.map((item) => item.value)
                                                ?.join()}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Target Dose:</strong>{" "}
                                              {value?.dailyDose}{" "}
                                              {value?.dailyDoseUnit}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Frequency:</strong>{" "}
                                              {value?.frequency} x{" "}
                                              {value?.frequencyUnit}
                                            </td>
                                          </tr>
                                          {value?.ShoopDoses.map(
                                            (dailyDose, index) => (
                                              <tr>
                                                <td>
                                                  <strong>
                                                    {" "}
                                                    Daily Dose {index + 1}:{" "}
                                                  </strong>{" "}
                                                  {dailyDose?.dose}{" "}
                                                  <span>
                                                    {" "}
                                                    {value?.dailyDoseUnit}{" "}
                                                    {dailyDose?.doseTime}{" "}
                                                  </span>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          <tr>
                                            <td>
                                              <strong>Ingestion Method:</strong>{" "}
                                              {value?.ingestionMethod}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Dosage Notes:</strong>{" "}
                                              {value?.note}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                  {this.state.visible < this.state.recommendedShoops.length && (
                    <div class="container load-more">
                      <div class="row">
                        <div class="col col-lg-7 offset-lg-3 text-center">
                          <button
                            onClick={this.loadMore}
                            type="button"
                            className="btn btn-primary submit mb-4"
                          >
                            Load more
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* {
                this.state.patientShoopHealthIntake === true ? <ViewShoop userID={this.state.patientID}></ViewShoop> : 
                <> 
                </>
              }  */}
            </div>
          </>
        ) : (
          ""
        )}
  {this.state.notes === true ? (
          <>
            <div className="patient-intake-for-physician">
              <div className="auth-form-phyiscian-patient patient-physician">
                <div className="container">
                  <label>
                    <h3> Consultation Notes</h3>
                  </label>

                  {this.state.physicianNotes.length === 0 ? (
                    <h3 className="font-italic text-center">
                      <strong className="text-secondary">
                        No Consultation Notes added Yet
                      </strong>
                    </h3>
                  ) : (
                    <>
                                          {this.state.physicianNotes
            .slice(0, this.state.visibleNotes)
            .map((value, index) => (
           <div className="add-symptoms-body">
                                 <div className="card profile-symptoms fade-in">
                    <div className="card-header">
                      <div className="row">
                        <div class="col-md-8">
                        <Link
                            className={
                              this.state.hideShowNotes[index]?.open === true
                                ? "collapsed-minus"
                                : "card-link"
                            }
                            onClick={(e) => this.onClickAccordionNotes(index)}
                          ></Link>

                          <div className="header-date">
                            <p>
                            {moment(value.createdAt)
                                .local()
                                .format("MMMM-DD-YYYY")}{" "}
                              ~ {moment(value.createdAt).local().format("LTS")}
                            </p>
                          </div>
                        </div>
                      
   
                      </div>
                     
                    </div>
                    {this.state.hideShowNotes[index]?.open === true ? (
                      <div>
                        <div className="card-body">
 

                            <div className="row">
                              <div className="col-lg-12 col-12">
                                <p className="heading-cmnts">
                                 <p className="notes-heading"> Notes: </p>{" "}
                                 <p className=" notes-paragraph">
                                {value?.note}
                                </p>
                                </p>
                                
                              </div>
                            </div>
                       
                            
                        </div>
                      </div>
                   ) : (
                    ""
                  )}
                  </div>
               
              </div>
           
           ))}
                    </>
                  )}
                  {this.state.visibleNotes < this.state.physicianNotes.length && (
                    <div class="container load-more">
                      <div class="row">
                        <div class="col col-lg-7 offset-lg-3 text-center">
                          <button
                            onClick={this.loadMoreNotes}
                            type="button"
                            className="btn btn-primary submit mb-4"
                          >
                            Load more
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* {
                this.state.patientShoopHealthIntake === true ? <ViewShoop userID={this.state.patientID}></ViewShoop> : 
                <> 
                </>
              }  */}
            </div>
          </>
        ) : (
          ""
        )}
        {/* {this.state.loading ? (
              <div className="col-lg-2">
                <div className="loading">Loading&#8230;</div>
              </div>
            ) : (
              ""
            )} */}
      </div>
    );
  }
}

export default PatientPhysicianProfile;
