import React, { PureComponent } from "react";

export class MedicaitionsSupplements extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      patientProfile: {},
      medications: {
        medications: [],
      },
      allergies: [],
    };
  }

  componentDidMount() {
    this.setState({
      patientProfile: this.props.patientProfile,
    });
    this.getMedications(
      this.props.patientProfile?.medications?.medications,
      this.props.patientProfile?.medications?.allergies
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        patientProfile: this.props.patientProfile,
      });
      this.getMedications(
        this.props.patientProfile?.medications?.medications,
        this.props.patientProfile?.medications?.allergies
      );
    }
  }

  getMedications(medications, allergies) {
    this.setState({
      medications: medications,
      allergies: allergies,
    });
  }

  render() {
    const { patientProfile } = this.props;
    return (
      <div className="card-body custom-inner-content  ">
        <h5 className="stepper-form-headings ">
          Medication & Supplements
        </h5>
        
        <div className="row">
          {patientProfile?.medications?.medications?.map((value, index) => (
            <div className="col-lg-12 col-12">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <span className="field-label">Medication Name:</span>
                      <span className="field-text"> {value.name}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="field-label">Dose:</span>
                      <span className="field-text"> {value.dose}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="field-label">Frequency:</span>
                      <span className="field-text"> {value.frequency}</span>
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          ))}
        </div>
        <div className="row">
              <div className="col-lg-12 col-12">
                  <table class="table table-striped">
                      <tbody>
                          <tr>
                              <td>
                                  <span className="field-label">Do you have any medication allergies?</span>
                                  <span className="field-text"> 

                                  {patientProfile?.medications?.allergy === true
                        ? "Yes"
                        : patientProfile?.medications?.allergy ===
                          null
                        ? <p className="font-italic px-4">Medication Allergies not added</p>
                        : "No"}


                                  </span>
                              </td>
                          </tr>
                        
                      </tbody>
                  </table>
              </div>
          
            </div>
            {
              patientProfile?.medications?.allergy === true ? <div className="row">
              <div className="col-lg-12 col-12">
              <label className="text-center  stepper-form-sub-headings ml-3">
                  Please List Medication Allergies
                </label>
              </div>
              </div> : "" }
            
        
        <div className="row">
          {patientProfile?.medications?.allergies?.map((value, index) => (
            <>
            
          
           
              <div className="col-lg-12 col-12">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <span className="field-label">Allergy Name:</span>
                        <span className="field-text"> {value.name}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default MedicaitionsSupplements;
