
export default class Validation {
  static physicianSigUpvalidation(statefields, personalPhone, officeNumber, string) {
    let fields = statefields;
    let errors = {};
    let formIsValid = true;

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }
    if (!fields["specialization"]) {
      formIsValid = false;
      errors["specialization"] = "This field is required";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "This field is required";
    }
    if (typeof fields["email"] !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      } else if (fields["email"].length > 255) {
        errors["email"] = "Please enter a short email";
      }
    }

    if (officeNumber) {
      if (officeNumber.startsWith("1") && officeNumber.length !== 11) {
        formIsValid = false;
        errors["officeNumber"] = "Please enter valid phone number.";
      }
    }
    if (!personalPhone) {
      formIsValid = false;
      errors["personalPhone"] = "This field is required";
    }
    if (personalPhone) {
      if (personalPhone.startsWith("1") && personalPhone.length !== 11) {
        formIsValid = false;
        errors["personalPhone"] = "Please enter valid phone number.";
      }
    }
    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }
  static physicianSigUpvalidationProfile(statefields, personalPhone, officeNumber, string) {
    let fields = statefields;
    let errors = {};
    let formIsValid = true;

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }
    if (!fields["specialization"]) {
      formIsValid = false;
      errors["specialization"] = "This field is required";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "This field is required";
    }
    if (typeof fields["email"] !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      } else if (fields["email"].length > 255) {
        errors["email"] = "Please enter a short email";
      }
    }

    if (officeNumber) {
      if (officeNumber.startsWith("1") && officeNumber.length !== 11) {
        formIsValid = false;
        errors["officeNumber"] = "Please enter valid phone number.";
      }
    }
    if (personalPhone) {
      if (personalPhone.startsWith("1") && personalPhone.length !== 11) {
        formIsValid = false;
        errors["personalPhone"] = "Please enter valid phone number.";
      }
    }
    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }
  static handleValidation(statefields, string) {
    let fields = statefields;
    let errors = {};
    let formIsValid = true;

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "This field is required";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "This field is required";
    }
    if (typeof fields["email"] !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      } else if (fields["email"].length > 255) {
        errors["email"] = "Please enter a short email";
      }
    }

    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static passwordValidation(statefields, string) {
    let fields = statefields;
    let errors = {};
    let formIsValid = true;
    let digit = true;
    let lowerCaseCheck = true;
    let upperCaseCheck = true;
    let specialCharactersCheck = true;
    let checkPasswordLength = true;

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "This field is required";
    }

    if (fields["password"] !== fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Password Does Not Match";
    }
    if (typeof fields["password"] !== "undefined") {
      const digitPattern = new RegExp("^(?=.*[0-9])");
      const lowerCase = new RegExp("^(?=.*[a-z])");
      const uppercase = new RegExp("^(?=.*[A-Z])");
      const SpecialCharacter = new RegExp("^(?=.*[!@#$%^&*])");
      // const passwordlength = fields["password"].length;
      if (!digitPattern.test(fields["password"])) {
        digit = false;
        formIsValid = false;
      }
      if (!lowerCase.test(fields["password"])) {
        formIsValid = false;
        lowerCaseCheck = false;
      }
      if (!uppercase.test(fields["password"])) {
        formIsValid = false;
        upperCaseCheck = false;
      }
      if (!SpecialCharacter.test(fields["password"])) {
        formIsValid = false;
        specialCharactersCheck = false;
      }
      if (fields["password"].length < 8 || fields["password"].length > 16) {
        formIsValid = false;
        checkPasswordLength = false;
      }
    }
    return {
      error: errors,
      formIsValid: formIsValid,
      digit: digit,
      lowerCaseCheck: lowerCaseCheck,
      upperCaseCheck: upperCaseCheck,
      specialCharactersCheck: specialCharactersCheck,
      checkPasswordLength: checkPasswordLength,
    };
  }

  static patientInformation(statefields, phone, homePhone, string) {
    let fields = statefields;
    let formIsValid = true;
    let errors = {};
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }
    if (!fields["zipCode"]) {
      formIsValid = false;
      errors["zipCode"] = "This field is required";
    }


    if (homePhone !== "") {
      if (homePhone !== null) {
        if (typeof homePhone !== "undefined") {
          if (homePhone !== null) {
            if (homePhone.startsWith("1") && homePhone.length !== 11) {
              formIsValid = false;
              errors["homePhone"] = "Please enter valid Home Phone.";
            }
          }
        }
      }
    }

    if (fields["weight"] !== "") {
      if (fields["weight"] !== null) {
        if (typeof fields["weight"] !== "undefined") {
          const numeric = new RegExp(/^[0-9]*$/);
          const check = !numeric.test(fields["weight"]);
          if (check) {
            formIsValid = false;
            errors["weight"] = "Please enter a Positive Whole value";
          }
        }
      }
    }
    if (fields["zipCode"] !== "") {
      if (fields["zipCode"] !== null) {
        if (typeof fields["zipCode"] !== "undefined") {
          const numeric = new RegExp(/^[0-9]*$/);
          const check = !numeric.test(fields["zipCode"]);
          if (check) {
            formIsValid = false;
            errors["zipCode"] = "Please enter a Positive Whole value";
          }
        }
      }
    }
    // console.log(fields["weight"])
    // console.log(fields["weightUnit"])

    // if (fields["weight"] !== "" || fields["weight"] !== null) {
    //   formIsValid = false;
    //   errors["weightUnit"] = "Please select Lbs OR kg";
    // }
    // if(fields["weightUnit"]){
    //   formIsValid = true;
    //   errors["weightUnit"] = "";
    // }
    if (fields["heightFeet"] !== "") {
      if (fields["heightFeet"] !== null) {
        if (typeof fields["heightFeet"] !== "undefined") {
          const numerics = new RegExp(/^[0-9]*$/);
          const check = !numerics.test(fields["heightFeet"]);
          if (check) {
            formIsValid = false;
            errors["heightFeet"] = "Please enter a Positive Whole value ";
          }
        }
      }
    }

    if (fields["heightInches"] !== "") {
      if (fields["heightInches"] !== null) {
        if (typeof fields["heightInches"] !== "undefined") {
          const numeric = new RegExp(/^[0-9]*$/);
          const check = !numeric.test(fields["heightInches"]);
          const numericEleven = new RegExp(/^([0-9]|1[011])$/);
          const checkNumericEleven = !numericEleven.test(fields["heightInches"]);

          if (check) {
            formIsValid = false;
            errors["heightInches"] = "Please enter a Positive Whole value";
          } else if (checkNumericEleven) {
            formIsValid = false;
            errors["heightInches"] = "Please Enter less then or equal to Eleven";
          }
        }
      }
    }

    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static personalInformation(statefields, string) {
    let fields = statefields;
    let formIsValid = true;
    let errors = {};
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }

    if(string === "personalPhysicianInformation") {

    }

    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }




  static contactsInformation(
    statefields,
    contactPhone,
    physicianPhoneNumber,
    string
  ) {
    let fields = statefields;
    let formIsValid = true;
    let errors = {};
    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }
    if (!contactPhone) {
      formIsValid = false;
      errors["contactPhone"] = "This field is required";
    }
    console.log(contactPhone)
    if (contactPhone !== "") {
      if (contactPhone !== null) {
        if (typeof contactPhone !== "undefined") {
          if (contactPhone !== null) {
            if (contactPhone.startsWith("1") && contactPhone.length !== 11) {
              formIsValid = false;
              errors["contactPhone"] = "Please enter valid  Contact Phone.";
            }
          }
        }
      }
    }

    if (physicianPhoneNumber) {
      if (
        physicianPhoneNumber.startsWith("1") &&
        physicianPhoneNumber.length !== 11
      ) {
        formIsValid = false;
        errors["physicianPhoneNumber"] = "Please enter valid Physician Phone Number.";
      }
    }
    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static myDocumentsInformation(
    statefields,
    string
  ) {
    let fields = statefields;
    let formIsValid = true;
    let errors = {};
    if (!fields["documentTitle"]) {
      formIsValid = false;
      errors["documentTitle"] = "This field is required";
    }

    if (!fields["documentType"]) {
      formIsValid = false;
      errors["documentType"] = "This field is required";
    }
    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static cannabisUseInformation(statefields, string) {
    let fields = statefields;
    let formIsValid = true;
    let errors = {};
    if (Object.keys(fields.objective).length === 0) {
      errors["objectiveFlag"] = "This field is required";
      formIsValid = false;
    } else if (Object.keys(fields.objective).length > 0) {
      errors["objectiveFlag"] = "";
      formIsValid = true;
    }
    // if (fields["treatmentLocatin"] !== "" &&  fields["treatmentLocatin"] !== null) {
    //   if (typeof fields["treatmentLocatin"] !== "undefined") {
    //     if (!fields["treatmentLocatin"].match(/^[a-zA-Z ]*$/)) {
    //       formIsValid = false;
    //       errors["treatmentLocatin"] = "Please Use Alphabets";
    //     } else if (fields["treatmentLocatin"].length > 30) {
    //       formIsValid = false;
    //       errors["treatmentLocatin"] = "Please use less then 30 Character";
    //     }
    //   }  else {
    //     formIsValid = true;
    //   }
    // }

    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static militaryInformation(stateFields, string) {
    // let fields = stateFields;
    let formIsValid = true;
    let errors = {};
    //   if (fields["highestRank"] !== "" && fields["highestRank"] !== null) {
    //   if (typeof fields["highestRank"] !== "undefined" ) {
    //     if (!fields["highestRank"].match(/^[a-zA-Z ]*$/)) {
    //       formIsValid = false;
    //       errors["highestRank"] = "Please Use Alphabets";
    //     } else if (fields["highestRank"].length > 15) {
    //       formIsValid = false;
    //       errors["highestRank"] = "Please use less then 15 Character ";
    //     }
    //   }
    // }
    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static treatmentHistory(stateFields, string) {
    let fields = stateFields;
    let formIsValid = true;
    let errors = {};
    if (fields["treatmentDate"] !== "" && fields["treatmentDate"] !== null) {
      formIsValid = false;
      errors["treatmentDate"] = "This field is required";
    } else if (fields["treatmentName"] !== "") {
      errors["treatmentDate"] = "";

    }

    if (typeof fields["treatmentName"] !== "undefined") {
      formIsValid = true;
      errors["treatmentDate"] = "";
    }
    // if (typeof fields["treatmentName"] !== "undefined") {
    //   if (!fields["treatmentName"].match(/^[a-zA-Z ]*$/)) {
    //     formIsValid = false;
    //     errors["treatmentName"] = "Please Use Alphabets";
    //   } else if (fields["treatmentName"].length > 15) {
    //     formIsValid = false;
    //     errors["treatmentName"] = "Please use less then 15 Character ";
    //   } 
    // } 

    // if (fields["treatmentName"] !== "") {
    //   formIsValid = true;
    //   errors["treatmentName"] = "";
    // }

    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }

  static medicationInformation(stateFields, medicationList, string) {
    let fields = stateFields;
    let formIsValid = true;
    let showMedication = true;
    let errors = {};
    // if (fields["frequency"] !== "" && fields["frequency"] !== null ) {
    //   formIsValid = false;
    //   errors["medicationName"] = "This field is required";

    // } 
    // if(typeof fields["medicationName"] !== "undefined") {
    //   formIsValid = true;
    //   errors["medicationName"] = "";
    // }

    // if (medicationList.data.length === 0) {
    //   formIsValid = false;
    //   showMedication = false; 
    //   errors["notFound"] = "Please Choose any from Suggested Diagnosis or";
    // }



    // if(fields["medicationName"] !== "") {
    //   formIsValid = false;
    //   errors["medicationName"] = "";
    // }


    // if (fields["dose"] !== "" && fields["dose"] !== null) {
    //   formIsValid = false;
    //   errors["medicationName"] = "This field is required";
    // }
    if (typeof fields["allergyName"] !== "undefined") {
      if (!fields["allergyName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["allergyName"] = "Please Use Alphabets";
      } else if (fields["allergyName"].length > 15) {
        formIsValid = false;
        errors["allergyName"] = "Please use less then 15 Character ";
      }
    }
    return {
      error: errors,
      formIsValid: formIsValid,
      showMedication: showMedication
    };
  }

  static nutritionExerciseInformation(statefields, name, string) {
    let fields = statefields;
    let formIsValid = true;
    let errors = {};

    if (fields["foodAllergies"] !== null || fields["foodAllergies"] !== "") {
      if (!fields["foodAllergies"]) {
        formIsValid = false;
        errors["foodAllergies"] = "This field is required";
      }
    }
    if (fields["dietaryRestrictions"] !== null || fields["dietaryRestrictions"] !== "") {
      if (!fields["dietaryRestrictions"]) {
        formIsValid = false;
        errors["dietaryRestrictions"] = "This field is required";
      }
    }


    if (fields["weightPattern"] !== null || fields["weightPattern"] !== "") {
      if (!fields["weightPattern"]) {
        formIsValid = false;
        errors["weightPattern"] = "This field is required";
      }
    }
    return {
      error: errors,
      formIsValid: formIsValid,
    };
  }
}
