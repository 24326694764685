import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "react-bootstrap-wizard/dist/react-wizard.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import 'antd/dist/antd.css';
import "./index.scss";
import "./assets/scss/style.scss";
import { Provider } from 'react-redux'
import store from './store/store';
import alertStore from './store/alertStore'

import FingerprintJS from "@fingerprintjs/fingerprintjs";


(async () => {
    // We recommend to call `load` at application startup.
    const fp = await FingerprintJS.load();
  
    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    const result = await fp.get();
  
    // This is the visitor identifier:
    const visitorId = result.visitorId;
    localStorage.setItem('fingerPrint', visitorId);
  })();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} alertStore={alertStore}>
      <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
