import React, { Component } from "react";
import { Link } from "react-router-dom";
import Remove from "../../../../assets/images/Icon feather-delete.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { environment } from "../../../../environment";
import axios from "axios";
import { toast } from "react-toastify";
import ImportShoop from "./import-shoop";
import store from "../../../../store/store";
import draftStore from "../../../../store/draftStore";

import DraftShoop from "./draft-shoop";
import http from "../../../../http-common";

const animatedComponents = makeAnimated();

const targetCannabinoid = [
  { value: "Tetrahydrocannabinol Delta 8 (THC)", label: "Tetrahydrocannabinol Delta 8 (THC)" },
  { value: "Tetrahydrocannabinol Delta 9 (THC)", label: "Tetrahydrocannabinol Delta 9 (THC)" },
  { value: "Cannabidiol (CBD)", label: "Cannabidiol (CBD)" },
  { value: "Cannabichromene (CBC)", label: "Cannabichromene (CBC)" },
  {
    value: "Cannabichromenic acid (CBCA)",
    label: "Cannabichromenic acid (CBCA)",
  },
  { value: "Cannabichromivarin (CBCV)", label: "Cannabichromivarin (CBCV)" },
  {
    value: "Cannabichromivarin acid (CBCA)",
    label: "Cannabichromivarin acid (CBCA)",
  },
  { value: "Cannabidiolic acid (CBDA)", label: "Cannabidiolic acid (CBDA)" },
  { value: "Cannabidivarin (CBDV)", label: "Cannabidivarin (CBDV)" },
  {
    value: "Cannabidivarin acid (CBDVA))",
    label: "Cannabidivarin acid (CBDVA)",
  },
  { value: "Cannabigerol (CBG)", label: "Cannabigerol (CBG)" },
  { value: "Cannabigerolic acid (CBGA)", label: "Cannabigerolic acid (CBGA)" },
  { value: "Cannabigevarin (CBGV)", label: "Cannabigevarin (CBGV)" },
  {
    value: "Cannabigevarin acid (CBGVA)",
    label: "Cannabigevarin acid (CBGVA)",
  },
  { value: "Cannabinol (CBN)", label: "Cannabinol (CBN)" },
  {
    value: "Tetrahydrocannabinolic acid (THCA)",
    label: "Tetrahydrocannabinolic acid (THCA)",
  },
  {
    value: "Tetrahydrocannabivarin (THCV)",
    label: "Tetrahydrocannabivarin (THCV)",
  },
  {
    value: "Tetrahydrocannabivarin acid (THCVA)",
    label: "Tetrahydrocannabivarin acid (THCVA)",
  },
];
const methodOfIngestion = [
  { value: "Inhaled", label: "Inhaled" },
  { value: "Ingested", label: "Ingested" },
  { value: "Sublingual", label: "Sublingual" },
  { value: "Suppository", label: "Suppository" },
  { value: "Topically", label: "Topically" },
];

const selectDayEvent = [
  { value: "Morning", label: "Morning" },
  { value: "Afternoon", label: "Afternoon" },
  { value: "Evening", label: "Evening" },
];

const selectTimeEvent = [
  { value: "Daily", label: "Daily" },
  { value: "Hourly", label: "Hourly" },
];

const unit = [
  { value: "mg", label: "mg" },
  { value: "g", label: "g" },
];

const terpenes = [
  { value: "Alpha-humulene", label: "Alpha-humulene" },
  { value: "Alpha-pinene", label: "Alpha-pinene" },
  { value: "Beta-caryophyllene", label: "Beta-caryophyllene" },
  { value: "Bisabolol", label: "Bisabolol" },
  { value: "Caryophyllene Oxide", label: "Caryophyllene Oxide" },
  { value: "Geraniol", label: "Geraniol" },
  { value: "Limonene", label: "Limonene" },
  { value: "Linalool", label: "Linalool" },
  { value: "Myrcene", label: "Myrcene" },
  { value: "Terpinolene", label: "Terpinolene" },
];

const frequency = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];
export class CreateShoop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importShoop: [],
      addModalImportShoopShow: false,
      addModalDraftShoopShow: false,
      disabled: true,
      disabledPositiveValue: true,
      showHideMessageOfDailyDose: false,
      loading: true,
      userId: this.props.userID,
      dosingHistory: [],
      shoopData: [],
      shoop: [
        {
          targetCannabinoid: "",
          terpenes: [],
          dailyDose: "",
          dailyDoseUnit: "",
          note: "",
          frequency: "",
          frequencyUnit: "",
          ingestionMethod: "",
          doses: [],
          showDailyDose: false,
          userId: this.props.userID,
          sendToPatient: false,
        },
      ],
      hideShowSHoopSelect: [
        {
          index: 0,
          selectValue: false,
        },
      ],
      hideErrorMessages: [
        {
          index: 0,
          selectValue: false,
          value: false,
          className: "white"
        },
      ],

      parentIndex: "",
      childIndex: "",
      targetDailyDose: "",
      dailyDose: "",
      addShoopBtnDisabled: true,
      doses: [],
      checkFlag: "",
      customDoses: [],
      targetCannabinoid: "",
      type: "Create New",
      targetDailyDosePositiveValue: "",
      hideShow: [
        {
          open: true,
          className: "btn-link",
        },
      ],
      token: localStorage.getItem("token"),
    };
  }


  componentDidMount() {
    this.getImportShoop();
    this.getDraftShoop();
    this.getImportShoops();
    this.getDraftShoops();
  }

  getImportShoops() {
    store.subscribe(() => {
      const shoopData = store.getState().importShoops.importShoops;
      let select = this.state.hideShowSHoopSelect;
      select.push({ index: select.length + 1, selectValue: false });
      let x = this.state.hideShow;
      x.push({
        index: shoopData.length + 1,
        open: true,
        className: "btn-link",
      });
      this.setState({
        shoop: shoopData,
        hideShow: x,
        disabled: false,
        addShoopBtnDisabled: false,
        hideShowSHoopSelect: select,
        type: "Import"
      });
    });


  }

  getDraftShoops() {

    draftStore.subscribe(() => {
      let shoopDataList = [];
      const shoopData = draftStore.getState().draftShoops;
      console.log(shoopData)
      shoopDataList = shoopData;

      let select = this.state.hideShowSHoopSelect;
      select.push({ index: select.length + 1, selectValue: false });
      let x = this.state.hideShow;
      x.push({
        index: shoopData.length + 1,
        open: true,
        className: "btn-link",
      });

      let dataDoses = []
      // data.doseUnit === "mg" ? data.dose : data.doseUnit === "g" ? data.dose * 1000 : ""
      shoopData[0].doses.forEach((data, index) => {
        let objects = {}
        objects.createdAt = data.createdAt
        objects.dose = parseFloat(data.dose)
        objects.doseTime = data.doseTime
        objects.doseUnit = data.doseUnit
        objects.id = data.id
        objects.isDeleted = data.isDeleted
        objects.shoopId = data.shoopId
        objects.updatedAt = data.updatedAt
        dataDoses.push(objects)
      })

      this.setState({
        shoop: shoopDataList,
        hideShow: x,
        disabled: false,
        addShoopBtnDisabled: true,
        hideShowSHoopSelect: select,
        type: "Draft",
        targetDailyDose: parseFloat(shoopData[0].dailyDose),
        dailyDose: dataDoses.reduce((a, v) => a = a + v.dose, 0)
      });
    });


  }
  addShoop = () => {
    let createShoops = this.state.shoop;
    createShoops.push({
      id: createShoops.length + 1,
      targetCannabinoid: "",
      terpenes: [],
      dailyDose: "",
      dailyDoseUnit: "",
      note: "",
      frequency: "",
      frequencyUnit: "",
      ingestionMethod: "",
      doses: [],
      userId: this.props.userID,
      showDailyDose: false,
      sendToPatient: false,
    });
    let x = this.state.hideShow;
    let select = this.state.hideShowSHoopSelect;
    select.push({ index: select.length + 1, selectValue: false });
    x.push({
      index: createShoops.length + 1,
      open: false,
      className: "btn-link",
    });
    let errorMessage = this.state.hideErrorMessages;
    errorMessage.push({
      index: 0,
      selectValue: false,
      value: false,
      className: "white"
    });
    this.setState({
      shoop: createShoops,
      hideShow: x,
      // disabled: false,
      addShoopBtnDisabled: true,
      dailyDose: "",
      hideShowSHoopSelect: select,
      hideErrorMessages: errorMessage,
    });

  };

  removeShoop(idx) {
    let createShoops = this.state.shoop;
    createShoops.splice(idx, 1);
    this.setState({ shoop: createShoops });
    let customDoses = [];
    if (this.state.customDoses.length > 0) {
      this.setState({
        customDoses: customDoses
      });
    }
  }

  onClickAccordion = (index) => {
    let select = this.state.hideShow;
    select[index].open = !select[index].open;

    if (select[index].open === true) {
      this.setState(
        {
          hideShow: select,
        },
        () => { }
      );
    } else if (select[index].open === false) {
      select[index].className = "";
      this.setState(
        {
          hideShow: select,
        },
        () => { }
      );
    }
  };

  handleUserInputChange(field, index, e) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].frequency = e.target.value;

    const countField = parseInt(createShoopList[index].frequency);
    if (this.state.shoop[index].showDailyDose === true || this.state.shoop[index].frequencyUnit === "Daily") {
      let customDoses = [];
      this.targetDailyDoseCal(index);

      if (this.state.customDoses.length > 0) {
        this.setState({
          customDoses: customDoses
        });
      }
      let showFrequencyData = [];
      for (let i = 1; i <= countField; i++) {
        showFrequencyData.push({
          id: i,
          dose: "",
          doseTime: "",
          doseUnit: "",
        });
      }
      const dailyDoses = this.state.shoop;
      dailyDoses[index].doses = showFrequencyData;
      this.setState({
        shoop: dailyDoses,
        // disabled: false,
      });
    } else {
      this.setState({
        checkFlag: "create",
        targetDailyDose: 0,
        dailyDose: 0
      });

    }
    this.setState({ shoop: createShoopList });
  }

  userInputChangeShift(field, index, e) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].frequencyUnit = e.target.value;
    this.setState({ shoop: createShoopList, disabled: false });

    if (e.target.value === "Daily") {
      let showFrequencyData = [];
      for (let i = 1; i <= this.state.shoop[index].frequency; i++) {
        showFrequencyData.push({
          id: i,
          dose: "",
          doseTime: "",
          doseUnit: "",
        });
      }
      const dailyDoses = this.state.shoop;
      dailyDoses[index].doses = showFrequencyData;
      this.setState({
        shoop: dailyDoses,
        disabled: false,
      });

      const showDailyDoses = this.state.shoop;
      showDailyDoses[index].showDailyDose = true;
      this.setState({
        shoop: showDailyDoses,
        disabled: false,
      });
      this.targetDailyDoseCal(index);

    } else {
      const showData = this.state.shoop;
      showData[index].doses = [];
      const showDailyDoses = this.state.shoop;
      showDailyDoses[index].showDailyDose = false;
      this.setState({
        shoop: showData,
        disabled: false,
        checkFlag: "create",
        // note validation on daily or target daily dose not selected
      });
      this.setState({
        shoop: showDailyDoses,
        disabled: false,
        targetDailyDose: 0,
        dailyDose: 0
      });
    }
  }

  handleTargetCannabinoid(e, idx) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[idx].targetCannabinoid = e.target.value;
    this.setState({ shoop: createShoopList });
  }

  handleMultiChange(option, index) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].terpenes = option;
    this.setState({ shoop: createShoopList });
  }

  handleTargetDailyDoseChange(e, index) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].dailyDose = e.target.value;
    if (createShoopList[index].dailyDose !== "") {
      if (createShoopList[index].dailyDose !== null) {
        const numeric = new RegExp(/^(?![0.]+$)\d+(\.\d{1,2})?$/gm);
        const check = !numeric.test(createShoopList[index].dailyDose);
        if (check) {
          this.setState({
            targetDailyDosePositiveValue: "Please enter a Positive or Fraction value",
            disabledPositiveValue: true
          })
        } else {
          this.setState({
            targetDailyDosePositiveValue: "",
            disabledPositiveValue: false
          })
        }

      }
    }


    this.setState({
      shoop: createShoopList,
      parentIndex: index,
      checkFlag: "create"
    });
    this.targetDailyDoseCal(index);


    this.HandleValidation(index)
  }

  handleUnitChange(e, index) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].dailyDoseUnit = e.target.value;
    this.setState({
      shoop: createShoopList,
      parentIndex: index,
      checkFlag: "create"
    });
    this.targetDailyDoseCal(index);

    this.HandleValidation(index)
  }

  HandleValidation(index) {
    if (this.state.shoop[index].dailyDose !== "" && this.state.shoop[index].dailyDoseUnit === "") {
      let showErrorMessage = this.state.hideShowSHoopSelect;
      showErrorMessage[index].selectValue = true
      this.setState({
        hideShowSHoopSelect: showErrorMessage,
        // disabled: true
      })
    } else {
      let showErrorMessage = this.state.hideShowSHoopSelect;
      showErrorMessage[index].selectValue = false
      this.setState({
        hideShowSHoopSelect: showErrorMessage,
        // disabled: false
      })
    }
  }

  handleMethodIngestionChange(e, index) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].ingestionMethod = e.target.value;
    this.setState({ shoop: createShoopList, disabled: false });
  }

  handleNotesChange(e, index) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[index].note = e.target.value;
    this.setState({ shoop: createShoopList, disabled: false });
  }

  handleDailyDoseChange(e, parentIndex, childIndex) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[parentIndex].doses[childIndex].dose = e.target.value;

    if (createShoopList[parentIndex].doses[childIndex].dose !== "") {
      if (createShoopList[parentIndex].doses[childIndex].dose !== null) {
        const numeric = new RegExp(/^(?![0.]+$)\d+(\.\d{1,2})?$/gm);
        const check = !numeric.test(createShoopList[parentIndex].doses[childIndex].dose);
        if (check) {
          this.setState({
            showHideMessageOfDailyDose: true,
            disabledPositiveValue: true,
            shoop: createShoopList
          })
        } else {
          this.setState({
            showHideMessageOfDailyDose: false,
            disabledPositiveValue: false,
            shoop: createShoopList
          })
        }

      }
    }
    this.setState({
      shoop: createShoopList,
      childIndex: childIndex,
      parentIndex: parentIndex,
      checkFlag: "create"
    });
    this.dosesList(parentIndex, childIndex);

  }

  handleDailyDayShitChange(e, parentIndex, childIndex) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[parentIndex].doses[childIndex].doseTime = e.target.value;
    this.setState({
      shoop: createShoopList, childIndex: childIndex, parentIndex: parentIndex,
    });
  }

  handleUnitDoseChange(e, parentIndex, childIndex) {
    let createShoopList = this.state.shoop.slice();
    createShoopList[parentIndex].doses[childIndex].doseUnit = e.target.value;
    this.setState({
      shoop: createShoopList,
      childIndex: childIndex,
      parentIndex: parentIndex,
      checkFlag: "create"
    });
    this.dosesList(parentIndex, childIndex);

  }

  targetDailyDoseCal(parentIndex) {
    const targetDailyDoseCalculate = this.state.shoop[parentIndex].dailyDoseUnit === "mg" ?
      this.state.shoop[parentIndex].dailyDose :
      this.state.shoop[parentIndex].dailyDoseUnit === "g" ?
        this.state.shoop[parentIndex].dailyDose * 1000 : ""
    this.setState({
      targetDailyDose: parseFloat(targetDailyDoseCalculate),
      checkFlag: "create"
    });
    let option = [];
    this.state.shoop[parentIndex]?.doses.forEach((value, index) => {
      option.push({
        dose: value?.doseUnit === "mg" ?
          parseFloat(value?.dose) :
          value?.doseUnit === "g" ?
            value?.dose * 1000 : "",
      })
    });
    const dailyDose = option.length > 0 ? option?.reduce((a, v) => a = a + v.dose, 0) : "";
    this.setState({
      dailyDose: dailyDose,
      // disabled: false,
      targetCannabinoid: this.state.shoop[parentIndex].targetCannabinoid,
      parentIndex: parentIndex
    })

  }

  dosesList(parentIndex, childIndex) {
    let customDoses = this.state.customDoses;
    customDoses[childIndex] = {
      "dose": this.state.shoop[parentIndex]?.doses[childIndex]?.doseUnit === "mg" ?
        parseFloat(this.state.shoop[parentIndex]?.doses[childIndex]?.dose) :
        this.state.shoop[parentIndex]?.doses[childIndex]?.doseUnit === "g" ?
          this.state.shoop[parentIndex]?.doses[childIndex]?.dose * 1000 : "",
    }

    let dataDoses = [];
    this.state.shoop[0].doses.forEach((data, index) => {
      let objects = {}
      objects.createdAt = data.createdAt
      objects.dose = parseFloat(data.dose)
      objects.doseTime = data.doseTime
      objects.doseUnit = data.doseUnit
      objects.id = data.id
      objects.isDeleted = data.isDeleted
      objects.shoopId = data.shoopId
      objects.updatedAt = data.updatedAt
      dataDoses.push(objects)
    })

    this.setState({
      customDoses: customDoses,
      disabled: false,
      dailyDose: this.state.type === "Draft" ? dataDoses.reduce((a, v) => a = a + v.dose, 0) : customDoses.reduce((a, v) => a = a + v.dose, 0),
      parentIndex: parentIndex
    });
  }

  onSubmit = async (flag) => {
    let showErrorMessage = this.state.hideErrorMessages;
    const shoop = this.state.shoop;
    
    let calculation = []
    shoop.forEach((data, index) => {
      const targetDailyDoseCalculate = data.dailyDoseUnit === "mg" ?
        data.dailyDose :
        data.dailyDoseUnit === "g" ?
          data.dailyDose * 1000 : ""
      const doses = shoop[index].frequencyUnit === "Hourly" ? targetDailyDoseCalculate : data.doses.reduce((a, v) => v.doseUnit === "mg" ? a = a + parseInt(v.dose) : v.doseUnit === "g" ? a = a + parseInt(v.dose) * 1000 : "", 0)

      let object = {}
      object.targetDailyDoseCalculate = parseFloat(targetDailyDoseCalculate)
      object.doses = doses;
      calculation.push(object);

    })
    const checkFlag = [];

    for (let i = 0; i < calculation.length; i++) {
      if (calculation[i].doses > calculation[i].targetDailyDoseCalculate) {
        checkFlag[i] = true;
        showErrorMessage[i].selectValue = true;
        showErrorMessage[i].value = false;
        this.setState({
          disabled: true,
          hideErrorMessages: showErrorMessage,
          addShoopBtnDisabled: true,
        });
      } else if (calculation[i].doses < calculation[i].targetDailyDoseCalculate) {
        checkFlag[i] = true;
        showErrorMessage[i].value = true;
        showErrorMessage[i].selectValue = false;
        this.setState({
          disabled: true,
          hideErrorMessages: showErrorMessage,
          addShoopBtnDisabled: true,
        });
      } else if (calculation[i].doses === calculation[i].targetDailyDoseCalculate) {
        checkFlag[i] = false;
        showErrorMessage[i].selectValue = false;
        showErrorMessage[i].value = false;
        this.setState({
          disabled: false,
          hideErrorMessages: showErrorMessage,
        });
      }
    }
    let checker = checkFlag.every(v => v === false);
    if (checker) {
      const params = {
        isReady: flag === "Save" ? false : true,
        shoop: this.state.shoop[0].hasOwnProperty('shoopId') ? this.state.shoop[0] : this.state.shoop,
      };
      const method = this.state.shoop[0].hasOwnProperty('shoopId') ? axios.put : axios.post

      method(environment.endPoint + "physician/shoop", params, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            if (this.state.checkFlag === "create") {
              showErrorMessage[this.state.parentIndex].selectValue = false;
              this.setState({
                disabled: true,
                addShoopBtnDisabled: true,
                hideErrorMessages: showErrorMessage,
              });
            } else {
              this.setState({
                disabled: true,
                addShoopBtnDisabled: false,
              });
            }
            this.reset()
            if (flag === "Save") {
              this.setState({
                addModalDraftShoopShow: true
              });
              this.getDraftShoop()
              toast.success("Shoop Draft has been saved", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            } else {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }


          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error("Shoop Not created", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }

    console.log(shoop[this.state.parentIndex])

  };


  createdShoop = () => {

  }





  reset = () => {
    if (this.state.shoop.length > 0) {
      this.setState({
        shoop: [
          {
            targetCannabinoid: "",
            terpenes: [],
            dailyDose: "",
            dailyDoseUnit: "",
            note: "",
            frequency: "",
            frequencyUnit: "",
            ingestionMethod: "",
            doses: [],
            showDailyDose: false,
            userId: this.state.userId
          },
        ],
        shoopData: [

        ],
        hideErrorMessages: [{
          index: 0,
          selectValue: false,
          value: false,
          className: "white"
        }],
        disabled: true,
      });
    }
  }

  getImportShoop = async () => {
    const params = {
      userId: this.state.userId,
    };
    return http.post(environment.endPoint + "physician/shoop/import", params, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          this.setState({
            importShoop: response.data.data,
            loading: false,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.setState({
            loading: false
          })
        }
      });
  };


  getDraftShoop = async () => {
    const data =
      "physician/shoop?patientId=" + this.state.userId + "&isReady=false";
    await axios
      .get(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          this.setState({
            dosingHistory: response.data.data,
            loading: false,
          });
        }
      })
      .catch((e) => { });
  };

  addModalImportShoopShow(e) {
    e.preventDefault();
    // document.getElementById('test-l-2').scrollIntoView(true)
    this.setState({ addModalImportShoopShow: true });
    this.getImportShoop()
  }

  addModalmportShoopClose = () =>
    this.setState({ addModalImportShoopShow: false });

  addModalDraftShoopShow(e) {
    e.preventDefault();
    this.setState({ addModalDraftShoopShow: true });
    this.getDraftShoop()
  }

  addModaDraftShoopClose = () =>
    this.setState({ addModalDraftShoopShow: false });

  render() {
    return (
      <div className="card-body custom-inner-content create-shoop-start ">
        <div className="row">
          <div className="col-md-6">
            <h5 className="stepper-form-headings">
              {this.state.type} SHOOP
            </h5>
          </div>
          <div className="col-md-6">
            <button
              onClick={this.addModalImportShoopShow.bind(this)}
              className="btn physician-btn-success border-radius pull-right py-2  px-2 ml-2"
            >
              <span className="btn-text">
                <i class="fa fa-plus" aria-hidden="true"></i> Import Shoop
              </span>
            </button>
            <button
              onClick={this.addModalDraftShoopShow.bind(this)}
              className="btn physician-btn-success border-radius pull-right py-2  px-2"
            >
              <span className="btn-text">
                <i class="fa fa-plus" aria-hidden="true"></i> Draft Shoop
              </span>
            </button>
          </div>
        </div>
        {this.state.shoop.map((value, parentIndex) => {
          return (
            <div className="create-shoop-collapse">
              <div>
                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <Link
                        class="btn btn-link"
                        className={
                          this.state.hideShow[parentIndex]?.open === true
                            ? "btn-link collapsed-minus"
                            : "btn-link"
                        }
                        onClick={(e) => this.onClickAccordion(parentIndex)}
                      >
                        {value.targetCannabinoid === "" ? (
                          <> Medicine {parentIndex + 1} </>
                        ) : (
                          value.targetCannabinoid
                        )}
                      </Link>

                      {this.state.shoop.length > 1 ? (
                        <button
                          class="btn btn-remove"
                          onClick={this.removeShoop.bind(this, parentIndex)}
                        >
                          <img src={Remove} alt="Remove" /> REMOVE
                        </button>
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  {this.state.hideShow[parentIndex]?.open === true ? (
                    <div>
                      <div class="card-body">
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="custom-form-row scrollable">
                              <select
                                className={
                                  value.targetCannabinoid === "" ||
                                    value.targetCannabinoid === undefined ||
                                    value.targetCannabinoid === null
                                    ? "disabled-option form-control custom-form-control"
                                    : "form-control custom-form-control"
                                }
                                value={value.targetCannabinoid || ""}
                                onChange={(e) =>
                                  this.handleTargetCannabinoid(e, parentIndex)
                                }
                              >
                                <option value="" selected disabled>
                                  {" "}
                                  Select Target Cannabinoid...{" "}
                                </option>
                                {targetCannabinoid.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Select Target Cannabinoid{" "}
                              </label>

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="custom-form-row">
                              <Select
                                className={
                                  value?.terpenes?.length === 0 ||
                                    value?.terpenes === undefined ||
                                    value?.terpenes === null
                                    ? "disabled-option  custom-form-control"
                                    : "custom-form-control"
                                }
                                isMulti
                                placeholder="Select Terpenes"
                                name="form-field-name"
                                components={animatedComponents}
                                value={value?.terpenes || ""}
                                onChange={(e) =>
                                  this.handleMultiChange(e, parentIndex)
                                }
                                options={terpenes}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Select Terpenes
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col col-12">
                            <div className="custom-form-row">
                              <input
                                className="form-control custom-form-control"
                                placeholder="Target Daily Dose"
                                value={value.dailyDose}
                                min="0"
                                type="number"
                                onChange={(e) =>
                                  this.handleTargetDailyDoseChange(
                                    e,
                                    parentIndex
                                  )
                                }
                              />
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Target Daily Dose{" "}
                              </label>
                              <span className="text-danger">
                                {this.state.targetDailyDosePositiveValue}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col col-12">
                            <div className="custom-form-row">
                              <select
                                className={
                                  value.dailyDoseUnit === "" ||
                                    value.dailyDoseUnit === undefined ||
                                    value.dailyDoseUnit === null
                                    ? "disabled-option  form-control custom-form-control"
                                    : "form-control custom-form-control"
                                }
                                value={value.dailyDoseUnit}
                                onChange={(e) =>
                                  this.handleUnitChange(e, parentIndex)
                                }
                              >
                                <option value="" selected disabled>
                                  {" "}
                                  Select Unit{" "}
                                </option>

                                {unit.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Select Unit{" "}
                              </label>
                              {this.state.hideShowSHoopSelect[parentIndex]?.selectValue === true ?
                                <span className="text-danger">
                                  Please Select Unit
                                </span> : ""
                              }
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col col-12">
                            <div className="custom-form-row">
                              <select
                                className={
                                  value.frequency === "" ||
                                    value.frequency === undefined ||
                                    value.frequency === null
                                    ? "disabled-option  form-control custom-form-control"
                                    : "form-control custom-form-control"
                                }
                                value={value.frequency || ""}
                                onChange={this.handleUserInputChange.bind(
                                  this,
                                  "frequency",
                                  parentIndex
                                )}
                              >
                                <option value="" selected disabled>
                                  {" "}
                                  Select Frequency{" "}
                                </option>
                                {frequency.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Select Frequency
                              </label>

                            </div>
                          </div>
                          <div className="col-md-6 col col-12">
                            <div className="custom-form-row">
                              <select
                                value={value.frequencyUnit || ""}
                                className={
                                  value.frequencyUnit === "" ||
                                    value.frequencyUnit === undefined ||
                                    value.frequencyUnit === null
                                    ? "disabled-option  form-control custom-form-control"
                                    : "form-control custom-form-control"
                                }
                                onChange={this.userInputChangeShift.bind(
                                  this,
                                  "shift",
                                  parentIndex
                                )}
                              >
                                <option value="" selected disabled>
                                  {" "}
                                  Select Time{" "}
                                </option>
                                {selectTimeEvent.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Select Time
                              </label>

                            </div>
                          </div>
                        </div>

                        {value.doses.map((value, childIndex) => (
                          <div className="row">
                            <div className="col-md-4 col col-12">
                              <div className="custom-form-row">
                                <input
                                  className="form-control daily-dose-form custom-form-control"
                                  placeholder={`Daily Dose ${childIndex + 1}`}
                                  value={value.dose || ""}
                                  min="0"
                                  type="number"
                                  onChange={(e) =>
                                    this.handleDailyDoseChange(
                                      e,
                                      parentIndex,
                                      childIndex
                                    )
                                  }
                                />
                                <label className="label-helper py-2 px-1">
                                  {" "}
                                  Daily Dose {childIndex + 1}{" "}
                                </label>
                                {
                                  this.state.showHideMessageOfDailyDose === true ?
                                    this.state.shoop[parentIndex].doses[childIndex].dose !== "" ?
                                      <span className="text-danger"> Please enter a Positive or Fraction value </span> : "" : ""
                                }
                              </div>

                            </div>
                            <div className="col-md-4 col col-12">
                              <div className="custom-form-row">
                                <select
                                  className={
                                    value.doseUnit === "" ||
                                      value.doseUnit === undefined ||
                                      value.doseUnit === null
                                      ? "disabled-option  form-control custom-form-control"
                                      : "form-control custom-form-control"
                                  }
                                  value={value.doseUnit || ""}
                                  onChange={(e) =>
                                    this.handleUnitDoseChange(
                                      e,
                                      parentIndex,
                                      childIndex
                                    )
                                  }
                                >
                                  <option value="" selected disabled>
                                    {" "}
                                    Select Unit
                                  </option>
                                  {unit.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                <label className="label-helper py-2 px-1">
                                  {" "}
                                  Select Unit{" "}
                                </label>
                                {this.state.shoop[parentIndex].doses[childIndex].dose !== "" &&
                                  this.state.shoop[parentIndex].doses[childIndex].doseUnit === "" === true ?
                                  <span className="text-danger">
                                    Please Select Unit
                                  </span> : ""
                                }
                              </div>
                            </div>

                            <div className="col-md-4 col col-12">
                              <div className="custom-form-row">
                                <select
                                  className={
                                    value.doseTime === "" ||
                                      value.doseTime === undefined ||
                                      value.doseTime === null
                                      ? "disabled-option  form-control custom-form-control"
                                      : "form-control custom-form-control"
                                  }
                                  value={value.doseTime || ""}
                                  onChange={(e) =>
                                    this.handleDailyDayShitChange(
                                      e,
                                      parentIndex,
                                      childIndex
                                    )
                                  }
                                >
                                  <option value="" selected disabled>
                                    {" "}
                                    Select Shift
                                  </option>
                                  {selectDayEvent.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                <label className="label-helper py-2 px-1">
                                  {" "}
                                  Select Shift{" "}
                                </label>

                              </div>
                            </div>
                          </div>
                        ))}

                        {
                          this.state.hideErrorMessages[parentIndex]?.selectValue === true ?
                            <span className="text-danger mb-3">
                              Daily Dose not greater than Target Daily Dose it should be equal to Target Daily Dose
                            </span> : ""
                        }

                        {
                          this.state.hideErrorMessages[parentIndex]?.value === true ?
                            <span className="text-danger mb-3">
                              Daily Dose is less than to Target Daily Dose, it should be equal to Target Daily Dose
                            </span> : ""
                        }

                        <div className={`row ${this.state.hideErrorMessages[parentIndex]?.selectValue === true || this.state.hideErrorMessages[parentIndex]?.value === true ? "mt-4" : ""} `}>
                          <div className="col-md-12 col-12">
                            <div className="custom-form-row">
                              <select
                                className={
                                  value.ingestionMethod === "" ||
                                    value.ingestionMethod === undefined ||
                                    value.ingestionMethod === null
                                    ? "disabled-option  form-control custom-form-control"
                                    : "form-control custom-form-control"
                                }
                                value={value.ingestionMethod}
                                onChange={(e) =>
                                  this.handleMethodIngestionChange(
                                    e,
                                    parentIndex
                                  )
                                }
                              >
                                <option value="" selected disabled>
                                  {" "}
                                  Select Ingestion Method{" "}
                                </option>
                                {methodOfIngestion.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Ingestion Method
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="custom-form-row">
                              <textarea
                                className="form-control custom-form-control"
                                placeholder="Notes"
                                value={value.note}
                                onChange={(e) =>
                                  this.handleNotesChange(e, parentIndex)
                                }
                              ></textarea>
                              <label className="label-helper py-2 px-1">
                                {" "}
                                Notes
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {this.state.loading ? (
          <div className="col-lg-2">
            <div class="loading">Loading&#8230;</div>
          </div>
        ) : (
          ""
        )}
        <ImportShoop
          addModalImportShoopShow={this.state.addModalImportShoopShow}
          onHideModal={this.addModalmportShoopClose}
          importShoop={this.state.importShoop}
          userID={this.state.userId}
        ></ImportShoop>

        <DraftShoop
          addModalDraftShoopShow={this.state.addModalDraftShoopShow}
          onHideModal={this.addModaDraftShoopClose}
          dosingHistory={this.state.dosingHistory}
          userID={this.state.userId}
          shoopData={[]}
        ></DraftShoop>

        <div className="create-shoop-btn">
          <button type="button" class="btn btn-plus" onClick={this.addShoop}
          //  disabled={this.state.addShoopBtnDisabled === true ? true: false}
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary btn-save"
            disabled={this.state.disabled ? true : false}
            onClick={(e) => this.onSubmit("Save")}
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-success btn-save-send"
            disabled={this.state.disabled ? true : false}
            onClick={(e) => this.onSubmit("Save&Send")}

          >
            Save & Send
          </button>
          <button
            type="button"
            class="btn btn-warning btn-reset"
            onClick={this.reset}
          >
            <i class="fa fa-repeat" aria-hidden="true"></i> Reset
          </button>
        </div>
      </div>
    );
  }
}

export default CreateShoop;
