import React, { Component } from "react";
import "../../../assets/scss/physician.scss";
import GroupUser from "../../../assets/images/Group-User.svg";
import Validation from "../../common/util";
import PhoneInput from "react-phone-input-2";
import Verify from "../registration/modal/Verify";
import authServices from "../../../services/authServices";
import { toast } from "react-toastify";
import Pencil from "../../../assets/images/Icon-pencil.svg";
import Send from "../../../assets/images/Icon-send.svg";
import Select from "react-select";
import axios from "axios";
import { environment } from "../../../environment";

export class PhysicianSignup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      phone: "",
      officeNumber: "",
      disabled: true,
      addModalShow: false,
      file: '',
      selected: null,
      imagePreviewUrl: GroupUser,
      token: localStorage.getItem("token"),
      specializations: []
    };
  }

  componentDidMount(){
    this.getGlobalSpecializations()
  }


  
  onSubmit = () => {
    this.setState({
      errors: Validation.physicianSigUpvalidation(
        this.state.fields,
        this.state.phone,
        this.state.officeNumber,
        "physicianSignup"
      ).error,
    });
    
    if (
      Validation.physicianSigUpvalidation(
        this.state.fields,
        this.state.phone,
        this.state.officeNumber,
        "physicianSignup"
      ).formIsValid
    ) {
      this.setState({
        disabled: false
      })
      const params = {
        phone: this.state.phone,
        email : this.state.fields.email,
        name: "registration",
      };
      authServices
        .resendPhoneOtp(params)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
              this.setState({ addModalShow: true, disabled: true});

          }
        })
        .catch((e) => {
          if (e.response.status !== undefined) {
            if (e.response.status === 400) {
              // this.setState({ addModalShow: true });
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
            if (e.response.status === 500) {
              this.setState({ addModalShow: false });
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        });
    }
  };

  getGlobalSpecializations = async () => {

    await axios.get(environment.endPoint+"global/specializations", {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            specializations: response.data.data[1]
          });
          this.getSpecializationsData(response.data.data[1])
        }
      })
      .catch((e) => {
        //   if(e.response.status === 401) {
        //     this.props.history.push("/login")
        // }
      });
  }
  getSpecializationsData(response) {
    let option = [];
    if (response.length > 0) {
      response.forEach((product) => {
        let specilizationData = {};
        specilizationData.value = product.id;
        specilizationData.label = product.name;
        option.push(specilizationData);
      });
    }
    this.setState({
      specializations: option,
    });
  }
  handleUserInputChange(field, e) {
    if (field === "personalPhone") {
      this.setState({
        phone: e,
        disabled: false

      });
    } else if(field === "officeNumber"){
      this.setState({
        officeNumber: e,
        disabled: false
      });
    } else {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields, disabled: false
      });
    }
  }


  
  handleChange = value => {
    let fields = this.state.fields;
    fields["specialization"] = value;
    this.setState({
      selected: value,
      fields,
      disabled: false
    });
  };

  photoUpload (e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        disabled: false
      });
    }
    if(file){
      reader.readAsDataURL(file);
    }
  }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });
    return (
      <div className="">
        {/* Start Physician Sign Up */}

        <div className="physician-sign-up">
          <div className="container">
            <div className="physician-singup-body">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8">
                  <div className="physician-signup-main-heading">
                    <h3>Registration</h3>
                  </div>
                </div>
              </div>
              <div className="physician-signup-form-main">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="physician-profile-image">
                      <div className="pos-relative">
                        
                        <img
                          src={this.state.imagePreviewUrl}
                          alt="user"
                          width="250"
                          height="250"
                          className="mb-5 physician-profile-dp"
                        />
                        <button className="btn" type="file">
                          <input type="file" accept=".jpg, .jpeg, .png" className="file-input img-pencil" onChange={(e)=>this.photoUpload(e)} />
                          <img src={Pencil} alt=""/>
                        </button>
                      </div>
                    </div>
                   
                  </div>
                  <div className="col-lg-8">
                    <div className="physician-form">
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="custom-form-row">
                            <input
                              className="form-control custom-form-control py-4"
                              placeholder="First Name*"
                              type="text"
                              onChange={this.handleUserInputChange.bind(
                                this,
                                "firstName"
                              )}
                              value={this.state.fields.firstName}
                            />
                            <label className="label-helper py-2 px-1">
                              First Name{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <span className="text-danger">
                              {this.state.errors["firstName"]}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                          <div className="custom-form-row">
                            <input
                              className="form-control custom-form-control py-4"
                              placeholder="Last Name*"
                              type="text"
                              onChange={this.handleUserInputChange.bind(
                                this,
                                "lastName"
                              )}
                              value={this.state.fields.lastName}
                            />
                            <label className="label-helper py-2 px-1">
                              Last Name{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <span className="text-danger">
                              {this.state.errors["lastName"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="custom-form-row">
                            <input
                              className="form-control custom-form-control py-4"
                              placeholder="Email*"
                              type="email"
                              onChange={this.handleUserInputChange.bind(
                                this,
                                "email"
                              )}
                              value={this.state.fields.email}
                            />
                            <label className="label-helper py-2 px-1">
                              Email <strong className="text-danger">*</strong>
                            </label>
                            <span className="text-danger">
                              {this.state.errors["email"]}
                            </span>
                          </div>
                        </div>
                        
                        
                        <div className="col-md-6 col-xs-12">
                          <div className="wrapper ">
                            <div className="custom-form-row">
                              <PhoneInput
                                
                                className="form-control custom-form-control"
                                country={"us"}
                              
                                value={this.state.fields.phone}
                                disableDropdown
                                onChange={this.handleUserInputChange.bind(
                                  this,
                                  "personalPhone"
                                )}
                              />
                              <label
                                className="verify-label text-uppercase"
                                // onClick={this.searchPhone.bind(
                                //   this
                                // )}
                              ></label>

                              <Verify
                                                  show={this.state.addModalShow}
                                                  phone={
                                                    this.state.phone
                                                  }
                                                  onHide={addModalClose}
                                                  fields = {this.state.fields}
                                                  textHeading="Please enter the OTP we send you"
                                                  wrongSignup={true}
                                                  physicianRegistration= {true}
                                                  file= {this.state.file}
                                                  officeNumber = {this.state.officeNumber}
                                                />

                              <label className="label-helper py-2 px-1">
                                Phone <strong className="text-danger">*</strong>
                              </label>
                              <span className="text-danger">
                              {this.state.errors["personalPhone"]}
                            </span>
                            </div>

                            
                          </div>
                        </div>
                   
                   
                   
                   
                      </div>
                     
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="custom-form-row">
                            <input
                              className="form-control custom-form-control py-4"
                              placeholder="Office Email"
                              type="email"
                              onChange={this.handleUserInputChange.bind(
                                this,
                                "officeEmail"
                              )}
                              value={this.state.fields.officeEmail}
                            />
                            <label className="label-helper py-2 px-1">
                             Office Email
                            </label>
                           
                          </div>
                        </div>
                        
                        
                        <div className="col-md-6 col-xs-12">
                          <div className="wrapper ">
                            <div className="custom-form-row">
                              <PhoneInput
                                className="form-control custom-form-control"
                                country={"us"}
                                
                                value={this.state.fields.officeNumber}
                                disableDropdown
                                onChange={this.handleUserInputChange.bind(
                                  this,
                                  "officeNumber"
                                )}
                              />
                              <label
                                className="verify-label text-uppercase"
                                
                              ></label>
                              <label className="label-helper py-2 px-1">
                              Office Number
                              </label>
                             
                            </div>

                           
                          </div>
                        </div>
                   
                   
                   
                   
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="custom-form-row">
                            {/* <input
                              className="form-control custom-form-control py-4"
                              placeholder="Specialization*"
                              type="text"
                              onChange={this.handleUserInputChange.bind(
                                this,
                                "specialization"
                              )}
                              value={this.state.fields.specialization}
                            /> */}
                             <Select
                              className="custom-form-control"
                              placeholder="Select Specializations*"
                              name="form-field-name"
                              onChange={this.handleChange.bind(
                                this                              )}
                              selected={this.state.selected}
                              // value={this.state.fields.specialization}
                              options={this.state.specializations}
                            />
                            <label className="label-helper py-2 px-1">
                               Specialization{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <span className="text-danger">
                              {this.state.errors["specialization"]}
                            </span>
                          </div>
                        </div>

                      </div>

 
                    </div>
                    <div className="submit-btn">
                      <button
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                        disabled={this.state.disabled}
                      >
                        SUBMIT{" "}
                        <img src={Send} alt=""/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Physician Sign Up */}
      </div>
    );
  }
}

export default PhysicianSignup;
