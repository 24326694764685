import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from "../../../../environment";

export class FamilyHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      familyHistory: {},
      patientStatus: this.props.status,
      userID:  this.props.userID,
      data: {},
      token: localStorage.getItem("token"),
      disabled: true

    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  handleCheckboxes(field, e) {
    let data = this.state.data;
    data[field] = e.target.checked;
    this.setState({ data,disabled: false });
  }

  prev(e) {
    e.preventDefault();
    this.props.prev();
  }
  componentDidMount() {
    this.getFamilyHistory()
  }
  getFamilyHistory = async()=> {
    const data = this.state.patientStatus === "physician" ? "intake/familyHistory?userId="+ this.state.userID : "intake/familyHistory";
    
    await axios.get(environment.endPoint+data, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        if (response.data.data[1] !== "") {
          if (response.data.data[1] !== null) {
            this.setState({
              data: response.data.data[1],
            });
          }
        }
      })
      .catch((e) => {
      });
  }

  onSubmit = async(e) => {
    e.preventDefault();
    const familyHistory = this.state.familyHistory;
    familyHistory.data = this.state.data;
    if(this.state.patientStatus === "physician"){
      let data = this.state.data;
      this.state.data.userId = this.state.userID;
      familyHistory.data = data;
    }
    this.setState({ familyHistory: familyHistory });

    await axios.post(environment.endPoint+"intake/familyHistory", familyHistory, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          this.callCompleted();
        }
      })
      .catch((e) => {
        // if (e.response.status === 400) {
        //   toast.error("Patient History Not Updated", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 3000,
        //   });
        // }
      });
  }
  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true
    })
    var myButtonClasses = document.getElementById("familyHistory").classList;
    if (myButtonClasses.contains("step")) {
      myButtonClasses.remove("active");
      myButtonClasses.add("patient-intake-active");
    } else {
      myButtonClasses.add("active");
    }
  }

  render() {
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">
            Family History
          </h5>

          <label className="text-left stepper-form-sub-headings">
            Have any of your immediate family members had any of the following
            conditions?
          </label>

          <div className="row mb-4 mt-3">
            <div className="col-lg-4 col-12">
              <p className="text-left">Alcoholism</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-422"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.alcoholism}
                  onChange={this.handleCheckboxes.bind(this, "alcoholism")}
                />
                <label htmlFor="switch-422" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Anemia</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-511"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.anemia}
                  onChange={this.handleCheckboxes.bind(this, "anemia")}
                />
                <label htmlFor="switch-511" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>
          
          
          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Anesthesia problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-611"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.anesthesia}
                  onChange={this.handleCheckboxes.bind(this, "anesthesia")}
                />
                <label htmlFor="switch-611" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Arthritis</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-711"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.arthritis}
                  onChange={this.handleCheckboxes.bind(this, "arthritis")}
                />
                <label htmlFor="switch-711" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Asthma</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-811"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.asthma}
                  onChange={this.handleCheckboxes.bind(this, "asthma")}
                />
                <label htmlFor="switch-811" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Birth Defects</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-911"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.birthDefects}
                  onChange={this.handleCheckboxes.bind(this, "birthDefects")}
                />
                <label htmlFor="switch-911" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Bleeding problems</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-101"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.bleedingProblems}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "bleedingProblems"
                  )}
                />
                <label htmlFor="switch-101" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Cancer</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-111"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.cancer}
                  onChange={this.handleCheckboxes.bind(this, "cancer")}
                />
                <label htmlFor="switch-111" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
            {/* (polyps, colitis) */}
              <p className="text-left">Colon Problems </p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-121"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.colonProblems}
                  onChange={this.handleCheckboxes.bind(this, "colonProblems")}
                />
                <label htmlFor="switch-121" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Crohn’s Disease</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-131"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.crohnDisease}
                  onChange={this.handleCheckboxes.bind(this, "crohnDisease")}
                />
                <label htmlFor="switch-131" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Depression</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-141"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.depression}
                  onChange={this.handleCheckboxes.bind(this, "depression")}
                />
                <label htmlFor="switch-141" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
            {/* (child) */}
              <p className="text-left">Diabetes, Type 1 </p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-151"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.diabetesType1}
                  onChange={this.handleCheckboxes.bind(this, "diabetesType1")}
                />
                <label htmlFor="switch-151" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
            {/* (adult) */}
              <p className="text-left">Diabetes, Type 2 </p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-161"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.diabetesType2}
                  onChange={this.handleCheckboxes.bind(this, "diabetesType2")}
                />
                <label htmlFor="switch-161" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <p className="text-left">Eczema Other</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-171"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.eczemaOther}
                  onChange={this.handleCheckboxes.bind(this, "eczemaOther")}
                />
                <label htmlFor="switch-171" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
            {/* (Seizures) */}
              <p className="text-left">Epilepsy</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-181"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.epilepsy}
                  onChange={this.handleCheckboxes.bind(this, "epilepsy")}
                />
                <label htmlFor="switch-181" className="switch-label">
                  Switch
                </label>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <p className="text-left">Genetic diseases</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-191"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.geneticDiseases}
                  onChange={this.handleCheckboxes.bind(this, "geneticDiseases")}
                />
                <label htmlFor="switch-191" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Glaucoma</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-201"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.glaucoma}
                  onChange={this.handleCheckboxes.bind(this, "glaucoma")}
                />
                <label htmlFor="switch-201" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
            {/* (Allergies) */}
              <p className="text-left">Hay fever</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-211"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.hayFever}
                  onChange={this.handleCheckboxes.bind(this, "hayFever")}
                />
                <label htmlFor="switch-211" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
            {/* (CAD) */}
              <p className="text-left">Heart Attack </p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-222"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.heartAttack}
                  onChange={this.handleCheckboxes.bind(this, "heartAttack")}
                />
                <label htmlFor="switch-222" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">High Blood Pressure</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-232"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.highBloodPressure}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "highBloodPressure"
                  )}
                />
                <label htmlFor="switch-232" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">High cholesterol</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-242"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.highCholesterol}
                  onChange={this.handleCheckboxes.bind(this, "highCholesterol")}
                />
                <label htmlFor="switch-242" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Kidney diseases</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-252"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.kidneyDiseases}
                  onChange={this.handleCheckboxes.bind(this, "kidneyDiseases")}
                />
                <label htmlFor="switch-252" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Lupus (SLE)</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-261"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.lupus}
                  onChange={this.handleCheckboxes.bind(this, "lupus")}
                />
                <label htmlFor="switch-261" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Mental retardation</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-272"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.mentalRetardation}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "mentalRetardation"
                  )}
                />
                <label htmlFor="switch-272" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Migraine headaches</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-281"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.migraineHeadaches}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "migraineHeadaches"
                  )}
                />
                <label htmlFor="switch-281" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Mitral Valve Prolapse</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-292"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.mitralValveProlapse}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "mitralValveProlapse"
                  )}
                />
                <label htmlFor="switch-292" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Osteoporosis</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-301"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.osteoporosis}
                  onChange={this.handleCheckboxes.bind(this, "osteoporosis")}
                />
                <label htmlFor="switch-301" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Stroke (CVA)</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-311"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.stroke}
                  onChange={this.handleCheckboxes.bind(this, "stroke")}
                />
                <label htmlFor="switch-311" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Substance abuse</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-322"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.substanceAbuse}
                  onChange={this.handleCheckboxes.bind(this, "substanceAbuse")}
                />
                <label htmlFor="switch-322" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <p className="text-left">Thyroid disorders</p>
            </div>
            <div className="col-lg-1 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-331"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.thyroidDisorders}
                  onChange={this.handleCheckboxes.bind(
                    this,
                    "thyroidDisorders"
                  )}
                />
                <label htmlFor="switch-331" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-12">
              <p className="text-left">Tuberculosis</p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch">
                <input
                  id="switch-342"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.data?.tuberculosis}
                  onChange={this.handleCheckboxes.bind(this, "tuberculosis")}
                />
                <label htmlFor="switch-342" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 offset-lg-4 col-6">
              <button
                id="first"
                type="button"
                className="btn btn-white border-radius py-1 px-3 custom-back-btn"
                onClick={this.prev}
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <div className="col-lg-4 col-6">
              <button
                id="third"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true: false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default FamilyHistory;
