import { ALERT_P450 } from '../actions/actionsTypes';

const initialState = {
    alertP450: {}
};

const alertP450 = (state, action) => {
  return { ...state, alertP450: action.alertP450}
}

const alertP450Reduers = (state = initialState, action) => {
  switch(action.type) {
    case ALERT_P450: return alertP450(state, action);
    default: return state;
  }
};

export default alertP450Reduers;