import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import AuthService from "../../../../services/authServices";
import { toast } from "react-toastify";

class Medication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.medicationName,
      user_id: "",
      errors: {},
      data: [],
      medicationList: {
        data: [
          {
            name: "",
          },
        ],
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.medicationName !== this.props.medicationName){
      this.setState({ name:this.props.medicationName });
    }
  }

  handleValidation() {
    let medicaiton = this.state;
    let errors = {};
    let formIsValid = true;

    if (!medicaiton["name"]) {
      formIsValid = false;
      errors["name"] = "This field is required";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  onSubmit(e) {
    if (this.handleValidation()) {
      e.preventDefault();
      const params = {
        name: this.state.name,
        user_id: this.props.user_id,
      };
      AuthService.postMedication(params)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Medication Add Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            const data = this.state.data
            data.push({
              id: response.data.data.id,
              name: response.data.data.name
            });
            // this.setState({

            // })
           const medicationid = this.props.medications.slice();
           medicationid[this.props.index].medicationId = response.data.data.id;
           medicationid[this.props.index].name = response.data.data.name
           const hideShowMedication = this.props.hideShowMedication.slice();
           hideShowMedication[this.props.index].value = false


            this.props.hideModal();
            this.props.onUpdate();
            this.props.medicationId(medicationid,hideShowMedication)
            this.props.initiliazeMedicationList(data,"AddMedication");
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error("Please check with your service provider", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else if (e.response.status === 500) {
            toast.error("Server Error", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        });
    }
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.addModalMedicationShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-verify-modal"
        >
          <form action="" onSubmit={this.onSubmit}>
            <Modal.Body>
              <h5 className="verify-modal-title p-3">Add New Medication</h5>

              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="custom-form-row">
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control custom-form-control"
                      value={this.state.name}
                      onChange={this.handleNameChange}
                    />
                    <label className="label-helper py-2 px-1">Name </label>
                  </div>
                  <span className="text-danger">
                    {this.state.errors["name"]}
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="verify-modal-footer">
            <button
                type="button"
                className="btn btn-white verify-button border-radius py-1  px-3"
                onClick={this.props.hideModal}
              >
                Cancel{" "}
              </button>
              <button
                type="submit"
                className="btn btn-primary border-radius py-1  px-3"
              >
                Submit{" "}
                <i className="fa fa-location-arrow" aria-hidden="true"></i>
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Medication;
