import React, { Component } from "react";

export class Dispensary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  prev = () => {
    this.props.prev();
  };
  onSubmit = () => {
    this.props.next();
  };

  render() {
    return (
      <div className="card-body card-body-profile custom-inner-content">
        <h5 className="stepper-form-headings d-none d-lg-block">Dispensary</h5>
        <div className="row">
          <div className="col-lg-12">
            <h5 className="stepper-form-headings">Section Pending!!!</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 offset-lg-3 col-6">
            <button
              id="first"
              type="button"
              className="btn btn-white border-radius py-1 px-3 custom-back-btn"
              onClick={this.prev}
            >
              <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
              Back
            </button>
          </div>
          <div className="col-lg-4 col-6">
            <button
              id="third"
              type="button"
              className="btn btn-primary border-radius py-1  px-3"
              onClick={this.onSubmit}
            >
              Update
              <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Dispensary;
