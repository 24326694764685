import React, { Component } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import moment from "moment";
import Validation from "../../../common/util";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { environment } from "../../../../environment";
const prefixSuffix = [
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Mrs", label: "Mrs" },
  { value: "Mr", label: "Mr" },
  { value: "Master", label: "Master" },
  { value: "Fr (Father)", label: "Fr (Father)" },
  { value: "Dr (Doctor)", label: "Dr (Doctor)" },
  { value: "Atty (Attorny)", label: "Atty (Attorny)" },
  { value: "Prof (Professor)", label: "Prof (Professor)" },
  { value: "Hon (Honourable)", label: "Hon (Honourable)" },
  { value: "Pres (President)", label: "Pres (President)" },
  { value: "Gov (Governor)", label: "Gov (Governor)" },
  { value: "Coach", label: "Coach" },
  { value: "ofc (Officer)", label: "ofc (Officer)" },
];

const Occupation = [
  { value: "Accounting/Finance", label: "Accounting/Finance" },
  {
    value: "Advertising/Public Relations",
    label: "Advertising/Public Relations",
  },
  { value: "Aerospace/Aviation", label: "Aerospace/Aviation" },
  {
    value: "Arts/Entertainment/Publishing",
    label: "Arts/Entertainment/Publishing",
  },
  { value: "Banking/Mortgage", label: "Banking/Mortgage" },
  { value: "Business Development", label: "Business Development" },
  { value: "Business Opportunity", label: "Business Opportunity" },
  { value: "Clerical/Administrative", label: "Clerical/Administrative" },
  { value: "Construction/Facilities", label: "Construction/Facilities" },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Customer Service", label: "Customer Service" },
  { value: "Education/Training", label: "Education/Training" },
  { value: "Energy/Utilities", label: "Energy/Utilities" },
  { value: "Engineering", label: "Engineering" },
  { value: "Government/Military", label: "Government/Military" },
  { value: "Green", label: "Green" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality/Travel", label: "Hospitality/Travel" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Installation/Maintenance", label: "Installation/Maintenance" },
  { value: "Insurance", label: "Insurance" },
  { value: "Internet", label: "Internet" },
  { value: "Job Search Aids", label: "Job Search Aids" },
  { value: "Law Enforcement/Security", label: "Law Enforcement/Security" },
  { value: "Legal", label: "Legal" },
  { value: "Management/Executive", label: "Management/Executive" },
  { value: "Manufacturing/Operations", label: "Manufacturing/Operations" },
  { value: "Marketing", label: "Marketing" },
  { value: "Non-Profit/Volunteer", label: "Non-Profit/Volunteer" },
  { value: "Pharmaceutical/Biotech", label: "Pharmaceutical/Biotech" },
  { value: "Professional Services", label: "Professional Services" },
  { value: "QA/Quality Control", label: "QA/Quality Control" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Restaurant/Food Service", label: "Restaurant/Food Service" },
  { value: "Retail", label: "Retail" },
  { value: "Sales", label: "Sales" },
  { value: "Science/Research", label: "Science/Research" },
  { value: "Skilled Labor", label: "Skilled Labor" },
  { value: "Technology", label: "Technology" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Transportation/Logistics", label: "Transportation/Logistics" },
  { value: "Other", label: "Other" },
];
const highestLevelOfEducation = [
  {
    value: "Haven't graduated high school",
    label: "Haven't graduated high school",
  },
  { value: "GED", label: "GED" },
  { value: "High school graduate", label: "High school graduate" },
  { value: "First year of college", label: "First year of college" },
  { value: "Third year of college", label: "Third year of college" },
  { value: "Fourth year of college", label: "Fourth year of college" },
  { value: "Bachelors", label: "Bachelors" },
  { value: "Masters", label: "Masters" },
  { value: "Doctorate", label: "Doctorate" },
];
const bloodType = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
  { value: "none", label: "I don't Know" },
];

const weight = [
  { value: "Lbs", label: "Lbs" },
  { value: "KG", label: "KG" },
];

const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  { value: "Decline To Answer", label: "Decline To Answer" },
];

class PersonalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: "patient-intake-active",
      fields: {},
      ethnicity: {},
      homePhone: null,
      phone: "",
      country: "",
      state: "",
      dob: null,
      errors: {},
      contacts: {},
      heightUnit: "",
      patientStatus: this.props.status,
      userID: this.props.userID,
      token: localStorage.getItem("token"),
      disabled: true,
      loading: false,
      showHeight: true,
    };
    this.next = this.next.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.handlePersonalDateChange = this.handlePersonalDateChange.bind(this);
  }
  componentDidMount() {
    this.getPatientInformation();
  }

  getPatientInformation = async () => {
    const data =
      this.state.patientStatus === "physician"
        ? "intake/pationInfo?userId=" + this.state.userID
        : "intake/pationInfo";
    this.setState({
      loading: true,
    });
    await axios
      .get(environment.endPoint + data, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        let fields = this.state.fields;
        this.setState({
          loading: false,
        });
        fields["firstName"] = response.data.data.firstName;
        fields["lastName"] = response.data.data.lastName;
        fields["gender"] = response.data.data.gender;
        this.setState({
          homePhone: response.data.data.homePhone,
          phone: response.data.data.phone,
          dob: response.data.data.dob
        });
        fields["homePhone"] = response.data.data.homePhone;
        fields["suffix"] = response.data.data.suffix;
        fields["address"] = response.data.data.address;
        fields["addressTwo"] = response.data.data.addressTwo;
        fields["bloodGroup"] = response.data.data.bloodGroup;
        fields["education"] = response.data.data.education;
        fields["city"] = response.data.data.city;
        fields["heightFeet"] = response.data.data.heightFeet;
        fields["heightCM"] = response.data.data.heightCM;
        fields["heightInches"] = response.data.data.heightInches;
        fields["weight"] = response.data.data.weight;
        fields["weightUnit"] = response.data.data.weightUnit;
        fields["occupation"] = response.data.data.occupation;
        fields["weight"] = response.data.data.weight;
        fields["zipCode"] = response.data.data.zipCode;

        if (response.data.data.ethnicity !== "") {
          if (response.data.data.ethnicity !== null) {
            this.setState({
              ethnicity: response.data.data.ethnicity,
            });
          }
        }

        this.setState({
          showHeight: response.data.data.heightUnit === "feet" ? true : false,
          heightUnit: response.data.data.heightUnit
        });

        this.setState({ fields });
        this.setState({
          country: response.data.data.country,
          state: response.data.data.state,
        });
      })
      .catch((e) => {});
  };

  next(e) {
    e.preventDefault();
    this.props.next();
  }

  selectCountry(val) {
    this.setState({ country: val, disabled: false });
  }

  selectRegion(val) {
    this.setState({ state: val, disabled: false });
  }

  handleUserInputChange(field, e) {
    if (field === "homePhone") {
      this.setState({
        homePhone: e,
        disabled: false,
      });
    } else if (field === "phone") {
      this.setState({
        phone: e,
        disabled: false,
      });
    } else {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields, disabled: false });
    }
  }

  handlePersonalDateChange(value, dateString) {
    let patientInformation = this.state;
    patientInformation.dob = value;
    this.setState({
      patientInformation: patientInformation,
      dob: value,
      disabled: false,
    });
  }

  handleCheckboxes(field, e) {
    let ethnicity = this.state.ethnicity;
    ethnicity[field] = e.target.checked;
    this.setState({ ethnicity, disabled: false });
  }

  handleHeightUnitCheck(e) {
    this.setState({
      showHeight: e.target.checked === true ? true : false,
      heightUnit: e.target.checked === true ? "feet" : "cm",
      disabled: false,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      errors: Validation.patientInformation(
        this.state.fields,
        this.state.phone,
        this.state.homePhone,
        "patientInformation"
      ).error,
    });
    if (
      Validation.patientInformation(
        this.state.fields,
        this.state.phone,
        this.state.homePhone,
        "patientInformation"
      ).formIsValid
    ) {
      let patientInformation = this.state.fields;
      patientInformation.country = this.state.country;
      patientInformation.homePhone = this.state.homePhone;
      patientInformation.state = this.state.state;
      patientInformation.ethnicity = this.state.ethnicity;
      patientInformation.heightUnit = this.state.heightUnit;

      if(this.state.dob !== null) {
        var fullDate = moment(this.state.dob, "MM-DD-YYYY");
        var date = fullDate.format("DD");
        var year = fullDate.format("YYYY");
        var month = fullDate.format("MM");
        const selectedDate = month + "-" + date + "-" + year;
        patientInformation.dob = this.state.dob !== null || this.state.dob === " " ? selectedDate : null;
      } else {
        patientInformation.dob = null
      }

      patientInformation.heightFeet =
        this.state.heightUnit === "cm"
          ? (patientInformation["heightFeet"] = "")
          : this.state.fields.heightFeet;
      patientInformation.heightInches =
        this.state.heightUnit === "cm"
          ? (patientInformation["heightInches"] = "")
          : this.state.fields.heightInches;
      patientInformation.heightCM =
        this.state.heightUnit === "feet"
          ? (patientInformation["heightCM"] = "")
          : this.state.fields.heightCM;

      if (this.state.patientStatus === "physician") {
        patientInformation.userId = this.state.userID;
      }

      this.setState({ patientInformation: patientInformation });
      await axios
        .post(environment.endPoint + "intake/pationInfo", patientInformation, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status !== undefined) {
            toast.success("Patient Information Update Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            this.callCompleted();
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error("Patient Information Not Updated", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        });
    }
  };

  callCompleted() {
    this.props.next();
    this.setState({
      disabled: true,
    });
    var myButtonClasses = document.getElementById("Patient").classList;
    if (myButtonClasses.contains("active")) {
      myButtonClasses.remove("active");
    } else {
      myButtonClasses.add("patient-intake-active");
    }
  }

  render() {
    let { country, state } = this.state;
    return (
      <form>
        <div className="card-body custom-inner-content  ">
          <h5 className="stepper-form-headings">
            Patient Information
          </h5>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.suffix || ""}
                  className={
                    this.state.fields.suffix === null
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "suffix")}
                >
                  <option value="" selected disabled>
                    Select Prefix / Suffix
                  </option>
                  {prefixSuffix.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Prefix / Suffix{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-6 ">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="First Name *"
                  value={this.state.fields.firstName || ""}
                  onChange={this.handleUserInputChange.bind(this, "firstName")}
                />
                <label className="label-helper py-2 px-1">
                  First Name <span className="text-danger">*</span>{" "}
                </label>
                <span className="text-danger">
                  {this.state.errors["firstName"]}
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Last Name *"
                  value={this.state.fields.lastName || ""}
                  onChange={this.handleUserInputChange.bind(this, "lastName")}
                />
                <label className="label-helper py-2 px-1">
                  Last Name <span className="text-danger">*</span>
                </label>
                <span className="text-danger">
                  {this.state.errors["lastName"]}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.occupation || ""}
                  className={
                    this.state.fields.occupation === null
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "occupation")}
                >
                  <option value="" disabled selected>
                    {" "}
                    Select Occupation
                  </option>
                  {Occupation.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">Occupation </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.education || ""}
                  className={
                    this.state.fields.education === null
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "education")}
                >
                  <option value="" disabled selected>
                    {" "}
                    Select Highest Level Of Education
                  </option>
                  {highestLevelOfEducation.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  Highest Level Of Education{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Address Line1"
                  value={this.state.fields.address || ""}
                  onChange={this.handleUserInputChange.bind(this, "address")}
                />
                <label className="label-helper py-2 px-1">Address Line1 </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Address Line2"
                  value={this.state.fields.addressTwo || ""}
                  onChange={this.handleUserInputChange.bind(this, "addressTwo")}
                />
                <label className="label-helper py-2 px-1">Address Line2 </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="City"
                  value={this.state.fields.city || ""}
                  onChange={this.handleUserInputChange.bind(this, "city")}
                />
                <label
                  htmlFor="country_code"
                  className="label-helper py-2 px-1"
                >
                  City{" "}
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Zip/Postal Code"
                  value={this.state.fields.zipCode || ""}
                  type="number"
                  min="0"
                  step="1"
                  onChange={this.handleUserInputChange.bind(this, "zipCode")}
                />
                <label className="label-helper py-2 px-1">
                  Zip/Postal Code <span className="text-danger">*</span>{" "}
                </label>
                <span className="text-danger">
                  {this.state.errors["zipCode"]}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <CountryDropdown
                  className="form-control custom-form-control "
                  defaultOptionLabel="Select a Country"
                  value={country || ""}
                  onChange={this.selectCountry}
                  priorityOptions={["US"]}
                />
                <label className="label-helper py-2 px-1">Country </label>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <RegionDropdown
                  className="form-control custom-form-control "
                  blankOptionLabel="No Country Selected"
                  defaultOptionLabel="Now select a Region"
                  country={country || ""}
                  value={state || ""}
                  onChange={this.selectRegion}
                />
                <label className="label-helper py-2 px-1">
                  State/Province/Region{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  className={
                    this.state.fields.gender === null
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "gender")}
                  value={this.state.fields.gender || ""}
                >
                  <option value="" disabled selected>
                    {" "}
                    What sex were you assigned at birth ?
                  </option>
                  {gender.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">
                  {" "}
                  What sex were you assigned at birth ?{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="wrapper ">
                <div
                  className={
                      this.state.homePhone !== null
                      ? "has-number custom-form-row"
                      : "custom-form-row"
                  }
                >
                  <PhoneInput
                    country={"us"}
                    inputProps={{
                      // name: "Home phone",
                      required: true,
                      // autoFocus: true,
                      placeholder: "Home Phone",
                    }}
                    disableDropdown
                    value={this.state.homePhone}
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "homePhone"
                    )}
                  />
                  <label className="label-helper py-2 px-1">Home Phone </label>
                  <span className="text-danger">
                    {this.state.errors["homePhone"]}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="wrapper ">
                <div
                  className={
                    this.state.phone === ""
                      ? "custom-form-row"
                      : "has-number custom-form-row"
                  }
                >
                  <PhoneInput
                    id="Input"
                    className="form-control custom-form-control"
                    country={"us"}
                    inputProps={{
                      name: "Cell Phone",
                      required: true,
                      // autoFocus: true,
                      placeholder: "Cell Phone",
                      readonly: "readonly",
                    }}
                    value={this.state.phone}
                    disableDropdown
                    onChange={this.handleUserInputChange.bind(this, "phone")}
                  />

                  <label className="label-helper py-2 px-1">
                    Cell Phone <span className="text-danger">*</span>{" "}
                  </label>

                  <span className="text-danger">
                    {this.state.errors["phone"]}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div
                className={
                  this.state.dob !== null
                    ? "has-number custom-form-row"
                    : "custom-form-row"
                }
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    openTo="date"
                    value={this.state.dob}
                    placeholder="Date of Birth"
                    format="MM-dd-yyyy"
                    views={["year", "month", "date"]}
                    onChange={this.handlePersonalDateChange}
                    disableFuture
                  />
                </MuiPickersUtilsProvider>

                <label className="label-helper py-2 px-1">Date of Birth</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12 mt-2">
              <h4 className="stepper-form-sub-headings">
                Which of the following best represents your racial or ethnic
                heritage?
              </h4>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  value={"hispanic"}
                  checked={this.state.ethnicity?.hispanic}
                  onChange={this.handleCheckboxes.bind(this, "hispanic")}
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Non-Hispanic White or Euro-American
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck2"
                  value={"black"}
                  checked={this.state.ethnicity?.black}
                  onChange={this.handleCheckboxes.bind(this, "black")}
                />
                <label className="form-check-label" htmlFor="exampleCheck2">
                  Black, Afro-Caribbean, or African American
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck3"
                  value={"latino"}
                  checked={this.state.ethnicity?.latino}
                  onChange={this.handleCheckboxes.bind(this, "latino")}
                />
                <label className="form-check-label" htmlFor="exampleCheck3">
                  Latino or Hispanic American
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck4"
                  value={"east"}
                  checked={this.state.ethnicity?.east}
                  onChange={this.handleCheckboxes.bind(this, "east")}
                />
                <label className="form-check-label" htmlFor="exampleCheck4">
                  East Asian or Asian American
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck5"
                  value={"south"}
                  checked={this.state.ethnicity?.south}
                  onChange={this.handleCheckboxes.bind(this, "south")}
                />
                <label className="form-check-label" htmlFor="exampleCheck5">
                  South Asian or Indian American
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck6"
                  value={"middle"}
                  checked={this.state.ethnicity?.middle}
                  onChange={this.handleCheckboxes.bind(this, "middle")}
                />
                <label className="form-check-label" htmlFor="exampleCheck6">
                  Middle Eastern or Arab American
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck7"
                  value={"native"}
                  checked={this.state.ethnicity?.native}
                  onChange={this.handleCheckboxes.bind(this, "native")}
                />
                <label className="form-check-label" htmlFor="exampleCheck7">
                  Native American or Alaskan Native
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck8"
                  value={"Other"}
                  checked={this.state.ethnicity?.other}
                  onChange={this.handleCheckboxes.bind(this, "other")}
                />
                <label className="form-check-label" htmlFor="exampleCheck8">
                  Other
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4 mt-3">
            <div className="col-lg-10 col-12">
              <p className="text-left stepper-form-sub-headings">
                Would you prefer to enter Height in Feet(ft) or CentiMeter(cm)?
              </p>
            </div>
            <div className="col-lg-2 col-12 text-right">
              <div className="switch height-switch">
                <input
                  id="height-unit"
                  type="checkbox"
                  className="switch-input"
                  checked={this.state.showHeight}
                  onChange={this.handleHeightUnitCheck.bind(this)}
                />
                <label htmlFor="height-unit" className="switch-label">
                  Switch
                </label>
              </div>
            </div>
          </div>

          {this.state.showHeight === true ? (
            <div className="row mt-4">
              <div className="col-lg-6 col-6">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control"
                    placeholder="Height in Feet"
                    type="number"
                    min="0"
                    // max="10"
                    step="1"
                    value={this.state.fields.heightFeet || ""}
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "heightFeet"
                    )}
                  />
                  <label className="label-helper py-2 px-1">
                    Height in ft(Feet){" "}
                  </label>
                </div>
                <span className="text-danger">
                  {this.state.errors["heightFeet"]}
                </span>
              </div>
              <div className="col-lg-6 col-6">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control"
                    placeholder="Height in Inches"
                    type="number"
                    min="0"
                    max="11"
                    step="1"
                    value={this.state.fields.heightInches || ""}
                    onChange={this.handleUserInputChange.bind(
                      this,
                      "heightInches"
                    )}
                  />
                  <label className="label-helper py-2 px-1">
                    Height in in(Inches){" "}
                  </label>
                </div>
                <span className="text-danger">
                  {this.state.errors["heightInches"]}
                </span>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control"
                    placeholder="Height in Centimeter"
                    type="number"
                    min="0"
                    max="10"
                    step="1"
                    value={this.state.fields.heightCM || ""}
                    onChange={this.handleUserInputChange.bind(this, "heightCM")}
                  />
                  <label className="label-helper py-2 px-1">
                    Height in cm(Centimeter){" "}
                  </label>
                </div>
                {/* <span className="text-danger">
          {this.state.errors["heightFeet"]}
        </span> */}
              </div>
            </div>
          )}

          <div className="row ">
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <input
                  className="form-control custom-form-control"
                  placeholder="Weight"
                  type="number"
                  min="0"
                  step="1"
                  value={this.state.fields.weight || ""}
                  onChange={this.handleUserInputChange.bind(this, "weight")}
                />
                <label className="label-helper py-2 px-1">Weight </label>
                <span className="text-danger">{this.state.errors["weight"]}</span>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.weightUnit || ""}
                  className={
                    this.state.fields.weightUnit === null
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "weightUnit")}
                >
                  <option value="" disabled selected>
                    {" "}
                    Lb's Or Kg ?
                  </option>
                  {weight.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <label className="label-helper py-2 px-1"> Lb's Or Kg ? </label>
              </div>
              <span className="text-danger">
                {this.state.errors["weightUnit"]}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-12">
              <div className="custom-form-row">
                <select
                  value={this.state.fields.bloodGroup || ""}
                  className={
                    this.state.fields.bloodGroup === null
                      ? "disabled-option form-control custom-form-control"
                      : "form-control custom-form-control"
                  }
                  onChange={this.handleUserInputChange.bind(this, "bloodGroup")}
                >
                  <option value="" disabled selected>
                    {" "}
                    Select Blood Type
                  </option>
                  {bloodType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <label className="label-helper py-2 px-1">Blood Type </label>
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="col-lg-2">
              <div class="loading">Loading&#8230;</div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-lg-4 col-6 offset-lg-5">
              <button
                id="second"
                type="button"
                className="btn btn-primary border-radius py-1  px-3"
                onClick={this.onSubmit.bind(this)}
                disabled={this.state.disabled ? true : false}
              >
                Update
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default PersonalInformation;
