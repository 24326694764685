import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { connect } from "react-redux";
import store from "../../../../store/store";

import { importShoops } from "../../../../actions/shoopActions";
import { toast } from "react-toastify";
class ImportShoop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importShoop: [],
      userId: "",
      showButton: [],
      shoopData: [],
      visible: 2,
      buttonName: "EDI TO SHOOP" 
    };
  }

  loadMore = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 2 };
    });
  };

  componentDidMount() {
    this.setState({
      importShoop: this.props.importShoop,
      userId: this.props.userID,
    });
    this.importShoops(this.props.importShoop);
  }

  componentDidUpdate(prevprops, prevState) {
    if (prevprops.importShoop !== this.props.importShoop) {
      this.setState({
        importShoop: this.props.importShoop,
        userId: this.props.userID,
      });
      this.importShoops(this.props.importShoop);
    }
  }

  importShoops(importShoop) {
    const option = [];
    importShoop.forEach((value, index) => {
      let productData = {
        buttonName: "Add to Shoop",
        btnClassName: "btn-primary",
        disabled: false,
      };
      option.push(productData);
      this.setState({
        showButton: option,
      });
    });
  }

  addTopShoop(index) {
    const data = this.state.importShoop[index];
    let shoopDoses = [];
    let showButtonData = this.state.showButton;
      shoopDoses = data.ShoopDoses;
      const shoopData = this.state.shoopData;
      shoopData.push({
        targetCannabinoid: data.targetCannabinoid,
        terpenes: data.terpenes,
        dailyDose: data.dailyDose,
        dailyDoseUnit: data.dailyDoseUnit,
        note: data.note,
        frequency: data.frequency,
        frequencyUnit: data.frequencyUnit,
        ingestionMethod: data.ingestionMethod,
        doses: shoopDoses,
        userId: this.props.userID,
      });
      store.dispatch(importShoops(shoopData));
      toast.success("Shoop Added Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      showButtonData[index].buttonName = "Shoop Added";
      showButtonData[index].btnClassName = "import-btn-success";
      showButtonData[index].disabled = true;

      this.setState({
        showButton: showButtonData,
      });
      this.onClose()
  }

  onClose = () => {
    this.props.onHideModal();
    this.setState({
      shoopData: []
    })
  };

  render() {
    return (
      <div>
        <div className="import-shoop-start">
          <Modal
            show={this.props.addModalImportShoopShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-verify-modal import-shoop-start"
          >
            <form>
              <Modal.Header>
                <h4 class="modal-title">Import Shoop</h4>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  onClick={() => this.onClose()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
              {this.state.importShoop
                    ?.slice(0, this.state.visible)
                    ?.map((value, index) => {
                  return (
                    <div class="card fade-in">
                      <div class="card-body">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="daignose">
                              <h5>
                                Primary Diagnosis:{" "}
                                <span>
                                  {" "}
                                  {value?.User?.Diagnose?.DignoseList?.name
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    value?.User?.Diagnose?.DignoseList?.name
                                      ?.substr(1)
                                      .toLowerCase()}
                                </span>
                              </h5>
                            </div>
                            <div className="mdcn">
                              <p>{value?.targetCannabinoid}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="send-btn">
                              <button
                                type="button"
                                disabled={
                                  this.state.showButton[index].disabled
                                    ? true
                                    : false
                                }
                                class={`btn ${this.state.showButton[index].btnClassName}`}
                                onClick={this.addTopShoop.bind(this, index)}
                              >
                                {this.state.showButton[index].buttonName}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div className="mdcn-status">
                              <p>
                                Created Date:{" "}
                                <span className="text-primary">
                                  {" "}
                                  {value?.createdAt
                                    ? moment(value?.createdAt).format(
                                      "MM-DD-YYYY"
                                      )
                                    : "####"}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="age-gender">
                              <span className="age">
                                Age:{" "}
                                <span>
                                  {" "}
                                  {moment().diff(value?.User?.dob, "years") ===
                                  0
                                    ? moment().diff(
                                        value?.User?.dob,
                                        "months"
                                      ) +
                                      " Months " +
                                      moment().diff(value?.User?.dob, "days") +
                                      " Days "
                                    : moment().diff(value?.User?.dob, "years") +
                                      " Years "}
                                </span>
                              </span>
                              <span className="gender">
                                Gender:{" "}
                                <span>
                                  {" "}
                                  {value?.User?.gender
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    value?.User?.gender
                                      ?.substr(1)
                                      .toLowerCase()}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <table class="table table-striped mt-2">
                          <tbody>
                            <tr>
                              <td>
                                Target Cannabinoid:{" "}
                                <span> {value?.targetCannabinoid}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Tarpenes:{" "}
                                <span>
                                  {" "}
                                  {value?.terpenes
                                    ?.map((item) => item?.value)
                                    ?.join()}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Target Dose:{" "}
                                <span>
                                  {" "}
                                  {value?.dailyDose} {value?.dailyDoseUnit}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Frequency:{" "}
                                <span>
                                  {" "}
                                  {value?.frequency} x {value?.frequencyUnit}
                                </span>
                              </td>
                            </tr>
                            {value?.ShoopDoses?.map((dailyDose, index) => (
                              <tr>
                                <td>
                                  Daily Dose {index + 1}: {dailyDose?.dose}{" "}
                                  <span>
                                    {" "}
                                    {dailyDose?.doseUnit} {dailyDose?.doseTime}{" "}
                                  </span>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td>
                                Ingestion Method:{" "}
                                <span> {value?.ingestionMethod}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
                {
                  this.state.importShoop?.length === 0 ?   <h3 className="font-italic text-center">
                  <strong className="text-secondary">No available Shoop fits the patient condition</strong>
                </h3>  : "" 
                }
                 {this.state.visible < this.state.importShoop.length && (
            <div class="container load-more">
              <div class="row">
                <div class="col col-lg-7 offset-lg-3 text-center">
                  <button
                    onClick={this.loadMore}
                    type="button"
                    className="btn btn-primary submit mb-4"
                  >
                    Load more
                  </button>
                </div>
              </div>
            </div>
          )}
              </Modal.Body>

              {/* <Modal.Footer className="verify-modal-footer">
                  <button
                    type="button"
                    className="btn btn-white verify-button border-radius py-1  px-3"
                   
                  >
                    Cancel{" "}
                  </button>
                </Modal.Footer> */}
            </form>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    importShoops: store.importShoops.importShoops,
    name: store.importShoops.name
  };
};

export default connect(mapStateToProps)(ImportShoop);
