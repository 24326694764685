import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { environment } from "../../../../environment";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import draftstore from "../../../../store/draftStore";
import { connect } from "react-redux";

import { draftShoops } from "../../../../actions/draftShoopActions";

export class DraftShoop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      userID: this.props.userID,

      dosingHistory: [],
      visible: 2,
      showButton: [],
      shoopData: this.props.shoopData,
      disabled: false

    };
  }

  loadMore = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 2 };
    });
  };

  componentDidMount() {
    this.setState({
      dosingHistory: this.props.dosingHistory,
      userId: this.props.userID,
    });
    this.draftShoops(this.props.dosingHistory)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dosingHistory !== this.props.dosingHistory) {
      this.setState({
        dosingHistory: this.props.dosingHistory,
      })
      this.draftShoops(this.props.dosingHistory)

    }
  }


  draftShoops(draftShoops) {
    const option = [];
    draftShoops.forEach((value, index) => {
      let productData = {
        buttonName: "Edit to Shoop",
        btnClassName: "btn-primary",
        disabled: false,
      };
      option.push(productData);
      this.setState({
        showButton: option,
      });
    });
  }


  recomendShoop = async (id) => {
    const params = {
      shoopId: id,
    };
    await axios
      .post(environment.endPoint + "physician/shoop/send", params, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status !== undefined) {
          const dosingHisotry = this.state.dosingHistory.map(x => {
            return x.id;
          }).indexOf(id);
          this.onClose();
          this.state.dosingHistory.splice(dosingHisotry, 1);
          this.setState({
            dosingHisotry: dosingHisotry
          })
          // this.getDosingHistory()
          document.getElementById("dosingShoop").click()
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch((e) => { });
  };

  addTopShoop = (index) => {
    const data = this.state.dosingHistory[index];
    let shoopDoses = [];
    let showButtonData = this.state.showButton;
    shoopDoses = data.ShoopDoses;
    const shoopData = this.state.shoopData;
    shoopData.push({
      targetCannabinoid: data.targetCannabinoid,
      terpenes: data.terpenes,
      dailyDose: data.dailyDose,
      dailyDoseUnit: data.dailyDoseUnit,
      note: data.note,
      frequency: data.frequency,
      frequencyUnit: data.frequencyUnit,
      ingestionMethod: data.ingestionMethod,
      doses: shoopDoses,
      userId: this.props.userID,
      shoopId: data.id,
    });

    draftstore.dispatch(draftShoops(shoopData));
    toast.success("Draft Shoop Added Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });

    showButtonData[index].buttonName = "Draft Shoop Added";
    showButtonData[index].btnClassName = "import-btn-success";
    showButtonData[index].disabled = true;
    this.setState({
      showButton: showButtonData,
      disabled: true
    });
    this.onClose()
  }



  onClose = () => {
    this.props.onHideModal();
    this.setState({
      shoopData: []
    })
  };


  render() {
    return (
      <div>
        <div className="import-shoop-start">
          <Modal
            show={this.props.addModalDraftShoopShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-verify-modal import-shoop-start"
          >
            <form>
              <Modal.Header>
                <h4 class="modal-title">Draft Shoop</h4>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  onClick={() => this.onClose()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                {this.state.dosingHistory
                  ?.slice(0, this.state.visible)
                  ?.map((value, index) => {
                    return (
                      <div class="card fade-in">
                        <div class="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="daignose">
                                <h5>
                                  Primary Diagnosis:{" "}
                                  <span>
                                    {" "}
                                    {value?.User?.Diagnose?.DignoseList?.name
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      value?.User?.Diagnose?.DignoseList?.name
                                        ?.substr(1)
                                        .toLowerCase()}
                                  </span>
                                </h5>
                              </div>
                              <div className="mdcn">
                                <p>{value?.targetCannabinoid}</p>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="send-btn">
                                {/* {this.state.showButton[index].btnClassName} */}

                                <button
                                  type="button"
                                  disabled={
                                    this.state.showButton[index].disabled
                                      ? true
                                      : false
                                  }
                                  class={`btn import-btn-success`}
                                  onClick={() => this.addTopShoop(index)}
                                >
                                  Edit Draft Shoop
                                    </button>
                              </div>
                            </div>

                            <div className="col-lg-2">
                              <div className="send-btn">
                                <button
                                  onClick={() =>
                                    this.recomendShoop(value?.id)
                                  }
                                  disabled={
                                    value?.isReady === true ? true : false
                                  }
                                  type="button"
                                  class="btn btn-primary px-4"
                                >
                                  Send
                                    </button>
                              </div>
                            </div>



                          </div>
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <div className="mdcn-status">
                                <p>
                                  Created Date:{" "}
                                  <span className="text-primary">
                                    {" "}
                                    {value?.createdAt
                                      ? moment(value?.createdAt).format(
                                        "MM-DD-YYYY"
                                      )
                                      : "####"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="age-gender">
                                <span className="age">
                                  Age:{" "}
                                  <span>
                                    {" "}
                                    {moment().diff(value?.User?.dob, "years") ===
                                      0
                                      ? moment().diff(
                                        value?.User?.dob,
                                        "months"
                                      ) +
                                      " Months " +
                                      moment().diff(value?.User?.dob, "days") +
                                      " Days "
                                      : moment().diff(value?.User?.dob, "years") +
                                      " Years "}
                                  </span>
                                </span>
                                <span className="gender">
                                  Gender:{" "}
                                  <span>
                                    {" "}
                                    {value?.User?.gender
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      value?.User?.gender
                                        ?.substr(1)
                                        .toLowerCase()}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <table class="table table-striped mt-2">
                            <tbody>
                              <tr>
                                <td>
                                  Target Cannabinoid:{" "}
                                  <span> {value?.targetCannabinoid}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Tarpenes:{" "}
                                  <span>
                                    {" "}
                                    {value?.terpenes
                                      ?.map((item) => item?.value)
                                      ?.join()}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Target Dose:{" "}
                                  <span>
                                    {" "}
                                    {value?.dailyDose} {value?.dailyDoseUnit}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Frequency:{" "}
                                  <span>
                                    {" "}
                                    {value?.frequency} x {value?.frequencyUnit}
                                  </span>
                                </td>
                              </tr>
                              {value?.ShoopDoses?.map((dailyDose, index) => (
                                <tr>
                                  <td>
                                    Daily Dose {index + 1}: {dailyDose?.dose}{" "}
                                    <span>
                                      {" "}
                                      {dailyDose?.doseUnit} {dailyDose?.doseTime}{" "}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td>
                                  Ingestion Method:{" "}
                                  <span> {value?.ingestionMethod}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Notes:{" "}
                                  <span> {value?.note}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                {
                  this.state.dosingHistory?.length === 0 ? <h3 className="font-italic text-center">
                    <strong className="text-secondary">No Draft Shoop added Yet</strong>
                  </h3> : ""
                }
                {this.state.visible < this.state.dosingHistory.length && (
                  <div class="container load-more">
                    <div class="row">
                      <div class="col col-lg-7 offset-lg-3 text-center">
                        <button
                          onClick={this.loadMore}
                          type="button"
                          className="btn btn-primary submit mb-4"
                        >
                          Load more
                  </button>
                      </div>
                    </div>
                  </div>
                )}
              </Modal.Body>


            </form>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (draftstore) => {
  return {
    draftShoops: draftstore.draftShoops,
    name: draftstore.name
  };
};

export default connect(mapStateToProps)(DraftShoop);
