import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Validation from "../common/util";
import AuthService from "../../services/authServices";
import { toast } from "react-toastify";
import Verify from "../auth/registration/modal/Verify";

class login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      fingerprint: "",
      fields: {},
      errors: {},
      platform: "web",
      type: "password",
      addModalShow: false,
      token: "",
      refreshToken: "",
      login: "",
      phone: "",
      role: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
  }

  componentDidMount() { }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  componentDidUpdate() {
    // console.log(this.props.value)
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      errors: Validation.handleValidation(this.state.fields, "login").error,
    });
    if (Validation.handleValidation(this.state.fields, "login").formIsValid) {
      const fingerPrint = localStorage.getItem("fingerPrint");
      const params = {
        email: this.state.fields.email,
        password: this.state.fields.password,
        fingerprint: fingerPrint,
        platform: "web",
      };
      AuthService.login(params)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              token: response.data.data.tokens.accessToken,
              refreshToken: response.data.data.tokens.refreshToken,
              login: response.data.data.user,
              phone: response.data.data.user.phone,
              role: response.data.data.user.role.name,
            });
            const search = this.props.location.search;

            if (response.data.data.user.role.name === "patient") {
              AuthService.setCurrentUser(response.data.data.user);
              AuthService.setUserRole(response.data.data.user.role);
              if (response.data.data.user.twoFA === false) {
                localStorage.setItem("token", this.state.token);
                localStorage.setItem("refreshToken", this.state.refreshToken);
                this.props.userHandler(this.state.login, true);
                this.props.userHandlerRole("patient");
                if (this.props.location.search.includes("nextpath")) {
                  const params = new URLSearchParams(search);
                  const redirectPath = params.get('nextpath');
                  this.props.history.push(redirectPath);
                  toast.success("Login Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                  });
                } else {
                  this.props.history.push("/dashboard");
                  // toast.success("Login Successfully", {
                  //   position: toast.POSITION.TOP_RIGHT,
                  //   autoClose: 1000,
                  // });
                }
              } else {
                this.setState({ addModalShow: true });
              }
            } else if (response.data.data.user.role.name === "physician") {
              AuthService.setCurrentUser(response.data.data.user);
              AuthService.setUserRole(response.data.data.user.role);
              if(response.data.data.user.twoFA === false) {
                localStorage.setItem("token", this.state.token);
                localStorage.setItem("refreshToken", this.state.refreshToken);
                this.props.userHandler(this.state.login, true);
                this.props.userHandlerRole("physician");
              } else {
                this.setState({ addModalShow: true });
              }
            } else if (response.data.data.user.role.name === "admin") {
              toast.error("You are not authorized to login", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }

          }
        })
        .catch((e) => {
          if (e.response.status !== undefined) {
            if (e.response.status === 400) {
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        });
    }
  }

  handleUserInputChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });

    return (
      <div className="auth-form mb-5">
        <form onSubmit={this.onSubmit}>
          <div className="custom-card">
            <h3 className="text-center stepper-form-headings mt-5">Login</h3>
            <div className="row mb-4 mt-4">
              <div className="col-lg-12 col-12">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control py-4"
                    placeholder="Email Address"
                    onChange={this.handleUserInputChange.bind(this, "email")}
                    value={this.state.fields.email}
                    type="text"
                  />
                  <label className="label-helper py-2 px-1">
                    Email Address <strong className="text-danger">*</strong>
                  </label>
                  <span className="text-danger">
                    {this.state.errors["email"]}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="custom-form-row">
                  <input
                    className="form-control custom-form-control py-4"
                    placeholder="Password"
                    onChange={this.handleUserInputChange.bind(this, "password")}
                    value={this.state.fields.password}
                    type={this.state.type}
                  />
                  <label className="label-helper py-2 px-1">
                    Password <strong className="text-danger">*</strong>
                  </label>
                  <span onClick={this.showHide}>
                    {this.state.type === "password" ? (
                      <i
                        className="fa fa-2x fa-eye-slash "
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-2x fa-eye " aria-hidden="true"></i>
                    )}
                  </span>
                </div>
                <span className="text-danger">
                  {this.state.errors["password"]}
                </span>
              </div>
            </div>

            <Verify
              show={this.state.addModalShow}
              redirect={() => {
                const search = this.props.location.search;
                if (this.state.role === "patient") {
                  localStorage.setItem("token", this.state.token);
                  localStorage.setItem("refreshToken", this.state.refreshToken);
                  this.props.userHandler(this.state.login, true);
                  this.props.userHandlerRole("patient");

                  if (this.props.location.search.includes("nextpath")) {
                    const params = new URLSearchParams(search);
                    const redirectPath = params.get('nextpath');
                    this.props.history.push(redirectPath);
                  } else {
                    this.props.history.push("/dashboard");
                  }

                } else if (this.state.role === "physician") {
                  localStorage.setItem("token", this.state.token);
                  localStorage.setItem("refreshToken", this.state.refreshToken);
                  this.props.userHandler(this.state.login, true);
                  this.props.userHandlerRole("physician");

                  if (this.props.location.search.includes("nextpath")) {
                    const params = new URLSearchParams(search);
                    const redirectPath = params.get('nextpath');
                    this.props.history.push(redirectPath);
                  } else {
                    this.props.history.push("/physician/dashboard");
                  }
                }
              }}
              phone={this.state.phone}
              onHide={addModalClose}
              token={this.state.token}
              refreshToken={this.state.refreshToken}
              login={true}
              fields={this.state.fields}
              textHeading="Please enter the OTP we sms you"
              textBreakHeading="on your linked phone number, Thanks"
            />

            <div className="row mt-4 flex-row-reverse">
              <div className=" col-lg-6 col-xs-12">
                <div className="form-check">
                  <NavLink className="form-check-label" to="/forgotPassword">
                    Forgot Password?
                  </NavLink>
                </div>
              </div>
              <div className=" col-lg-6 col-xs-12">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" for="exampleCheck1">
                    Remember Me
                  </label>
                </div>
              </div>
            </div>

            <div className="patient-login-btn">
              <div className="mt-4">
                <button className="btn btn-primary border-radius py-2  px-4">
                  Login
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default login;
